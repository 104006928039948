.headingDiv{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 8px;
}

.headingDiv img{
    margin-right: 10px;
    background-color: #ED1E24;
    border-radius: 100%;
    padding: 5px;
    height: 20px;
}

.headingDiv h4{
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

@media (max-width: 580px){
    .headingDiv h4{
        font-size: 14px;
        font-weight: 500;
    }
}