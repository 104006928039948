.membersListContainer {
  margin-top: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  flex-direction: row;
  border-radius: 10px;
}
.memberContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 26px 10px 26px;
  justify-content: space-between;
  align-items: center;
}
.memberImage {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  border-radius: 25px;
}
.memberHr {
  border: 1px solid #e5e5e5;
}
.memberDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.memberImageContainer {
  display: flex;
  flex-direction: column;
}
.memberPriv {
  border-radius: 5px;
  border: 1px solid #ED1E24;
  height: fit-content;
}
.memberPriv {
  border-radius: 5px;
  border: 1px solid #ED1E24;
  height: fit-content;
}
.actionBody {
  position: relative;
}
.actionMenu {
  list-style: none;
  width: 120px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  margin: 0;
  padding: 0 10px;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: 3px 2px 4px 2px #55555529 !important;
}
