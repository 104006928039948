.main_container {
    margin-top: 90px;
    height: 100%;
    width: 100%;
  }
  
  .back_btn {
    color: #858585;
    font-weight: 400;
    padding: 20px;
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
  }
  
  .feed_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 1018px;
    padding-top: 12px;
  }
  
  .title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .dueDate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
  }
  
  .dropDown ul {
    list-style: none;
    padding: 1px 0px;
  }
  
  .submissions_container {
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    padding: 20px 0;
    margin: 15px auto;
    margin-bottom: 0%;
    width: 130vh;
    /* for content */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .choice {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Montserrat;
  }
  
  .choice > .status {
    margin: 0 15px;
  }
  
  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
    cursor: pointer;
  }
  
  .count {
    font-size: 2rem;
  }
  
  .options {
    font-size: 0.9rem;
    color: #7b7b7b;
  }
  
  .bottom_container {
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .student_list_container {
    /* box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px; */
    /* padding: 0px 10px; */
    margin: 10px auto;
    /* margin-top: 15px; */
    width: 100%;
    font-family: Montserrat;
    margin-top: 0;
    max-height: 290px;
    /* overflow-y: scroll; */
    min-height: 210px;
  }
  
  .inner_list_container{
    max-height: 220px;
    min-height: 220px;
    overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  .inner_list_container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  
  .return_btn {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
  }
  
  .student_list_header {
    display: flex;
    /* justify-content: space-between; */
    color: #858585;
    font-family: Montserrat;
    font-weight: 400;
  
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px;
    align-items: center;
    /* width: 95%; */
  }
  
  .student_list_header_quiz {
    display: flex;
    justify-content: space-between;
    color: #858585;
    font-family: Montserrat;
    font-weight: 400;
  
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px;
    align-items: center;
    /* width: 95%; */
  }
  
  .student_list_heading {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .student_list_date {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .student_list_rmk {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .student_list_grade {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .student_list_assign {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .checkbox_round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #767676;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0 20px 0 0;
  }
  
  .checkbox_round:checked {
    background-color: #ED1E24;
    border: 1px solid #ED1E24;
  }
  
  .unassign_dropdown {
    /* height: 50px; */
    cursor: pointer;
    margin-left: 10px;
  }
  
  /* Not used */
  .assignment_list {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #3f3f411a;
    margin-top: 15px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .student_list_combo {
    display: flex;
    width: 100%;
  }
  .view_ass_btn_span_mobile {
    display: none;
  }
  .view_ass_btn_span {
    margin-right: 10px;
  }
  .no_std_msg {
    height: 210px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7b7b7b;
    font-size: 14px;
    justify-content: center;
  }
  @media (min-width: 800px) and (max-width: 1018px) {
    .main_container {
      margin-top: 94px !important;
    }
  }
  
  @media (max-width: 1018px) {
    .feed_container {
      width: 90%;
    }
    .main_container {
      margin-top: 0;
    }
    .student_list_header {
      display: none;
    }
    .student_list_header_quiz {
      display: none;
    }
    .title_container {
      flex-direction: column;
      align-items: flex-start;
    }
    .dueDate {
      margin-top: 15px;
    }
    .view_ass_btn_span {
      display: none;
    }
    .choice > .status {
      margin: 0 6px;
    }
    .view_ass_btn_span_mobile {
      display: inline-block;
    }
    .submissions_container > div {
      margin: 0 10px;
    }
    .back_btn{
      display: none;
    }
  }
  @media (max-width: 1099px) {
    .bottom_container {
      margin-bottom: 85px;
    }
  }
  
  @media (max-width: 800px) {
    .student_list_container {
      max-height: none;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 800px) {
    .inner_list_container {
      max-height: none;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 420px) {
    .status{
      max-width: 20%;
    }
    .count {
      font-size: 1.75rem;
    }
    .options {
      font-size: 0.75rem;
      overflow: hidden;
      width:99%;
      white-space: nowrap;
      text-overflow: ellipsis;   
    }
    .choice{
      margin-left:auto;
      max-width: 68%;
    }
  }
  
  @media (max-width: 338px) {
    .view_ass_btn_span_mobile{
      display: none;
    }
  }
  
  .loader_style{
    text-align: center;
     margin-top: 200px
  }

  @media (max-width: 580px){
    .feed_container {
      width: 95%;
    }
    .count {
      font-size: 18px;
      font-weight: 500;
    }
    .no_std_msg {
      padding: 5px;
    }
    .return_btn {
      padding: 25px;
    }
  }
  
  