@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap");
/* * {
    max-width: 100%;
    height: 100%;
} */

/* #topviewable {
    content: "";
    background: url(./watermark/Chalkmate_Watermark.svg);
    opacity: 0.6;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
} */

#topviewable,
#main {
  font-family: "Montserrat", sans-serif;
}

#vw-profmail {
  font-family: "Montserrat", sans-serif;
}

#vw-prsc {
  flex-direction: "row";
}

#btn-ftr {
  color: #ED1E24;
}

#btn-ftr:hover {
  background-color: #ED1E24;
  transition: background-color 150ms linear;
  cursor: pointer;
}

#btn-ftr:hover p {
  color: #fff;
  z-index: 100;
}

#leftfeed {
  display: none;
}

@media print {
  @page {
      /* size: A3 portrait; */
      size: 15in 20in;
      margin: 2.25rem 3rem  !important;
      /* margin: 25mm 25mm 25mm 25mm; */
  }
  html, body {
    height: 99%;    
}

  body {
      padding: 4.65rem;
  }

  #leftfeed {
    display: block;
  }

  #pagebreak{ 
    break-after: always;
  }
}