.Home-Wrapper{
    padding-top: 3.75rem;
    /* padding-left:200px;
    padding-right:200px; */
    /* padding-left: 8%;
    padding-right: 8%; */
    box-sizing: border-box;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;
    /* background-image: url("../../../animation/Hero/bg1.svg"); */
    /* background-image: url("../../../animation/Hero/bgDesktop.svg"); */
    background-size: contain;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.subLayout{
    padding-left:180px;
    padding-right:180px;
}
.Home-Section1-Wrapper {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    /* border: 2px solid red; */
    clip-path: url(#clip-path-section1);  
    /* padding-top: 10rem; */
    padding-left:10%;
    padding-right:8%;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
    
}

.hero-image {
    object-fit: contain;
    width: 100%;
}

.Home-Section1 > h1{
    font-style: normal;
    font-weight: 500;
    font-size: 4.375rem;
    text-align: center;
    color: #000000;
    margin-bottom:24px;
}

.Home-Section1 > p{
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 175%;
    text-align: center;
    color: #000000;

} 

.Home-Section1-buttons{
    margin-top:24px;
    display: flex;
    justify-content: center;
    gap:12px;
}

.Home-section1-btn1
,.Home-section1-btn2{
    background: #000000;
    border: 2px solid #000000;
    border-radius: 40px;
    width: 180px;
    height:50px;
    color:white;
    font-weight: 700;   
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
}

.Home-section1-btn1 {
    background: linear-gradient(90.00deg, rgb(237, 30, 36) -30.514%,rgb(238, 130, 130) 116.019%);
    border: none;
}

.Home-section1-btn2{
    border: 2px solid #000000;
    border-radius: 40px;
    background-color: transparent;
    color:#000000;
}

.Home-section2{
    margin-top:48px;
    margin-bottom:48px;
    width: 85%;
    height: 85%;
}

.Home-section3{
   position: relative;
   height:60vh;
   display: flex;
   justify-content: center;
   align-items: center;

}

.Home-section3-group{
    background: #F8F8F8;
    border-radius: 32px;
    display: flex;
    gap:12px;
    padding:40px;

}
.Home-section3-stable{
    /* z-index:5; */
    width:40%;
}
.Home-section3-stable >h4{
    font-weight: 700;
    font-size: 40px;
    line-height: 105%;
}
.Home-section3-stable >p{
    font-weight: 400;
    font-size: 16px;
    line-height: 166.5%;
    margin-top:12px;
}
.Home-section3-adCards{
    /* z-index: 4; */
    position:absolute;
    width: 78%;
    background: transparent;
    overflow-x: scroll !important;
    left:42%;
    display: flex;
    gap:12px;
}
/* .vertical-line{
    height:10;
    width: 20px;
} */


.Home-section3-adCards::-webkit-scrollbar {
    height:0px;
  }

.new_home_section_3 {
    padding: 0 10%;
}

.new_home_section_title {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 215%;
    letter-spacing: 0%;
    text-align: center;
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 6.5%;
    /* justify-content: space-evenly;
    align-items: baseline; */
}

.card {
    box-sizing: border-box;
    padding: 1.875rem;
    border: 1px solid rgb(255, 207, 208);
    border-radius: 1rem;
    box-shadow: 2px 2px 30px 1px rgba(0, 0, 0, 0.2);
    /* Gradient homepage */
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.card:hover {
    background: linear-gradient(180.78deg, rgb(239, 63, 63) -27.067%,rgb(255, 255, 255) 116.436%);
}

.card_thumbnail {
    width: 9.375rem;
    aspect-ratio: 1 / 1;
}

.card-main-text {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    line-height: 105%;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 1.75rem;
    text-transform: capitalize;
}

.card-sub-text {
    color: rgb(30, 30, 30);
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 145.5%;
    letter-spacing: 0%;
    text-align: center;
    margin-top: .75rem;
    /* margin-top: auto; */
}

.Home-section4{
    height:auto;
    position: relative;
    margin-top:48px;
    width: 100%;
    text-align: center;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    clip-path: url(#clip-path-section4);  
    padding-top: 10rem;
    padding-left:10%;
    padding-right:8%;
    padding-bottom: 0;
}

.homepage-video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    clip-path: url(#clip-path-section4);  
    padding-top: 10rem;
    padding-left:10%;
    padding-right:8%;

}

.explore-label {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.13rem;
    font-weight: 700;
    line-height: 215%;
    letter-spacing: 0%;
    text-align: left;
    margin-top: 12rem;
}

.video-container {
    margin-top: 1rem;
    margin-bottom: 11rem;
    width: 60%;
}

.home-section5-new {
    display: flex;
    flex-direction: column;
    align-items: center;        
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    clip-path: url(#clip-path-section5);  
    padding-top: 15rem;
    padding-left:10%;
    padding-right:8%;

}

.svg-section4, .svg-section1, .svg-section5 {
    position: absolute;
    width: 0;
    height: 0;
}



.Home-section4-wrapper{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-left:-260px;
    background-position: right;
    background-image:url("../../../animation/Hero/bg2.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.Home-section4-video h3{
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 215%;
    color: #000000;
    margin-top: 15.625rem;
}

.Home-section4-video>p{
    margin-top:24px;
    width:100%;
    height:400px;
    border-radius: 56px;
    background:grey;
}

.video-div{
    width: 100%;
}

.video{
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 30px;
    /* margin: 30px auto;  */
    width: 100% !important;
    height: 100% !important;
    padding:0;
}
.video > video{
    border-radius: 20px;
}
.play-btn{
    position: absolute;
    top: -3px;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-section5{
    margin-top: 120px;
    position: relative;
}
.Home-section5-bg{
    position:absolute;
    width:100vw;
    height:75vh;
    background-position: contain;
    background-repeat: no-repeat;
    margin-left:-260px;
    background-repeat: no-repeat;
}   
.bg-1{
    background: url("../../../animation/Hero/bg3.svg");
    background-position:left;
}

.bg-2{
    background: url("../../../animation/Hero/bg5.svg");
    background-position:right;
}

.bg-3{
    background: url("../../../animation/Hero/bg5.svg");
    background-position:left;
}

.bg-4{
    background: url("../../../animation/Hero/bg5.svg");
    background-position:right;
}

.bg-5{
    background: url("../../../animation/Hero/bg6.svg");
    background-position:right;
}
.Home-section6{
    margin-bottom: 10rem;

    display: flex;
    flex-direction: column;
    align-items: center;
        
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    clip-path: url(#clip-path-section5);  
    padding-top: 15rem;
    padding-left:10%;
    padding-right:8%;
}

.Home-section6>h4{
    /* font-weight: 700;
    font-size: 40px;
    line-height: 196.5%;
    text-align: center; */
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.13rem;
    font-weight: 700;
    line-height: 196.5%;
    letter-spacing: 0%;
    text-align: center;
}

.Home-section6-cards{
    margin-top:24px;
    display: flex;
    justify-content: center;
    align-items:center;
    gap:12px;
    max-width: 100%;
    width: 100%;
}

.tabs-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 40vw;
}

.Home-section6-cards-part1{
    max-width: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap:12px;
}
.Home-section6-cards-part2{
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Hero-section7{
    height:35vh;
    padding:0 80px;
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
    width: 75%;
    /* background: url("../../../animation/Hero/subsBg.svg"); */
    /* background-position: left; */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    margin-top:60px;
    margin: auto;
    margin-bottom: 6rem;
    border-radius: 4.5rem;
    box-shadow: -0.06rem 0.25rem 0.94rem 0.13rem rgba(0, 0, 0, 0.1);
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
}

.Hero-section7 > h1{
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 148%;
    text-align: center;
}

.Hero-section7-subs {
    
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top:40px;
    gap:12px;
    width: 90%;
    height: 3.45rem;
}

.Hero-section7-subs > input{
    width: 40%;
    padding: 16px 40px;
    background: #FFFFFF;
    border: 1px solid #A6A6A6;
    border-radius: 40px;
    font-size:20px;
}

.Hero-section7-subs > input:focus{
    outline:none !important;
    border:1px solid black !important;
}

.Hero-section7-subs > input::placeholder{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #A6A6A6;
    text-align: center;
}

.Hero-section7-subs > button{
    background: #000000;
    text-transform: uppercase;
    width: 12rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    cursor: pointer;
    border:none;
    color: #FFFFFF;
    border-radius: 40px;

    border-radius: 4.5rem;

    box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
    /* Linear */
    background: linear-gradient(90.00deg, rgb(237, 30, 36) -30.514%,rgb(238, 130, 130) 116.019%);

}


@media only screen and (max-width: 1450px) {
    /* .Home-Wrapper{
        padding-top: 180px;
        padding-left:180px;
        padding-right:180px;
    } */
    .Home-section5-bg{
        margin-left:-180px;
    }
    .Home-section4-wrapper{
        margin-left:-180px;
    }
    .Hero-section7{
        padding:0 40px;
    }
    .Home-Section1-Wrapper {
        padding-bottom: 5rem;
        overflow: hidden;
    }
}
/* ############################ Responsive 1024 ############################ */

@media only screen and (max-width: 1024px) {
    .Home-Wrapper{
        /* padding-top: 210px;
        padding-left:130px;
        padding-right:130px; */
    }
    
    .subLayout{
        padding-left:130px;
        padding-right:130px;
    }
    
    .Home-Section1 > h1{
        font-weight: 700;
        font-size: 56px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 160%;
    } 
    
    .Home-Section1-buttons{
        margin-top:12px;
        gap:12px;
    }
    
    .Home-section1-btn1,
    .Home-section1-btn2{
        width: 180px;
        height:50px;

    }
    
    
    .Home-section2{
        height:100%;
        width: 100%;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        gap:12px;
        padding:30px;
    }

    .Home-section3-stable >h4{
        font-size: 30px;
        line-height: 105%;
    }
    .Home-section3-stable >p{
        font-size: 14px;
        line-height: 166.5%;
        /* margin-top:12px; */
    }
    
    .Home-section4{
        height:auto;
        margin-top:44px;
    }
    
    .Home-section4-video h3{
        font-size: 32px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
        border-radius: 56px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        border-radius: 20px;
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
        
    }
    
    .Home-section6>h4{
        font-size: 40px;
        line-height: 196.5%;
        text-align: center;
    }
    
    .Home-section6-cards{
        margin-top:24px;
        display: flex;
        justify-content: center;
        align-items:center;
        gap:12px;
        max-width: 100%;
        width: 100%;
    }
    
    .Home-section6-cards-part1{
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .Home-section6-cards-part2{
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Hero-section7{
        height:auto;
        padding:0 0;
        display: flex;
        flex-direction:column ;
        align-items: center;
        justify-content: center;
        margin-top:40px;

    }
    .Hero-section7 > h1{
        font-size: 48px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        display: flex;
        margin-top:32px;
        width: 90%;
        height: 70px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        font-size:18px;
        padding: 16px 40px;
    }

    
    .Hero-section7-subs > input::placeholder{
        font-size: 20px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 250px;
        font-size: 20px;
        line-height: 29px;
    }
    
  }

@media only screen and (max-width: 950px) {
    .Home-Section1-Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 80vh;

        background-image: url("../../../animation/mobile_bgs/bg1.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        overflow-x: hidden;
        max-width:100%;
        /* clip-path: none; */
    }

    .svg-section4, .svg-section1, .svg-section5 {
        display: none;
    }

    .explore-label {
        margin-top: 1rem;
    }

    .homepage-video-section, .home-section5-new, .Home-section6 {
        /* background-image: url("../../../animation/mobile_bgs/bg2.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        overflow-x: hidden;
        max-width:100%; */
        clip-path: none;
    }
    

    /* .Home-Section1  {
        padding-top: 2rem;
        height: 30vh;
    } */

    .Home-Wrapper{
        padding-top: 5rem;
        /* padding-left:80px;
        padding-right:80px; */
    }
    
    .subLayout{
        padding-left:80px;
        padding-right:80px;
    }

    .Home-section5-bg{
        margin-left:-100px;
        height:60vh;
    }
    .Home-section4-wrapper{
        margin-left:-100px;
    }
    
    .Home-Section1 > h1{
        font-size: 48px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 180%;
    } 
    
    .Home-Section1-buttons{
        margin-top:12px;
        gap:12px;
    }
    
    .Home-section1-btn2,
    .Home-section1-btn1{
        width: 180px;
        height:50px;
    }
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        gap:12px;
        padding:25px;
    }

    .Home-section3-stable{
        width:60%;
    }
    .Home-section3-stable>h4{
        font-size: 1.25rem;
    }
    .Home-section3-adCards{
        left:60%;
    }

    .Home-section4{
        height:auto;
        margin-top:44px;
    }
    
    .Home-section4-video h3{
        font-size: 32px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 60%;
    }
    
    .video{
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
    }
    
    .Home-section6>h4{
        font-size: 40px;
        line-height: 196.5%;
    }
    
    .Home-section6-cards{
        margin-top:24px;
        gap:12px;
        max-width: 100%;
        width: 100%;
    }
    
    .Home-section6-cards-part1{
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .Home-section6-cards-part2{
        max-width: 50%;
    }
    
    .Hero-section7{
        height:auto;
    }
    .Hero-section7 > h1{
        font-size: 48px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        margin-top:32px;
        width: 90%;
        height: 55px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        padding: 16px 40px;
        font-size:16px;

    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: 20px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 250px;
        font-size: 20px;
        line-height: 29px;
    
    }

    .Home-Section1  {
        padding-top: 2rem;
        height: 34vh !important;
    }

    .new_home_section_title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }

    .Home-section6 {
        padding: 0;
        padding-top: 4rem;
    }

    .tabs-wrapper {
        width: 90vw;
    }

    

  }

@media only screen and (max-width: 650px) {
    /* .Home-Wrapper{
        padding-top: 180px;
        padding-left:50px;
        padding-right:50px;
    } */
    
    .subLayout{
        padding-left:50px;
        padding-right:50px;
    }
    .Home-section5-bg{
        margin-left:-80px;
        height:80vh;

    }
    .Home-section4-wrapper{
        margin-left:-80px;
    }
    
    .Home-Section1 > h1{
        font-size: 40px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 180%;
    } 
    
    .Home-Section1-buttons{
        flex-direction: column;
        align-items: center;
        margin-top:12px;
        gap:12px;
    }

    .Home-Section1-buttons-link{
        width: 100%;
    }

    .Home-section1-btn2,
    .Home-section1-btn1{
        width: 100%;
        height:50px;
    }
    
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
        height: 100%;
        width: 100vw;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        display: flex;
        gap:12px;
        padding:20px;
    }

    .Home-section3-stable{
        /* z-index:5; */
        width:60%;
    }
    .Home-section3-stable>h4{
        font-size: 24px;
    }
    .Home-section3-stable>p{
        /* font-size: */
    }
    .Home-section3-adCards{
        left:60%;
    }
    
    .Home-section4{
        height:auto;
        margin-top:34px;
    }
    
    .Home-section4-video h3{
        font-size: 24px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        margin-top: 30px;
        padding: 0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
    }
    
    .Home-section6>h4{
        font-size: 32px;
        line-height: 120%;
    }
    
    .Home-section6-cards{
        flex-direction: column;
        gap:12px;
    }
    
    .Home-section6-cards-part2{
        max-width: 100%;
    }
    
    .Hero-section7{
        height:auto;
        margin-top: 44px;
    }
    .Hero-section7 > h1{
        font-size: 32px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        display: flex;
        margin-top:32px;
        width: 100%;
        height: 55px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        padding: 8px 24px;
    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: 16px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 200px;
        font-size: 16px;
        line-height: 0;
    
    }
  }

/* ----------------- Mobile Resolutions -------------------- */
  
@media only screen and (max-width: 480px) {
    .Home-Wrapper{
        /* padding-top: 150px; */
        /* background: url("../../../animation/Hero/phoneBg.svg"); */
        /* padding-left:30px;
        padding-right:30px; */
    }
    
    .subLayout{
        padding-left:30px;
        padding-right:30px;
    }

    .Home-section5-bg{
        margin-left:-30px;
        height:90vh;

    }
    .Home-section4-wrapper{
        margin-left:-30px;
    }

    .Home-Section1 {
        margin-bottom: 3.125rem;
    }
    
    .Home-Section1 > h1{
        font-size: 2.1875rem;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 12px;
        line-height: 150%;
    } 
    
    .Home-Section1-buttons{
        margin-top:24px;
        gap:12px;
    }
    
    .Home-Section1-buttons button:nth-child(1)
    ,.Home-Section1-buttons button:nth-child(2){
        width: 100%;
        height:46px;
        font-size:12px;
    }
    
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
        height: 100%;
        width: 100vw;
    }
    
    .new_home_section_3{
       margin-top: -4rem;
    }

    .new_home_section_title {
        font-size: 1.85rem;
    }

    .card-main-text {
        font-size: 1.125rem;
    }

    .card-sub-text {
        font-size: .75rem;
    }

    .explore-label {
        font-size: 1.25rem;
    }
    
    .Home-section3-group{
        display: flex;
        gap:12px;
        padding:12px;
    }
    .Home-section3-stable >h4{
        font-size: 16px;
        line-height: 105%;
    }
    .Home-section3-stable >p{
        font-size: 8px;
        line-height: 166.5%;
    }
    
    .Home-section4{
        height:auto;
        margin-top:24px;
    }
    
    .Home-section4-video h3{
        font-size: 20px;
        line-height: 215%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 60px;
    }
    
    .Home-section6{
        width: 100%;
        margin-top:12px;
    }
    
    .Home-section6>h4{
        font-size: 20px;
        line-height: 120%;
    }
    
    .Home-section6-cards{
        flex-direction: column;
    }
    
    .Home-section6-cards-part2{
        max-width: 100%;
    }
    
    .Hero-section7{
        height:auto;
        padding: 1.25rem 1.25rem 1.75rem 1.25rem;
        margin-top:60px;
        border-radius: 1rem;
    }
    .Hero-section7 > h1{
        font-size: 1.5rem;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        margin-top:2rem;
        gap:0.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .Hero-section7-subs > input{
        width: 85%;
        padding: 0px 12px;
        font-size: .75rem;
        padding: 0.6rem .75rem ;
    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: .75rem;
        /* line-height: 15px; */
    }
    
    .Hero-section7-subs > button{
        width: 95px;
        font-size: .75rem;
        padding: .875rem
        /* line-height:0; */
    
    }
    
  }