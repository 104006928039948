.main {
  box-sizing: border-box;
}

.middle_container {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.info_container {
  display: flex;
  align-items: center;
  margin-bottom: 1.75rem;
  text-transform: capitalize;
  width: 100%;
  /* row-gap: 1rem; */
}

.name_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}

.name {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
}

.otw {
  color: rgb(110, 110, 110);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.icon_wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.icon_wrapper > img {
  width: 1.375rem;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5625rem;
}

.title_label {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
}

.value {
  color: rgb(97, 97, 97);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
}
