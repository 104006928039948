.popupModalBox {
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background: #31313150;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.hidePopupModalBox {
  display: none;
}

.main_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 220px; */
  height: fit-content;
  max-height: 430px;
  /* height: fit-content; */
  padding-top: 5px;
  padding: 30px;
  border-radius: 32px;
  width: 50%;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main_container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main_container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add_attachment_text {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 12px;

}

.drop_area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  border: 1px dashed #ED1E24;
  background: #F8FCFA;
  border-radius: 5px;
  flex-direction: column;
}

.drop_area_active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  border: 1px solid #ED1E24;
  background: #F8FCFA;
  border-radius: 5px;
  flex-direction: column;
}

.drop_area input[type='file']{
  opacity: 0;
  cursor: pointer;
  width: 49%;
  height: 14%;
  font-size: 0;
  position: absolute;
}

.drop_area_active input[type='file']{
  opacity: 0;
  cursor: pointer;
  width: 49%;
  height: 14%;
  font-size: 0;
  position: absolute;
}

/* .drop_input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
} */

.upload_icon {
  width: 14px;
  height: 17px;
  /* fill: #ED1E24; */
  padding: 5px;
}

.drag_and_drop_text {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat";
  text-align: center;
}

.drag_active_text {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat";
  text-align: center;
}

.browse_text {
  color: #ED1E24;
}

.supported_files_text {
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #BDBDBD;
  margin-top: 3px;
}

.file_attachments_container {
  margin-top: 15px;
  margin-bottom: 20px;
  /* max-height: 90px;
  overflow-y: scroll;
  min-height: 45px; */
}

add_links_text {
  font-family: "Euclid Square";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.add_links_input_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.input_link {
  border: 1px solid #5555555A;
  padding: 10px;
  font-size: 15px;
  border-radius: 50px;
  width: 83%;
  margin-top: 10px;
}

.add_btn {
  padding: 10px;
  border-radius: 50px;
}

.review_btn {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/*--- Media ---*/

@media (max-width: 1450px) {
  .main_container{
    width: 70%;
  }
}

@media (max-width: 960px){
  .main_container{
    width: 75%;
  }
}

@media (max-width: 400px){
  .main_container{
    width: 80%;
  }
}