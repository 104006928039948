.quiz_outer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.quiz_modal_container {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  min-height: 80vh;
  max-height: 150vh;
  padding-top: 5px;
  padding: 20px;
  border-radius: 25px;
  width: 70vw;
}

.quiz_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quiz_header_one{
  display: flex;
}

.quiz_header_one > img{
  height: 24px;
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: 12px;
}

.quiz_header > img {
  height: 24px;
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  /* line-height: 1.0625rem; */
  /* letter-spacing: 0em; */
  text-align: center;
}

.progress_and_timer_container {
  position: relative;
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.required_label{
  text-transform: lowercase;
  position: absolute;
  color: #E81123;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal; 
  bottom: 8px;
  right: 8px;
}

.grade_label  {
  margin-left: auto;
  margin-right: 10px;
  font-weight: 500;
  font: 1rem;
}

.grade_input_container {
  border-radius: 59px;
  border: 1px solid #A6A6A6;
  padding: 2px 14px;
  display: flex;
}

.grade_input_container > input {
  width: 30px;
  outline: none;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #a9a9a9;
  margin: 2px 0;
}


.progress_container,
.progress_bar,
.timer_container {
  width: 30%;
  /* border: 1px solid blue */
}

.timer_container {
  display: grid;
  grid-template-columns: 0fr 0fr;
  /* align-items: center;
  justify-content: flex-end; */
  width: 20%;
}

.timer_container > img {
  margin-right: 8px;
  margin-left: auto;
}

.time_running_out {
  color: red;
  animation: blinker 1.5s linear infinite;
  font-weight: 600;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.question_container {
  /* display: flex; */
  /* flex-direction: column; */
  box-sizing: border-box;
  /* justify-content: center;
  align-items: flex-start; */
  padding: 32px;
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  margin-top: 1rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.question_header {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #555;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.question_header > span:first-child {
  border-radius: 30px;
  border: 1px solid #dbdbdb;
  padding: 8px 20px;
}

.pagination_container {
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  margin-bottom: 90px;
}

.grade_input_inner_container{
  margin: 3px;
}

@media (max-width: 580px){
  .quiz_outer_container {
    width: 94vw;
  }
  .btn_label {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
  }
  .progress_and_timer_container {
    padding: 15px;
    margin-top: 10px;
  }
  .progress_and_timer_container span{
    letter-spacing: -0.07px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 15px 0px;
  }
  .progress_container{
    font-size: 14px;
    font-weight: 400;
    display: contents;
  }
  .question_header {
    font-size: 15px;
    font-weight: 400;
  }
  .pagination_container {
   padding: 10px;
   margin-bottom: 15px;
  }
  .pagination_container nav{
    margin: 10px 0px;
  }
  .question_container {
    padding: 20px;
    min-height: auto;
  }

.timer_container {
  width: fit-content;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.question_header > span:first-child {
  width: 100%;
  color: #555;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.question_header > span:nth-child(2) {
  display: none;
}

.grade_input_container > input {
  width: 30px;
  outline: none;
  text-align: center;
  margin: 3px 0px 3px 0px;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-bottom: none;
}

.grade_input_container {
  display: flex;
  padding: 3px;
}

.grade_input_inner_container{
  margin: 2.3px 0px 0px 0px;
}

.choice_text{
  color: #555;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.quiz_sub_header{
  display: block;
}

.required_label_mobile{
  float: right;
  text-transform: lowercase;
  color: #E81123;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal; 
}

.quiz_header_one > img{
  height: 15px;
  width: 15px;
  margin-right: 1rem;
  margin-top: 7px;
  cursor: pointer;
}

}

