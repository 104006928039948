.main {
  width: 100%;
  margin-top: 2rem;
}

.label_title {
  color: #434343;
  font-family: Montserrat;
  font-size: 1.125rem;
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.label {
  color: #434343;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: .625rem;
  text-transform: capitalize;
}

.label > span {
  font-weight: 400;
}

.email {
  text-transform: lowercase;
}

@media (max-width: 800px) {

  .label {
    font-size: .875rem;
  }

  .label_title {
    font-size: 1rem;
  }


}
