.container {
  flex: 0.6;
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  /* position: relative; */
  display: flex;
  flex-direction: column;
}

.pic {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.content {
  padding: 25px;
  text-align: center;
  /* text-transform: capitalize; */
}

.content > h2 {
  font: normal normal medium 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.content > p {
  text-align: center;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  padding: 10px;
  max-width: 467px;
  margin: auto;
}

.buttonsContainer {
  margin: auto;
  display: flex;
  gap: 30px;
  /* padding-left: 18px;
  padding-right:18px; */
  padding-bottom: 30px;
}

.button {
  display: flex;
  padding: 16px 30px 16px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  text-align: center;
}

.button:hover {
  background-color: #ED1E24;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .button {
    padding: 15px 25px 15px 25px;
  }
  .buttonsContainer{
    flex-direction: column;
    gap: 15px;
  }
}