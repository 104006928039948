.main {
  display: grid;
  grid-template-columns: 35fr 65fr;
  margin: auto;
  justify-content: center;
  width: 80vw;
  margin-top: 120px;
  margin-bottom: 100px;
}

.left_container {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* background-color: antiquewhite; */
  /* min-height: 150px; */

}

.right_container {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  min-height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 16px;
}

.thumbnail_container {
  margin-bottom: 16px;
  max-width: 95%;
  height: 180px;
  position: relative;
}
.thumbnail_container::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 10px;
  z-index: 1;
}

.thumbnail_container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
 
}

.course_type_label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 20px 8px 0;
}

.course_type_span {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}

.inputTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  width: 92%;
  border: 1px solid rgb(227, 223, 223);
  margin: 0px 0 16px 0;
  /* min-height: 35px; */
  /* height: auto; */
  padding: 5px;
  overflow-x: scroll !important;
}

.inputTags::-webkit-scrollbar {
  display: none;
}

.input_label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;
}

.courseType {
  margin: 25px 0 10px 0;
}

.subtext {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 8px 0;
}

.add_content_label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;

}

.add_section_btn {
  display: flex;
  gap: 0.5rem;
  margin-right: auto;
  margin-bottom: 10px;
  width: fit-content;
}

.btn_div {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  margin-bottom: 20px;
  width: fit-content;
}

.btn_label {
  font-family: "Montserrat";
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}


.loader_style{
  text-align: center;
   margin-top: 200px
}

@media (max-width:800px) {
  .main {
    margin: 12px;
    display: block;
    width: auto;
    margin-bottom: 10vh;
  }

  .left_container {
    display: none;
  }

  .right_container {
    min-height: 201px;
    padding:15px;
  }

  .add_content_label {
    margin: 0px 0 12px 0;
    line-height: normal;
    font-size: 18px;
  }

  .btn_div {
    margin-right: 0px;
    margin-bottom: 0px;
  }

}

@media (max-width:1000px) {
  .main{
    width: 95vw;
  }

}

@media (max-width:500px) {
  .main {
    margin: 12px;
    display: block;
    width: auto;
    margin-bottom: 10vh;
  }

  .left_container {
    display: none;
  }

  .right_container {
    min-height: 0px;
    padding:15px;
  }

  .add_content_label {
    margin: 0px 0 12px 0;
    line-height: normal;
    font-size: 18px;
  }

  .btn_div {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .btn_label{
    font-size: 10px;
    font-weight: 700;
  }

}