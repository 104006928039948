.main {
  /* display: grid; */
  /* min-height: 10rem; */
  /* grid-template-rows: 1fr 2fr 2fr; */
  /* grid-template-columns: 8fr 2fr; */
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  font-family: "Montserrat";
  padding: 20px;
}
.heading{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.paragraph{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.title {
  display: flex;
  grid-column: 1;
  justify-self: start;
  align-self: center;
}

.menu {
  justify-self: end;
  align-self: center;
}

.details {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  color: #707070;

  margin-top: 10px;
  justify-self: start;
  align-self: start;
}

.max_marks {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 15px;

  margin-top: 10px;
  justify-self: end;
  align-self: start;
  white-space: nowrap;
}

.bottom_container {
  
  font-weight: 700;
  font-size: .75rem;
  font-family: 'Montserrat';
  justify-self: start;
  align-self: center;
}

.time, .count {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.time > img, .count>img {
  padding-right: 10px;
  height: 15px;
}

@media (max-width: 580px){
  .main {
    padding: 15px;
    grid-template-rows: 0fr;
    grid-template-columns: 1fr;
  }
  .paragraph{
    display: grid;
    width: 100%;
    justify-content: space-between;
  }
  .details {
    color: #707070;
    /* mb/Caption */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .max_marks {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .count{
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}