.main {
  box-sizing: border-box;
}

.middle_container {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.title_label {
  color: rgb(125, 125, 125);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}

.tag_container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.25rem;
  flex-wrap: wrap;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #7d7d7d;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid #7d7d7d;
  border-radius: 3.125rem;
  padding: 0.25rem 0.75rem;
  min-width: 6rem;
  cursor: pointer;
}

.selected_tag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1e24;
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid #ed1e24;
  border-radius: 3.125rem;
  padding: 0.25rem 0.75rem;
  min-width: 6rem;
  cursor: pointer;
}

.checkbox_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* margin-top: .5rem; */
}

.add_frame {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1rem;
  color: #545454;
}

.bottom_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 1rem 1.375rem 0 1.375rem;
  /* width: 100%; */
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: center;
}

.btn_label_cancel {
  font-family: "Montserrat";
  color: rgb(133, 133, 133);
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;
}

.input_field {
  /* border-radius: 25px;
  outline: none;
  border: 1px #c2c2c2 solid;
  margin-top: 1rem;
  width: 100%;
  min-height: 1rem; */

  border-radius: 5px;
  width: 100%;
  margin-top: 0.75rem;
  height: 2rem;
  outline: none;
  border: 1px #000 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: .875rem;
  font-weight: 400;
  font-family: Montserrat;

}