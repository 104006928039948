.profilePic {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.textareaContainer {
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 5px;
  border: 1px solid #9e9e9e;
}

.textarea {
  resize: none;
  width: 100%;
  border: none;
  outline: none;
}

.textareaContainer:focus-within {
  border: 1px solid #ED1E24;
}

.dropDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.hashtag {
  color: #ED1E24;
  padding-left: 10px;
  padding-top: 11px;
  font-size: small;
  cursor: pointer;
}
.textareaDisc {
  border-radius: 15px;
  padding: 10px;
  width: 540px;
  padding-bottom: 5px;
  outline: none;
  margin-left: 15px;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.crossSvg{
  margin-top: -2px;
  margin-left: -2px;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg) brightness(108%) contrast(102%);
}