.publicCourseCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    height: 460px;
    width: 340px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    cursor: pointer;
}

.imageContainer {
    background: #ED1E24 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    height: 200px;
    width: 100%;
    position: relative;
}

.imageContainer::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px 10px 0px 0px;
}

.courseImage {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}

.description {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

#courseTitle {
    margin-bottom: 10px;
}

#instructor {
    margin-top: 5px;
}

#descCont {
    height: 60px;
    overflow: hidden;
}

.priceAndEnrollContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 300px;
}

.publicCoursePrice {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ED1E24;
}

.publicCourseButton {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10.949px;
    line-height: 13px;
    background-color: #ED1E24;
    color: #FFFFFF;
    border-radius: 60px;
    padding: 14px 49px;
    cursor: pointer;
    border: none;
}

.smallContanier{
    padding: 0px 20px 10px 20px;
}