.main {
  box-sizing: border-box;
  margin-top: 6.5rem;
  /* width: 100%; */
  margin-left: 10vw;
  margin-right: 10vw;

  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 0.75fr 1.5fr 1.5fr;

  /* height: 88vh; */
  min-height: 70vh;
  height: 100%;
  grid-gap: 1rem;
}

.sidebar {
  grid-row: 1 / -1;
  /* background-color: orangered; */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  border-radius: 1.25rem;
  background: rgb(255, 255, 255);

  display: grid;
  grid-template-rows: 0.75fr 3fr;
  grid-gap: 1.5rem;
}

.sidebar_top {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
}

.sidebar_top > p {
  font: Montserrat;
  font-weight: 600;
  font-size: 1.88rem;
}

.sidebar_top > img {
  width: 25%;
}
.header {
  grid-column: 2 / -1;
  /* background-color: dodgerblue; */
  /* box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1); */
  border-radius: 1.25rem;
  /* background: rgb(255, 255, 255); */
  /* height: auto; */

  display: flex;
  justify-content: space-between;
  gap: 1rem;
  /* gap: 4rem; */
}

.table {
  grid-column: 2 / -1;
  grid-row: 2 / -1;
  /* background-color: coral; */
  /* box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1); */
  border-radius: 1.25rem;
  background: rgb(255, 255, 255);
  padding: 1.875rem;
  height: 65vh;
}

.flex_wrap {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* gap: 3rem */
}

.header_card {
  width: 100%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.main_header_card {
  width: 90%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.counter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  height: 100%;
  padding: 1rem 1.5rem;
  justify-items: start;
  align-items: center;
  box-sizing: border-box;
}

.count {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.13rem;
  letter-spacing: 0%;
  text-align: left;
}

.count_label {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.icon_wrapper {
  border-radius: 50px;
  padding: 1rem;
  justify-self: end;
  /* border: 1px solid red; */

  /* Gradient homepage */
  background: linear-gradient(
    180deg,
    rgb(255, 236, 236),
    rgb(255, 246, 246) 93.835%
  );

  aspect-ratio: 1/1;
}

.counter_icon {
  height: 1.75rem;
  aspect-ratio: 1/1;
}

/* .header_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.75rem;
} */

.searchbar {
  display: flex;
  padding: 0.5rem 0.75rem;
  width: 80%;
  border: 0.06rem solid rgb(208, 208, 208);
  border-radius: 0.63rem;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.menu_icon {
  padding-right: 4px;
}

.searchbar > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: 0.25rem;
  color: #49454f;
  font-weight: 400;
  font-size: 1rem;
  color: rgb(18, 18, 18);
}

.searchbar > input::placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.search_dropdown_wrapper {
  position: relative;
}

.searchbar_wrapper {
  /* padding: 1.75rem 2.5rem; */
  padding: 0 2.5rem;
  justify-content: center;

  display: flex;
  flex-direction: column;
}

.asg_quiz_searchbar_wrapper {
  justify-content: center;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.dropdown_wrapper {
  width: 30%;
  padding: 1.75rem 2.5rem;

  display: flex;
  flex-direction: column;
}

.search_label {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin: 0.35rem 0 1rem 0;
}

.search_dropdown {
  box-sizing: border-box;
  background: white;
  width: 85%;
  border: 1px solid rgb(208, 208, 208);
  position: absolute;
  max-height: 20rem;
  overflow-y: scroll;
  padding: 0.75rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
}

.dropdown_row {
  margin-bottom: 0.5rem;
}

.charts_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  height: 100%;
}

.bar_chart {
  grid-column: 1 / -1;
}

.post_feed_container {
  margin: auto;
  width: 65%;
}

.post_feed_wrapper {
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.post_feed_wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.post_feed_wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.data_not_found_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.not_found {  
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}
.top_navbar {
  display: none;
}

.top_navbar > .menu_icon {
  cursor: pointer;
  width: 2.5rem;
}

.top_navbar > .logo_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1.5rem;
}

.logo_wrapper > p {
  font-size: 1.5rem;
  font-weight: 700;
}

.dashboard_main_header {
  display: flex;
  height: fit-content;
  gap: 1rem;
}

/* ---------------------- Media Queries ---------------- */


@media (max-width: 1140px) {
  .sidebar {
    display: none;
  }
  .header, .table {
    grid-column: 1/-1;
  }
  .main {
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .top_navbar {
    display: flex;
    background-color: #f0f0f0;
    margin-top: 7.5rem;
    width: 85%;
    border-radius: 0.25rem;
    margin: auto;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .charts_container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .dashboard_main_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .main_header_card {
    width: 45%;
  }

  .counter {
    padding: .5rem .75rem;
  }

  .count {
    font-size: 1.5rem;
  }

  .counter_icon {
    height: 1.5rem;
  }
  .icon_wrapper {
    padding: 0.5rem;
  }

  .count_label{
    font-size: .75rem;
  }

  .post_feed_wrapper {
    margin-bottom: 2rem;
  }
  .post_feed_container {
    width: 90%;
  }

  .dropdown_wrapper {
    display: none;
  }

  .flex_wrap{
    align-items: center;

  }

  .asg_quiz_searchbar_wrapper {
    width: 90%;
    margin-left: 1rem;
  }

  .add_new_faculty_student {
    display: none;
  }


}

@media (max-width: 800px) {
  .top_navbar {
    margin-top: 1rem;

  }
}