.main {
  display: flex;
  box-sizing: border-box;
  width: 95%;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 6px;
}

.main:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #f0f0f0;
}

.data_wrap {
  display: grid;
  grid-template-columns: 0.8fr 0.25fr;
  width: 100%;
  box-sizing: border-box;
  margin-left: 0.625rem;
}

.name {
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.425px;
}

.last_message{
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.35px;
  margin-top:.3125rem;
}

.timestamp {
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
}
