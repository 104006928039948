.configureContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rightImage {
  height: 100vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Gradient-light, linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%));
}
.rightImage > img {
  width: 80%;
  height: auto;
}
.fieldContainer {
  width: 50%;
  padding: 0 220px;
  box-sizing: border-box;
}
.fieldContainer > a {
  text-decoration: none;
}
.field {
  display: flex;
  flex-direction: column;
}
.heading {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}
.subHeading {
  color: #555555;
  font-size: 16px;
}
.configureField {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 60px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.configureField:focus {
  outline: none;
}
.zoomBtn {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #ED1E24;
  border: 1px solid #ED1E24;
}
.zoomBtn:hover {
  background-color: #ED1E24;
  color: #ffffff;
  border: 1px solid #ED1E24;
}
.inputAlert {
    padding: 5px 0 0 20px;
    font-weight: 600;
    color: #e0003b;
  }

/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
  .fieldContainer {
    padding: 0 120px;
  }
}
/* ############################ Responsive 1420 ############################ */
@media only screen and (max-width: 1420px) {
  .heading {
    font-size: 30px;
  }
  .fieldContainer {
    padding: 0 110px;
  }
}
/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .fieldContainer {
    padding: 0 90px;
  }
}
/* ############################ Responsive 1090 ############################ */
@media only screen and (max-width: 1090px) {
  .rightImage {
    display: none;
  }
  .fieldContainer {
    padding: 0;
  }
  .configureContainer {
    height: 100vh;
    justify-content: center;
  }
  .fieldContainer {
    width: 70%;
  }
}
/* ############################ Responsive 800 ############################ */
@media only screen and (max-width: 800px) {
  .configureContainer {
    height: 60vh;
    justify-content: center;
  }
  .fieldContainer {
    width: 70%;
  }
}
/* ############################ Responsive 550 ############################ */
@media only screen and (max-width: 550px) {
  .fieldContainer {
    width: 90%;
  }
}
