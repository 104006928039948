.main {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  align-items: flex-start;
  padding: 32px 24px;
  /* gap: 0.4rem; */
  background: #ffffff;
  font-family: "Montserrat";
  max-width: 400px;
}

.dropdown {
  display: flex;
  justify-content: space-between;
}

.dropdown > div {
  margin-right: 10px;
}

.header {
  display: flex;
  align-items: center;
  margin: 0px;
}

.header > span {
  font-weight: 500;
  margin-left: 5px;
}

.header > img {
  width: 20px;
  height: 20px;
}
.time{
  width: 100%;
}

.time_switch > span {
  font-weight: 500;
  margin-right: 8px;
}

.order, .attempts, .reveal {
  width: 100%;
}

.order > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.attempts > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.reveal > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.time_switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time_container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.time_container > span {
  white-space: nowrap;
}

.time_unit {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  align-items: center;
}

.time_unit > span {
    font-size: 0.625rem;
    font-weight: 400;
    font-family: Montserrat;
    margin-left: 10px;
    line-height: 2.4;
}

.time_input {
  border-radius: 25px;
  /* width: 90%; */
  max-width: 4rem;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  /* padding: 3px 4px 3px 22px; */
  /* box-sizing: border-box; */
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  margin-left: 6px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
}

.header_cross{
  display: none;
}

@media (max-width: 580px){
  .main{
    width:80%;
  }
  .header > span {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }
  .time_switch > span {
    font-weight: 500;
    margin-right: 8px;
    font-size: 14px;
  }
  .order > span {
    font-weight: 500;
    font-size: 14px;
  }
  .attempts > span {
    font-weight: 500;
    font-size: 14px;
  }
  .reveal > span {
    font-weight: 500;
    font-size: 14px;
  }
  .header_top{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .header_cross{
    display:block;
  }
  
}