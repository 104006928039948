.main {
    margin-top: 4.75rem;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    /* border: 1px solid blue; */
    padding: 12px;
    background-color: #F6F6F6;
}

.icon {
    width: 1rem;
}


@media (max-width: 800px) {
    .main {
        margin-top: 2rem;
    }
}
