.main {
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
  padding: 0.25rem 0.625rem;
  min-height: 3rem;
  margin-top: .375rem;
  margin-right: auto;
  margin-left: 0;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;  
}

.wrapper {
  display: flex;
  align-items: center;
}

.message {
  color: #111b21;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.35px;
}

.sender {
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.25rem 0.625rem;
  min-height: 3rem;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
  background: #ffeced;
  margin-top: .375rem;
  margin-left: auto;
  margin-right: 0;
}

.date_wrap {
  display: flex;
  align-items: center;
  justify-content: right;
  color: #535353;
  font-family: Montserrat;
  font-size: .625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.225px;
  margin-left: 1rem;
  margin-top: auto;
  
}


.text {
    font-family: 'Montserrat';
    font-weight: 400;
    font-weight: 1rem;
    color: #000;
}

.editing {
  background-color: #B7E0FF;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;;
}