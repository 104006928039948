.classModal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.inputField {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.inputField1 {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 15px 0px 15px 15px;
}

input.inputField1::placeholder {
  top: 10px;
  position: absolute;
}
.inputLabel {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 1rem;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: normal;
}

.saveDetailsButton {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 24px;
}

.saveDetailsButton:hover {
  background-color: #ED1E24;
  color: white;
  margin-bottom: 24px;
  /* border: 1px #236ae8 solid; */
}
@media (max-width: 800px) {
  .inputField, .inputField1 {
    width: 85%;
  }
  .thumbnailArea {
    width: 85%;
  }
  .thumbnailPic {
    width: 100%;
  }
}