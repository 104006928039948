.main {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.add_quiz_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.add_quiz_container > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-right: 8px;
}


.btn_div {
  display: flex;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: fit-content;
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width: 580px){
  .add_quiz_container {
    display: block;
  }
  .add_quiz_container > span {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .btn_label {
    font-family: Montserrat;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}