.main {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-top: 5.5%;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  gap: 1rem;
}

.sidebar_container {
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.right_container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.header_container {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2.5rem;
}

.main_header_wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;
}

.header_wrapper {
  display: flex;
  border-bottom: 2px solid rgb(237, 30, 36);
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255); 
  justify-content: space-between;
  padding: 1.5rem 2rem;
  align-items: center;
  min-height: 5rem;
}

.header_left, .header_right {
  display: flex;
  flex-direction: column;
  gap: 0.5rem
}

.sidebar_header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.username_label_wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.username_label_wrapper > img {
  width: 1.25rem;
  height: 1.25rem;
}

.username_label_wrapper > p {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.19rem;
  font-weight: 400;
  line-height: 1.44rem;
  letter-spacing: 0%;
  text-align: left;
}

.usertype {
  color: rgb(85, 85, 85);
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: 0%;
  text-align: center;
}

.sidebar_menu_item_wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem
}

.sidebar_menu_item_wrapper > img {
  width: 2rem;
}

.header_right {
  width: 45%;
}

.searchbar {
  display: flex;
  padding: 0.5rem 0.75rem;
  width: 90%;
  border: 0.06rem solid rgb(208, 208, 208);
  border-radius: 0.63rem;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.searchbar > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: 0.25rem;
  color: #49454f;
  font-weight: 400;
  font-size: 1rem;
  color: rgb(18, 18, 18);
}

.searchbar > input::placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.charts_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  box-sizing: border-box;
  width: 100%;
  min-height: 60vh;
  flex: 1
}

.bar_chart {
  grid-column: 1 / -1;
}

.post_feed_container {
  margin: auto;
  width: 65%;
}

.post_feed_wrapper {
  overflow: scroll;
  margin-bottom: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.post_feed_wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.post_feed_wrapper {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.data_not_found_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.not_found {  
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}

.header_card {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.counter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  height: 100%;
  padding: 1rem 1.5rem;
  justify-items: start;
  align-items: center;
  box-sizing: border-box;
}

.count {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.13rem;
  letter-spacing: 0%;
  text-align: left;
}

.count_label {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.icon_wrapper {
  border-radius: 50px;
  padding: 1rem;
  justify-self: end;
  /* border: 1px solid red; */

  /* Gradient homepage */
  background: linear-gradient(
    180deg,
    rgb(255, 236, 236),
    rgb(255, 246, 246) 93.835%
  );

  aspect-ratio: 1/1;
}

.counter_icon {
  height: 1.75rem;
  aspect-ratio: 1/1;
}

.profile_pic {
  width: 8rem;
  border-radius: 50%;
  aspect-ratio: 1;
}



.top_navbar {
  display: none;
}

.top_navbar > .menu_icon {
  cursor: pointer;
  width: 2.5rem;
}

.top_navbar > .logo_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1.5rem;
}

.logo_wrapper > p {
  font-size: 1.5rem;
  font-weight: 700;
}


/* ---------------------- Media Queries ---------------- */


@media (max-width: 1140px) {
  .sidebar_container {
    display: none;
  }
  .main {
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .top_navbar {
    display: flex;
    background-color: #f0f0f0;
    margin-top: 7.5rem;
    width: 85%;
    border-radius: 0.25rem;
    margin: auto;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .charts_container {
    grid-template-columns: 1fr;
    margin-bottom: 5rem;
  }

  .main_header_wrapper {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .header_card {
    width: 45%;
  }

  .counter {
    padding: .5rem .75rem;
  }

  .count {
    font-size: 1.5rem;
  }

  .counter_icon {
    height: 1.5rem;
  }
  .icon_wrapper {
    padding: 0.5rem;
  }

  .count_label{
    font-size: .75rem;
  }

  .header_left {
    display: none;
  }

  .header_right {
    width: 95%;
  }

  .post_feed_wrapper {
    margin-bottom: 2rem;
  }
  .post_feed_container {
    width: 90%;
  }


}