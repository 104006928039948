.main {
  width: 100%;
  font-family: Montserrat;
  /* border: 1px solid red; */
}

.title_container {
  margin-bottom: 4rem;
}

.title {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
}

.count {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.middle_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* border: 1px solid red; */
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2.5rem;
}

.btn_label {
  font-size: 20px;
  font-weight: 700;
}

.instructions > span {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.bottom_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* border: 1px solid red; */
  border-top: 2px solid #e5e5e5;
  margin: 16px 0;
  padding: 16px 0;
}

.max_marks_text,
.your_grade_text {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.marks_value {
  color: #000;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 131.579% */
}

.loader_style {
  text-align: center;
  margin-top: 200px;
}

@media (max-width: 580px){
  .main {
   margin-left: 40px;
  }
  .title {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .count {
    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .instructions > span {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .instructions > p {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
  }
  .title_container {
    margin-bottom: 30px;
  }
  .bottom_container {
    border-top: none;
    display: block;
  }
  .max_marks_text, .your_grade_text {
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .marks_value {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
  }
  .bottom_container_top{
    margin-bottom: 17px;
  }
  .btn_label {
    color: #FFF;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}