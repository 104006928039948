.outline_btn {
  /* margin-top: 20px; */
  /* width: 100%; */
  padding: 12px 28px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.full_width {
  width: 100%;
}
.outline_btn:hover {
  background-color: #ED1E24;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.solid_btn {
  /* margin-top: 20px; */
  /* width: 100%; */
  padding: 12px 28px;;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ED1E24;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.solid_btn:hover {
  background-color: #ffffff;
  color: #ED1E24;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.solid_btn:active, .outline_btn:active {
  /* transform: scale(0.98); */
  box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.14);
}

input{
  border: 0;
}
.disable{
  padding: 12px 28px;
  border: 1px solid #f6f6f6;
  border-radius: 50px;
  /* cursor: auto; */
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #959595;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.2s all;
}

@media (max-width: 500px) {
  .solid_btn{
    font: normal normal 600 10px Montserrat;
    width: auto;
    height: 35px;
    padding: 10px 21.5px;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .outline_btn{
    font: normal normal 600 10px Montserrat;
    padding: 12px 18px;
  }
  .disable{
    padding: 7.5px 16.5px;
    font: normal normal 600 10px Montserrat;
  }
}