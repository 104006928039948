.container {
  display: flex;
  padding: 12px 20px 12px 20px;
  border: 1px solid #9e9e9e;
  border-radius: 50px;
  min-width: 125px;
  cursor: pointer;
}

.container:hover {
  color: #ED1E24;
  /* border: 1px solid #6a96ec; */
}

.container:hover > .light {
  color: #ED1E24;
}

.light {
  color: #757575;
}

.eye {
  height: 20px;
  width: 17px;
  margin-right: 5px;
}

.dropArrow {
  height: 20px;
  position: relative;
  top: -2px;
  margin-left: auto;
  order: 2;
}

.dropdown_menu1 {
  width: 130px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
}

.dropdown_menu1 ul {
  list-style: none;
  padding: 3px 0px;
}

.dropdown_menu1 li {
  padding: 4px 12px;
}

.Hr3 {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0;
}

.menu1 {
  text-align: left;
  font-size: 12px;
}

.navbar_menu1 {
  z-index: 12;
  height: 45px;
  width: 160px;
}
