.academicsContainer {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.academicsHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    cursor: pointer;
    padding: 0 15px 5px 15px;
}

.academicsHeadingOnClick {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    color: #ED1E24;
    padding: 0 15px 5px 15px;
    border-bottom: 2px solid #ED1E24;
}

.hideAcademicMyClasses {
    display: none;
}

.hideAcademicMyGroups {
    display: none;
}

.hideAcademicMyCourses {
    display: none;
}

.headWrapper{
    margin: 120px 0px 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.acBtn{
    margin-right: 50px;
}

@media screen and (max-width: 800px) {
    .headWrapper {
        margin: 20px 0px 0px 0px;
        justify-content: center;
    }
    .academicsContainer{
        justify-content: center;
        gap:10px;
    }
    .acBtn{
        margin-right: 0px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 500px) {
    .acBtn{
        display: none;
    }

    .academicsContainer {
        gap: 10px;
        justify-content: center;
        margin: 0;
    }

    .headWrapper {
        margin-top: 20px;
        margin-left: 0px;
        justify-content: center;
    }

    .academicsHeading {
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.0015em;
        border-bottom: 2px solid #000000;
    }

    .academicsHeadingOnClick {
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.0015em;
        color: #ED1E24;
        padding: 0 15px 5px 15px;
    }
}