/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */
.hidden {
  display: none;
}

.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 150px;
}
.social-links {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0px;
  top: 30%;
}
.social-links > a {
  text-decoration: none;
  color: white;
}
.social-links > a > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.social-links > a > div > img {
  padding: 20px 20px;
}
.facebook,
.linkedin,
.twitter,
.instagram {
  width: 35px;
  border-radius: 0 0 0 0;
  color: transparent;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding-right: 30px;
}
.facebook:hover,
.linkedin:hover,
.twitter:hover,
.instagram:hover {
  width: 230px;
  border-radius: 0 10px 10px 0;
  color: white;
  transition: all 0.1s ease-out;
}
.hero-video {
  height: 100vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  position: relative;
}
.intro {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.hero-img > img {
  height: 650px;
  width: auto;
}
.email:focus {
  outline: none;
}

.subscribe-button {
  text-decoration: none;
  color: #ED1E24;
  padding: 15px 45px !important;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ED1E24;
}

.subscribe-button:focus {
  outline: none;
}
.heading {
  font-size: 100px;
  font-weight: 500;
  color: white;
}
.sub-heading {
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 10px 0;
  margin-bottom: 40px;
}
.subscribe-button-group {
  padding-top: 30px;
}
.subscribe-button-group,
.subscribe-button-group > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subscribe-button-group > div {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}
.signup {
  color: white;
  border: 2px solid #ffffff;
  border-radius: 100px;
  padding: 25px 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: transparent;
  transition: 0.3s;
  margin: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.signup:hover {
  color: #ED1E24;
  background-color: white;
  border: 2px solid white;
}
.card-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 85vh;
  margin-top: -250px;
}
.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 90px 40px;
  margin: 0 100px;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
}
.info-number {
  color: #ED1E24;
  font-size: 70px;
  font-weight: 600;
  height: 150px;
  font-family: "Bebas Neue", cursive;
}
.info-number > div {
  font-size: 18px;
}
.info-text {
  color: black;
  font-size: 14px;
  width: 200px;
}

.info_card_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.info_card_container > :nth-child(3) {
  grid-column: 1 / span 2;
  width: 50%;
  margin: auto;
}

.subscribe-button {
  color: #ED1E24;
  border: 2px solid #ED1E24;
  border-radius: 100px;
  padding: 25px 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: white;
  transition: 0.3s;
  text-transform: uppercase;
}
.subscribe-button:hover {
  color: white;
  background-color: #ED1E24;
  border: 2px solid #ED1E24;
}
.section {
  display: flex;
  align-items: center;
  padding-top: 0px;
}
.section-img > img {
  height: 600px;
  width: auto;
}
.section-text {
  margin: 0 100px;
}
.section-heading {
  font-size: 50px;
  font-weight: 700;
}
.section-sub-heading {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  width: 500px;
  margin: 30px 0 60px 0;
  opacity: 0.8;
}
.section-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-area :nth-child(even) {
  flex-direction: row-reverse;
}
.map-section {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}
.map {
  padding: 50px 0 0 0;
  width: 80%;
  height: auto;
}

.intrests {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}
.topic-active {
  color: #ED1E24;
}
.topic-list {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  margin-bottom: 70px;
  align-items: center;
  justify-content: center;
}
.topic-list > div {
  font-size: 14px;
  padding: 0 40px;
  cursor: pointer;
}
.topic-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  width: 450px;
  margin: 10px;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
}
.card-text {
  padding: 0 30px;
}
.topic-card > img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}
.title {
  font-size: 18px;
  font-weight: 700;
  padding-top: 30px;
}
.level {
  font-size: 16px;
  font-weight: 400;
  padding-top: 15px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.learn-more {
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-top: 70px;
  padding-bottom: 40px;
}
.learn-more > a {
  text-decoration: none;
  color: #ED1E24;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}
.setup-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 200px 0;
  overflow: hidden;
}
.setup-heading {
  font-size: 50px;
  font-weight: 700;
  width: 440px;
  margin-right: 10px;
}
.email {
  border-style: none;
  padding-left: 14px;
  color: #444444;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  background-color: #eeefef;
  padding: 25px 40px;
  width: 550px;
  border-radius: 100px;
  margin-right: 60px;
}
.footer {
  /* background-color: #ED1E24; */
  /* background: #000000; */
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  color: #656565;
  margin-top: 70px;

  background: rgb(246, 247, 251);
    clip-path: url(#clip-path-footer);  
    padding-top: 5rem;
    /* padding-left:10%;
    padding-right:8%; */
}
.footer-left {
  width: 50%;
  padding: 100px 50px 100px 300px;
}
.footer-left > div > p {
  width: 500px;
  font-size: 16px;
  line-height: 24px;
}
.footer-right {
  width: 50%;
  padding: 100px 50px 0px 50px;
  /* 
  display: flex;
  justify-content: center;
  align-items: flex-end; */
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.footer-link {
  cursor: pointer;
}

.footer-right > img {
  height: 350px;
  width: auto;
}

.footer-header {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 700;
  line-height: 0.81rem;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
  padding-bottom: 1rem;
}

.footer-left > div > img {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}
.footer-social {
  display: flex;
  flex-direction: row;
}
.footer-social > a > div {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.16);
}
.footer-img {
  width: 25px;
  height: auto;
  opacity: 1;
}
.footer-img:hover {
  opacity: 0.8;
}
.footer-right > div {
  padding: 10px 0;
}
.carousel {
  height: 500px;
  width: auto;
}

.monetize-section{
  text-align: center;
  margin-top: 40px;
}

.monetize-sub-heading{
  margin-top: 10px;
  font-size: 14px;
}
.moentize-video-div{
  position: relative;
}

.play-btn-img{
  opacity: 1 !important;
  z-index: 2;
}
.play-btn img{
  position: relative;
  opacity: 1;
  height: 180px;
  width: 180px;
}
.actual-video{  
  border-radius: 20px;
  margin-top: 30px;
  margin: 30px auto; 
  width: 70% !important;
  height: 100% !important;
}

.actual-video > video{
  border-radius: 20px;

}

/* ############################ Responsive 1450 ############################ */
@media only screen and (max-width: 1450px) {
  .intro-section {
    margin-bottom: 100px;
  }
  .heading {
    font-size: 70px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .email {
    padding-left: 14px;
    font-size: 18px;
    padding: 25px 40px;
    width: 400px;
    margin-right: 60px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-right {
    padding: 80px 150px 80px 150px;
  }

  .Home-Section1 {
    height: 64vh;
  }
}

/* ############################ Responsive 1330 ############################ */
@media only screen and (max-width: 1330px) {
  .intro-section {
    margin-bottom: 80px;
  }
  .heading {
    font-size: 70px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .email {
    padding-left: 14px;
    font-size: 18px;
    padding: 25px 40px;
    width: 400px;
    margin-right: 60px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-right {
    padding: 80px 20px 80px 20px;
  }
  
}

/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .intro-section {
    margin-bottom: 250px;
  }
  .heading {
    font-size: 70px;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .email {
    padding: 20px 40px;
    margin-right: 60px;
  }
  .section {
    padding-top: 20px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
}

/* ############################ Responsive 1130 ############################ */
@media only screen and (max-width: 1130px) {
  .intro-section {
    margin-bottom: 250px;
  }
  .heading {
    font-size: 70px;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .email {
    padding: 20px 40px;
    margin-right: 20px;
  }
  .section {
    padding-top: 20px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 20px;
    padding: 80px 30px;
  }
  .learn-more {
    padding-bottom: 20px;
    padding-top: 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .map {
    padding: 50px 0 0 0;
    height: 550px;
    width: auto;
  }
  .topic-card {
    width: 325px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 35px;
    width: 300px;
    margin-right: 10px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-left > div > p {
    width: 300px;
  }
}

/* ############################ Responsive 1024 ############################ */
@media only screen and (max-width: 1024px) {
  .intro-section {
    margin-bottom: 50px;
    text-align: center;
  }
  .heading {
    width: 600px;
    font-size: 70px;
    text-align: center;
  }
  .sub-heading {
    font-size: 18px;
  }
  .card-section {
    display: none;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .email {
    padding: 20px 40px;
    margin-right: 20px;
  }
  .section {
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .section-area :nth-child(even) {
    flex-direction: column;
  }
  .reverse {
    flex-direction: column-reverse !important;
  }
  .card-container {
    flex-direction: column;
    gap: 3rem;
  }
  .section-heading {
    font-size: 35px;
  }
  .map {
    padding: 50px 0 0 0;
    height: auto;
    width: 90vw;
  }
  .topic-card {
    width: 80vw;
    margin: 10px;
  }
  .topic-list > div {
    padding: 0 10px;
  }
  .setup-section {
    flex-direction: column;
    text-align: center;
  }
  .setup-section > div,
  .setup-heading > input,
  .setup-section > subscribe-button {
    margin: 20px 0;
  }
  .setup-heading {
    width: 500px;
  }
  .intrests {
    margin-top: 50px;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 80px 20px;
  }
  .footer-right {
    align-items: center;
    padding: 0px 20px 80px 20px;
  }
  .footer-right > img {
    width: 300px;
    height: auto;
  }
  .footer-left > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-left > div > img {
    width: 250px;
    height: auto;
  }
  .footer-left > div > p {
    width: 500px;
    text-align: center;
  }
  .play-btn img{
    height: 130px;
    width: 130px;
  }
  /* .play-btn{
    top: 20%;
    left: 40%;
  } */
}
@media only screen and (max-width: 950px) {
  .section-heading {
    margin: 50px 0 30px 0;
  }
  .section-sub-heading {
    width: 400px;
  }
  /*     .topic-list{
        display: none;
    } */
  .topic-list:nth-child(7) {
    display: block !important;
  }
  .heading {
    width: 80vw;
    font-size: 50px;
    font-weight: 500;
  }
  .setup-heading {
    width: 80vw;
  }
  .section-text {
    margin: 0;
  }
  .section-sub-heading {
    width: 80vw;
  }
  .section-img > img {
    height: auto;
    width: 60vw;
  }
  .email {
    width: 250px;
    margin-right: 0;
  }
  .section-text {
    margin: 0 20px;
  }
  .footer-left {
    padding: 0px 0px 0px 0px;
  }
  .footer-left > p {
    width: 80vw;
  }
  .map-section {
    padding-top: 20px;
  }
  .map-section > div {
    width: 80vw;
    text-align: center;
  }
  .intrests > div {
    text-align: center;
  }
  .map {
    padding: 0;
  }
  .footer-left {
    padding: 80px 20px 80px 20px;
  }
  .footer-right {
    padding-bottom: 20px;
  }
  /* .play-btn{
    top: 20%;
    left: 35%;
  } */
  .play-btn img{
    height: 100px;
    width: 100px;
  }
  .explore-label {
    font-size: 2rem;
  }
  .video-container {
    width: 100%;
  }
  .info_card_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .info_card_container > :nth-child(3) {
    grid-column: 1 / span 2;
    width: 100%;
    margin: auto;
  }
}
@media only screen and (max-width: 650px) {
  .social-links {
    display: none;
  }
  .heading {
    width: 90vw;
  }
  .intro {
    padding-bottom: 0px;
  }
  .section-img > img {
    height: auto;
    width: 80vw;
  }
  .subscribe-button {
    padding: 25px 25px;
  }
  .footer-left {
    padding: 50px 10px 50px 10px;
  }
  .footer-left > div > p {
    width: 350px;
    text-align: center;
  }
  .play-btn img{
    height: 70px;
    width: 70px;

  }
}

@media only screen and (max-width: 480px) {
  .play-btn img{
    height: 50px;
    width: 50px;

  }

  .footer-right {
    width: 80%;
    gap: 0.5rem;
    text-align: center;
  }

  .footer-header {
    text-align: center;
  }

  .info_card_container > :nth-child(3) {
    grid-column: 1 / span 2;
    width: 100%;
    margin: auto;
  }

  .Home-section6 {
    margin-bottom: 1rem;
  }
  
  .Hero-section7 {
    margin-bottom: 1rem;
  }

  .homepage-video-section{
    padding-top: 1rem;
  }
  .video-container {
    margin-bottom: 6rem;
  }
  .home-section5-new {
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .play-btn img{
    height: 30px;
    width: 30px;

  }

  .hero-image {
    margin-top: 5rem;
  }

  .homepage-video-section .explore-label {
    font-size: 1.75rem;
  }
}

