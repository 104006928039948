.modal_container {
    text-align: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    height: fit-content;
    padding-top: 5px;
    padding: 24px 0px 24px 40px;
    border-radius: 5px;
    width: 75vh;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    gap: 20px;
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .header {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    line-height: 17px;
    color: #A6A6A6;
    margin: 5px 0;
  }

  .question_types {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
  }

  .question_icon {
    box-sizing: border-box;
    width: 50%;
    margin: 12px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .question_icon > img {
    margin-right: 6px;
    /* height: 17px; */
  }

  .question_icon > span {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
  }
  @media (max-width: 580px){
    .modal_container {
      padding: 25px;
      width: 37vh;
      border-radius: 14px;
    }
    .question_types {
      display: grid;
      grid-template-columns: 1fr;
    }
    .question_icon > span {
      white-space: nowrap;
    }
  }