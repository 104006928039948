.drop_down_menu{
    padding: 10px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px #3F3F411A;

}

.drop_down_item{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    cursor: pointer;
}

.drop_down_item:hover {
    background-color: #ffcfd0;
}

.drop_down_item_icon{
    width: 1.5rem;

}

.drop_down_item_name{
    margin-left: 10px;
    font-size: 12px;
}

.fileUpload{
    opacity: 0;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  
    /* IE 5-7 */
    filter: alpha(opacity=0);
  
    /* Netscape or and older firefox browsers */
    -moz-opacity: 0;
  
    /* older Safari browsers */
    -khtml-opacity: 0;
  
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.drop_down_item input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }