.FillInTheBlankQuestion {
    /* margin-top: 200px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.FillInTheBlankQuestion input {
    border: 1px solid #DBDBDB;
    border-radius: 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    margin-right: 25px;
}

.FillInTheBlankQuestion input:focus {
    outline: none;
}

.FillInTheBlankQuestion button {
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: white;
    border: none;
    color: #ED1E24;
    z-index: 1;
    white-space: nowrap;
}

@media (max-width: 580px){
    .FillInTheBlankQuestion {
        display: block;
    }
    .FillInTheBlankQuestion button {
        float: right;
        margin-top: 10px;
    }
}