.main {
  box-sizing: border-box;
  min-height: 50vh;
  min-width: 36vw;
  width: 36vw;
  height: 50vh;
}

.middle_container {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1.125rem 1.375rem;
}

.button_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.tab_container {
  height: 100%;
  width: 100%;
  min-height: 38vh;
  background-color: #ebebeb;
  margin-bottom: 0.5rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.reset_icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.upload_box {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.upload_box input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.upload_img {
  margin-bottom: 5px;
}

.upload_text {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.25rem 1rem;
  border: 1px solid #ed1e24;
  color: #ed1e24;
  border-radius: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.support_txt {
  font-size: .75rem;
}

.file_upload {
  opacity: 0;
  cursor: pointer;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}


.thumbnail_pic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  object-fit: contain;
}

.change_thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

/* ----------- Media Query -------------- */

@media (max-width: 800px) {
  .main {
    box-sizing: border-box;
    width: 90vw;
    height: 70vh;
  }
  

}