.main {
  margin-top: 0;
  margin-bottom: 1rem;
}

.card {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  margin-top: 0.25rem;
  box-shadow: 0px 0px 20px #5555551a;
  padding: .625rem;
  border-radius: 0.625rem;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.circle {
  width: 7rem;
  height: auto;
  position: relative;
}

.count {
  position: absolute; /* Position relative to the parent container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust position to center */
  height: auto; /* Maintain aspect ratio */
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Montserrat;
  color: rgb(237, 30, 36);
}


.count_label {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}