.view_assignment_modal{
    background-color: white;
    width: 65%;
    padding: 20px;
    border-radius: 20px;

}

.view_assignment_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.view_assignment_description{
    font-size: 14px;
    color: #707070;
    margin: 20px 10px;
    word-wrap: break-word;
    word-break: break-all;
    max-height: 9.375rem;
    overflow-y: auto;
}

  /* Hide scrollbar for Chrome, Safari and Opera */
.view_assignment_description::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.view_assignment_description {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



.view_assignment_attachment{
    margin-bottom: 20px;
}

  
.view_assignment_button{
    display: flex;
    justify-content: flex-end;
}
.dropdown{
    width: fit-content;
    cursor: pointer;
    margin-left: 15px;
    height: 45px;
}
.bottom_btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.threeDotsMenu{
    height: 45px;
}


@media (max-width: 1099px) {
    .view_assignment_modal{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .view_assignment_modal{
        width: 90%;
    }
}

@media (max-width: 580px) {
    .view_assignment_modal{
        width: 90%;
        padding: 8px;
    }
    .threeDotsMenu{
        height: 30px;
        margin-top: 8px;
    }
    .view_assignment_description{
        margin: 10px 0px;
    }
}