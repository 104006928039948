.popupModalBox {
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    background: #31313150;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.publicPrivate {
    padding: 30px;
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    width: 60%;
    background: #fff;
    overflow: scroll !important;
    scrollbar-width: 0;
    border-radius: 32px;
    z-index: 1000;
    width: 674px;
    height: 468px;
}

.publicPrivate::-webkit-scrollbar {
    display: none;
}

.publicPrivateHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.publicPrivateHeading #cross {
    padding-left: 25px;
    cursor: pointer;
}

.insidePublicPrivateHeading {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.insidePublicPrivateHeading p {
    padding-left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.publicPrivateBoxes {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    flex-direction: row;
    margin: 60px 0 30px 0;
}

.publicPrivateBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 4.84161px 4.84161px 22.3924px rgba(0, 0, 0, 0.08);
    border-radius: 34.8544px;
    width: 280.21px;
    height: 256px;
    cursor: pointer;
}

.publicPrivateBox h2 {
    padding: 10px 0 10px 0;
    font-weight: 700;
    font-size: 19.3664px;
    line-height: 105%;
}

.publicPrivateBox img {
    padding: 10px 0 10px 0;
}

.publicPrivateBoxFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.publicPrivateBoxFlex img {
    margin: 4px 0 0 5px;
    width: 13.33px;
    height: 13.33px;
}

.publicPrivateButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.publicPrivateButton button {
    background: #ED1E24;
    border-radius: 60px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 20px 10px 20px 10px;
    width: 25%;
    cursor: pointer;
}

.hidePopupModalBox {
    display: none;
}


.nextbtn_div {
    margin: 35px 40px 0px auto;
    width: fit-content;
  }

@media screen and (max-width: 800px) {

    .publicPrivateBoxes {
        gap: 30px;
    }

    .publicPrivate {
        width: 500px;
        height: 390px;
    }

    .publicPrivateBox {
        width: 200px;
        height: 200px;
    }

    .publicPrivateBox img {
        padding: 10px 0 0px 0;
    }

    .publicPrivateBox h2 {
        padding: 10px 0 0px 0;
    }

    #lockIcon {
        width: 45px;
        height: 58px;
    }

    .publicPrivateBoxFlex h2 {
        font-weight: 700;
        font-size: 12px;
        line-height: 105%;
    }

    .publicPrivateButton button {
        width: 30%;
    }

    .nextbtn_div {
        margin: 15px 30px 0px auto;
        width: fit-content;
    }

}

@media screen and (max-width: 630px) {

    .publicPrivate {
        width: 320px;
        height: 263px;
        border-radius: 10px;
    }

    .publicPrivateBoxes {
        gap: 20px;
        margin: 30px 0 15px 0;
    }

    .publicPrivateBox {
        padding: 5px;
        width: 138px;
        height: 137px;
        border-radius: 24.1667px;
    }

    #lockIcon {
        width: 35.37px;
        height: 48px;
    }

    #select {
        width: 8.68px;
        height: 8.68px;
    }

    #cross {
        height: 12px;
        width: 12px;
    }

    .insidePublicPrivateHeading img {
        width: 30px;
        height: 31px;
    }

    .insidePublicPrivateHeading p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
    }

    .publicPrivateButton button {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        padding: 10px 5px 10px 5px;
    }

    .nextbtn_div {
        margin: 5px 0px 0px auto;
        width: fit-content;
    }

}

@media screen and (max-width: 430px) {
    .publicPrivate {
        margin: 20px;
    }

    .publicPrivateBoxes {
        gap: 10px;
    }
}