.container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  width: 257px;
  height: 350px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.container:hover {
  cursor: pointer;
}
.imageContainer {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 140px;
  position: relative;
}

.imageContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none; /* Allows you to interact with the underlying content */
  border-radius: 10px 10px 0px 0px;
}
.textContainer {
  padding: 0 20px;
}
.subjectImage {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
  object-fit: cover;
}
.subjectText {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #555555;
  margin-bottom: 30px;
}
.gradeText {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0px;
  color: #555555;
  font-family: "Montserrat";
  opacity: 1;
  margin-bottom: 10px;
}
.nameText {
  text-align: left;
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  font-family: "Montserrat";
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
