.main {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin-bottom: 1rem;
}

.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
}

.section_title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 0.875rem;
  color: #000;
}
.section_title > span {
  font-weight: 400;
  margin-left: 10px;
}

.icons {
  margin-right: 1.75rem;
}

.icons > img {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.icons_disabled {
  margin-right: 1.75rem;
}

.icons_disabled > img {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
}

.btn_label {
  font-family: "Montserrat";
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}


.selection_bar {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1.4px solid #ED1E24;
  border-radius: 50px;
  color: #ED1E24;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  cursor: pointer;
}

.option_quiz {
  border-left: 1.4px solid #e5e5e5;
  border-right: 1.4px solid #e5e5e5;
}

.option > img {
  width: 11px;
  height: 11px;
  margin-right: 4px;
}

@media (max-width:500px){
  .main {
    border-radius: 19px;
  }
  .icons {
    margin-right: 13px;
  }
  .section_title {
    font-size: 15px;
  }
  .section_title > span{
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  .btn_label {
    font-size: 10px;
    font-weight: 700;
  }
  .option {
    font-size: 11px;
    font-weight: 700;
  }
  .option > img {
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }

}