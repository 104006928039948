.inputOption {
  display: flex;
  align-items: center;
  color: #b4b4b4;
  padding: 10px;
  cursor: pointer;
  gap: 10px;
}

.inputOption:hover {
  background-color: whitesmoke;
  border-radius: 10px;
}

.inputOption > h4 {
  font-size: 13px;
  letter-spacing: 0.7px;
  color: #b4b4b4;
}

.InputOptionsIconImage {
  width: 20px;
}

@media (max-width: 768px) {
  .text {
    display: none;
  }
}
