.main {
  box-sizing: border-box;
  display: flex;
  padding: 0.75rem 0.5rem;
  font-family: "Montserrat";
  width: 100%;
  gap: 0.5rem;

  border-bottom: 1px solid #dadada;
}

.left_container {
  border-radius: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 2rem;
}

.left_container > img {
  width: 2rem;
  height: 2rem;
}

.right_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 1px solid blue; */
}

.name {
  font-size: 1rem;
  font-weight: 500;
  color: #ed1e24;
}

.designation {
  font-size: 0.875rem;
  font-weight: 400;
  color: #555555;
}

.location {
  font-size: 0.75rem;
  color: #858585;
}

.button {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #ed1e24;
  text-align: center;
  padding: 0.25rem 1rem;
  border: 1px solid #ed1e24;
  border-radius: 1.5625rem;
}

.view_icon {
  width: 2rem;
  cursor: pointer;
}


/* ------------ Media Queries ------- */

@media (max-width: 800px) {
  .button {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
  
}