.dropdown {
  position: relative;
  font-family: 'Montserrat';

}
.dropdown.open > .dropdown-menu {
  display: block;
}
.dropdown .dropdown-toggle {
  display: inline-block;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #ED1E24;   
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border-radius:50px;
  padding-top:12px;
  padding-bottom:12px;
  width:100%;
  background: white;
  color:#ED1E24;
  cursor: pointer;
}
.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9 ;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown .dropdown-menu {
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 100%;
  /* min-width: 160px; */
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* border: 1px solid #ccc; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown .dropdown-menu li {
  cursor: pointer;
}
.dropdown .dropdown-menu li :hover {
  background: #ddd;
  background-color: #ED1E24;
}
.dropdown .dropdown-menu li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdownIcon{
  color:#ED1E24;
  margin-left:6px;
}



@media only screen and (max-width: 800px) {

  .dropdown .dropdown-toggle {
    font-size: 14px;
    padding-top:12px;
    padding-bottom:12px;
    padding-left: 12px;
    padding-right: 12px;

  }
  .dropdown .dropdown-toggle .caret {
    margin-left: 2px;
  }
  .dropdown .dropdown-menu {
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    width:auto;

  }
  .dropdown .dropdown-menu li > a {
    padding: 5px 10px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  }

  .dropdownIcon{
    color:#ED1E24;
    margin-left:6px;
  }
}


@media only screen and (max-width: 500px) {

  .dropdown .dropdown-toggle {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .dropdown .dropdown-menu li > a {
    padding: 5px 8px;
  }
  .dropdown .dropdown-menu {
    font-size: 13px;
    left: 0px;
  }
}