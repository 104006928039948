.main{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 5px;
}

.text_input {
    outline: none;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    line-height: 24px;
    margin-left: 4px;
    width: 100%;
}

.text_input:focus {
    border-bottom: 1px solid #000000;
    outline: none;
}

.text_input::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.correct {
    opacity: 0;
    display: flex;
    align-items: center;
    flex-wrap: none;
    flex-direction: row;
}

.correct > span {
    font-family: 'Montserrat';
    font-size: 16px; 
    font-weight: 400;
    line-height: 24px;
    white-space:nowrap;
    margin: 0 5px;
}

.correct > img {
    width: 12px;
    cursor: pointer;
    padding-right: 5px;
}

input:focus ~ .correct {
  opacity: 1;
}

.correct + img {
    /* visibility: hidden; */
    width: 18px;
    height: 16px;
    margin-right: 2rem;
    margin-left: 5px;
}

/* -- for grid questions */
.text_input_grid {
    outline: none;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    line-height: 24px;
    margin-left: 4px;
    margin-right: 2rem;
}

.text_input_grid:focus {
    border-bottom: 1px solid #000000;
    outline: none;
}

.text_input_grid::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.grid_choice {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
}

.text_input_grid + img {
    opacity: 0;
    width: 12px;
    cursor: pointer;
    margin-right: 1rem;
}

.text_input_grid:focus ~ img {
    opacity: 1;
}

.upload_icon {
    width: 1.5rem;
    cursor: pointer;
    padding-left: 8px;
  margin-right: 10px;
  
}

.image_container {
    width: 50%;
}

.fib_option, .img_empty_option{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fib_cross, .img_cross {
    padding-top: 12px;
    margin-left: auto;
    cursor: pointer;
}

.image_checked {
    margin-left: auto;
    margin-right: 25px;
    width: 18px;
    height: 16px;
}

@media (max-width: 580px){
    .grid_choice_inner{
        display: flex;
        flex-direction: row;
    }
    .text_input_grid {
        width: 50%;
    }
}