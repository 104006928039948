.main {
  margin-top: 1.5rem;
  box-sizing: border-box;
  color: #212121;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main_disabled {
  pointer-events: none;
  margin-top: 1.5rem;
  box-sizing: border-box;
  color: #212121;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.fib_choice_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  justify-items: start;
  align-items: baseline;
  margin-bottom: 1rem;  
}

.span_container {
  margin-top: 1rem;
  text-transform: capitalize;
}

.list_number_span {
  margin-right: 0.5rem;
  font-weight: 500;
}

.dropdown_container {
  display: flex;
  align-items: center;
  min-width: 150px;
  max-width: 80%;
  box-sizing: border-box;
  /* width: fit-content; */
}


.grid_choice_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  justify-items: start;
  align-items: baseline;
  margin-bottom: 1rem;  
  width: 45%;
}

.image_choice {
  width: 40%;
}

.answer_container {
  width: 80%;
}

.not_found_text {
  color: #ED1E24;
  margin: auto;
}

.correct_incorrect_div{
  display: flex;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;

}

.correct_incorrect_div > img {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.correct_answer {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;
  color: #46CB4C;
}

.answer_label {
  font-weight: 600;
}

.answer_label_mcq {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;
  color: #46CB4C;
  font-weight: 600;
}

.correct_answer_mcq {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #46CB4C;
}

.not_graded_yet_text {
  color: #E81123;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem; 
}

@media (max-width: 580px){
  .main span{
    color: #212121;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 184.615% */
    letter-spacing: 0.02px;
  }
  .grid_choice_container {
    column-gap: 3.5rem;
    width:auto;
  }
  .span_container {
    white-space: nowrap;
  }
  .correct_incorrect_div{
    display: flex;
    text-align: left;
    margin-top: 1rem;
    color: var(--Wrong, #E81123);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
  }
  .correct_answer {
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
    margin-top: 0px;
    margin-left: 30px;
  }
  .answer_label_mcq {
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
    margin-top: 0px;
  }
  .correct_answer_mcq {
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
  }
  .not_graded_yet_text {
    color: #E81123;
    font-family: Montserrat;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin-top: 1rem; 
  }
}