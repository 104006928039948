.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}
.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}
.feed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130vh;
}
.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.form_settings_container {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.form_container {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  width: 100%;
  flex: 2 1;
}
.input_label {
  font-size: 13px;
  /* margin: 0 10px; */
  /* padding-left: 1rem; */
  /* margin-left: 0px; */
  /* margin-top: 20px; */
  font-weight: normal;
}

/* .add_template {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 99.5%;
  margin-top: 20px;
  margin-bottom: 0px;
  font: normal normal 500 16px Montserrat;
} */

.input_title_container {
  display: flex;
  align-items: center;
  font: normal normal 500 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin: 15px 0;
}
.label_icon {
  margin-right: 10px;
}
.input_field {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.input_field1 {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 140px;
  outline: none;
  padding: 17px 0px 15px 17px;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}

.settings_container {
  flex: 1;
  margin-left:10px;
}

.tooltip_icon{
  margin-left: 0.5rem;
  height: 0.8rem;
}

/* MEDIA */

@media (max-width: 1450px) {
  .feed_container {
    width: 120vh;
  }
}

@media (max-width: 960px) {
  .feed_container {
    width: 90%;
  }
  .modal_container {
    width: 90%;
  }
}
@media (min-width: 780px) and (max-width: 800px){
  .main_container {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .main_container {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .max_due_container {
    display: flex;
    flex-direction: column;
  }
  .modal_container {
    width: 85%;
  }
  .date_time_container {
    flex-direction: column;
  }
  .date_time_container > div {
    margin-bottom: 10px;
  }
  .back_btn{
    display: none;
  }
  .close_icon {
    width: 22px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 510px) {
  .discard_modal_container {
    width: 83%;
  }
  .form{
    margin: 0 5px !important;
  }
  .form_container {
    box-shadow: none;
    padding: 0px;
  }
}

@media (max-width: 380px) {
  .discard_modal_container {
    width: 76%;
  }
}
.form{
  margin: 0 30px;
}
input::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
.modal_form{
  margin: 0 15px;
}

.due_date_time_container{
  margin-left: 20px;
  margin-top: 0px;
}
@media (max-width: 780px) {
  .due_date_time_container {
    margin-left: 0px ;

  }
}
@media (max-height: 700px) {
  .input_title_container {
    font: normal normal 600 14px Montserrat;
  }
}

.loader_style{
  text-align: center;
   margin-top: 200px
}

.radio_group{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.tooltip_icon{
  margin-right: 14px;
}

@media(max-width: 580px){
  .settings_container{
    display: none;
  }
  .input_title_container {
    font: normal normal 500 14px Montserrat;
  }
  .input_field1 {
    min-height: 251px;
  }
  .mob_btn{
    float: right;
    margin-top: 15px;
  }
  .setting_btn{
    
  }
}