.main {
  margin: auto;
  margin-top: 7.5rem;
  margin-bottom: 2.5rem;
  /* border: 1px solid red; */
  width: 72%;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.loader {
  height: 90vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top_container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  align-items: center;
  /* border: 1px sollid violet; */
}

.select {
  box-sizing: border-box;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  background: rgb(255, 255, 255);
  cursor:pointer;
  padding: 0.25rem .875rem;
  color: #676767;
}

.selected {
  box-sizing: border-box;
  border: 1px solid rgb(237, 30, 36);
  border-radius: 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: rgb(237, 30, 36);
  cursor:pointer;
  padding: 0.25rem .875rem;
}

.tags_container {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.tags {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  box-sizing: border-box;
  border: 1px solid rgb(237, 30, 36);
  border-radius: 50px;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 400;
  color: rgb(237, 30, 36);
  background: white;
  cursor:pointer;
  padding: 0.25rem 0.875rem;
  text-transform: capitalize;
  cursor: pointer;
}

.tags > img {
  width: 1.25rem;
  cursor: pointer;
}

.search_header {
  margin-top: 1rem;
}

.middle_container {
  display: flex;
  gap: 1rem;
  width: 100%;
  /* border: 1px solid green; */
}

.search_section {
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 72%;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin-top: 1rem;
}

.connections_section {
  /* display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem; */
  margin-top: 1rem;
  width: 28%;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  min-height: 20rem;
  margin: 14rem 8rem;
}

.pagination_container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


/* ----------- Media Queries ---------- */

@media (max-width: 800px) {

  .main {
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    width: 90%;
  }

  .connections_section {
    display: none;
  }

  .search_section {
    width: 100%;
  }

  .tags {
    font-size: 0.875rem;
    padding: 0.2rem 0.5rem;
  }

  .tags > img {
    width: 1rem;
    cursor: pointer;
  }

  .noResults {
    margin: 2rem 0
  }

  .noResults > img {
    width: 14rem;
  }
  
}
