.name_container {
  display: flex;
  align-items: center;
}

.student_name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  margin-right: 10px;
}

.class_name {
  color: #636363;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
  letter-spacing: -0.14px;
}

.avatar {
  margin-right: 18px;
}
/* 
  @media (max-width: 800px) {
    .name_container {
      margin-bottom: 20px;
    }  
  } */

/* @media (max-width: 600px) {
  .name_container,
  .student_name,
  .avatar {
    display: flex;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    white-space: nowrap;
  }
} */

@media (min-width: 320px) and (max-width: 480px) {

  .student_name {
    font-size: .875rem;
  }


}
