.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.lecture_title {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 0.875rem;
  color: #000;
}

.lecture_last_name{
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.lecture_last_name > span{
  margin-left: 10px;
}

.lecture_last_name img{
  width: 15px;
  margin-right: 5px;
}

.lecture_title > img {
  width: 1rem;
  height: 1rem;
  padding: 0 0.75rem;
}

.icons {
  margin-right: 1rem;
}

.icons > img {
  width: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.icons_disabled {
  margin-right: 1rem;
}

.icons_disabled > img {
  width: 1rem;
  margin-left: 1rem;
}

@media (max-width:500px){
  .main {
    border-radius: 19px;
  }

  .lecture_title {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: flex-start;
    margin-left: 5px;
  }
  
  .icons {
    margin-right: 0px;
  }

  .lecture_last_name{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 0px;
  }

  .lecture_last_name img{
    width: 15px;
    margin-right: 5px;
  }

  .lecture_last_name > span{
    margin-left: 10px;
    word-break: break-all;
  }
}
