.main {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: fit-content;
    padding: 1.5rem 1.5rem;
    padding-top: 5px;
    border-radius: 25px;
    width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main > img {
    width: 7rem;
    padding: 10px 0;
  }
  
  .question_text {
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
  
  .btn_div {
    display: flex;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* margin-bottom: 20px; */
    width: 100%;
  }
  
  .btn_label {
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.0625rem;
    letter-spacing: 0em;
    text-align: left;
  }
  /*   
    @media(max-width: 600px){
      .action_modal_container {
        width: 75%
      }
    }
    
    @media(max-width: 400px){
      .action_modal_container {
        width: 70%
      }
    } */
  
  @media (max-width: 600px){
    .main{
      width:auto;
    }  
  }