.myCourseCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 8px;
    min-width: 257px;
    max-width: 257px;
    height: 365px;
    margin: 0 15px 15px 0;
    cursor: pointer;
    position: relative;
}

.myCourseCard img {
    width: 100%;
    height: 160px;
    border-radius: 8px 8px 0 0;
}

.myCourseCardContent {
    margin: 15px;
}

.myCourseCardContent h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #A6A6A6;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.myCourseCardContent h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
    padding-bottom: 10px;
}

.myCourseCardContent h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ED1E24;
    padding-bottom: 10px;
}

.studentCount {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.studentCount img {
    height: 20px;
    width: 20px;
    padding-right: 10px;
}

.classTagPara {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ED1E24;
    padding: 10px 0 0 10px;
}

.progress_icon > img{
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
    z-index: 2;
}

.cardImage{
    width: 100%;
}

.cardImage::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px 10px 0px 0px;
}

.draft {
    text-transform: uppercase;
    border: 1px solid #f80300;
    background-color: #FF6666;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: .875rem;
    font-weight: bold;
    width: fit-content;
    /* letter-spacing: 0.25rem; */
    height: fit-content;
    font-family: 'Courier New', Courier, monospace;
  }

.published {
    text-transform: uppercase;
    border: 1px solid #40A578;
    background-color: #9DDE8B;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: .875rem;
    font-weight: bold;
    margin-left: auto;
    width: fit-content;
    letter-spacing: 0.25rem;
    font-family: 'Courier New', Courier, monospace;
}