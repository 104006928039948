.outerContainer {
  display: flex;
  gap: 10px;
  padding-top: 13px;
}

.innerContainer {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px;
}

.name {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
}

.titleDiv {
  display: flex;
  gap: 12px;
  flex-grow: 1;
  padding-bottom: 5px;
}

.timestamp {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #858585;
  order: 2;
  margin-left: auto;
}

.second {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #858585;
}

.message {
  font: normal normal normal 14px/17px Montserrat;
  letter-spacing: 0px;
  color: #555555;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.likeDiv {
  padding-left: 10px;
  display: flex;
  gap: 17px;
}

.bar {
  width: 0px;
  height: 19px;
  border-left: 1px solid #e5e5e5;
  opacity: 1;
}
