.MessageSender {
  /* margin-top: 10px; */
  background-color: #fff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
}

.messageSender_top {
  background-color: white;
  padding: 10px 0 0 0;
  /* padding-bottom: 10px; */
  border-radius: 10px;
  margin-bottom: 20px;
}

.messageSenderDivider {
  height: 0px;
  /* border-bottom: 1px solid #E5E5E5; */
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.message_input {
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
  box-shadow: 0px 0px 0px #3f3f411a;
}

.message_input > form {
  display: flex;
  width: 100%;
}

.message_input > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

/* .message-input > form > button {
  margin-left: 200px;
  border: 1px solid blue;
  border-radius: 30px;
  padding: 5px 35px;
  cursor: pointer;
  background-color: white;
} */
/* 
.message-input > form > button:hover {
  color: blue;
} */

.messageSender_bottom {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  /* justify-content: space-evenly; */
  padding-bottom: 10px;
  padding-top: 10px;
}

.messageSender_rightDiv {
  order: 2;
  margin-left: auto;
  display: flex;
  gap: 25px;
}
/* @media (max-width:1099px){
  .MessageSender{
    margin-top:60px;
  }
} */
@media (max-width: 1050px) {
  .messageSender_rightDiv {
    gap: 0px;
  }
}
