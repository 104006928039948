.main {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  padding-top: 5px;
  padding: 30px;
  border-radius: 20px;
  width: 30vw;
  min-width: 340px;
}

.middle_container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin: 1rem 0rem;
}

.description {
  margin-left: 1rem;
  color: #707070;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.count_div {
  margin: 1rem;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-bo */
}

.count_div > img {
  margin-right: 14px;
}

.buttons_div {
  display: flex;
  justify-content: end;
  width: 100%;
}

@media (max-width: 580px) {
  .main {
    width: 30vw;
    min-width: 320px;
    padding: 20px;
  }
  .description {
    color: #707070;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.25px;
    margin-left: 0px;
  }
  .count_div {
    margin: 10px 0px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-bo */
  }
  .count_div span{
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btn_label{
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
}