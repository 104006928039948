.PictureCard{
    position: relative;
    margin-top: 4rem;
    /* height:75vh; */
    width:80%;
    box-sizing: border-box;
}
.PictureCard-bg{
    position: absolute;
    width: 100vw;
    /* height: 100vh; */
    margin-left: -260px;
    /* background-position: right; */
    background-size: contain;
    background-repeat: no-repeat;
}
.PictureCard-wrapper{
    display: flex;
    justify-content: space-between;
    gap:50px;
    align-items: center;
}
.PictureCard-image{
    width: 50%;
}
.PictureCard-text{
    width: 50%;

}
.PictureCard-text>h4{
    /* text-transform:capitalize;
    font-weight: 700;
    font-size: 54px;
    line-height: 130%;
    color: #000000; */
    color: rgb(29, 29, 29);
    font-family: Montserrat;
    font-size: 60px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0%;
    text-align: left;
}
.PictureCard-text>p{
    /* margin-top:12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 215%; */
    color: rgb(29, 29, 29);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 194%;
    letter-spacing: 2%;
    text-align: left;
}

.PictureCard-image >img{
    width:100%;
}

@media only screen and (max-width: 1024px) {
    .PictureCard{
        margin-bottom: 100px;
        height:auto;
    }
    .PictureCard-wrapper{
        display: flex;
        /* flex-direction: column !important; */
        flex-direction: column-reverse !important;
        justify-content: center !important;
        align-items: center;
        gap:0;
    }
    .PictureCard-image{
        width: 500px;
        height: 500px;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 48px;
    }
    .PictureCard-text>p{
        margin-top:12px;
        font-size: 16px;
        line-height: 215%;
    }
    
    .PictureCard-image >img{
        width:100%;
    }
    
}

@media only screen and (max-width: 650px) {
    .PictureCard{
        margin-bottom: 50px;
    }
    .PictureCard-image{
        width: 100%;
        height: 60%;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 32px;
    }
    .PictureCard-text>p{
        margin-top:12px;
        font-size: 16px;
        line-height: 175%;
    }
}

@media only screen and (max-width: 480px) {
    .PictureCard{
        margin-bottom: 60px;
    }
    .PictureCard-image{
        width: 100%;
        height: 60%;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 1.25rem;
    }
    .PictureCard-text>p{
        margin-top:8px;
        font-size: .75rem;
        line-height: 175%;
    }
}