@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* @namespace example url(); */

* {
  /* margin: 0px; */
  font-family: "Montserrat", sans-serif;
}
/* 
.dashapp{
  padding-top: 100px;
}

@media (max-width:1099px){
  .dashapp{
    padding-top: 50px;
  }
} */

@media (max-width: 786px) {
  .dashboardFeedMiddleRow {
    max-width: 100vw;
  }
}
