.main {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  font-family: Montserrat; 
}

.hide_connect_label {
  opacity: 0;
}

.boost_btn {
  border-radius: 50px;
  padding: 1rem;
  text-align: center;
  border: 1.2px solid #ed1e24;
  color: #ed1e24;
  margin: 0.5rem 0 1.5rem 0;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}

.boost_btn p {
  font-size: 1rem;
  font-weight: 600;
  font-family: Montserrat; 
  color: #ed1e24;
}

.boost_btn img {
  width: 1.5rem;
  margin-right: 0.75rem;
}

.text_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity_container {
  background-color: #f6f6f6;
  padding: 1rem;
  border-radius: 0.75rem;
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 1.5rem;
  font-family: Montserrat; 
}

.post_label {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.625rem 0;
  padding-bottom: 0;
  box-sizing: border-box;
  font-family: Montserrat; 
}

.manage_label {
  color: #707070;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  margin-left: 4.2rem;
  margin-top: 0;
  box-sizing: border-box;
  font-family: Montserrat; 
}
