.Class_Sidebar {
  text-align: center;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  height: fit-content;
  z-index: 5;
  /* width:250px;
  margin-left: 70px; */
}
.Classnavbar_menu1 {
  z-index: 11;
  height: 0;
  width: 24px;
}
.Classsidebar_mycontent {
  display: flex;
  justify-content: space-between;
}
.dropdown_menu1 {
  width: 184px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #d6cccc29;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
  position: relative;
  right: 160px;
}

.dropdown_menu1 ul {
  list-style: none;
  padding: 3px 0px;
}

.dropdown_menu1 li {
  padding: 4px 12px;
}
.menu1 {
  text-align: left;
  font-size: 12px;
}

.Class_side {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.Classsidebar_icon1 {
  background: #ED1E24 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}
.video {
  height: 70px;
  width: 70px;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 8px;
  margin: 0 10px;
}
.live_session {
  margin-top: 20px;
  align-self: stretch;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.live_session:hover {
  background-color: #ED1E24;
  color: #ffffff;
}

.Classsidebar_icon2 {
  color: #929292;
}

.Classsidebar_mycontent > h3 {
  font: normal normal medium 16px/19px Montserrat;
}

.Classsidebar_stats {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.Classsidebar_stat {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
  cursor: pointer;
}

.Classsidebar_stat > p {
  color: #555555;
  font: normal normal normal 14px/18px Montserrat;
}

.ClassHr {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
