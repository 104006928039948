.main {
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.5fr 0.5fr 0.5fr 0.6fr;  
  align-items: center; 
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1.25rem;
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.05),
    0px 2px 8px 0px rgba(25, 33, 61, 0.04);
  border-radius: 10px;
  padding: 1.5rem;
  box-sizing: border-box;
}

.btn_label {
  color:#ed1e24;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.view_btn {
  background-color: #FFCFD0;
  border-color: #ED1E24;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .625rem .75rem;
  width: fit-content;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  color: #ED1E24;
}

.view_btn > img {
  width: 24px;
  margin-right: 10px;
}

.view_btn:active { 
  transform: scale(0.98); 
} 

/* -------- MEDIA QUERIES ---------- */

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  .hide {
    display: none;
  }

  .main {
    display: grid;
    grid-template-columns: 0.25fr 1.5fr 1fr;
    font-size: .875rem;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 1.25rem;
  }

  .view_btn {
    font-size: .875rem;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .hide {
    display: none;
  }
  .main {
    display: grid;
    grid-template-columns: 0.25fr 1.5fr 1fr;
    font-size: .875rem;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 1.25rem;
  }

  .view_btn {
    font-size: 0.875rem;
  }
  
}