.layout{
    /* background-image: url("../../../animation/Hero/bgCombinedDesk.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    background-position-y: 13%;
}

@media only screen and (max-width: 480px) {
    
    /* .layout{
        background-image: url("../../../animation/Hero/bgCombinedMob.svg");
    } */
    
}