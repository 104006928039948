.boxContainer {
  display: flex;
  flex-direction: column;
  /* margin-top: 200px; */
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 10px;
}

.partOne {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.partOne>div {
  flex: 1;
}

.leftChild {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  gap: 8px;
  background-color: red;
  height: 44px;
  width: 354px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}

.leftChild span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.rightChild {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.rightChild span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.rightChild img {
  margin-right: 22px;
  cursor: pointer;
}

#requiredIcon {
  margin-right: 5px;
}

.choices {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.addChoices {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}

.addChoices > img {
  width: 22px;
}

.maxMarksSpan {
  white-space: nowrap;
  padding-right: 10px;
}

.rightChild > input {
  border: 1px solid #555555;
  border-radius: 60px;
  /* padding: 0 0.4rem; */
  text-align: center;
  width: 2.7rem;
  line-height: 1.5rem;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}




@media(max-width: 630px) {
  .rightChild img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  .leftChild {
    padding: 6px 7px;
    width: 70%;
    border-radius: 8px;
    height: 28px;
    margin-top: 10px;
  }

  .boxContainer {
    width: 89%;
  }

  .partOne {
    display: block;
  }

  .rightChild {
    justify-content: center;
  }

  .rightChild span {
    font-size: 13px;
    line-height: 10px;
    font-weight: 500;
  }

  .leftChild img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .leftChild span {
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media(max-width: 515px) {
  .leftChild {
    padding: 3px 3.5px;
  }

  #requiredIcon {
    width: 25px;
    height: 25px;
  }
}

@media(max-width: 405px) {

  #requiredIcon {
    width: 20px;
    height: 20px;
  }

  .leftChild {
    gap: 4px;
  }

}