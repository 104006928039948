@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap);
/* ADS */
.slideshow {
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 100ms;
}

.slide {
  display: inline-flex;
  height: 150px;
  width: 100%;
  border-radius: 10px;
}

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 7px 0px;
  background-color: #fff;
}

.slideshowDot.active {
  width: 20px;
}

#scroll_div::-webkit-scrollbar {
  display: none;
}

.scroll_div::-webkit-scrollbar {
  display: none;
}

#scroll_div,
.scroll_div,
select {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.App {
  font-family: "Montserrat", sans-serif;
  --primary: #297ced;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0px;
  padding: 0px;
}

.fixed {
  position: fixed;
}

.button {
  outline: none;
  cursor: pointer;
}

.noul {
  text-decoration: none;
}

.s13 {
  font-size: 13px;
}

.s14 {
  font-size: 14px;
}

.s18 {
  font-size: 18px;
}

.s24 {
  font-size: 24px;
}

.s40 {
  font-size: 40px;
}

.s15 {
  font-size: 15px;
}

.cfff {
  color: #ffffff;
}

.c333 {
  color: #333;
}

.c777 {
  color: #777;
}

.b000 {
  color: #000;
}

.flex {
  display: flex;
}

.aic {
  align-items: center;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.fsfb {
  font-size: 40px;
  font-weight: bolder;
  margin-bottom: 30px;
}

.mb30 {
  margin-bottom: 30px;
}

.abc {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.abcb:before {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
}

.n {
  font-weight: normal;
}

.anim {
  transition: all 0.2s linear 0s;
}

.header {
  top: 0px;
  left: 0px;
  right: 0px;
  background: #ffffff;
  z-index: 9;
  padding: 30px;
  height: 65px;
  box-sizing: border-box;
}

.header .nav {
  flex: 1 1;
}

.header .user {
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.header .you div {
  width: 45px;
  height: 45px;
  background: #0066ff;
  color: #fff;
  border-radius: 50%;
}

.hclr {
  height: 85px;
}

/*HOMEPAGE*/
.home {
  /* padding: 100px 0px 100px 100px; */
  width: 100%;
  top: 0px;
  display: flex;
}

.home .meta {
  width: 50%;
  float: left;
  margin-left: 9%;
  margin-right: 5%;
}

.meta {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .meta .tagline {
  margin: 15px 0px 40px 0px;
}

.home .meta .actions button {
  border: 0px;
  padding: 25px 25px;
  border-radius: 27px;
  background: #ED1E24;
  margin-right: 10px;
  height: 40px;
}

.actions {
  display: flex;
}

.gap {
  margin-bottom: 0;
}

.home .meta .actions button div {
  margin: 0px 8px 0px 0px;
}

.famr {
  margin-right: 8px;
}

.home .meta .actions input {
  border: 1px #9a9a9a solid;
  padding: 15px 30px 15px 15px;
  border-radius: 27px;
  margin-right: 10px;
  height: 20px;
  outline: none;
}

.home .meta .actions input:focus {
  border: 2px solid #ED1E24;
}

.home img {
  width: 50%;
  height: 100vh;
  object-fit: cover;
  object-position: left;
  float: right;
  z-index: -2;
}

.home .tablet-frame {
  flex: 1 1;
  margin-left: 75px;
}

.home .tablet-frame .tablet {
  width: 700px;
  height: 460px;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0px 0px 0px 15px #dadada;
}

.home .tablet-frame .tablet .notch {
  width: 20px;
  height: 20px;
  background: #ccc;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
}

.home .tablet-frame .tablet .hbtn {
  width: 40px;
  height: 40px;
  background: #ccc;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 20px;
}

.home .tablet-frame .tablet .screen {
  border: 2px #dadada solid;
  top: 20px;
  right: 55px;
  bottom: 20px;
  left: 80px;
}

.home .tablet-frame .tablet .screen img {
  height: 100%;
  width: 100%;
}

/*MEETING PAGE*/
.meeting {
  width: 960px;
  margin: 0 auto;
}

.meeting .screen {
  width: 675px;
  height: 400px;
  background: #000;
  margin-right: 50px;
  border-radius: 10px;
}

.meeting .screen .btns {
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.meeting .screen .btns button {
  width: 50px;
  height: 50px;
  border: 0px;
  margin: 0px 5px;
  border-radius: 50%;
  background: #0066ff;
}

.meeting .screen .btns button:hover {
  background: #2a7fff;
}

.meeting .screen .btns button .tip {
  opacity: 0;
  -webkit-user-select: none;
          user-select: none;
  background: #444;
  white-space: nowrap;
  padding: 6px 10px;
  border-radius: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: -40px;
  pointer-events: none;
}

.meeting .screen .btns button .tip:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 8px rgb(68 68 68 / 0%) solid;
  border-top: 8px #444 solid;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.meeting .screen .btns button:hover .tip {
  opacity: 1;
  top: -35px;
}

.meeting .stats {
  margin-top: 10px;
  white-space: nowrap;
}

.meeting .stats {
  flex: 1 1;
}

.meeting .stats .btns {
  margin-top: 30px;
}

.meeting .stats .btns button {
  border: 2px solid #236ae8;
  padding: 15px 30px;
  border-radius: 27px;
  background: #ffffff;
  margin-right: 10px;
  height: 50px;
  color: #0066ff;
}

.meeting .stats .btns button:hover {
  background-color: #236ae8;
  color: white;
}

.classGroupIndividually {
  margin-top: 150px;
}

/* ############################ Responsive 1330 ############################ */
@media only screen and (max-width: 1330px) {
  .home img {
    width: 40%;
  }
}

/* ############################ Responsive 1074 ############################ */
@media only screen and (max-width: 1074px) {
  .actions {
    display: block;
  }

  .gap {
    margin-bottom: 10px;
  }

  .home img {
    width: 50%;
  }
}

/* ############################ Responsive 900 ############################ */
@media only screen and (max-width: 900px) {
  .s40 {
    font-size: 32px;
  }
}

/* ############################ Responsive 900 ############################ */
@media only screen and (max-width: 900px) {
  .home .meta .tagline {
    margin: 10px 0px 20px 0px;
  }

  .s15 {
    font-size: 12px;
  }

  .meta {
    margin-top: 100px;
    justify-content: flex-start;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

/* ############################ Responsive 770 ############################ */
@media only screen and (max-width: 770px) {
  .videoChatHome {
    display: none;
  }

  .home .meta {
    width: 100%;
    float: none;
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
  }

  .s40 {
    font-size: 40px;
    text-align: center;
  }

  .s15 {
    font-size: 16px;
    text-align: center;
  }
}

/* ############################ Responsive 375 ############################ */
@media only screen and (max-width: 375px) {
  .s40 {
    font-size: 32px;
    text-align: center;
  }

  .s15 {
    font-size: 14px;
    text-align: center;
  }
}
.nav {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  background-color: transparent;
  text-align: center;
  align-items: center;
  width: 100vw;
  z-index: 5;
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)); */ 
  /* background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%); */
  background-color: #ffeded;
  box-sizing: border-box;
  padding: 10px 200px;
  transition: 0.2s ease-in-out;
}
.nav-active {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: space-between;
  background-color: white;
  text-align: center;
  align-items: center;
  padding: 20px 0;
  width: 100vw;
  z-index: 5;
  box-sizing: border-box;
  /* padding: 10px 260px; */
  padding: 10px 200px;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
  transition: 0.2s ease-in-out;
}
.logo-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo {
  height: 35px;
  width: auto;
  padding-right: 50px;
}
.navlink {
  color: black;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  font-weight: 400;
  transition: 0.2s ease-in-out;
}
.navlink > a {
  margin-left: 20px;
}
.navlink-active {
  color: #9c9c9c;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  font-weight: 400;
  transition: 0.2s ease-in-out;
}
.navlink-active > a {
  margin-left: 20px;
  transition: 0.2s ease-in-out;
}
.signin {
  padding: 15px 45px !important;
  /* background-color: white; */
  background-color: black;
  border-radius: 25px;
  /* color: #ED1E24; */
  color:white;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.signin:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.signin-header {
  text-decoration: none;
}
.join-header {
  text-decoration: none;
  border: 1px solid black;
  font-weight: 700;
  padding: 15px 45px !important;
  background-color: transparent;
  border-radius: 25px;
  text-transform: uppercase;
  color: black;
}
.join-header:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  text-transform: uppercase;

}
.join-header-active {
  text-decoration: none;
  color: black;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px 45px !important;
  background-color: white;
  border-radius: 25px;
  border: 1px solid black;
}
.join-header-active:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.signin-active {
  padding: 15px 45px !important;
  background-color: black;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 25px;
  color: white;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.signin-active:hover {
  opacity: 0.8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}

/* ----------------------------Mobile nav----------------------- */

.nav-mobile {
  position: fixed;
  z-index: 5;
  display: none;
}
.nav-icon {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
  padding: 15px 0;
}
.menu_icon {
  width: 30px;
  height: auto;
}
.nav-icon :nth-child(1) {
  margin-left: 40px;
  width: 150px;
  height: auto;
}
.nav-icon :nth-child(2) {
  margin-right: 40px;
  width: 50px;
  height: auto;
}

.side-nav {
  position: absolute;
  right: -300px;
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 10px 20px rgba(85, 85, 85, 0.16);
  transition: right 0.3s ease-in-out;
  padding-top: 30px;
}
.side-nav-active {
  right: 0 !important;
  transition: right 0.3s ease-in-out;
}
.side-nav-links > div {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 500;
}
.side-nav-links > div > a {
  text-decoration: none;
  color: black;
}

.language-header{
  text-decoration: none;
  padding: 15px 10px 15px 45px!important;
  background-color: transparent;
  /* border-radius: 25px; */
  color: white;
}
.langTextDiv{
  align-items: center;
  margin: auto;
}
.language-div{
  display: flex;
  cursor: pointer;
  position: relative;
}
.language-div-dark{
  display: flex;
  color: #ED1E24;
  color:black;
  cursor: pointer;
  position: relative;
}
.togglerLang{
  position: relative;
}
.dropdownLanguage{
  width: 100px;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  top: 80%;
  left: 40%;
  opacity: 1;
  background-color: #fff;
  z-index: 10;
}

.dropdownList{
  list-style: none;
  padding: 0;
}

.inactiveList{
  margin-top: 6; 
  color: #555555;
  cursor: "pointer"
}

.activeList{
  margin-top: 6; 
  color: #ED1E24;
  cursor: "pointer"
}



/* ############################ Responsive 1350 ############################ */
@media only screen and (max-width: 1350px) {
  .nav,
  .nav-active {
    padding: 10px 120px;
  }
}
/* ############################ Responsive 1024 ############################ */
@media only screen and (max-width: 1024px) {
  .nav,
  .nav-active {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  
  .dropdownLanguage{
    top: 90%!important;
    left: 0% !important;
  }

}
.cards{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top:24px;
    padding-left:24px;
    padding-right: 24px;
    min-width: 340px;
    height: 130px;
    background: #FFFFFF;
    /* box-shadow: 8px 8px 34px rgba(0, 0, 0, 0.08); */
    border:solid 1px #ebebeb;
    border-radius: 25px;
}

.cards>img{
    width: 60px;
    height:60px;
}

.cards>p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 105%;
}
@media only screen and (max-width: 950px) {
    .cards{
        padding-top:24px;
        padding-left:24px;
        padding-right: 24px;
        min-width: 315px;
        height: 110px;
    }
    
    .cards>img{
        width:50px;
        height:50px;
    }
    
    .cards>p{
        font-size: 22px;
    }
}


@media only screen and (max-width: 650px) {
    
    .cards{
        padding-top:24px;
        padding-left:24px;
        padding-right: 24px;
        width: 100%;
        min-width: 280px;
        height: 110px;
    }
    
    .cards>p{
        font-size: 16px;
        line-height: 105%;
    }
    .cards>img{
        width:40px;
        height:40px;
    }
    
}

@media only screen and (max-width: 480px) {
    .cards{
        min-width: 220px;
        height: 58px;
        border-radius: 22px;
        padding-top:12px;
        padding-left:12px;
        padding-right: 12px;
    }
    
    .cards>img{
        width: 30px;
        height:30px;
    }
    
    .cards>p{
        font-size: 11px;
    }
}
.InfoCard{
    box-sizing:border-box;
    width: 100%;
    height: 300px;
    padding:10% 5%;
    background: #000000;
    border-radius: 1rem;
    white-space: normal;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0.06rem 0.06rem 0.5rem 0rem rgba(0, 0, 0, 0.25);
}

.InfoCard > h4{
    font-weight: 700;
    font-size: 24px;
    line-height: 146%;
}

.InfoCard >h6{
    margin-top: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 146%;
}

.InfoCard > p{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 146%;
}

@media only screen and (max-width: 1350px) {
    .InfoCard{
        height: 300px;
        padding:10% 8%;
    }
    
    .InfoCard > h4{
        font-size: 22px;
    }
    
    .InfoCard >h6{
        font-size: 18px;
    }
    
    .InfoCard > p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 950px) {
    .InfoCard{
        width: 100%;
        height: 300px;
        padding:10% 8%;
    }
    
    .InfoCard > h4{
        font-size: 18px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 16px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 14px;
    }
    
}

@media only screen and (max-width: 650px) {
    .InfoCard{
        width: 100%;
        height:250px;
        padding:10% 10%;
    }
    
    .InfoCard > h4{
        font-size: 18px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 16px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {

    .InfoCard{
        width: 100%;
        height:200px;
        padding:8% 8%;
    }
    
    .InfoCard > h4{
        font-size: 17px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 12px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 10px;
        -webkit-line-clamp: 3;

    }
}
.PictureCard{
    position: relative;
    margin-top: 4rem;
    /* height:75vh; */
    width:80%;
    box-sizing: border-box;
}
.PictureCard-bg{
    position: absolute;
    width: 100vw;
    /* height: 100vh; */
    margin-left: -260px;
    /* background-position: right; */
    background-size: contain;
    background-repeat: no-repeat;
}
.PictureCard-wrapper{
    display: flex;
    justify-content: space-between;
    grid-gap:50px;
    gap:50px;
    align-items: center;
}
.PictureCard-image{
    width: 50%;
}
.PictureCard-text{
    width: 50%;

}
.PictureCard-text>h4{
    /* text-transform:capitalize;
    font-weight: 700;
    font-size: 54px;
    line-height: 130%;
    color: #000000; */
    color: rgb(29, 29, 29);
    font-family: Montserrat;
    font-size: 60px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0%;
    text-align: left;
}
.PictureCard-text>p{
    /* margin-top:12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 215%; */
    color: rgb(29, 29, 29);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 194%;
    letter-spacing: 2%;
    text-align: left;
}

.PictureCard-image >img{
    width:100%;
}

@media only screen and (max-width: 1024px) {
    .PictureCard{
        margin-bottom: 100px;
        height:auto;
    }
    .PictureCard-wrapper{
        display: flex;
        /* flex-direction: column !important; */
        flex-direction: column-reverse !important;
        justify-content: center !important;
        align-items: center;
        grid-gap:0;
        gap:0;
    }
    .PictureCard-image{
        width: 500px;
        height: 500px;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 48px;
    }
    .PictureCard-text>p{
        margin-top:12px;
        font-size: 16px;
        line-height: 215%;
    }
    
    .PictureCard-image >img{
        width:100%;
    }
    
}

@media only screen and (max-width: 650px) {
    .PictureCard{
        margin-bottom: 50px;
    }
    .PictureCard-image{
        width: 100%;
        height: 60%;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 32px;
    }
    .PictureCard-text>p{
        margin-top:12px;
        font-size: 16px;
        line-height: 175%;
    }
}

@media only screen and (max-width: 480px) {
    .PictureCard{
        margin-bottom: 60px;
    }
    .PictureCard-image{
        width: 100%;
        height: 60%;
    }
    .PictureCard-text{
        width: 100%;
        height: 40%;

    }
    .PictureCard-text>h4{
        font-size: 1.25rem;
    }
    .PictureCard-text>p{
        margin-top:8px;
        font-size: .75rem;
        line-height: 175%;
    }
}
.Home-Wrapper{
    padding-top: 3.75rem;
    /* padding-left:200px;
    padding-right:200px; */
    /* padding-left: 8%;
    padding-right: 8%; */
    box-sizing: border-box;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;
    /* background-image: url("../../../animation/Hero/bg1.svg"); */
    /* background-image: url("../../../animation/Hero/bgDesktop.svg"); */
    background-size: contain;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.subLayout{
    padding-left:180px;
    padding-right:180px;
}
.Home-Section1-Wrapper {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    /* border: 2px solid red; */
    -webkit-clip-path: url(#clip-path-section1);
            clip-path: url(#clip-path-section1);  
    /* padding-top: 10rem; */
    padding-left:10%;
    padding-right:8%;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
    
}

.hero-image {
    object-fit: contain;
    width: 100%;
}

.Home-Section1 > h1{
    font-style: normal;
    font-weight: 500;
    font-size: 4.375rem;
    text-align: center;
    color: #000000;
    margin-bottom:24px;
}

.Home-Section1 > p{
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 175%;
    text-align: center;
    color: #000000;

} 

.Home-Section1-buttons{
    margin-top:24px;
    display: flex;
    justify-content: center;
    grid-gap:12px;
    gap:12px;
}

.Home-section1-btn1
,.Home-section1-btn2{
    background: #000000;
    border: 2px solid #000000;
    border-radius: 40px;
    width: 180px;
    height:50px;
    color:white;
    font-weight: 700;   
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
}

.Home-section1-btn1 {
    background: linear-gradient(90.00deg, rgb(237, 30, 36) -30.514%,rgb(238, 130, 130) 116.019%);
    border: none;
}

.Home-section1-btn2{
    border: 2px solid #000000;
    border-radius: 40px;
    background-color: transparent;
    color:#000000;
}

.Home-section2{
    margin-top:48px;
    margin-bottom:48px;
    width: 85%;
    height: 85%;
}

.Home-section3{
   position: relative;
   height:60vh;
   display: flex;
   justify-content: center;
   align-items: center;

}

.Home-section3-group{
    background: #F8F8F8;
    border-radius: 32px;
    display: flex;
    grid-gap:12px;
    gap:12px;
    padding:40px;

}
.Home-section3-stable{
    /* z-index:5; */
    width:40%;
}
.Home-section3-stable >h4{
    font-weight: 700;
    font-size: 40px;
    line-height: 105%;
}
.Home-section3-stable >p{
    font-weight: 400;
    font-size: 16px;
    line-height: 166.5%;
    margin-top:12px;
}
.Home-section3-adCards{
    /* z-index: 4; */
    position:absolute;
    width: 78%;
    background: transparent;
    overflow-x: scroll !important;
    left:42%;
    display: flex;
    grid-gap:12px;
    gap:12px;
}
/* .vertical-line{
    height:10;
    width: 20px;
} */


.Home-section3-adCards::-webkit-scrollbar {
    height:0px;
  }

.new_home_section_3 {
    padding: 0 10%;
}

.new_home_section_title {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 215%;
    letter-spacing: 0%;
    text-align: center;
}

.card-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 6.5%;
    /* justify-content: space-evenly;
    align-items: baseline; */
}

.card {
    box-sizing: border-box;
    padding: 1.875rem;
    border: 1px solid rgb(255, 207, 208);
    border-radius: 1rem;
    box-shadow: 2px 2px 30px 1px rgba(0, 0, 0, 0.2);
    /* Gradient homepage */
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.card:hover {
    background: linear-gradient(180.78deg, rgb(239, 63, 63) -27.067%,rgb(255, 255, 255) 116.436%);
}

.card_thumbnail {
    width: 9.375rem;
    aspect-ratio: 1 / 1;
}

.card-main-text {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 700;
    line-height: 105%;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 1.75rem;
    text-transform: capitalize;
}

.card-sub-text {
    color: rgb(30, 30, 30);
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 145.5%;
    letter-spacing: 0%;
    text-align: center;
    margin-top: .75rem;
    /* margin-top: auto; */
}

.Home-section4{
    height:auto;
    position: relative;
    margin-top:48px;
    width: 100%;
    text-align: center;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    -webkit-clip-path: url(#clip-path-section4);
            clip-path: url(#clip-path-section4);  
    padding-top: 10rem;
    padding-left:10%;
    padding-right:8%;
    padding-bottom: 0;
}

.homepage-video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    -webkit-clip-path: url(#clip-path-section4);
            clip-path: url(#clip-path-section4);  
    padding-top: 10rem;
    padding-left:10%;
    padding-right:8%;

}

.explore-label {
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.13rem;
    font-weight: 700;
    line-height: 215%;
    letter-spacing: 0%;
    text-align: left;
    margin-top: 12rem;
}

.video-container {
    margin-top: 1rem;
    margin-bottom: 11rem;
    width: 60%;
}

.home-section5-new {
    display: flex;
    flex-direction: column;
    align-items: center;        
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    -webkit-clip-path: url(#clip-path-section5);
            clip-path: url(#clip-path-section5);  
    padding-top: 15rem;
    padding-left:10%;
    padding-right:8%;

}

.svg-section4, .svg-section1, .svg-section5 {
    position: absolute;
    width: 0;
    height: 0;
}



.Home-section4-wrapper{
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-left:-260px;
    background-position: right;
    background-image:url(/static/media/bg2.55d20b13.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.Home-section4-video h3{
    font-weight: 700;
    font-size: 3.125rem;
    line-height: 215%;
    color: #000000;
    margin-top: 15.625rem;
}

.Home-section4-video>p{
    margin-top:24px;
    width:100%;
    height:400px;
    border-radius: 56px;
    background:grey;
}

.video-div{
    width: 100%;
}

.video{
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 30px;
    /* margin: 30px auto;  */
    width: 100% !important;
    height: 100% !important;
    padding:0;
}
.video > video{
    border-radius: 20px;
}
.play-btn{
    position: absolute;
    top: -3px;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-section5{
    margin-top: 120px;
    position: relative;
}
.Home-section5-bg{
    position:absolute;
    width:100vw;
    height:75vh;
    background-position: contain;
    background-repeat: no-repeat;
    margin-left:-260px;
    background-repeat: no-repeat;
}   
.bg-1{
    background: url(/static/media/bg3.19c81294.svg);
    background-position:left;
}

.bg-2{
    background: url(/static/media/bg5.dc6c8f39.svg);
    background-position:right;
}

.bg-3{
    background: url(/static/media/bg5.dc6c8f39.svg);
    background-position:left;
}

.bg-4{
    background: url(/static/media/bg5.dc6c8f39.svg);
    background-position:right;
}

.bg-5{
    background: url(/static/media/bg6.cee358b0.svg);
    background-position:right;
}
.Home-section6{
    margin-bottom: 10rem;

    display: flex;
    flex-direction: column;
    align-items: center;
        
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 255, 255) 85.201%);
    -webkit-clip-path: url(#clip-path-section5);
            clip-path: url(#clip-path-section5);  
    padding-top: 15rem;
    padding-left:10%;
    padding-right:8%;
}

.Home-section6>h4{
    /* font-weight: 700;
    font-size: 40px;
    line-height: 196.5%;
    text-align: center; */
    color: rgb(0, 0, 0);
    font-family: Montserrat;
    font-size: 3.13rem;
    font-weight: 700;
    line-height: 196.5%;
    letter-spacing: 0%;
    text-align: center;
}

.Home-section6-cards{
    margin-top:24px;
    display: flex;
    justify-content: center;
    align-items:center;
    grid-gap:12px;
    gap:12px;
    max-width: 100%;
    width: 100%;
}

.tabs-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 40vw;
}

.Home-section6-cards-part1{
    max-width: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap:12px;
    gap:12px;
}
.Home-section6-cards-part2{
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Hero-section7{
    height:35vh;
    padding:0 80px;
    display: flex;
    flex-direction:column ;
    align-items: center;
    justify-content: center;
    width: 75%;
    /* background: url("../../../animation/Hero/subsBg.svg"); */
    /* background-position: left; */
    /* background-size: contain; */
    /* background-repeat: no-repeat; */
    margin-top:60px;
    margin: auto;
    margin-bottom: 6rem;
    border-radius: 4.5rem;
    box-shadow: -0.06rem 0.25rem 0.94rem 0.13rem rgba(0, 0, 0, 0.1);
    background: linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%);
}

.Hero-section7 > h1{
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 148%;
    text-align: center;
}

.Hero-section7-subs {
    
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top:40px;
    grid-gap:12px;
    gap:12px;
    width: 90%;
    height: 3.45rem;
}

.Hero-section7-subs > input{
    width: 40%;
    padding: 16px 40px;
    background: #FFFFFF;
    border: 1px solid #A6A6A6;
    border-radius: 40px;
    font-size:20px;
}

.Hero-section7-subs > input:focus{
    outline:none !important;
    border:1px solid black !important;
}

.Hero-section7-subs > input::-webkit-input-placeholder{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #A6A6A6;
    text-align: center;
}

.Hero-section7-subs > input::placeholder{
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #A6A6A6;
    text-align: center;
}

.Hero-section7-subs > button{
    background: #000000;
    text-transform: uppercase;
    width: 12rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    cursor: pointer;
    border:none;
    color: #FFFFFF;
    border-radius: 40px;

    border-radius: 4.5rem;

    box-shadow: 0rem 0.13rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
    /* Linear */
    background: linear-gradient(90.00deg, rgb(237, 30, 36) -30.514%,rgb(238, 130, 130) 116.019%);

}


@media only screen and (max-width: 1450px) {
    /* .Home-Wrapper{
        padding-top: 180px;
        padding-left:180px;
        padding-right:180px;
    } */
    .Home-section5-bg{
        margin-left:-180px;
    }
    .Home-section4-wrapper{
        margin-left:-180px;
    }
    .Hero-section7{
        padding:0 40px;
    }
    .Home-Section1-Wrapper {
        padding-bottom: 5rem;
        overflow: hidden;
    }
}
/* ############################ Responsive 1024 ############################ */

@media only screen and (max-width: 1024px) {
    .Home-Wrapper{
        /* padding-top: 210px;
        padding-left:130px;
        padding-right:130px; */
    }
    
    .subLayout{
        padding-left:130px;
        padding-right:130px;
    }
    
    .Home-Section1 > h1{
        font-weight: 700;
        font-size: 56px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 160%;
    } 
    
    .Home-Section1-buttons{
        margin-top:12px;
        grid-gap:12px;
        gap:12px;
    }
    
    .Home-section1-btn1,
    .Home-section1-btn2{
        width: 180px;
        height:50px;

    }
    
    
    .Home-section2{
        height:100%;
        width: 100%;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        grid-gap:12px;
        gap:12px;
        padding:30px;
    }

    .Home-section3-stable >h4{
        font-size: 30px;
        line-height: 105%;
    }
    .Home-section3-stable >p{
        font-size: 14px;
        line-height: 166.5%;
        /* margin-top:12px; */
    }
    
    .Home-section4{
        height:auto;
        margin-top:44px;
    }
    
    .Home-section4-video h3{
        font-size: 32px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
        border-radius: 56px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        border-radius: 20px;
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
        
    }
    
    .Home-section6>h4{
        font-size: 40px;
        line-height: 196.5%;
        text-align: center;
    }
    
    .Home-section6-cards{
        margin-top:24px;
        display: flex;
        justify-content: center;
        align-items:center;
        grid-gap:12px;
        gap:12px;
        max-width: 100%;
        width: 100%;
    }
    
    .Home-section6-cards-part1{
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .Home-section6-cards-part2{
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Hero-section7{
        height:auto;
        padding:0 0;
        display: flex;
        flex-direction:column ;
        align-items: center;
        justify-content: center;
        margin-top:40px;

    }
    .Hero-section7 > h1{
        font-size: 48px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        display: flex;
        margin-top:32px;
        width: 90%;
        height: 70px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        font-size:18px;
        padding: 16px 40px;
    }

    
    .Hero-section7-subs > input::-webkit-input-placeholder{
        font-size: 20px;
        line-height: 29px;
    }

    
    .Hero-section7-subs > input::placeholder{
        font-size: 20px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 250px;
        font-size: 20px;
        line-height: 29px;
    }
    
  }

@media only screen and (max-width: 950px) {
    .Home-Section1-Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 80vh;

        background-image: url(/static/media/bg1.3e046bfa.png);
        background-size: cover;
        background-repeat: no-repeat;
        overflow-x: hidden;
        max-width:100%;
        /* clip-path: none; */
    }

    .svg-section4, .svg-section1, .svg-section5 {
        display: none;
    }

    .explore-label {
        margin-top: 1rem;
    }

    .homepage-video-section, .home-section5-new, .Home-section6 {
        /* background-image: url("../../../animation/mobile_bgs/bg2.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        overflow-x: hidden;
        max-width:100%; */
        -webkit-clip-path: none;
                clip-path: none;
    }
    

    /* .Home-Section1  {
        padding-top: 2rem;
        height: 30vh;
    } */

    .Home-Wrapper{
        padding-top: 5rem;
        /* padding-left:80px;
        padding-right:80px; */
    }
    
    .subLayout{
        padding-left:80px;
        padding-right:80px;
    }

    .Home-section5-bg{
        margin-left:-100px;
        height:60vh;
    }
    .Home-section4-wrapper{
        margin-left:-100px;
    }
    
    .Home-Section1 > h1{
        font-size: 48px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 180%;
    } 
    
    .Home-Section1-buttons{
        margin-top:12px;
        grid-gap:12px;
        gap:12px;
    }
    
    .Home-section1-btn2,
    .Home-section1-btn1{
        width: 180px;
        height:50px;
    }
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        grid-gap:12px;
        gap:12px;
        padding:25px;
    }

    .Home-section3-stable{
        width:60%;
    }
    .Home-section3-stable>h4{
        font-size: 1.25rem;
    }
    .Home-section3-adCards{
        left:60%;
    }

    .Home-section4{
        height:auto;
        margin-top:44px;
    }
    
    .Home-section4-video h3{
        font-size: 32px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 60%;
    }
    
    .video{
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
    }
    
    .Home-section6>h4{
        font-size: 40px;
        line-height: 196.5%;
    }
    
    .Home-section6-cards{
        margin-top:24px;
        grid-gap:12px;
        gap:12px;
        max-width: 100%;
        width: 100%;
    }
    
    .Home-section6-cards-part1{
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .Home-section6-cards-part2{
        max-width: 50%;
    }
    
    .Hero-section7{
        height:auto;
    }
    .Hero-section7 > h1{
        font-size: 48px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        margin-top:32px;
        width: 90%;
        height: 55px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        padding: 16px 40px;
        font-size:16px;

    }
    
    .Hero-section7-subs > input::-webkit-input-placeholder{
        font-size: 20px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: 20px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 250px;
        font-size: 20px;
        line-height: 29px;
    
    }

    .Home-Section1  {
        padding-top: 2rem;
        height: 34vh !important;
    }

    .new_home_section_title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
    }

    .Home-section6 {
        padding: 0;
        padding-top: 4rem;
    }

    .tabs-wrapper {
        width: 90vw;
    }

    

  }

@media only screen and (max-width: 650px) {
    /* .Home-Wrapper{
        padding-top: 180px;
        padding-left:50px;
        padding-right:50px;
    } */
    
    .subLayout{
        padding-left:50px;
        padding-right:50px;
    }
    .Home-section5-bg{
        margin-left:-80px;
        height:80vh;

    }
    .Home-section4-wrapper{
        margin-left:-80px;
    }
    
    .Home-Section1 > h1{
        font-size: 40px;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 18px;
        line-height: 180%;
    } 
    
    .Home-Section1-buttons{
        flex-direction: column;
        align-items: center;
        margin-top:12px;
        grid-gap:12px;
        gap:12px;
    }

    .Home-Section1-buttons-link{
        width: 100%;
    }

    .Home-section1-btn2,
    .Home-section1-btn1{
        width: 100%;
        height:50px;
    }
    
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
        height: 100%;
        width: 100vw;
    }
    
    .Home-section3{
       height:auto;
    }
    
    .Home-section3-group{
        display: flex;
        grid-gap:12px;
        gap:12px;
        padding:20px;
    }

    .Home-section3-stable{
        /* z-index:5; */
        width:60%;
    }
    .Home-section3-stable>h4{
        font-size: 24px;
    }
    .Home-section3-stable>p{
        /* font-size: */
    }
    .Home-section3-adCards{
        left:60%;
    }
    
    .Home-section4{
        height:auto;
        margin-top:34px;
    }
    
    .Home-section4-video h3{
        font-size: 24px;
        line-height: 180%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        margin-top: 30px;
        padding: 0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 24px;
    }
    
    .Home-section6{
        width: 100%;
    }
    
    .Home-section6>h4{
        font-size: 32px;
        line-height: 120%;
    }
    
    .Home-section6-cards{
        flex-direction: column;
        grid-gap:12px;
        gap:12px;
    }
    
    .Home-section6-cards-part2{
        max-width: 100%;
    }
    
    .Hero-section7{
        height:auto;
        margin-top: 44px;
    }
    .Hero-section7 > h1{
        font-size: 32px;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        display: flex;
        margin-top:32px;
        width: 100%;
        height: 55px;
    }
    
    .Hero-section7-subs > input{
        width: 70%;
        padding: 8px 24px;
    }
    
    .Hero-section7-subs > input::-webkit-input-placeholder{
        font-size: 16px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: 16px;
        line-height: 29px;
    }
    
    .Hero-section7-subs > button{
        width: 200px;
        font-size: 16px;
        line-height: 0;
    
    }
  }

/* ----------------- Mobile Resolutions -------------------- */
  
@media only screen and (max-width: 480px) {
    .Home-Wrapper{
        /* padding-top: 150px; */
        /* background: url("../../../animation/Hero/phoneBg.svg"); */
        /* padding-left:30px;
        padding-right:30px; */
    }
    
    .subLayout{
        padding-left:30px;
        padding-right:30px;
    }

    .Home-section5-bg{
        margin-left:-30px;
        height:90vh;

    }
    .Home-section4-wrapper{
        margin-left:-30px;
    }

    .Home-Section1 {
        margin-bottom: 3.125rem;
    }
    
    .Home-Section1 > h1{
        font-size: 2.1875rem;
        margin-bottom:12px;
    }
    
    .Home-Section1 > p{
        font-size: 12px;
        line-height: 150%;
    } 
    
    .Home-Section1-buttons{
        margin-top:24px;
        grid-gap:12px;
        gap:12px;
    }
    
    .Home-Section1-buttons button:nth-child(1)
    ,.Home-Section1-buttons button:nth-child(2){
        width: 100%;
        height:46px;
        font-size:12px;
    }
    
    .Home-section2{
        margin-top:48px;
        margin-bottom:48px;
        height: 100%;
        width: 100vw;
    }
    
    .new_home_section_3{
       margin-top: -4rem;
    }

    .new_home_section_title {
        font-size: 1.85rem;
    }

    .card-main-text {
        font-size: 1.125rem;
    }

    .card-sub-text {
        font-size: .75rem;
    }

    .explore-label {
        font-size: 1.25rem;
    }
    
    .Home-section3-group{
        display: flex;
        grid-gap:12px;
        gap:12px;
        padding:12px;
    }
    .Home-section3-stable >h4{
        font-size: 16px;
        line-height: 105%;
    }
    .Home-section3-stable >p{
        font-size: 8px;
        line-height: 166.5%;
    }
    
    .Home-section4{
        height:auto;
        margin-top:24px;
    }
    
    .Home-section4-video h3{
        font-size: 20px;
        line-height: 215%;
    }
    
    .Home-section4-video>p{
        margin-top:12px;
        height:400px;
    }
    
    .video-div{
        width: 100%;
    }
    
    .video{
        margin-top: 30px;
        padding:0;
    }
    
    .play-btn{
        position: absolute;
    }
    
    .Home-section5{
        margin-top: 60px;
    }
    
    .Home-section6{
        width: 100%;
        margin-top:12px;
    }
    
    .Home-section6>h4{
        font-size: 20px;
        line-height: 120%;
    }
    
    .Home-section6-cards{
        flex-direction: column;
    }
    
    .Home-section6-cards-part2{
        max-width: 100%;
    }
    
    .Hero-section7{
        height:auto;
        padding: 1.25rem 1.25rem 1.75rem 1.25rem;
        margin-top:60px;
        border-radius: 1rem;
    }
    .Hero-section7 > h1{
        font-size: 1.5rem;
        line-height: 148%;
    }
    
    .Hero-section7-subs {
        margin-top:2rem;
        grid-gap:0.5rem;
        gap:0.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .Hero-section7-subs > input{
        width: 85%;
        padding: 0px 12px;
        font-size: .75rem;
        padding: 0.6rem .75rem ;
    }
    
    .Hero-section7-subs > input::-webkit-input-placeholder{
        font-size: .75rem;
        /* line-height: 15px; */
    }
    
    .Hero-section7-subs > input::placeholder{
        font-size: .75rem;
        /* line-height: 15px; */
    }
    
    .Hero-section7-subs > button{
        width: 95px;
        font-size: .75rem;
        padding: .875rem
        /* line-height:0; */
    
    }
    
  }
/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */
.hidden {
  display: none;
}

.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 150px;
}
.social-links {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0px;
  top: 30%;
}
.social-links > a {
  text-decoration: none;
  color: white;
}
.social-links > a > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.social-links > a > div > img {
  padding: 20px 20px;
}
.facebook,
.linkedin,
.twitter,
.instagram {
  width: 35px;
  border-radius: 0 0 0 0;
  color: transparent;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding-right: 30px;
}
.facebook:hover,
.linkedin:hover,
.twitter:hover,
.instagram:hover {
  width: 230px;
  border-radius: 0 10px 10px 0;
  color: white;
  transition: all 0.1s ease-out;
}
.hero-video {
  height: 100vh;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  position: relative;
}
.intro {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}
.hero-img > img {
  height: 650px;
  width: auto;
}
.email:focus {
  outline: none;
}

.subscribe-button {
  text-decoration: none;
  color: #ED1E24;
  padding: 15px 45px !important;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ED1E24;
}

.subscribe-button:focus {
  outline: none;
}
.heading {
  font-size: 100px;
  font-weight: 500;
  color: white;
}
.sub-heading {
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 10px 0;
  margin-bottom: 40px;
}
.subscribe-button-group {
  padding-top: 30px;
}
.subscribe-button-group,
.subscribe-button-group > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subscribe-button-group > div {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}
.signup {
  color: white;
  border: 2px solid #ffffff;
  border-radius: 100px;
  padding: 25px 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: transparent;
  transition: 0.3s;
  margin: 0 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.signup:hover {
  color: #ED1E24;
  background-color: white;
  border: 2px solid white;
}
.card-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 85vh;
  margin-top: -250px;
}
.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 90px 40px;
  margin: 0 100px;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
}
.info-number {
  color: #ED1E24;
  font-size: 70px;
  font-weight: 600;
  height: 150px;
  font-family: "Bebas Neue", cursive;
}
.info-number > div {
  font-size: 18px;
}
.info-text {
  color: black;
  font-size: 14px;
  width: 200px;
}

.info_card_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 1rem;
  padding: 1rem 0;
}

.info_card_container > :nth-child(3) {
  grid-column: 1 / span 2;
  width: 50%;
  margin: auto;
}

.subscribe-button {
  color: #ED1E24;
  border: 2px solid #ED1E24;
  border-radius: 100px;
  padding: 25px 80px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: white;
  transition: 0.3s;
  text-transform: uppercase;
}
.subscribe-button:hover {
  color: white;
  background-color: #ED1E24;
  border: 2px solid #ED1E24;
}
.section {
  display: flex;
  align-items: center;
  padding-top: 0px;
}
.section-img > img {
  height: 600px;
  width: auto;
}
.section-text {
  margin: 0 100px;
}
.section-heading {
  font-size: 50px;
  font-weight: 700;
}
.section-sub-heading {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0;
  width: 500px;
  margin: 30px 0 60px 0;
  opacity: 0.8;
}
.section-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-area :nth-child(even) {
  flex-direction: row-reverse;
}
.map-section {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}
.map {
  padding: 50px 0 0 0;
  width: 80%;
  height: auto;
}

.intrests {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}
.topic-active {
  color: #ED1E24;
}
.topic-list {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  margin-bottom: 70px;
  align-items: center;
  justify-content: center;
}
.topic-list > div {
  font-size: 14px;
  padding: 0 40px;
  cursor: pointer;
}
.topic-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  width: 450px;
  margin: 10px;
  box-shadow: 0 0 20px rgba(85, 85, 85, 0.1);
}
.card-text {
  padding: 0 30px;
}
.topic-card > img {
  width: 100%;
  height: auto;
  border-radius: 10px 10px 0 0;
}
.title {
  font-size: 18px;
  font-weight: 700;
  padding-top: 30px;
}
.level {
  font-size: 16px;
  font-weight: 400;
  padding-top: 15px;
}
.description {
  font-size: 14px;
  font-weight: 400;
  color: #555555;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.learn-more {
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  padding-top: 70px;
  padding-bottom: 40px;
}
.learn-more > a {
  text-decoration: none;
  color: #ED1E24;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}
.setup-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 200px 0;
  overflow: hidden;
}
.setup-heading {
  font-size: 50px;
  font-weight: 700;
  width: 440px;
  margin-right: 10px;
}
.email {
  border-style: none;
  padding-left: 14px;
  color: #444444;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  background-color: #eeefef;
  padding: 25px 40px;
  width: 550px;
  border-radius: 100px;
  margin-right: 60px;
}
.footer {
  /* background-color: #ED1E24; */
  /* background: #000000; */
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  color: #656565;
  margin-top: 70px;

  background: rgb(246, 247, 251);
    -webkit-clip-path: url(#clip-path-footer);
            clip-path: url(#clip-path-footer);  
    padding-top: 5rem;
    /* padding-left:10%;
    padding-right:8%; */
}
.footer-left {
  width: 50%;
  padding: 100px 50px 100px 300px;
}
.footer-left > div > p {
  width: 500px;
  font-size: 16px;
  line-height: 24px;
}
.footer-right {
  width: 50%;
  padding: 100px 50px 0px 50px;
  /* 
  display: flex;
  justify-content: center;
  align-items: flex-end; */
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.footer-link {
  cursor: pointer;
}

.footer-right > img {
  height: 350px;
  width: auto;
}

.footer-header {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 700;
  line-height: 0.81rem;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
  padding-bottom: 1rem;
}

.footer-left > div > img {
  width: 250px;
  height: auto;
  margin-bottom: 20px;
}
.footer-social {
  display: flex;
  flex-direction: row;
}
.footer-social > a > div {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.16);
}
.footer-img {
  width: 25px;
  height: auto;
  opacity: 1;
}
.footer-img:hover {
  opacity: 0.8;
}
.footer-right > div {
  padding: 10px 0;
}
.carousel {
  height: 500px;
  width: auto;
}

.monetize-section{
  text-align: center;
  margin-top: 40px;
}

.monetize-sub-heading{
  margin-top: 10px;
  font-size: 14px;
}
.moentize-video-div{
  position: relative;
}

.play-btn-img{
  opacity: 1 !important;
  z-index: 2;
}
.play-btn img{
  position: relative;
  opacity: 1;
  height: 180px;
  width: 180px;
}
.actual-video{  
  border-radius: 20px;
  margin-top: 30px;
  margin: 30px auto; 
  width: 70% !important;
  height: 100% !important;
}

.actual-video > video{
  border-radius: 20px;

}

/* ############################ Responsive 1450 ############################ */
@media only screen and (max-width: 1450px) {
  .intro-section {
    margin-bottom: 100px;
  }
  .heading {
    font-size: 70px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .email {
    padding-left: 14px;
    font-size: 18px;
    padding: 25px 40px;
    width: 400px;
    margin-right: 60px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-right {
    padding: 80px 150px 80px 150px;
  }

  .Home-Section1 {
    height: 64vh;
  }
}

/* ############################ Responsive 1330 ############################ */
@media only screen and (max-width: 1330px) {
  .intro-section {
    margin-bottom: 80px;
  }
  .heading {
    font-size: 70px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .email {
    padding-left: 14px;
    font-size: 18px;
    padding: 25px 40px;
    width: 400px;
    margin-right: 60px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-right {
    padding: 80px 20px 80px 20px;
  }
  
}

/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .intro-section {
    margin-bottom: 250px;
  }
  .heading {
    font-size: 70px;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .email {
    padding: 20px 40px;
    margin-right: 60px;
  }
  .section {
    padding-top: 20px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .topic-card {
    width: 350px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 40px;
    width: 350px;
    margin-right: 10px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
}

/* ############################ Responsive 1130 ############################ */
@media only screen and (max-width: 1130px) {
  .intro-section {
    margin-bottom: 250px;
  }
  .heading {
    font-size: 70px;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .email {
    padding: 20px 40px;
    margin-right: 20px;
  }
  .section {
    padding-top: 20px;
  }
  .section-heading {
    font-size: 40px;
  }
  .info-card {
    margin: 0 20px;
    padding: 80px 30px;
  }
  .learn-more {
    padding-bottom: 20px;
    padding-top: 40px;
  }
  .section-sub-heading {
    font-size: 16px;
    padding: 10px 0;
    width: 400px;
    margin: 30px 0 60px 0;
  }
  .section-img > img {
    height: 400px;
    width: auto;
  }
  .map {
    padding: 50px 0 0 0;
    height: 550px;
    width: auto;
  }
  .topic-card {
    width: 325px;
    margin: 10px;
  }
  .card-text {
    padding: 0 30px;
  }
  .topic-list {
    margin: 50px 0;
  }
  .topic-list > div {
    padding: 0 20px;
  }
  .setup-section {
    margin: 100px 0;
  }
  .setup-heading {
    font-size: 35px;
    width: 300px;
    margin-right: 10px;
  }
  .footer-left {
    padding: 80px 30px 80px 150px;
  }
  .footer-left > div > p {
    width: 300px;
  }
}

/* ############################ Responsive 1024 ############################ */
@media only screen and (max-width: 1024px) {
  .intro-section {
    margin-bottom: 50px;
    text-align: center;
  }
  .heading {
    width: 600px;
    font-size: 70px;
    text-align: center;
  }
  .sub-heading {
    font-size: 18px;
  }
  .card-section {
    display: none;
  }
  .subscribe-button {
    padding: 20px 60px;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .email {
    padding: 20px 40px;
    margin-right: 20px;
  }
  .section {
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .section-area :nth-child(even) {
    flex-direction: column;
  }
  .reverse {
    flex-direction: column-reverse !important;
  }
  .card-container {
    flex-direction: column;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .section-heading {
    font-size: 35px;
  }
  .map {
    padding: 50px 0 0 0;
    height: auto;
    width: 90vw;
  }
  .topic-card {
    width: 80vw;
    margin: 10px;
  }
  .topic-list > div {
    padding: 0 10px;
  }
  .setup-section {
    flex-direction: column;
    text-align: center;
  }
  .setup-section > div,
  .setup-heading > input,
  .setup-section > subscribe-button {
    margin: 20px 0;
  }
  .setup-heading {
    width: 500px;
  }
  .intrests {
    margin-top: 50px;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 80px 20px;
  }
  .footer-right {
    align-items: center;
    padding: 0px 20px 80px 20px;
  }
  .footer-right > img {
    width: 300px;
    height: auto;
  }
  .footer-left > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-left > div > img {
    width: 250px;
    height: auto;
  }
  .footer-left > div > p {
    width: 500px;
    text-align: center;
  }
  .play-btn img{
    height: 130px;
    width: 130px;
  }
  /* .play-btn{
    top: 20%;
    left: 40%;
  } */
}
@media only screen and (max-width: 950px) {
  .section-heading {
    margin: 50px 0 30px 0;
  }
  .section-sub-heading {
    width: 400px;
  }
  /*     .topic-list{
        display: none;
    } */
  .topic-list:nth-child(7) {
    display: block !important;
  }
  .heading {
    width: 80vw;
    font-size: 50px;
    font-weight: 500;
  }
  .setup-heading {
    width: 80vw;
  }
  .section-text {
    margin: 0;
  }
  .section-sub-heading {
    width: 80vw;
  }
  .section-img > img {
    height: auto;
    width: 60vw;
  }
  .email {
    width: 250px;
    margin-right: 0;
  }
  .section-text {
    margin: 0 20px;
  }
  .footer-left {
    padding: 0px 0px 0px 0px;
  }
  .footer-left > p {
    width: 80vw;
  }
  .map-section {
    padding-top: 20px;
  }
  .map-section > div {
    width: 80vw;
    text-align: center;
  }
  .intrests > div {
    text-align: center;
  }
  .map {
    padding: 0;
  }
  .footer-left {
    padding: 80px 20px 80px 20px;
  }
  .footer-right {
    padding-bottom: 20px;
  }
  /* .play-btn{
    top: 20%;
    left: 35%;
  } */
  .play-btn img{
    height: 100px;
    width: 100px;
  }
  .explore-label {
    font-size: 2rem;
  }
  .video-container {
    width: 100%;
  }
  .info_card_container {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .info_card_container > :nth-child(3) {
    grid-column: 1 / span 2;
    width: 100%;
    margin: auto;
  }
}
@media only screen and (max-width: 650px) {
  .social-links {
    display: none;
  }
  .heading {
    width: 90vw;
  }
  .intro {
    padding-bottom: 0px;
  }
  .section-img > img {
    height: auto;
    width: 80vw;
  }
  .subscribe-button {
    padding: 25px 25px;
  }
  .footer-left {
    padding: 50px 10px 50px 10px;
  }
  .footer-left > div > p {
    width: 350px;
    text-align: center;
  }
  .play-btn img{
    height: 70px;
    width: 70px;

  }
}

@media only screen and (max-width: 480px) {
  .play-btn img{
    height: 50px;
    width: 50px;

  }

  .footer-right {
    width: 80%;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    text-align: center;
  }

  .footer-header {
    text-align: center;
  }

  .info_card_container > :nth-child(3) {
    grid-column: 1 / span 2;
    width: 100%;
    margin: auto;
  }

  .Home-section6 {
    margin-bottom: 1rem;
  }
  
  .Hero-section7 {
    margin-bottom: 1rem;
  }

  .homepage-video-section{
    padding-top: 1rem;
  }
  .video-container {
    margin-bottom: 6rem;
  }
  .home-section5-new {
    padding-top: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .play-btn img{
    height: 30px;
    width: 30px;

  }

  .hero-image {
    margin-top: 5rem;
  }

  .homepage-video-section .explore-label {
    font-size: 1.75rem;
  }
}


.Layout_layout__1PRlu{
    /* background-image: url("../../../animation/Hero/bgCombinedDesk.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    background-position-y: 13%;
}

@media only screen and (max-width: 480px) {
    
    /* .layout{
        background-image: url("../../../animation/Hero/bgCombinedMob.svg");
    } */
    
}
.VideoCard_player_container__1zLYY{
  position: relative;
}

.VideoCard_play_btn__3hX9Y {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);  
}

.VideoCard_play_btn_icon__lbRJz > img {
  height: 62px;
  cursor: pointer;
}


.Modal_Overlay__16JW7 {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: #232D4C; */
  background: #000;
  opacity: 0.5;
}

.Modal_Modal__2mUGy {
  z-index: 110;
  /* position: fixed; */
  position: absolute;
  top: 50%;
  background: white;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Modal_ModalContainer__PEd_B {
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}
.Modal_classModal__25izB {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.Modal_formFields__siTHR {
  padding: 0 30px;
  box-sizing: border-box;
}
.Modal_classModal__25izB {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.Modal_formFields__siTHR {
  padding: 0 30px;
  box-sizing: border-box;
}
.Modal_inputLabel__1zp-J {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}
.Modal_inputField__2CHoZ {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}
/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */

.career_comingSoon__2oa62 {
  text-align: center;
  font: normal normal bold 42px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 102px 71px;
  background: #ED1E24;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}

.career_bottomContainer__P3_jO {
  padding: 20px 75px 41px 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.career_outerContainer__1EybQ {
  background: #ffffff;
  border-radius: 10px;
  max-width: 520px;
  position: relative;
}

.career_diamond__1iLm0 {
  height: 40px;
  width: 42px;
}

.career_title__3-R00 {
  text-align: center;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
}

.career_desc__xyveU {
  text-align: center;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding: 10px;
}

.career_close__-ocbD {
  width: 33px;
  height: 33px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.referral_refrralImage__1HVzG {
  width: auto;
  height: 200px;
  margin-bottom: 50px;
}
.referral_bottomContainer__YZIcs {
  padding: 20px 65px 40px 65px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0 0 10px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.referral_bottomContainer__YZIcs > a {
  text-decoration: none;
}
.referral_mailBtn__33tTN {
  padding: 15px 50px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  background-color: white;
}
.referral_mailBtn__33tTN:hover {
  background-color: #ED1E24;
  color: #fff;
}
.referral_title__1Q_vo {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.referral_info__2vyJS {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.referral_copyContainer__3O7zr {
  background: #f2f3f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 30px 21px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.referral_link__2lSYQ {
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 20px;
}

.button_btn__SGdXd {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: white;
}

.button_btn__SGdXd:hover {
  background-color: #ED1E24;
  color: #ffffff;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat", sans_serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* hide scrollbar on Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar on Firefox */
html {
  scrollbar-width: none;
}

textarea,
input[type="text"],
.Profile_textinp__fyWrP {
  font-family: "Montserrat", sans_serif;
}

.Profile_textarea__1QGsL:focus,
textarea:focus,
#Profile_input_profile__Wclp_ {
  outline: none !important;
  /* box-shadow: 0 0 10px #ED1E24; */
}

.Profile_input_foc__3UHq4:focus {
  box-shadow: 0 0 10px #ED1E24;
  outline: none !important;
}

.Profile_btn__3qvhG,
.Profile_btn_fade__1AF36 {
  background-color: #fff;
}

.Profile_center_div__24m1z {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile_inner_invitation__19I3Y {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  margin: 10px 0px;
}

.Profile_border_color__1fCEL {
  border-color: #ED1E24;
}

.Profile_flex_sp__3VV7v {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.Profile_invitationSection__YYtZp {
  display: flex;
}
.Profile_connect_btn__1xqSA {
  border: 2px solid #ED1E24;
  padding: 12px 20px;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 10px;
}

.Profile_connect_btn_fade__1dfAo {
  border: 2px solid #989898;
  padding: 12px 20px;
  border-radius: 40px;
  cursor: pointer;
  margin: 10px;
}

.Profile_flex_col_cen__18e25 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Profile_inner_invitation_img__1q9tk {
  margin-right: 10px;
}

.Profile_search_global_mains__199AE {
  display: flex;
  justify-content: center;
}

.Profile_inner_mains__1KieX {
  width: 80em;
  background-color: green;
}

.Profile_invitation_content__1R1aB {
  border-radius: 10px;
  box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.Profile_suggestion_card_div__3Yg6o {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.Profile_suggestion_card__jNqlD {
  height: 250px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 5px;
  box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.Profile_suggestion_card_header__3gYxN {
  height: 30%;
  flex: 2 1;
  background-color: #ED1E24;
  display: flex;
  justify-content: flex-end;
}

.Profile_suggestion_card_body__2cflD {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 40px 15px 0px 15px;
}

.Profile_suggestion_card_avatar_div__2RPw6 {
  position: absolute;
  border-radius: 50%;
  top: 50px;
  left: 10px;
}

.Profile_suggestion_card_avatar_div__2RPw6 img {
  height: 60px;
}

.Profile_dismiss_suggestion_card__3OPtH {
  color: #113474;
  cursor: pointer;
  margin: 10px;
  height: 22px;
}

.Profile_desc__1Yg_B p {
  margin: 0px;
}

.Profile_btn_text__3RtC3 {
  margin: 0;
  font: normal normal 600 14px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  opacity: 1;
}

.Profile_feed__3W7_2 {
  /* width: 94em; */
}

.Profile_btn_text_fade__1b4aS {
  margin: 0;
  font: normal normal 600 14px Montserrat;
  letter-spacing: 0px;
  color: #989898;
  text-transform: uppercase;
  opacity: 1;
}

.Profile_btn__3qvhG:hover {
  background-color: #ED1E24;
}

.Profile_btn_fade__1AF36:hover {
  background-color: #989898;
}

.Profile_btn__3qvhG:hover .Profile_btn_text__3RtC3,
.Profile_btn_fade__1AF36:hover .Profile_btn_text_fade__1b4aS {
  color: #fff;
}

.Profile_up__3CJ1_:hover {
  background-color: #ED1E24;
}

.Profile_up__3CJ1_:hover .Profile_up_desc__162UZ {
  color: #fff;
  text-decoration: underline;
}

.Profile_up_desc__162UZ {
  color: #828282;
}

.Profile_pilus__3OWyx {
  color: #ED1E24;
}

.Profile_hov__3Ga0c:hover,
.Profile_hov__3Ga0c:hover .Profile_pilus__3OWyx {
  background-color: #ED1E24;
  color: #fff;
}

/* ADS COROUSEL */

.Profile_slideshow__2JLpG {
  overflow: hidden;
}

.Profile_slideshowSlider__27-al {
  white-space: nowrap;
  transition: ease 100ms;
}

.Profile_slide__1X38z {
  display: inline-flex;
  width: 100%;
  border-radius: 10px;
}

.Profile_slideshowDots__2mVyc {
  text-align: center;
}

.Profile_slideshowDot__1aXsa {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 7px 0px;
  background-color: #fff;
}

.Profile_slideshowDot_active__34A85 {
  width: 15px;
  height: 15px;
}

#Profile_scroll_div__1zRWP::-webkit-scrollbar {
  display: none;
}

.Profile_scroll_div__1zRWP::-webkit-scrollbar {
  display: none;
}

#Profile_scroll_div__1zRWP,
.Profile_scroll_div__1zRWP,
select {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/*language */

.Profile_box__2Cmww {
  width: 89px;
  height: 29px;
  background: gray;
  border-radius: 16px;
  opacity: 1;
  box-sizing: border-box;
}

/* FEATURED DROPDOWN */

.Profile_dropdown_featured__vsaSu {
  position: relative;
  display: inline_block;
}

.Profile_dropdown_content_featured__1vhpn {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: -30px;
}

.Profile_dropdown_content_featured__1vhpn a {
  color: rgb(116, 116, 116);
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: 600;
}

.Profile_dropdown_content_featured__1vhpn a:hover {
  background-color: #f1f1f1;
}

.Profile_dropdown_featured__vsaSu:hover .Profile_dropdown_content_featured__1vhpn {
  display: block;
}

/* HEADER CSS */

.Profile_header__2Nloy {
  position: fixed;
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  z-index: 20;
  top: 0px;
  left: -350px;
  right: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  opacity: 1;
  padding-left: 40px;
  padding-top: 22px;
  padding-bottom: 22px;
}

.Profile_header_left__2zTkN {
  display: flex;
  margin-left: -350px;
}

.Profile_header_left__2zTkN > img {
  object-fit: contain;
  top: 21px;
  left: 270px;
  width: 178px;
  height: 40px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 5px;
}

.Profile_header_search__2JqGb {
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  width: 250px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  margin-left: 25px;
  margin-top: 5px;
}

.Profile_header_search__2JqGb > form > input {
  font: italic normal medium 13px/16px Montserrat;
  outline: none;
  border: none;
  background: none;
  width: 160px;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  margin-right: 10px;
}

.Profile_header_right__3mdmJ {
  top: 20px;
  display: flex;
  height: 50px;
  margin-left: 30px;
  grid-gap: 20px;
  gap: 20px;
}

.Profile_header_option__271S4 {
  text-align: center;
  display: grid;
  place-items: center;
}

.Profile_image__2S4TS {
  width: 24px;
  height: 18px;
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.Profile_header_avatar__3smcc {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  object-fit: cover;
  opacity: 1;
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
  opacity: 1 !important;
}

.Profile_specific__3Mxv3 {
  text-align: center;
  font: normal normal normal 13px/16px Montserrat;
  letter-spacing: 0px;
  color: #858585;
  opacity: 1;
  height: 16px;
  cursor: pointer;
}

.Profile_Search__2QetW {
  color: #b4b4b4;
}

.Profile_profile_icon__2E5FN {
  margin-left: 20px;
  text-align: center;
  display: flex;
  align-items: center;
}

.Profile_header_options_container__2Ukq6 {
  display: grid;
  place-items: center;
}

.Profile_profile_icon__2E5FN img {
  width: 25px;
  height: 25px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.3;
}

.Profile_header_search__2JqGb img {
  height: 18px;
  width: 19px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.5;
  margin-right: 15px;
}

.Profile_notifications_menu__2AkJ3 {
  position: absolute;
  width: 230px;
  height: 12em;
  box-shadow: 0px 0px 20px #55555529;
  border-radius: 10px;
  opacity: 1;
  top: 96px;
  background-color: #fff;
  /* margin-left: -130px; */
}

.Profile_notifications_menu__2AkJ3 ul {
  list-style: none;
  padding: 0px 0px;
}

.Profile_notifications_menu__2AkJ3 li {
  padding: 0px 15px;
}

.Profile_notification__2mN1w {
  display: flex;
  align-items: center;
  justify-items: center;
}

.Profile_notification__2mN1w h1 {
  margin: 0px;
  margin-left: 3px;
}

.Profile_dropdown_menu__354Ry {
  width: 250px;
  height: auto;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  margin-top: 300px;
  margin-left: -250px;
  background-color: #fff;
  z-index: 10;
}

.Profile_dropdown_menu__354Ry ul {
  list-style: none;
  padding: 10px 0px;
  margin: 0;
}

.Profile_dropdown_menu__354Ry li {
  padding: 10px 15px;
}

.Profile_profile__1GXPm {
  display: flex;
}

.Profile_profile__1GXPm h1 {
  margin: 0px;
  margin-left: 10px;
}

.Profile_drop_profile__sdFOV {
  border: 2px solid #ED1E24;
  border-radius: 50%;
}

.Profile_Hr2__35KQA {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Profile_menu__3NOrC {
  text-align: left;
  font-size: 12px;
}

.Profile_navbar_menu__GOnu6 {
  width: 80px;
}

.Profile_icon_container__3Q9AP {
  background-color: #ED1E24;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.Profile_inner_icon__3uBn- {
  height: 20px;
  width: 20px;
}

.Profile_icon_text__R4q12 {
  text-transform: capitalize;
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  margin-left: 15px;
  letter-spacing: 0px;
}
.Profile_icon_text_large__UrplH{
  text-transform: capitalize;
  margin: 0px;
  font-weight: 500;
  font-size: 32px;
  margin-left: 15px;
  letter-spacing: 0px;
}

.Profile_featured_desc__2wGRN {
  font: normal normal normal 14px/18px Montserrat;
  color: #000000;
  opacity: 0.7;
  letter-spacing: 0px;
  /* height:3.3rem; */
  width: 15.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.Profile_section_desc__2hlMF {
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
  letter-spacing: 0px;
  word-break: break-all;
}
.Profile_ellipse__lDxD5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.Profile_edit_info__9txv- {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  margin: 0px 0px 15px 0px;
}

.Profile_edit_info__9txv- {
  margin: 10px !important;
}
.Profile_mains_search__129f5 {
  width: 70em;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 40px;
}
.Profile_see_all_text__1fD_F {
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  text-transform: uppercase;
  opacity: 1;
  margin: 0px;
  padding: 10px;
}

.Profile_followers__28UQH {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  margin-top: 5px;
}

/* MODALS */

.Profile_modal_title__3cniI {
  font: normal normal 600 17px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0px;
  margin-left: 10px;
}

.Profile_modal_heading__ArLeF {
  font: normal normal 500 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0px;
  margin-bottom: 10px;
  margin-left: 15px;
}
.Profile_modal_date__2J6XN {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
.Profile_modal_date__2J6XN > div {
  display: flex; 
  flex-direction: column;
}
.Profile_modal_date__2J6XN > div:nth-child(1) {
  margin-right: 20px;
}
.Profile_sub_heading__1jT_G {
  font-size: 16px;
  margin: 0;
}
.Profile_imageUpload__3W0cq {
  margin-bottom: 20px;
}

.Profile_modal_para__19bQI {
  outline: none !important;
  font: normal normal normal 14px/24px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  border: 1px solid #5555555a;
  padding: 15px;
  border-radius: 25px;
  margin: 0px;
}

.Profile_mar0__2c5q7 p {
  margin: 0px;
}
/* MAINS */
.Profile_noResult__11Vxf {
  height: auto;
  width: 30vw;
}
.Profile_feed__3W7_2 {
  margin-top: 100px;
  padding: 0px 10px;
}
.Profile_profile_margin__21A7u{
  margin: 0 8em;
}
.Profile_header_nav__1IsRv {
  display: none;
}

.Profile_header_option__271S4 {
  display: flex;
}

.Profile_cover_feed__Ksxwy {
  flex: 3 1;
}

.Profile_left_feed__3JcRm {
  flex: 1 1;
  width: 68%
}

.Profile_right_feed__2mfDY {
  flex: 0.45 1;
}

.Profile_left_side_bar__XNnV2 {
  margin: 0px 10px 0px 0px;
  flex: 0.5 1;
  display: flex;
  justify-content: center;
}

.Profile_header_left__2zTkN img {
  display: block;
}

#Profile_template_modal__38gPw {
  width: 560px;
}

textarea,
.Profile_textarea__1QGsL {
  width: 100%;
  resize: none;
  scrollbar-width: none;
}

#Profile_hamburger_card__1gSGt {
  width: 500px;
  padding: 30px 20px 30px 20px;
  box-sizing: border-box;
}

.Profile_hamburger_view__3_uf_ {
  display: none;
}

.Profile_leftbar_hamburger_view__2Dez8 {
  display: none;
  margin-top: -120px;
}

.Profile_wrap_100__uv2pH {
  width: 50%;
}
.Profile_search_feed__2silq {
  width: 90%;
}
.Profile_peopleSection__1WVf- {
  width: 100%;
}
.Profile_coverPicture__Kh_Pm {
  width: 100%;
  height: auto;
  border-radius: 10px;
}
/* MEDIA */
@media (max-width: 1450px) {
  .Profile_header_left__2zTkN {
    margin-left: -100px;
  }
}
@media (max-width: 1350px) {
  .Profile_left_feed__3JcRm {
    width: 45%
  }
  .Profile_featured_scroll__3BJWm {
    width: 600px;
  }
  .Profile_feed__3W7_2 {
    /* width: 94em; */
    font-size: small;
  }
  .Profile_right_feed__2mfDY {
    display: block;
  }
  .Profile_featured_scroll__3BJWm {
    width: 600px;
  }
  .Profile_header_left__2zTkN {
    margin-left: 20px;
  }
  .Profile_section_title__3uJLL {
    font-size: 15px;
  }  .Profile_profile_margin__21A7u{
    margin: 0 4em;
  }
  .Profile_peopleSection__1WVf- {
    width: 90%;
  }
}
@media (max-width: 1290px) {
  .Profile_featured_scroll__3BJWm {
    width: 550px;
  }
  .Profile_left_feed__3JcRm {
    width: 30%
  }
}
@media (max-width: 1250px) {
  .Profile_header_left__2zTkN {
    margin-left: 180px;
  }
  .Profile_left_feed__3JcRm {
    width: 25%;
  }
  .Profile_header_search__2JqGb {
    width: 180px;
  }
  .Profile_header_search__2JqGb > form > input {
    width: 50%;
  }
}

@media (max-width: 1140px) {
  .Profile_header_nav__1IsRv {
    display: block;
  }
  .Profile_Featured__3XWA6 {
    width: 45em;
  }
  .Profile_right_feed__2mfDY {
    display: none;
  }
  .Profile_header_option__271S4 {
    display: none;
  }
  .Profile_chat_container__3CA0T__e1ijy {
    display: none;
  }
  .Profile_featured_scroll__3BJWm {
    width: 650px;
  }
  .Profile_header_left__2zTkN {
    margin-left: -550px;
  }
  /* .language_div_dark{
    display: none !important;
  } */
  .Profile_dropdownLanguage__2s6eK{
    bottom: 55px !important;
    left: unset !important;
    top: unset !important;
    }
}
@media (min_width: 1100px) {
  .Profile_mobileheader__1QVwQ {
    display: none !important;
  }
  .Profile_header__2Nloy {
    display: flex;
  }
  .Profile_noResult__11Vxf {
    width: 70vw;
}
.Profile_right_feed__2mfDY {
  display: none;
}
}
@media (max-width: 1032px) {
  .Profile_left_side_bar__XNnV2 {
    display: none;
  }
  .Profile_feed__3W7_2, .Profile_Featured__3XWA6 {
    width: 60em;
  }
  .Profile_featured_scroll__3BJWm {
    width: 700px;
  }
  .Profile_leftbar_hamburger_view__2Dez8 {
    display: block;
  }
  .Profile_left_feed__3JcRm {
    display: block;
  }
  .Profile_search_feed__2silq {
    margin: 0px 20px;
  }
  .Profile_mains__19yyD {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 950px) {
  .Profile_header_left__2zTkN {
    margin-left: -180px;
  }
}

@media (max-width: 800px) {
  .Profile_mains_search__129f5 {
    margin-top: 20px;
    margin-bottom: 100px;
  }
  .Profile_header__2Nloy {
    position: inherit;
  }
  .Profile_header_left__2zTkN {
    margin-left: 0px;
  }
  .Profile_header_search__2JqGb {
    margin-left: -50px;
    margin-top: 0px;
  }
  .Profile_header_left__2zTkN img {
    display: none;
  }
  .Profile_header_search__2JqGb img {
    display: block;
  }
  .Profile_header_left__2zTkN {
    position: absolute;
  }
  .Profile_profile_icon__2E5FN {
    align-items: center;
  }
  .Profile_dropdown_menu__354Ry {
    position: absolute;
    left: 280px;
    top: -210px;
  }
  .Profile_dropdown_menu__354Ry ul {
    margin: 0px;
  }
  .Profile_header_avatar__3smcc {
    position: absolute;
    left: 50px;
    top: 20px;
  }
  .Profile_header_nav__1IsRv {
    position: absolute;
    right: 50px;
  }
  .Profile_followers__28UQH {
    font-size: 14px;
  }
  .Profile_search_feed__2silq {
    margin: 0px 20px;
    margin-bottom: 50px;
  }
  .Profile_feed__3W7_2, .Profile_Featured__3XWA6 {
    width: 50em;
  }
  .Profile_feed__3W7_2 {
    margin-top: 20px;
  }
  .Profile_leftbar_hamburger_view__2Dez8 {
    margin-top: -150px;
  }
  .Profile_inner_invitation__19I3Y {
    flex-direction: column;
  }
  .Profile_flex_sp__3VV7v {
    width: 100%;
    justify-content: flex-start;
  }
  .Profile_invitationSection__YYtZp {
    width: 100%;
  }
  .Profile_icon_text_large__UrplH{
    font-weight: 600;
    font-size: 14px;
    margin-left: 8px;
    letter-spacing: 0px;
  }
}

@media (max-width: 720px) {
  .Profile_suggestion_card_div__3Yg6o {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 670px) {
  .Profile_header__2Nloy {
    position: inherit;
  }
  .Profile_feed__3W7_2,
  .Profile_Featured__3XWA6 {
    width: 35em;
  }
  .Profile_inner_invitation__19I3Y {
    padding: 10px 10px;
  }
  .Profile_leftbar_hamburger_view__2Dez8 {
    margin-top: -160px;
  }
  .Profile_coverPicture__Kh_Pm {
    width: 100%;
    height: 120px;
  }
  #Profile_template_modal__38gPw {
    width: 80%;
  }
}
@media (max-width: 580px) {
  .Profile_suggestion_card_div__3Yg6o {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .Profile_modal_date__2J6XN > div {
    width: 100%;
  }
  .Profile_modal_date__2J6XN > div:nth-child(1) {
    margin-right: 0px;
  }
}
@media (max-width: 550px) {
  .Profile_header__2Nloy {
    position: inherit;
  }

  .Profile_header_search__2JqGb {
    width: 240px;
  }

  .Profile_header_search__2JqGb form input {
    width: 160px;
  }
}
@media (max-width: 538px) {
  .Profile_header__2Nloy {
    position: inherit;
  }
  .Profile_profile_icon__2E5FN {
    margin-left: 0px;
  }
}

@media (max-width: 520px) {
  .Profile_header__2Nloy {
    position: inherit;
  }
  .Profile_feed__3W7_2,
  .Profile_Featured__3XWA6 {
    width: 30em;
  }
  .Profile_wrap_100__uv2pH {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .Profile_mobileheader__1QVwQ {
    display: flex;
  }
}
@media (max-width: 462px) {
  .Profile_header__2Nloy {
    position: inherit;
  }

  .Profile_header_search__2JqGb {
    width: 176px;
  }

  .Profile_header_search__2JqGb form input {
    width: 100px;
  }
}

@media (max-width: 432px) {
  .Profile_header__2Nloy {
    position: inherit;
  }
  .Profile_feed__3W7_2,
  .Profile_Featured__3XWA6 {
    width: 28em;
  }
  .Profile_header_search__2JqGb {
    width: 130px;
  }

  .Profile_header_search__2JqGb form input {
    width: 52px;
  }
  #Profile_hamburger_card__1gSGt {
    width: 100%;
    padding: 30px 30px 30px 30px;
  }
  .Profile_inner_invitation__19I3Y {
    flex-direction: column;
  }
  .Profile_inner_invitation__19I3Y > div{
    width: 100%;
  }
  .Profile_flex_sp__3VV7v {
    justify-content: flex-start;
  }
  .Profile_flex_sp__3VV7v > div {
    width: 100%;
  }
  .Profile_connect_btn__1xqSA {
    width: 100%;
    margin-top: 10px;
  }
  .Profile_connect_btn_fade__1dfAo {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 390px) {
  .Profile_header__2Nloy {
    position: inherit;
  }

  .Profile_feed__3W7_2,
  .Profile_Featured__3XWA6 {
    width: 25em;
  }
  .Profile_header_search__2JqGb {
    width: 130px;
  }
  .Profile_header_search__2JqGb form {
    width: 60px;
  }
}

@media (max-width: 356px) {
  .Profile_header__2Nloy {
    position: inherit;
  }

  .Profile_feed__3W7_2,
  .Profile_Featured__3XWA6 {
    width: 22em;
  }
  .Profile_header_search__2JqGb {
    width: 97px;
  }

  .Profile_header_search__2JqGb form input {
    width: 16px;
  }
}

/* Dropdown Button */

.Profile_editdescfeed_dropdown__2HR_z {
  position: relative;
  display: inline-block;
}

.Profile_editdescfeed_dropdown_content__BLVbQ {
  display: none;
  position: absolute;
  top: 50px;
  left: -95px;
  background-color: #ffffff;
  width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.Profile_editdescfeed_dropdown_content__BLVbQ a {
  color: #555555;
  padding: 8px 8px;
  text-decoration: none;
  display: block;
  border: 2px solid rgb(247, 247, 247);
  font-size: 12px;
}

.Profile_editdescfeed_dropdown_content__BLVbQ a:hover {
  background-color: #ddd;
}

.Profile_editdescfeed_dropdown__2HR_z:hover .Profile_editdescfeed_dropdown_content__BLVbQ {
  display: block;
}

.Profile_root_search__3dRaO {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.Profile_icon_text__R4q12 {
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  margin-left: 15px;
  letter-spacing: 0px;
}

.Profile_suggestion_card_div__3Yg6o {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  /* grid-template-columns: repeat(auto-fill, 17.5em); */
}

.Profile_suggestion_card__jNqlD {
  height: 250px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 5px;
  box-shadow: rgba(85, 85, 85, 0.1) 0px 0px 15px;
}

.Profile_suggestion_card_avatar_div__2RPw6 {
  position: absolute;
  border-radius: 50%;
  top: 50px;
  left: 10px;
}

.Profile_suggestion_card_avatar_div__2RPw6 img {
  height: 60px;
}

.Profile_suggestion_card_header__3gYxN {
  height: 30%;
  flex: 2 1;
  background-color: #ED1E24;
  display: flex;
  justify-content: flex-end;
}

.Profile_dismiss_suggestion_card__3OPtH {
  color: #113474;
  cursor: pointer;
  margin: 10px;
  height: 22px;
}

.Profile_suggestion_card_body__2cflD {
  flex: 3 1;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 40px 15px 0px 15px;
}
.Profile_inner_invitation__19I3Y {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  margin: 10px 0px;
}

.Profile_flex_col_cen__18e25 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Profile_section_title__3uJLL {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
}

.Profile_center_div__24m1z {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile_inner_invitation_img__1q9tk {
  margin-right: 10px;
}

.Profile_search_global_mains__199AE {
  display: flex;
  justify-content: center;
}

.Profile_tagList__rdkFy {
  background-color: #EFEFEF;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 15px  5px 15px;
  font-weight: 600;
  margin: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.Profile_langList__FfMnA{
  display: flex;
  padding: 5px 15px  5px 15px;
  font-weight: 600;
  margin: 5px;
  align-items: center;
  position: relative;
}
.Profile_langHr__27fCU {
  border: 2.4px solid;
  width: 16px;
  margin: 0 10px;
  border-radius: 10px;
}
.Profile_buttonsContainer__3QT1X .Profile_button__1xhlU {
  display: flex;
  padding: 16px 30px 16px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  margin-top: 23px;
  margin-bottom: 23px;
}

.Profile_buttonsContainer__3QT1X .Profile_button__1xhlU:hover {
  background-color: #ED1E24;
  color: #fff;
}

/* Modal popups */
.Profile_modal_wrapper__3anT2 {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
}

.Profile_modal_header__1W6-H {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #5555555a;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.Profile_scroll_div_class__1std1 {
  max-height: 400px;
  max-width: 600px;
  overflow: scroll;
  padding: 3px;
}

.Profile_save_btn_wrapper__3HU71 {
  margin-top: 40px;
}

.Profile_save_btn__3GBGm {
  border: 2px solid #ED1E24;
  padding: 12px 60px;
  border-radius: 40px;
  cursor: pointer;
}

.Profile_save_btn_p__3-fnR {
  margin: 0px;
  font-weight: 600;
  text-transform: uppercase;
}

.Profile_pointer_div__3_ct7 {
  cursor: pointer;
}

.Profile_modal_input_text__38rJo {
  border: 1px solid #5555555a;
  padding: 10px;
  font-size: 15px;
  border-radius: 50px;
}

.Profile_sidebar_stats__3y1R9 {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.Profile_Hr__3iILe {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Profile_sidebar_stat1__goWL_ {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.Profile_sidebar_stat1__goWL_ > p {
  display: flex;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
}

/* language icon css */
.Profile_language_header__2cttq{
  text-decoration: none;
  /* padding: 15px 10px 15px 45px!important; */
  padding: 1px 0px 4px 0px;
  background-color: transparent;
  border-radius: 25px;
  color: white;

  width: 22px;
  /* height: 18px; */
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.Profile_langTextDiv__1pxoz{
  align-items: center;
  margin: auto;
  font: normal normal normal 13px/16px Montserrat;
  /* height: 16px */
}
.Profile_language_div__1qGCD{
  display: flex;
  cursor: pointer;
  position: relative;
}
.Profile_language_div_dark__zyBj6{
  display: flex;
  color: #858585;
  cursor: pointer;
  position: relative;
 
    text-align: center;
    display: grid;
    place-items: center;
  
}

.Profile_dropdownLanguage__2s6eK{
  width: 100px;
  height: 125px;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  left: -10px;
  opacity: 1;
  background-color: #fff;
  z-index: 10;
}

.Profile_dropdownList__2u2yx{
  list-style: none;
  padding: 0;
  height: 93.6px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.Profile_inactiveList__3SF4q{
  margin-top: 6px !important; 
  color: #555555;
  cursor: "pointer";
  font-size: 15.5px
}

.Profile_activeList__2oXMI{
  margin-top: 6px !important; 
  color: #ED1E24;
  cursor: "pointer";
  font-size: 15.5px
}

.Profile_icon_container_assignment__3IJ2P {
  background-color: #ED1E24;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 38px;
  width: 38px;
}

.Profile_inner_icon_assignment__2novu {
  height: 24px;
  width: 24px;
}
@media (max-width: 800px) {
  .Profile_icon_container_assignment__3IJ2P {
    height: 28px;
    width: 28px;
  }
  .Profile_inner_icon_assignment__2novu {
    height: 19px;
    width: 19px;
  }
}

@media (max-width: 580px){
  .Profile_icon_container_assignment__3IJ2P {
    height: 20px;
    width: 20px;
  }
  .Profile_icon_text__R4q12 {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  /* .icon_container {
   background-color: white;
  } */
}
.qrModal_container__9j8ZV {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  padding: 30px 30px 20px 30px;
  flex-direction: column;
  height: auto;
}
.qrModal_qrModalContainer__1krKz {
  /* max-width: 540px; */
}
.qrModal_qrImage__3YGEL {
  display: flex;
  justify-content: center;
}

.qrModal_qrImage__3YGEL > img {
  width: 85px;
  height: 85px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
}

.qrModal_qrdetails__2VeBh {
  padding-bottom: 30px;
  padding-top: 10px;
}

.qrModal_qrdetails__2VeBh > h3 {
  text-align: center;
  font: normal normal 600 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.qrModal_qrdetails__2VeBh > h4 {
  text-align: center;
  font: normal normal normal 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding-top: 10px;
}

.qrModal_qrcode__2DzSc > img {
  width: 170px;
  height: 170px;
}

.qrModal_qrcode__2DzSc {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrModal_qrbuttons__XYHVM {
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrModal_qrButton__3_w3E:nth-child(1){
  margin-right: 20px;
}

.qrModal_qrButton__3_w3E {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 200px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  color: #ED1E24;
  cursor: pointer;
}

.qrModal_qrButton__3_w3E:hover {
  background-color: #ED1E24;
  color: white;
}
@media only screen and (max-width: 600px) {
  .qrModal_qrbuttons__XYHVM {
    flex-direction: column;
  }
  .qrModal_qrButton__3_w3E:nth-child(1){
    margin-bottom: 20px;
  }
  .qrModal_qrModalContainer__1krKz {
    min-width: 600px;
  }
}
.shareModal_socialhandle__cYROm {
  display: flex;
}

.shareModal_container__1uTyv {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.shareModal_socialContainer__3NWah {
  display: flex;
  justify-content: space-between;
}

.shareModal_socialContainer__3NWah > img {
  cursor: pointer;
}

.shareModal_copyContainer__1NULx {
  background: #f2f3f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 30px 21px 30px;
  display: flex;
  align-items: center;
}

.shareModal_link__1s29A {
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.shareModal_copyButton__3VwR_ {
  order: 2;
  margin-left: auto;
  cursor: pointer;
}

.groupsidebar_Class_Sidebar__1CvKp {
  text-align: center;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 5;
  /* width:250px;
  margin-left: 70px; */
}
.groupsidebar_Classnavbar_menu1__366c4 {
  z-index: 11;
  height: 0;
  width: 24px;
}
.groupsidebar_Classsidebar_mycontent__3qHSu {
  display: flex;
  justify-content: space-between;
}
.groupsidebar_dropdown_menu1__1ciQ9 {
  width: 184px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #d6cccc29;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
  position: relative;
  right: 160px;
}

.groupsidebar_dropdown_menu1__1ciQ9 ul {
  list-style: none;
  padding: 3px 0px;
}

.groupsidebar_dropdown_menu1__1ciQ9 li {
  padding: 4px 12px;
}
.groupsidebar_menu1__EqMVM {
  text-align: left;
  font-size: 12px;
}

.groupsidebar_Class_side__Mszua {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.groupsidebar_Classsidebar_icon1__2xjPa {
  background: #ED1E24 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}
.groupsidebar_video__3FUo0 {
  height: 70px;
  width: 70px;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 8px;
  margin: 0 10px;
}
.groupsidebar_live_session__LYmQ0 {
  margin-top: 20px;
  align-self: stretch;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.groupsidebar_live_session__LYmQ0:hover {
  background-color: #ED1E24;
  color: #ffffff;
}

.groupsidebar_Classsidebar_icon2__2Jypm {
  color: #929292;
}

.groupsidebar_Classsidebar_mycontent__3qHSu > h3 {
  font: normal normal medium 16px/19px Montserrat;
}

.groupsidebar_Classsidebar_stats__3uGGb {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.groupsidebar_Classsidebar_stat__1ou5w {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
  cursor: pointer;
}

.groupsidebar_Classsidebar_stat__1ou5w > p {
  color: #555555;
  font: normal normal normal 14px/18px Montserrat;
}

.groupsidebar_ClassHr__3n0Ub {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.grouprightsidebar_group_rightsidebar__I4-55 {
  border-radius: 10px;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* margin-top: 210px; */
  margin-top: 15px;
  /* width:300px;
    margin-right: 70px;
    margin-left: 20px; */
}
.grouprightsidebar_groupRightsidebar_stats__2KHqb {
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.grouprightsidebar_groupRightsidebar_stat__2FBrV {
  text-align: left;
  padding: 20px 15px 20px 15px;
}
.grouprightsidebar_groupRightsidebar_inner_stat__3J7At {
  flex-direction: "row";
  justify-content: space-between;
  background-color: "red";
}
.grouprightsidebar_groupHr__3sN5f {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.grouprightsidebar_groupRightsidebar_stat1__1s7gS {
  text-align: left;
  padding: 20px 15px 20px 15px;
}

.grouprightsidebar_classinputdiv__1woYp {
  margin-top: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  /* width:200px; */
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  /* margin-left: 25px; */
  margin-bottom: 15px;
}

.grouprightsidebar_classinputdiv__1woYp > form > input {
  font: italic normal medium 13px/16px Montserrat;
  outline: none;
  border: none;
  background: none;
  width: 190px;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  /* margin-right: 2px; */
}

.grouprightsidebar_groupinviteButton__2P0IC {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  height: 40px;
  font: normal normal 600 12px/15px Montserrat;
  font-weight: 600;
  color: #ED1E24;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
}

.grouprightsidebar_groupinviteButton__2P0IC:hover {
  background-color: #ED1E24;
  color: white;
}

.mobileBurger {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: white;
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  transition: all ease-in 1s;
  overflow-x: hidden;
}

#mobileBurg {
  display: none;
}

.mobileBurger.showBurger {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
}

@media (min-width: 800px) {
  .mobileBurger {
    left: 350px;
  }
}

.classsidebar_Class_Sidebar__17rOR {
  text-align: center;
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 5;
  /* width:250px; */
  /* margin-left: 70px; */
}
.classsidebar_classList__o_Nnb:hover {
  cursor: pointer;
}

.classsidebar_Classsidebar_mycontent__3IAbv {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.classsidebar_Classnavbar_menu1__1ysPu {
  z-index: 11;
  height: 0;
  width: 24px;
}
.classsidebar_Class_side__8kXi2 {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}
.classsidebar_live_stream__ta-k- {
  margin-bottom: 10;
}
.classsidebar_sidebar_stat1__13bEi > p {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
}

.classsidebar_Classsidebar_icon1__1APiH {
  background: #ED1E24 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}
.classsidebar_dropdown_menu1__199T9 {
  width: 184px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
  position: relative;
  right: 160px;
}

.classsidebar_dropdown_menu1__199T9 ul {
  list-style: none;
  padding: 3px 0px;
}

.classsidebar_dropdown_menu1__199T9 li {
  padding: 4px 12px;
}
.classsidebar_menu1__2O2K7 {
  text-align: left;
  font-size: 12px;
}

.classsidebar_navbar_menu1__1cyZp {
  z-index: 11;
  height: 0;
  width: 24px;
}

.classsidebar_Classsidebar_icon2__1OA-o {
  color: #929292;
}

.classsidebar_Classsidebar_mycontent__3IAbv > h3 {
  font-size: normal normal medium 16px/19px Montserrat;
}

.classsidebar_Classsidebar_stats__ifmWV {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.classsidebar_Classsidebar_stat__35I58 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
  cursor: pointer;
}
.classsidebar_Classsidebar_stat1__Xdw7I {
  margin-top: 10px;
  display: flex;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 5px 5px 15px;
}

.classsidebar_Classsidebar_stat__35I58 > p {
  color: #555555;
  font: normal normal normal 14px/18px Montserrat;
}

.classsidebar_ClassHr__2Oj-v {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
.classsidebar_video__3j3Pj {
  height: 70px;
  width: 70px;

  border: none;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 8px;
  margin: 0 10px;
}
.classsidebar_live_session__2pt7s {
  margin-top: 20px;
  align-self: stretch;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.classsidebar_live_session__2pt7s:hover {
  background-color: #ED1E24;
  color: #ffffff;
}

.classsidebar_Classsidebar_statNumber__O4jKk {
  text-align: left;
  margin: auto 15px;
}
.classsidebar_onhover__3eFZ0{
  color: inherit;
}
.classsidebar_onhover__3eFZ0:hover{
  color: #ED1E24;
  /* text-align: left;
  margin: auto 15px; */
}
/* .Classsidebar_statNumber:hover {
  color: #ED1E24;
} */

.classsidebar_sidebar_stat1__13bEi {
  cursor: pointer;
}

.classsidebar_classModal__11D-E {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.classsidebar_formFields__1J78H {
  padding: 0 30px;
  box-sizing: border-box;
}
.classsidebar_inputLabel__2iS0F {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}
.classsidebar_inputField__1fnUS {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.classsidebar_saveDetailsButton__u71hP {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  /* margin-left: 20px; */
  margin-top: 20px;
  cursor: pointer;
}

.classsidebar_saveDetailsButton__u71hP:hover {
  background-color: #ED1E24;
  color: white;
  /* border: 1px #236ae8 solid; */
}
.classsidebar_textarea__2-XEx {
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  padding-bottom: 5px;
  outline: none;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .classsidebar_Classsidebar_mycontent__3IAbv {
    margin-top: 0;
  }
  .classsidebar_Class_side__8kXi2 {
    margin-top: 10px;
  }
  .classsidebar_Classnavbar_menu1__1ysPu {
    margin-top: 10px;
  }
}

.Modal_Overlay__3p_Ou {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: #232D4C; */
  background: #000;
  opacity: 0.5;
}

.Modal_Modal__2tODS {
  z-index: 110;
  /* position: fixed; */
  position: absolute;
  top: 50%;
  background: white;
  border-radius: 10px;
  width: 80%;
  max-width: 900px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Modal_ModalContainer__2r9bp {
  top: 0;
  left: 0;
  z-index: 110;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* .NewScrollBar::-webkit-scrollbar {
    width: 7px;
    height: 7px;
   }
.NewScrollBar::-webkit-scrollbar-thumb {
background: linear-gradient(13deg, #f9d4ff 14%,#c7ceff 64%);
border-radius: 10px;
}
.NewScrollBar::-webkit-scrollbar-thumb:hover{
background: linear-gradient(13deg, #c7ceff 14%,#f9d4ff 64%);
}
.NewScrollBar::-webkit-scrollbar-track{
background: #ffffff;
border-radius: 15px;
box-shadow: inset 17px 0px 0px #f0f0f0;
} */

.MiddleContain_classModal__2iMzr {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.MiddleContain_thumbnailArea__1qNmP {
  width: 550px;
  height: 125px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 25px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
}
.MiddleContain_inputField__EzCM5 {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}
.MiddleContain_textarea__dqzZK {
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 5px;
  outline: none;
  margin-left: 15px;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.MiddleContain_inputField1__JTK9F {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 15px 0px 15px 15px;
}
.MiddleContain_classHrDiv__2cEXs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

/* .drop {
  display: flex;
  align-items: center;
}  */

input.MiddleContain_inputField1__JTK9F::-webkit-input-placeholder {
  top: 10px;
  position: absolute;
}

input.MiddleContain_inputField1__JTK9F::placeholder {
  top: 10px;
  position: absolute;
}
.MiddleContain_inputLabel__2u3jt {
  font-size: 13px;
  margin: 0 10px;
  padding-left: 1rem;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: normal;
}

.MiddleContain_saveDetailsButton___CtdA {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 24px;
}

.MiddleContain_saveDetailsButton___CtdA:hover {
  background-color: #ED1E24;
  color: white;
  margin-bottom: 24px;
  /* border: 1px #236ae8 solid; */
}
.MiddleContain_fileUpload__19QU7 {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.MiddleContain_uploadBox__3ryAP input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.MiddleContain_thumbnailPic__25cgs {
  width: 550px;
  height: 125px;
}
@media (max-width: 800px) {
  .MiddleContain_inputField__EzCM5, .MiddleContain_inputField1__JTK9F {
    width: 85%;
  }
  .MiddleContain_thumbnailArea__1qNmP {
    width: 85%;
  }
  .MiddleContain_thumbnailPic__25cgs {
    width: 100%;
  }
}

.AgendaBottomContainer_container__3BUV9 {
  padding: 10px 0px;
  grid-gap: 30px;
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  align-items: center;
  text-align: justify;
  margin-left: 15px;
}

.AgendaBottomContainer_photo__1vgXJ {
  /* transition: all .3s; */
  height: 32px;
  cursor: pointer;
}

.AgendaBottomContainer_rightInner__qWrFo {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  order: 2;
  margin-left: auto;
}

.AgendaBottomContainer_innerBox__1dPNy {
  grid-gap: 25px;
  gap: 25px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.AgendaBottomContainer_photo__1vgXJ:hover {
  -webkit-filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg) brightness(95%) contrast(93%);
          filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg) brightness(95%) contrast(93%);
}

.AgendaBottomContainer_post__2MqzB {
  display: flex;
  padding: 13px 30px 2px 30px;
  border: 1px solid #ED1E24;
  color: #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
}

.AgendaBottomContainer_post__2MqzB:hover {
  background-color: #ED1E24;
  color: #fff;
}

.AgendaBottomContainer_fileUpload__1Zl6t {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.AgendaBottomContainer_uploadBox__166-l input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.DropBox_container__1VRcc {
  display: flex;
  padding: 12px 20px 12px 20px;
  border: 1px solid #9e9e9e;
  border-radius: 50px;
  min-width: 125px;
  cursor: pointer;
}

.DropBox_container__1VRcc:hover {
  color: #ED1E24;
  /* border: 1px solid #6a96ec; */
}

.DropBox_container__1VRcc:hover > .DropBox_light__JzS7I {
  color: #ED1E24;
}

.DropBox_light__JzS7I {
  color: #757575;
}

.DropBox_eye__hfpJ9 {
  height: 20px;
  width: 17px;
  margin-right: 5px;
}

.DropBox_dropArrow__3rT9h {
  height: 20px;
  position: relative;
  top: -2px;
  margin-left: auto;
  order: 2;
}

.DropBox_dropdown_menu1__2GdNq {
  width: 130px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: 20px;
}

.DropBox_dropdown_menu1__2GdNq ul {
  list-style: none;
  padding: 3px 0px;
}

.DropBox_dropdown_menu1__2GdNq li {
  padding: 4px 12px;
}

.DropBox_Hr3__3J51y {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0;
}

.DropBox_menu1__14EIb {
  text-align: left;
  font-size: 12px;
}

.DropBox_navbar_menu1__1wh04 {
  z-index: 12;
  height: 45px;
  width: 160px;
}

.Sidebar_sidebar__1H74E {
  /* position: sticky; */
  /* top: 80px; */
  /* flex: 0.24; */
  text-align: center;
  /* margin-left: 80px; */
  margin-top: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 20px #3F3F411A; */
  opacity: 1;
  /* width: 310px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 5;
}

.Sidebar_profile_section__GONv3 {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
  padding-bottom: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Sidebar_sidebar_avatar__1_4e3 {
  /* margin-bottom: 80px; */
  width: 105px !important;
  height: 105px !important;
  border-radius: 100% !important;
  margin-top: -30px;
}

.Sidebar_sidebar_top__2AA0Z {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  /* border: 1px solid #555555;
  border-color: white;
  border-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  padding-bottom: 10px;
  margin-bottom: 5px; */
}

.Sidebar_sidebar_top__2AA0Z > img {
  margin-bottom: -20px;
  width: 100%;
  height: 100px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.Sidebar_name__1yAAL {
  margin-top: 20px;
  display: flex;
  margin-bottom: 5px;
}

.Sidebar_sidebar_top__2AA0Z > p {
  text-align: center;
  font: normal normal normal 14px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  font-weight: 300px;
  opacity: 1;
}

.Sidebar_name__1yAAL > h2 {
  text-align: center;
  font: normal normal 600 19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 300px;
  opacity: 1;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
}

.Sidebar_Hr__2oJYz {
  /* height: 2px !important;
  width: 280px !important;
  color: #555555 !important;
  opacity: 0.3; */
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Sidebar_view__3FnNv {
  text-align: left;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
}

.Sidebar_view__3FnNv > p {
  font: normal normal normal 13px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  /* font-weight: 300px; */
  opacity: 1;
  margin-top: 10px;
}

.Sidebar_premium_view__3dIA1 {
  justify-content: flex-start;
  grid-gap: 12px;
  gap: 12px;
  cursor: pointer;
}

.Sidebar_activity__3ecLn {
  margin-top: 18px;
  margin-bottom: 10px;
  align-items: center;
  text-transform: uppercase;
}

.Sidebar_live_session__3vlW3 {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 20px 12px 20px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: white;
}

.Sidebar_live_session__3vlW3:hover {
  background-color: #ED1E24;
  color: #ffffff;
}

.Sidebar_sidebar_mycontent__2xZIA {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.Sidebar_side__3gtfT {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.Sidebar_sidebar_icon1__1-nPq {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}

.Sidebar_sidebar_icon2__1r2HZ {
  color: #929292;
}

.Sidebar_sidebar_mycontent__2xZIA > h3 {
  font: normal normal medium 16px/19px Montserrat;
}

.Sidebar_sidebar_stats__1x6Je {
  margin-top: 15px;
  /* padding: 10px; */
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0 0 10px 5px #d3d3d3; */
  box-shadow: 0px 0px 20px #3f3f411a;
}

.Sidebar_sidebar_stat__1YUdz {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px;
}

.Sidebar_sidebar_stat__1YUdz > p {
  color: #555555;
  font: normal normal normal 13px Montserrat;
  /* text-align: left; */
  /* font-weight: 600; */
}

.Sidebar_sidebar_stat1__263cA {
  cursor: pointer;
}

.Sidebar_sidebar_stat1__263cA > p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
  border-top: 1px solid #d6d6d6;
}

.Sidebar_sidebar_stat2__2Q7KN {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
  /* border-top: 1px solid #d6d6d6; */
}

.Sidebar_dropdown_menu1__DZo12 {
  width: 100px;
  height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: -80px;
}

.Sidebar_dropdown_menu1__DZo12 ul {
  list-style: none;
  padding: 1px 0px;
}

.Sidebar_dropdown_menu1__DZo12 li {
  padding: 4px 12px;
}

.Sidebar_Hr3__3B1TI {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Sidebar_menu1__3zElg {
  text-align: left;
  font-size: 12px;
}

.Sidebar_navbar_menu1__13bfD {
  z-index: 11;
  height: 0;
  width: 24px;
}

.Sidebar_modalBody__20ef3 {
  background-color: #fff;
  /* padding: 12px 20px; */
  border-radius: 0 0 15px 15px;
}

.Sidebar_premium_text_sidebar__3D8Lk {
  font: normal normal medium 15px/19px Montserrat;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: capitalize;
}

.Sidebar_connection_text_sidebar__XknEA {
  font: normal normal medium 13px Montserrat;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: capitalize;
}

.Sidebar_hastagsParagraph__2tZzZ {
  text-align: left;
  padding-left: 5px;
}

.Sidebar_menuList__36JTS:hover {
  cursor: pointer;
}

.Sidebar_joinClassCode__11CJ7 {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
}
@media (max-width: 1140px) {
  .Sidebar_sidebar__1H74E {
    margin-top: 60px;
  }
}
@media (min-width: 1100px) {
  .Sidebar_premium_view__3dIA1 img {
    height: 25px !important;
    width: 24px !important;
  }
}

.Sidebar_courseFeedData__2_ytK {
  display: flex;
  /* justify-content: center; */
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.Sidebar_courseFeedData__2_ytK img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  /* margin-right: 10px; */
  /* margin: 10px; */
  padding: 10px;
}

.Sidebar_Hr3__3B1TI {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Sidebar_courseFeedData__2_ytK p {
  text-align: left;
  padding: 10px 0;
  margin: auto 0;
  color: #707070;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.classrightsidebar_group_rightsidebar__2j5bX {
  border-radius: 10px;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 15px;
  /* width:300px; */
  /* margin-right: 70px; */
  /* margin-left: 20px; */
}
.classrightsidebar_groupRightsidebar_stats__1Nurq {
  margin-bottom: 10px;
  border: 1px solid rgb(75, 64, 64);
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}

.classrightsidebar_groupRightsidebar_stat__Y-KQd {
  text-align: left;
  padding: 0px 15px;
}
.classrightsidebar_groupRightsidebar_inner_stat__3dkcA {
  flex-direction: "row";
  justify-content: space-between;
  background-color: "red";
}

.classrightsidebar_groupHr__39rcL {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.classrightsidebar_groupRightsidebar_stat1___6cbi {
  text-align: left;
  padding: 0 15px;
}

.classrightsidebar_classinputdiv__1ZxjH {
  margin-top: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  /* width:200px; */
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  /* margin-left: 25px; */
  margin-bottom: 15px;
}

.classrightsidebar_classinputdiv__1ZxjH > p {
  font: italic normal medium 13px/16px Montserrat;
  outline: none;
  border: none;
  background: none;
  width: 190px;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  cursor: pointer;
  flex: 0.9 1;
  /* margin-right: 2px; */
}

/* height: 40px;
    border-radius:25px;
    margin-top: 5px;
    /* width: 240px; */
/* border: 1px solid #C2C2C2;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding-left: 20px;
    outline: none;  */

.classrightsidebar_groupinviteButton__3zIZi {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-top: 10px;
  cursor: pointer;
}

.classrightsidebar_groupinviteButton__3zIZi:hover {
  background-color: #ED1E24;
  color: white;
}

.classrightsidebar_class_agenda__2BeYc {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 20px;
  padding: 10px;
}

.classrightsidebar_class_agenda_details__oYBcK {
  display: flex;
  margin-bottom: 5px;
}

.classrightsidebar_class_agenda_details__oYBcK > img {
  height: 23px;
  width: 26px;
  margin-right: 10px;
  cursor: pointer;
}

.classrightsidebar_class_agenda_details__oYBcK > button {
  border: 1px #ED1E24 solid;
  background-color: white;
  width: 57px;
  height: 27px;
  border-radius: 2rem;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  cursor: pointer;
}

.classrightsidebar_class_agenda_details__oYBcK > button:hover {
  background-color: #ED1E24;
  color: white;
}

.classrightsidebar_class_agenda_details__oYBcK > h4 {
  font: normal normal medium 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.classrightsidebar_class_agenda__2BeYc > h4 {
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.classrightsidebar_Classsidebar_mycontent__3pgFI {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.classrightsidebar_Classsidebar_mycontent__3pgFI > h3 {
  font-size: normal normal medium 16px/19px Montserrat;
}
.classrightsidebar_Classsidebar_stats__2Ykuy {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 20px #3f3f411a;
}
.classrightsidebar_ClassHr__3SUKv {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}
.classrightsidebar_sidebar_stat1__2ibZE > p {
  display: flex;
  align-items: center;
  margin-left: 25%;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
}
.classrightsidebar_sidebar_stat1__2ibZE {
  cursor: pointer;
}
.classrightsidebar_Classsidebar_icon1__mhwHX {
  background: #ED1E24 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  opacity: 1 !important;
}
.classrightsidebar_Class_side__2FLro {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.autosuggestioncomponent_dropdown_menu1__27kMZ {
  width: 470px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  opacity: 1;
  padding: 10px;
  position: relative;
  right: 20px;
}

.InvitePeopleModal_modalHeading__3QXm6 {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.InvitePeopleModal_comingSoon__1T63k {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}
.InvitePeopleModal_groupinviteButton__1hRVK {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 25%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 26px;
  margin-left: 23px;
  margin-top: 26px;
}
.InvitePeopleModal_groupinviteButton__1hRVK:hover {
  background-color: #ED1E24;
  color: white;
}
.InvitePeopleModal_bottomContainer__1n453 {
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.InvitePeopleModal_bottom_details__38Tdk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.InvitePeopleModal_bottom__12C48 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.InvitePeopleModal_inputLabel__2q1_p {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 1rem;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: normal;
}

.InvitePeopleModal_outerContainer__o_ywS {
  background: #ffffff;
  border-radius: 10px;
  max-width: 537px;
  position: relative;
}

.InvitePeopleModal_close__2VshF {
  width: 21px;
  height: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #171616;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.InvitePeopleModal_tab__-YfX9 {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: medium;
}

.InvitePeopleModal_tablinks__1R6mP {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.InvitePeopleModal_tablinks__1R6mP:hover {
  border-bottom: #ED1E24;
  border-bottom-width: 2px;
}

.InvitePeopleModal_tablinksactive__1NYBG {
  background-color: red;
  border-bottom: #ED1E24;
  border: 1px solid #ED1E24;
}
.InvitePeopleModal_inputField1__ah_Vx {
  border-radius: 25px;
  width: 470px;
  margin-left: 15px;
  min-height: 40px;
  height: -webkit-max-content;
  height: max-content;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}
.InvitePeopleModal_modalContainer__swFd8 {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  max-width: 530px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.InvitePeopleModal_userBox__3EQ2W {
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  max-height: 30px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  flex-direction: "row";
}

.UpcomingAssignmentsComponent_ClassHr__pBjzF {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.UpcomingAssignmentsComponent_upcoming_container__2-N4M {
}

.UpcomingAssignmentsComponent_top_container__3MlTj {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UpcomingAssignmentsComponent_card_container__lZYtk {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0px solid #e5e5e5;
  border-color: #e5e5e5;
  /* box-shadow: 0px 0px 20px #3f3f411a; */
  border-radius: 10px;
  padding: 8px 0;

  /* Card shadow */
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
}

.UpcomingAssignmentsComponent_bottom_container__Wz60D {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
  cursor: pointer;
}

/* 
margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px #3f3f411a;
 */

.UpcomingAssignmentList_list_container__2YZ9T {
  /* display: flex;
  justify-content: space-between; */
}

.UpcomingAssignmentList_assignment_title__sfPp_ {
  margin: 0 5px 0 18px;
  font-size: 12px;
}

.UpcomingAssignmentList_title_and_date__20SAp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.UpcomingAssignmentList_assignment_due_date__LOn7F {
  margin: 0px 18px 0 5px;
  font-size: 11px;
}
.UpcomingAssignmentList_ClassHr__jT7_7 {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.MobileBottom_mobileBottom__Ns9Zt {
  width: auto;
  height: 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #55555533;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 20;
}

.MobileBottom_bottom__2iPuO {
  top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.MobileBottom_bottom_container__Zt-Rv {
  text-align: center;
  display: grid;
  place-items: center;
}

.MobileBottom_image_name__3PUQV {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
}

.MobileBottom_mobile_image__3ft03 {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.3;
  cursor: pointer;
}

@media (min-width: 1140px) {
  .MobileBottom_mobileBottom__Ns9Zt {
    display: none;
  }
}

@media (max-width: 1099px) {
  .MobileBottom_mobileBottom__Ns9Zt {
    display: flex;
  }
}

/* .chatMobileClass {
    display: block !important;
    top: 90px !important;
    bottom: 76px !important;
    left: 0px !important;
    background-color: #fff !important;
    width: 100% !important;
} */

/* language icon css */

.MobileBottom_language_header__2L5Pb{
  text-decoration: none;
  padding: 0px 0px 4px 0px;
  background-color: transparent;
  border-radius: 25px;
  color: white;

  width: 22px;
  /* height: 18px; */
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.MobileBottom_langTextDiv__1GbOO{
  align-items: center;
  margin: auto;
  /* text-transform:uppercase; */
  font: normal normal normal 13px/16px Montserrat;
  /* height: 16px */
}
.MobileBottom_language_div__LvhOh{
  display: flex;
  cursor: pointer;
  position: relative;
}
.MobileBottom_language_div_dark__3VfHz{
  display: flex;
  color: #b4b4b4;
  cursor: pointer;
  position: relative;
 
    text-align: center;
    display: grid;
    place-items: center;
  
}

.MobileBottom_dropdownLanguage__2vIDc{
  width: 100px;
  height: 125px;
  box-shadow: 3px -1px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  bottom: 55px;
  /* left: -10px; */
  opacity: 1;
  background-color: #fff;
  z-index: 10;
}

.MobileBottom_dropdownList__wKAyj{
  list-style: none;
  padding: 0;
  height: 93.6px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.MobileBottom_inactiveList__1yKdH{
  margin-top: 6px !important; 
  color: #555555;
  cursor: "pointer";
  font-size: 15.5px
}

.MobileBottom_activeList__3yRig{
  margin-top: 6px !important; 
  color: #ED1E24;
  cursor: "pointer";
  font-size: 15.5px
}
.SmallChatBox_outerContainer___Lwen {
  max-width: 300px;
  width: 100%;
  min-width: 300px;
  margin-top: auto;
  pointer-events: all;
}

@media screen and (max-width: 410px) {
  .SmallChatBox_outerContainer___Lwen {
    right: 0;
    max-width: 100%;
  }
}

.SmallChatBox_innerContainer__2DNes {
  pointer-events: all;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 7px 7px 0 0;
  display: flex;
  /* box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.15); */
  box-shadow: 0px 3px 20px #5555551a;
  align-items: center;
  font: small;
  grid-gap: 7px;
  gap: 7px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  /* min-width: 300px; */
}

.SmallChatBox_status__1HkoL {
  position: absolute;
  bottom: 2px;
  right: 2px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #2ddf20;
  z-index: 5;
}

.SmallChatBox_profilePicContainer__3PJTU {
  position: relative;
}

.SmallChatBox_arrowUp__18s0K {
  position: relative;
  top: 2px;
  height: 20px;
  border-radius: 100%;
  transition: all 0.2s;
  padding: 4px;
  cursor: pointer;
}

.SmallChatBox_arrowDown__beLdM {
  position: relative;
  top: 2px;
  height: 20px;
  border-radius: 100%;
  transition: all 0.2s;
  padding: 4px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SmallChatBox_arrowDown__beLdM:hover {
  background-color: rgba(238, 238, 238, 0.3);
}

.SmallChatBox_arrowUp__18s0K:hover {
  background-color: rgba(238, 238, 238, 0.3);
}

.SmallChatBox_rightContainer__2hlsB {
  order: 2;
  margin-left: auto;
  display: flex;
}

.SmallChatBox_profilePic__2wf4T {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  object-fit: cover;
}

.SmallChatBox_phone__XuKc- {
  position: relative;
  top: 2px;
  height: 20px;
  border-radius: 100%;
  transition: all 0.2s;
  padding: 4px;
  cursor: pointer;
  -webkit-filter: invert(75%) sepia(21%) saturate(0%) hue-rotate(231deg) brightness(94%) contrast(86%);
          filter: invert(75%) sepia(21%) saturate(0%) hue-rotate(231deg) brightness(94%) contrast(86%);
}

.SmallChatBox_video__1Hwmv {
  position: relative;
  top: 2px;
  height: 20px;
  border-radius: 100%;
  transition: all 0.2s;
  padding: 4px;
  cursor: pointer;
  -webkit-filter: invert(78%) sepia(68%) saturate(1308%) hue-rotate(139deg) brightness(91%) contrast(93%);
          filter: invert(78%) sepia(68%) saturate(1308%) hue-rotate(139deg) brightness(91%) contrast(93%);
}

.ChatList_container__1PUhM {
  background-color: #fff;
  /* display: flex; */
  max-height: 550px;
  overflow-y: auto;
  padding-right: 4px;
  /* flex-direction: column; */
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
}

.ChatList_container__1PUhM::-webkit-scrollbar {
  width: 5px;
}
.ChatList_container__1PUhM::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}
.ChatList_container__1PUhM::-webkit-scrollbar-thumb:hover {
  background: #e6e6e6;
}
.ChatList_container__1PUhM::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}

.ChatSmall_container__2a_JM {
  padding-bottom: 2px;
  cursor: pointer;
  background: #eee;
  /* background: -moz-linear-gradient(left, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%);
    background: -webkit-linear-gradient(left, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%);
    background: linear-gradient(to right, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%); */
}

.ChatSmall_innerContainer__TgRm5 {
  padding: 10px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  background-color: #fff;
  border-left: 3.5px solid transparent;
}

.ChatSmall_innerContainer__TgRm5:hover {
  /* background-color: #29daed; */
  background-color: #f5f7f6;
  border-left: 3.5px solid #ED1E24;
}

.ChatSmall_innerContainerClicked__280mT {
  padding: 10px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  background-color: #ED1E24;
}

.ChatSmall_profilePic__YxaeJ {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  object-fit: cover;
}

.ChatSmall_status__28W8I {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #2ddf20;
  z-index: 5;
}

.ChatSmall_profilePicContainer__3YDcy {
  position: relative;
}

.ChatSmall_nameContainer__1dNu3 {
  display: flex;
  /* flex-direction: column; */
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.ChatSmall_name__31u5u {
  font-weight: 500;
}

.ChatSmall_innerNameContainer__xCLab {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.ChatSmall_lastMessage__KRE-x {
  text-align: left;
  font-weight: 300;
}

.ChatSmall_dateContainer__35gNT {
  order: 2;
  margin-left: auto;
  align-content: flex-start;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.7;
}

.chat_container__1fdq4 {
  position: fixed;
  bottom: 0;
  right: 30px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  opacity: 1;
  z-index: 12;
  pointer-events: none;
}

@media (max-width: 1139px) {
  .chat_container__1fdq4 {
    display: none;
  }
}

.bigBox_container__2JTY- {
  background-color: #fff;
  height: 350px;
  /* transition: all 3s; */
  display: flex;
  width: 350px;
  flex-direction: column;
  padding: 0 1px 10px 7px;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  /* padding-top: 0;
    padding-right: 1px ;
    padding-left: 7px; */
  pointer-events: all;
}

.bigBox_middleContainer__3g_6y {
  /* display: flex; */
  /* flex-grow: 0; */
  padding-top: 5px;
  padding-right: 5px;
  overflow-y: auto;
  flex-direction: column;
  margin-top: auto;
}

.bigBox_inputContainer__2DDqL {
  display: flex;
  border-radius: 20px;
  grid-gap: 10px;
  gap: 10px;
  order: 2;
  /* margin-top: auto; */
  align-items: center;
  padding-right: 5px;
}

.bigBox_innerInputContainer__1PxUy {
  /* background-color: #eee; */
  display: flex;
  border-radius: 20px;
  grid-gap: 5px;
  gap: 5px;
  vertical-align: middle;
  align-items: center;
  padding-left: 5px;
  flex-grow: 1;
  border: 2px solid #eee;
  padding-right: 5px;
}

.bigBox_imagesContainer__3GmLA {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.bigBox_greyImage__2dsCP {
  -webkit-filter: invert(87%) sepia(0%) saturate(1129%) hue-rotate(253deg) brightness(84%) contrast(85%);
          filter: invert(87%) sepia(0%) saturate(1129%) hue-rotate(253deg) brightness(84%) contrast(85%);
}

.bigBox_addContainer__1IVla {
  background-color: #ED1E24;
  display: flex;
  align-items: center;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}

.bigBox_inputBox__3GNLu {
  /* background-color: #eee; */
  border: none;
  height: 30px;
  display: flex;
  flex-grow: 1;
  /* margin-bottom: 5px; */
  border-radius: 20px;
  padding: 5px;
  padding-left: 10px;
  color: #5e5e5e;
}

.bigBox_inputBox__3GNLu:focus {
  outline: none;
}

.bigBox_unreadText__1NkuP {
  color: #8a8a8a;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #8a8a8a;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.bigBox_unreadSpan___LAdc {
  background: #fff;
  padding: 0 10px;
}

.bigBox_middleContainer__3g_6y::-webkit-scrollbar {
  width: 5px;
}

.bigBox_middleContainer__3g_6y::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.bigBox_middleContainer__3g_6y::-webkit-scrollbar-thumb:hover {
  background: #e6e6e6;
}

.bigBox_middleContainer__3g_6y::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}

.mymessage_container__1KI76 {
  /* background-color: #29daed; */
  background-color: #ED1E24;
  color: #102d31;
  margin-left: auto;
  padding: 12px;
  border-radius: 17px;
  max-width: 80%;
  display: inline-block;
  word-wrap: break-word;
  margin-bottom: 7px;
}

.mymessage_outerContainer__1gqBo {
  order: 2;
  display: flex;
  width: 100%;
}

.yourmessage_container__3M-1Z {
  background-color: #fff;
  display: inline-block;
  color: #5e5e5e;
  padding: 12px;
  border-radius: 17px;
  max-width: 80%;
  flex-shrink: 1;
  margin-bottom: 7px;
  word-wrap: break-word;
  box-shadow: 0px 2px 4px 0px #dadada;
  margin-left: 5px;
}

.yourmessage_outer__2Mk9S {
  width: 100%;
}

.zoomHome_container__9u1rb {
  background-color: #fff;
  border-radius: 0 0 14px 14px;
  min-height: 250px;
}

.zoomHome_classModal__1GI9l {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 260px; */
  padding-top: 5px;
  padding-bottom: 25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.zoomHome_inputField__WzbM2 {
  border-radius: 25px;
  width: 550px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.zoomHome_inputField1__1ZCZ3 {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.zoomHome_inputField__WzbM2 {
  border-radius: 25px;
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  padding: 0 20px;
}
/* .drop {
  display: flex;
  align-items: center;
}  */

input.zoomHome_inputField1__1ZCZ3::-webkit-input-placeholder {
  top: 10px;
  position: absolute;
}

input.zoomHome_inputField1__1ZCZ3::placeholder {
  top: 10px;
  position: absolute;
}
/* .inputLabel {
    font-size: 13px;
    margin-bottom: 10px;
    padding-left: 1rem;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: normal;
  }   */

.zoomHome_inputLabel__1G5A0 {
  font-size: 16px;
  margin: 10px 0 10px 20px;
  font-weight: normal;
}

.zoomHome_saveDetailsButton__3-rqm {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  /* margin-left: 20px; */
  margin-top: 20px;
  cursor: pointer;
}

.zoomHome_saveDetailsButton__3-rqm:hover {
  background-color: #ED1E24;
  color: white;
  /* border: 1px #236ae8 solid; */
}
.zoomHome_hidden__g_NpD {
  display: none;
}
.zoomHome_formFields__Ccj7R {
  padding: 0 30px;
  box-sizing: border-box;
}

.career_comingSoon__wN0QE {
  text-align: center;
  font: normal normal bold 42px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 102px 71px;
  background: #ED1E24;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}

.career_bottomContainer__16nfO {
  padding: 20px 75px 41px 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.career_outerContainer__3Tbl9 {
  background: #ffffff;
  border-radius: 10px;
  max-width: 520px;
  position: relative;
}

.career_diamond__9jY4w {
  height: 40px;
  width: 42px;
}

.career_title__2vybR {
  text-align: center;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
}

.career_desc__ewekq {
  text-align: center;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding: 10px;
}

.career_close__CEHM0 {
  width: 33px;
  height: 33px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.5;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.SidebarClass_sidebarClass__1S-Xw {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}

.SidebarClass_classImage__38Tvp {
  flex: 0.3 1;
  padding: 10px 10px; 
}
.SidebarClass_classimg__2_Kwe {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}

.SidebarClass_classImageHover__gbQZu {
  position: relative;
  flex: 0.3 1;
  padding: 10px 10px;
}

.SidebarClass_classImageHover__gbQZu::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 18px;
  width: 67%;
  height: 75%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 100px;
  z-index: 1;
}

.SidebarClass_classPara__9fPsh {
  flex: 0.7 1;
  text-align: left;
  padding: 10px 0;
  margin: auto 0;
  color: #707070;
  font-weight: 600;
}
.SidebarClass_classText__1IM91 {
  margin: 10px;
}
/* .sidebar_stat1 {
  cursor: pointer;
} */

.SidebarClass_sidebar_stat1__4RYLW {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #ED1E24;
  cursor: pointer;
  font: normal normal 600 13px/16px Montserrat;
}

@media (max-width: 580px){
  .SidebarClass_classImageHover__gbQZu {
    position: relative;
    flex: 0.3 1;
    padding: 10px 10px;
  }
  
  .SidebarClass_classImageHover__gbQZu::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 25px;
    width: 59%;
    height: 75%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 100px;
    z-index: 1;
  }
}

.SidebarGroup_sidebarClass__1OFcv {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}
.SidebarGroup_classImage__1F0e- {
  flex: 0.3 1;
  padding: 10px 10px;
}
.SidebarGroup_classimg__PWqem {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}
.SidebarGroup_classPara__YFJJN {
  flex: 0.7 1;
  text-align: left;
  padding: 10px 0;
  margin: auto 0;
  color: #707070;
  font-weight: 600;
}
.SidebarGroup_classText__Z2A5Q {
  margin: 10px;
}

.SidebarGroup_sidebar_stat1__297gx {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #ED1E24;
  cursor: pointer;
  font: normal normal 600 13px/16px Montserrat;
}

.SidebarGroup_classImageHover__3qm-Q {
  position: relative;
  flex: 0.3 1;
  padding: 10px 10px;
}

.SidebarGroup_classImageHover__3qm-Q::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 18px;
  width: 67%;
  height: 75%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 100px;
  z-index: 1;
}

@media (max-width: 580px){
  .SidebarGroup_classImageHover__3qm-Q {
    position: relative;
    flex: 0.3 1;
    padding: 10px 10px;
  }
  
  .SidebarGroup_classImageHover__3qm-Q::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 25px;
    width: 59%;
    height: 75%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 100px;
    z-index: 1;
  }
}

.MyClass_dropdown_menu1__2CSEc {
  /* width: 100px; */
  /* height: 70px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  /* margin-top: 10px; */
  margin-left: -80px;
}

.MyClass_dropdown_menu1__2CSEc ul {
  list-style: none;
  padding: 1px 0px;
}

.MyClass_dropdown_menu1__2CSEc li {
  padding: 4px 12px;
}

.MyClass_Hr3__COquK {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.MyClass_menu1__2jaxB {
  text-align: left;
  font-size: 12px;
}

.MyClass_menuList__3aoEk:hover {
  cursor: pointer;
}

.MyClass_menuList__3aoEk{
  overflow: scroll;
}

.MyClass_menuList__3aoEk::-webkit-scrollbar {
  display: none;
}
.Feed_feed__1ZLaV {
  /* flex: 0.57; */
  /* margin: 0 20px;  */
  margin-top: 15px;
  /* max-width: 585px; */
}

.MessageSender_MessageSender__2asrM {
  /* margin-top: 10px; */
  background-color: #fff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
}

.MessageSender_messageSender_top__2D12W {
  background-color: white;
  padding: 10px 0 0 0;
  /* padding-bottom: 10px; */
  border-radius: 10px;
  margin-bottom: 20px;
}

.MessageSender_messageSenderDivider__2ITfg {
  height: 0px;
  /* border-bottom: 1px solid #E5E5E5; */
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.MessageSender_message_input__3sTe5 {
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
  box-shadow: 0px 0px 0px #3f3f411a;
}

.MessageSender_message_input__3sTe5 > form {
  display: flex;
  width: 100%;
}

.MessageSender_message_input__3sTe5 > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

/* .message-input > form > button {
  margin-left: 200px;
  border: 1px solid blue;
  border-radius: 30px;
  padding: 5px 35px;
  cursor: pointer;
  background-color: white;
} */
/* 
.message-input > form > button:hover {
  color: blue;
} */

.MessageSender_messageSender_bottom__NvYDu {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  /* justify-content: space-evenly; */
  padding-bottom: 10px;
  padding-top: 10px;
}

.MessageSender_messageSender_rightDiv__2W1rf {
  order: 2;
  margin-left: auto;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}
/* @media (max-width:1099px){
  .MessageSender{
    margin-top:60px;
  }
} */
@media (max-width: 1050px) {
  .MessageSender_messageSender_rightDiv__2W1rf {
    grid-gap: 0px;
    gap: 0px;
  }
}

.InputOption_inputOption__7ahcS {
  display: flex;
  align-items: center;
  color: #b4b4b4;
  padding: 10px;
  cursor: pointer;
  grid-gap: 10px;
  gap: 10px;
}

.InputOption_inputOption__7ahcS:hover {
  background-color: whitesmoke;
  border-radius: 10px;
}

.InputOption_inputOption__7ahcS > h4 {
  font-size: 13px;
  letter-spacing: 0.7px;
  color: #b4b4b4;
}

.InputOption_InputOptionsIconImage__l95Bs {
  width: 20px;
}

@media (max-width: 768px) {
  .InputOption_text__3X_Du {
    display: none;
  }
}

.MiddleContainer_profilePic__3rC5X {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.MiddleContainer_container__GIWXu {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.MiddleContainer_textareaContainer__1YOae {
  border-radius: 15px;
  padding: 10px;
  padding-bottom: 5px;
  border: 1px solid #9e9e9e;
}

.MiddleContainer_textarea__dy3V1 {
  resize: none;
  width: 100%;
  border: none;
  outline: none;
}

.MiddleContainer_textareaContainer__1YOae:focus-within {
  border: 1px solid #ED1E24;
}

.MiddleContainer_dropDiv__2y5bg {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.MiddleContainer_hashtag__9_2b9 {
  color: #ED1E24;
  padding-left: 10px;
  padding-top: 11px;
  font-size: small;
  cursor: pointer;
}
.MiddleContainer_textareaDisc__IQcfd {
  border-radius: 15px;
  padding: 10px;
  width: 540px;
  padding-bottom: 5px;
  outline: none;
  margin-left: 15px;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.MiddleContainer_crossSvg__3Iwtt{
  margin-top: -2px;
  margin-left: -2px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg) brightness(108%) contrast(102%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg) brightness(108%) contrast(102%);
}
.modal_modalBody__3DIyJ {
  background-color: #fff;
  padding: 12px 20px;
  border-radius: 0 0 14px 14px;
}

.modal_textareaDisc__g95ZZ {
  border-radius: 10px;
  width: 95%;
  padding: 10px;
  border: 1px solid #55555533;
}

.modal_crossSvg__jTpgY{
  margin-top: -2px;
  margin-left: -2px;
  -webkit-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg) brightness(108%) contrast(102%);
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(271deg) brightness(108%) contrast(102%);
}
.BottomContainer_container__2e__s {
  padding: 10px 0px;
  grid-gap: 30px;
  gap: 30px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
  align-items: center;
  text-align: justify;
}

.BottomContainer_photo__2SXyQ {
  /* transition: all .3s; */
  height: 32px;
  cursor: pointer;
}

.BottomContainer_rightInner__1D_gZ {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  order: 2;
  margin-left: auto;
}

.BottomContainer_innerBox__3t7vq {
  grid-gap: 25px;
  gap: 25px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.BottomContainer_photo__2SXyQ:hover {
  -webkit-filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg) brightness(95%) contrast(93%);
          filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg) brightness(95%) contrast(93%);
}

.BottomContainer_post__rfucE {
  display: flex;
  padding: 10px 30px;
  border: 1px solid #ED1E24;
  color: #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  justify-content: center;
  font-weight: 600;
}

.BottomContainer_post__rfucE:hover {
  background-color: #ED1E24;
  color: #fff;
}

.BottomContainer_fileUpload__2Nj0t {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.BottomContainer_uploadBox__3HyFU input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.BottomContainer_dropDiv__3UOjA {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.Post_Post__8gLc1 {
  /* flex: 0.57; */
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  position: relative;
}
.Post_infoContainer__P5FgZ {
  display: flex;
  cursor: pointer;
}
.Post_post_header__2LZI4 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  grid-gap: 7px;
  gap: 7px;
  padding: 30px 26px 0 26px;
  box-sizing: border-box;
  align-items: center;
}

.Post_boost_btn__fbBjT {
  border-radius: 50px;
  padding: 1rem;
  text-align: center;
  border: 1px solid #ed1e24;
  background-color: #ed1e24;
  color: #ffffff;
  margin: 0.5rem 0 1.5rem 0;
  cursor: pointer;
  box-sizing: border-box;
}

.Post_boost_btn__fbBjT p {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Montserrat; 
}

.Post_post_Info__3sB6y {
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.Post_post_tag__1ZgHS {
  position: absolute;
  right: 70px;
  top: 0;
  padding: 8px 24px 7px 24px;
  background: #ffaf02 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0.6px;
  color: #ffffff;
  text-transform: capitalize;
}

.Post_boost_post_tag__1_ogb {
  position: absolute;
  right: 12%;
  top: 0;
  padding: 0.1rem 1rem 0.3rem 1rem;
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 6px 6px;
  font-family: Montserrat;
  letter-spacing: 0.6px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
}

.Post_post_info_follower__2DU1e {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  margin: 0;
}

.Post_post_info_timestamp__1DgPj {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  margin: 0;
}

.Post_post_profile_pic__2rtdP {
  height: 65px;
  width: 65px;
  border-radius: 100%;
  object-fit: cover;
}

.Post_post_Info__3sB6y > p {
  font-size: 12px;
  color: #848484;
}

.Post_post_header_options__KaTxh {
  color: #b4b4b4;
  order: 2;
  margin-left: auto;
}

.Post_post_body_wrapper__2V5Yd {
  position: relative;
}

.Post_hide_post_body__2OBAm {
  -webkit-filter: blur(0.5rem);
          filter: blur(0.5rem);
  position: relative;
  cursor: not-allowed;
  background-color: #b8b8b8;
  background-image: linear-gradient(147deg, #b8b8b8 0%, #ebebeb 100%);
}

.Post_censor_text__2zcs5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* padding: .3125rem; */
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  z-index: 5;
  position: absolute;
  font-size: 1.25rem;
  left:50%; 
  top:50%; 
  -webkit-transform: translate(-50%, -50%); 
          transform: translate(-50%, -50%);
  align-items: center;
  cursor: not-allowed;
}

.Post_censor_text__2zcs5 > p {
  text-align: center;
  /* color: #f3f3f3; */
  /* text-shadow: black 12px -24px 20px; */
}

.Post_censor_text__2zcs5 > img {
  height: 3.125rem;
}

.Post_post_body__3dBoa {
  overflow-wrap: anywhere;
}

.Post_post_body__3dBoa > p {
  padding: 17px 26px 0 26px;
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0px;
  color: #707070;
}

.Post_post_body__3dBoa > img {
  width: 100%;
  height: auto;
}

.Post_post_body_readMore__2clpZ {
  color: #ED1E24;
  cursor: pointer;
}

.Post_post_info_userName__2ue7X {
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
}

.Post_post_option__2DaQc {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-left: 10px;
  padding-top: 10px;
}

.Post_comment_input__gH6X7 {
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
}

.Post_comment_avatar__3WdUL {
  /* width: fit-content; */
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
  border-radius: 100%;
  /* object-fit: cover; */
  /* margin-left: 25px; */
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
  opacity: 1 !important;
}

.Post_comment_input_div__3XzGQ {
  position: relative;
  display: flex;
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  /* padding-left: 16px; */
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
  min-width: 100px;
}

.Post_comment_input__gH6X7 > div > form {
  display: flex;
  width: 100%;
  /* flex-grow: 1; */
}

.Post_comment_input_div__3XzGQ:focus-within {
  border: 1px solid #ED1E24;
}

.Post_comment_input__gH6X7 > div > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1 1;
  /* margin-left: 10px; */
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

.Post_comments_icons_div__3qZyq {
  display: flex;
  margin: auto;
  grid-gap: 10px;
  gap: 10px;
  padding-right: 15px;
}

.Post_post_likeCommentBox__2N-OR {
  padding: 17px 26px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  grid-gap: 19px;
  gap: 19px;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #a6a6a6;
}

.Post_comments_div__2Hq9p {
  padding: 30px 30px 30px 25px;
}


.Post_hide_connect_label__4uCca {
  opacity: 0;
}

.Post_button__2GHoY {
  display: flex;
  padding: 0.75rem 1.25rem;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  position: relative;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.Post_button__2GHoY:hover {
  background-color: #ED1E24;
}

.Post_button__2GHoY:hover p {
  color: #fff;

}


.Post_mostRelevant__1bXGi {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.Post_overlay__3-1WM {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}

@media (max-width: 1099px) {
  .Post_Post__8gLc1:last-child {
    margin-bottom: 80px;
  }
}

@media (max-width: 1099px) {
  .Post_comments_icons_div__3qZyq {
    grid-gap: 2px;
    gap: 2px;
  }
}

@media (max-width: 768px) {
  .Post_comments_icons_div__3qZyq {
    display: none;
  }
  .Post_post_option__2DaQc {
    grid-gap: 0px;
    gap: 0px;
  }
}
@media (max-width: 965px) {
  .Post_post_option__2DaQc {
    grid-gap: 2px;
    gap: 2px;
  }
}
.Post_translateText__3t3zj{
  font-weight: 500 !important;
  color: #ED1E24 !important;
  cursor: pointer;
}
.comment_outerContainer__3n_-P {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 13px;
}

.comment_innerContainer__7ZoIk {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  flex-grow: 1;
  padding: 10px;
}

.comment_name__2k1j4 {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
}

.comment_titleDiv__1Ai7t {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  flex-grow: 1;
  padding-bottom: 5px;
}

.comment_timestamp__3aGWE {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #858585;
  order: 2;
  margin-left: auto;
}

.comment_second__1D0IK {
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #858585;
}

.comment_message__2ohtp {
  font: normal normal normal 14px/17px Montserrat;
  letter-spacing: 0px;
  color: #555555;
}

.comment_commentContainer__3PhI3 {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  flex-grow: 1;
}

.comment_likeDiv__1v1SD {
  padding-left: 10px;
  display: flex;
  grid-gap: 17px;
  gap: 17px;
}

.comment_bar__3Cao2 {
  width: 0px;
  height: 19px;
  border-left: 1px solid #e5e5e5;
  opacity: 1;
}

aside.emoji-picker-react {
  position: absolute !important;
  z-index: 21;
  right: 0;
  top: 41px;
}
/* .fGONoI {
    position: absolute !important;
    z-index: 10;
    left: -100px;
    top: 41px;
} */

.BoostConfirmation_main__17m37 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  border: 1px solid #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem 1.5rem;
  border-radius: 16px;
  width: 23vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.BoostConfirmation_icon__1sDq6 > img {
  width: 6rem ;
  margin: auto;
}

.BoostConfirmation_message__3tYSz {
  margin: 1rem 0;
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.BoostConfirmation_btn_container__3wie7 {
  display: flex;
  margin: auto;
}

.BoostConfirmation_btn1__86q9C,
.BoostConfirmation_btn2__1V_I9 {
  border-radius: 50px;
  border: 1px solid #ED1E24;
  background-color: #ED1E24;
  padding: 0.3rem 1rem;
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  text-align: center;
}
.BoostConfirmation_btn2__1V_I9{
  margin-left: 1rem;
}

/* ---------- Media Queries ----------- */

@media screen and (max-width: 1024px) {
  .BoostConfirmation_main__17m37 {
    width: 40vw;
  }
  
}


@media screen and (max-width: 900px) {
  .BoostConfirmation_main__17m37 {
    width: 55vw;
  }
  
}


@media screen and (max-width: 700px) {
  .BoostConfirmation_main__17m37 {
    width: 80vw;
  }
  
}


@media screen and (max-width: 600px) {
  .BoostConfirmation_main__17m37 {
    width: 85vw;
  }
  
}


@media screen and (max-width: 500px) {
  .BoostConfirmation_main__17m37 {
    width: 90vw;
  }
  
}
.GetStarted_container__3Tcdq {
  flex: 0.6 1;
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  /* position: relative; */
  display: flex;
  flex-direction: column;
}

.GetStarted_pic__1PlKD {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.GetStarted_content__j5TCW {
  padding: 25px;
  text-align: center;
  /* text-transform: capitalize; */
}

.GetStarted_content__j5TCW > h2 {
  font: normal normal medium 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.GetStarted_content__j5TCW > p {
  text-align: center;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  padding: 10px;
  max-width: 467px;
  margin: auto;
}

.GetStarted_buttonsContainer__20cPT {
  margin: auto;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  /* padding-left: 18px;
  padding-right:18px; */
  padding-bottom: 30px;
}

.GetStarted_button__1e9m9 {
  display: flex;
  padding: 16px 30px 16px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  text-align: center;
}

.GetStarted_button__1e9m9:hover {
  background-color: #ED1E24;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .GetStarted_button__1e9m9 {
    padding: 15px 25px 15px 25px;
  }
  .GetStarted_buttonsContainer__20cPT{
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
  }
}
.suggestionCard_container__1DX5K {
  min-width: 207px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
}

.suggestionCard_innerContainer__u-fqN {
  position: relative;
  align-items: center;
  text-align: center;
}

.suggestionCard_contentcontainer__3hmaS {
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-top: 15px;
}

.suggestionCard_following__1NTQm {
  padding: 7px;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #b4b4b4;
}

.suggestionCard_pic__1QIdY {
  width: 55px;
  height: 55px;
  /* position: absolute;
    top: -30px;
    left: auto; */
  border-radius: 100%;
  /* transform: translate(-25px); */
  margin-top: -28px;
}

.suggestionCard_button__MLUjx {
  /* display: flex; */
  flex-grow: 0;
  padding: 16px 30px 16px 30px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  max-width: 107px;
  margin: 20px;
}

.suggestionCard_button__MLUjx:hover {
  background-color: #ED1E24;
  color: #fff;
}

* {
  scroll-behavior: smooth;
}

.suggestions_container__Lkj-Y {
  display: flex;
  width: 100%;
  overflow-x: auto;
  grid-gap: 28px;
  gap: 28px;
  padding-bottom: 30px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.suggestions_container__Lkj-Y::-webkit-scrollbar {
  display: none;
}

.suggestions_settingsContainer__3ljZA {
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 38px;
}

.BoostedProfile_Post__17mFl {
    /* flex: 0.57; */
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    border-radius: 10px;
    position: relative;
  }
  .BoostedProfile_infoContainer__34Efm {
    display: flex;
  }
  .BoostedProfile_post_header__USkRv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    grid-gap: 7px;
    gap: 7px;
    padding: 30px 26px 0 26px;
    box-sizing: border-box;
  }
  
  .BoostedProfile_boost_btn__8yxLu {
    border-radius: 50px;
    padding: 1rem;
    text-align: center;
    border: 1px solid #ed1e24;
    background-color: #ed1e24;
    color: #ffffff;
    margin: 0.5rem 0 1.5rem 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .BoostedProfile_boost_btn__8yxLu p {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: Montserrat; 
  }


  .BoostedProfile_hide_connect_label__1l212 {
    opacity: 0;
  }
  
  .BoostedProfile_post_Info__HMehr {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  
  .BoostedProfile_post_tag__1VjJX {
    position: absolute;
    right: 12%;
    top: 0;
    padding: 0.1rem 1rem 0.3rem 1rem;
    background: #ED1E24 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 6px 6px;
    font-family: Montserrat;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .BoostedProfile_post_info_follower__1PSTo {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .BoostedProfile_post_info_timestamp__feQFX {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .BoostedProfile_post_profile_pic__2Q-V_ {
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  
  .BoostedProfile_profile_info__16v0w {
    margin-left: 2rem;
  }
  
  .BoostedProfile_profile_name__26LaI {
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0.5rem;
  }
  
  .BoostedProfile_profile_about__2kyju, .BoostedProfile_profile_location__9Vs-f {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: .3125rem;
  }

  .BoostedProfile_profile_location__9Vs-f {
    font-weight: 400;
    padding-bottom: 0;
  }

  .BoostedProfile_profile_exp__2WJHY {
    display: flex;
    align-items: center;
    margin: 0.625rem 0;
    color: #555;

    text-align: start;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }

  .BoostedProfile_profile_exp__2WJHY .BoostedProfile_label__7IzbQ {
    font-weight: 500;
  }

  .BoostedProfile_profile_exp__2WJHY > img {
    width: 1.688rem;
    margin-right: 0.5rem;
    background-color: #ED1E24;
    padding: 4px 6px;
    border-radius: 6px;
  }
  
  .BoostedProfile_post_Info__HMehr > p {
    font-size: 12px;
    color: #848484;
  }
  
  .BoostedProfile_post_header_options__2k2CF {
    color: #b4b4b4;
    order: 2;
    margin-left: auto;
  }
  
  .BoostedProfile_post_body__1K0ZC {
    display: flex;
    overflow-wrap: anywhere;
  }
  
  .BoostedProfile_post_body__1K0ZC > p {
    padding: 17px 26px 0 26px;
    font: normal normal normal 14px/20px Montserrat;
    letter-spacing: 0px;
    color: #707070;
  }
  
  .BoostedProfile_post_body__1K0ZC > img {
    width: 100%;
    height: auto;
  }
  
  .BoostedProfile_post_body_readMore__2DrQl {
    color: #ED1E24;
    cursor: pointer;
  }
  
  .BoostedProfile_post_info_userName__3vjdz {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .BoostedProfile_post_option__3BcdW {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 10px;
    padding-top: 10px;
  }
  
  .BoostedProfile_comment_input__3FSIr {
    /* border: 1px solid lightgray; */
    border-radius: 30px;
    display: flex;
    padding: 10px;
    color: #b4b4b4;
    padding-left: 19px;
    padding-right: 19px;
  }
  
  .BoostedProfile_comment_avatar__2wsSb {
    /* width: fit-content; */
    width: 45px !important;
    height: 45px !important;
    object-fit: cover;
    border-radius: 100%;
    /* object-fit: cover; */
    /* margin-left: 25px; */
    background: transparent 0% 0% no-repeat padding-box;
    cursor: pointer;
    opacity: 1 !important;
  }
  
  .BoostedProfile_comment_input_div__1UnGw {
    position: relative;
    display: flex;
    font: normal normal normal 14px/16px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    /* padding-left: 16px; */
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 25px;
    border: none;
    flex: 1 1;
    margin-left: 10px;
    outline-width: 0;
    font-weight: 600;
    cursor: pointer;
    min-width: 100px;
  }
  
  .BoostedProfile_comment_input__3FSIr > div > form {
    display: flex;
    width: 100%;
    /* flex-grow: 1; */
  }
  
  .BoostedProfile_comment_input_div__1UnGw:focus-within {
    border: 1px solid #ED1E24;
  }
  
  .BoostedProfile_comment_input__3FSIr > div > form > input {
    font: normal normal normal 14px/16px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    padding-left: 16px;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 25px;
    border: none;
    flex: 1 1;
    /* margin-left: 10px; */
    outline-width: 0;
    font-weight: 600;
    cursor: pointer;
  }
  
  .BoostedProfile_comments_icons_div__2C2z0 {
    display: flex;
    margin: auto;
    grid-gap: 10px;
    gap: 10px;
    padding-right: 15px;
  }
  
  .BoostedProfile_post_likeCommentBox__31f7l {
    padding: 17px 26px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    grid-gap: 19px;
    gap: 19px;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #a6a6a6;
  }
  
  .BoostedProfile_comments_div__idTCJ {
    padding: 30px 30px 30px 25px;
  }
  
  .BoostedProfile_mostRelevant__eohxw {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .BoostedProfile_overlay__3LEa1 {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }
  
  /* --------- boosted Profile ---------- */
  
  .BoostedProfile_buttons_container__Wgydt {
    margin: auto;
    margin-top: 1rem;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .BoostedProfile_button__FcaWk {
    display: flex;
    padding: 0.5rem 1.25rem;
    border: 1px solid #ED1E24;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s;
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #ED1E24;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  
  .BoostedProfile_button__FcaWk:hover {
    background-color: #ED1E24;
  }
  
  .BoostedProfile_button__FcaWk:hover p {
    color: #fff;

  }
  
  
  
  /* --------- boosted Profile ---------- */
  
  @media (max-width: 1099px) {
    .BoostedProfile_Post__17mFl:last-child {
      margin-bottom: 80px;
    }
  }
  
  @media (max-width: 1099px) {
    .BoostedProfile_comments_icons_div__2C2z0 {
      grid-gap: 2px;
      gap: 2px;
    }
  }
  
  @media (max-width: 768px) {
    .BoostedProfile_comments_icons_div__2C2z0 {
      display: none;
    }
    .BoostedProfile_post_option__3BcdW {
      grid-gap: 0px;
      gap: 0px;
    }
  }
  @media (max-width: 965px) {
    .BoostedProfile_post_option__3BcdW {
      grid-gap: 2px;
      gap: 2px;
    }
  }
  .BoostedProfile_translateText__1khrx{
    font-weight: 500 !important;
    color: #ED1E24 !important;
    cursor: pointer;
  }
  
  
  @media only screen and (max-width: 600px) {
    
    .BoostedProfile_buttons_container__Wgydt{
      flex-direction: column;
      grid-gap: 15px;
      gap: 15px;
    }
  }
.RightSidebar_RightSidebar__MAmU5 {
  /* position: sticky; */
  /* top: 80px; */
  /* flex: 0.23; */
  border-radius: 10px;
  text-align: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* margin-right: 80px; */
  margin-top: 15px;
  /* max-width: 290px; */
}

.RightSidebar_Rightsidebar_mycontent__1v1E2 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.RightSidebar_side__1Yy8p {
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
  align-items: center;
}

.RightSidebar_Rightsidebar_icon1__f_xhn {
  background: #ED1E24 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 5px;
}

.RightSidebar_Rightsidebar_icon2__1Ed3Z {
  color: #929292;
}

.RightSidebar_Rightsidebar_mycontent__1v1E2 > h3 {
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  /* font-weight: 600;
  margin-right: 80px; */
}

.RightSidebar_Rightsidebar_stats___UPSj {
  margin-top: 10px;
  /* padding: 10px; */
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* box-shadow: 0 0 10px 5px #d3d3d3; */
  box-shadow: 0px 0px 20px #3f3f411a;
}

.RightSidebar_Rightsidebar_stat__qKFpn {
  margin-top: 10px;
  display: flex;
  /* justify-content: space-evenly; */
  margin-bottom: 10px;
  margin-left: 9px;
}

.RightSidebar_Rightsidebar_statNumber__1fejJ {
  padding-left: 10px;
  cursor: pointer;
}

.RightSidebar_Rightsidebar_statNumber2__1-M75 {
  padding-left: 10px;
}

.RightSidebar_Rightsidebar_statNumber__1fejJ:hover {
  color: #ED1E24;
  text-decoration: underline;
}

.RightSidebar_Rightsidebar_stat__qKFpn > p {
  color: #707070;
  font: normal normal normal 13px Montserrat;
  text-align: left;
}

.RightSidebar_Rightsidebar_details__1lu0P {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.RightSidebar_Rightsidebar_details__1lu0P > h4 {
  font-size: 14px;
  /* font-weight: 600; */
  color: #ED1E24;
  text-align: left;
}
.RightSidebar_Hr1__2W7pm {
  /* height: 2px !important;
  width: 280px !important;
  color: #555555 !important;
  opacity: 0.3; */
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.RightSidebar_sidebar_stat2__lsvpq {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ED1E24;
  font: normal normal 600 13px/16px Montserrat;
  text-transform: uppercase;
  /* border-top: 1px solid #d6d6d6; */
}

.RightSidebar_view_enterprise_data_label__3u516 {
  color: rgb(112, 112, 112);
  font-family: Montserrat;
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.06rem;
  letter-spacing: 0%;
  text-align: center;
  margin: 1.25rem;
}
.premium_comingSoon__1TI0d {
  text-align: center;
  font: normal normal bold 42px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 102px 71px;
  background: #ED1E24;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}

.premium_bottomContainer__1xwcz {
  padding: 20px 75px 41px 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.premium_outerContainer__1uYlZ {
  background: #ffffff;
  border-radius: 10px;
  max-width: 520px;
  position: relative;
}

.premium_diamond__3x7sh {
  height: 40px;
  width: 42px;
  cursor: pointer;
}

.premium_title__mq7ix {
  text-align: center;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
}

.premium_desc__2Xpak {
  text-align: center;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding: 10px;
}

.premium_close__3MPuc {
  width: 33px;
  height: 33px;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.5;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.MiddleContain_classModal__1QrXK {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.MiddleContain_inputField__2w2qG {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 20px;
}

.MiddleContain_inputField1__2gbii {
  border-radius: 25px;
  width: 550px;
  margin-left: 15px;
  height: 105px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 15px 0px 15px 15px;
}

input.MiddleContain_inputField1__2gbii::-webkit-input-placeholder {
  top: 10px;
  position: absolute;
}

input.MiddleContain_inputField1__2gbii::placeholder {
  top: 10px;
  position: absolute;
}
.MiddleContain_inputLabel__iPrug {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 1rem;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: normal;
}

.MiddleContain_saveDetailsButton__9Jkdy {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 136px;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-left: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 24px;
}

.MiddleContain_saveDetailsButton__9Jkdy:hover {
  background-color: #ED1E24;
  color: white;
  margin-bottom: 24px;
  /* border: 1px #236ae8 solid; */
}
@media (max-width: 800px) {
  .MiddleContain_inputField__2w2qG, .MiddleContain_inputField1__2gbii {
    width: 85%;
  }
  .MiddleContain_thumbnailArea__1Pu3V {
    width: 85%;
  }
  .MiddleContain_thumbnailPic__3UmRe {
    width: 100%;
  }
}
.app-body {
  display: flex;
  background-color: #ffffff;
  font-family: Montserrat Family;
}
.dashboardInnerContainer {
  padding-top: 94px;
}
@media (max-width:990px){
  .dashboard {
    width: 100vw;
  }
  .dashboardInnerContainer {
    margin: 0;
  }
}
@media (max-width: 900px) {
  .header {
    display: none !important;
  }
  .col-xs-0,
  .col-sm-0,
  .col-md-0,
  .col-lg-0 {
    flex: 0 0;
    max-width: 0;
    padding-right: 0;
    padding-left: 0;
    display: none;
  }
  .dashboardFeedMiddleRow{
    width: 100%;
  }
}
@media (max-width: 800px) {
  .dashboardInnerContainer {
    padding-top: 0;
  }
  
}
.shareModal_socialhandle__1tYki {
  display: flex;
}

.shareModal_container__1DZza {
  background-color: #fff;
  border-radius: 0 0 15px 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.shareModal_socialContainer__1KPRP {
  display: flex;
  justify-content: space-between;
}

.shareModal_socialContainer__1KPRP > img {
  cursor: pointer;
}

.shareModal_copyContainer__2O2fE {
  background: #f2f3f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 34px 21px 31px;
  display: flex;
  align-items: center;
}

.shareModal_link__2r633 {
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.shareModal_copyButton__2t8_x {
  order: 2;
  margin-left: auto;
  cursor: pointer;
}

.welcome_comingSoon__3IbgR {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-transform: uppercase;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}

.welcome_bottomContainer__2Vkkn {
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.welcome_bottom_details__2u9Sa {
  display: flex;
}

.welcome_bottom__13FKa {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.welcome_outerContainer__XAi-N {
  background: #ffffff;
  border-radius: 10px;
  max-width: 537px;
  position: relative;
}

.welcome_close__2h-gR {
  width: 21px;
  height: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #171616;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.MobileBottom_mobileBottom__3j3Cs {
  width: auto;
  height: 75px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 15px #55555533;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.MobileBottom_bottom__4ioVT {
  top: 20px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.MobileBottom_bottom_container__C7OPZ {
  text-align: center;
  display: grid;
  place-items: center;
}

.MobileBottom_image_name__hU0EU {
  text-align: center;
  font: normal normal normal 9px/11px Montserrat;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
}

.MobileBottom_mobile_image__1zbwt {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 0.3;
  cursor: pointer;
}
@media (max-width: 1099px) {
  .MobileBottom_mobileBottom__3j3Cs {
    display: flex;
  }
}
@media (min-width: 1100px) {
  .MobileBottom_mobileBottom__3j3Cs {
    display: none;
  }
}

/* @namespace example url(); */

* {
  /* margin: 0px; */
  font-family: "Montserrat", sans-serif;
}
/* 
.dashapp{
  padding-top: 100px;
}

@media (max-width:1099px){
  .dashapp{
    padding-top: 50px;
  }
} */

@media (max-width: 786px) {
  .dashboardFeedMiddleRow {
    max-width: 100vw;
  }
}

/* * {
    max-width: 100%;
    height: 100%;
} */

/* #topviewable {
    content: "";
    background: url(./watermark/Chalkmate_Watermark.svg);
    opacity: 0.6;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
} */

#topviewable,
#main {
  font-family: "Montserrat", sans-serif;
}

#vw-profmail {
  font-family: "Montserrat", sans-serif;
}

#vw-prsc {
  flex-direction: "row";
}

#btn-ftr {
  color: #ED1E24;
}

#btn-ftr:hover {
  background-color: #ED1E24;
  transition: background-color 150ms linear;
  cursor: pointer;
}

#btn-ftr:hover p {
  color: #fff;
  z-index: 100;
}

#leftfeed {
  display: none;
}

@media print {
  @page {
      /* size: A3 portrait; */
      size: 15in 20in;
      margin: 2.25rem 3rem  !important;
      /* margin: 25mm 25mm 25mm 25mm; */
  }
  html, body {
    height: 99%;    
}

  body {
      padding: 4.65rem;
  }

  #leftfeed {
    display: block;
  }

  #pagebreak{ 
    page-break-after: always; 
    -webkit-column-break-after: always; 
            break-after: always;
  }
}
.OpenToWorkModal_main__1ii_b {
  box-sizing: border-box;
}

.OpenToWorkModal_middle_container__2bIs5 {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.OpenToWorkModal_title_label__2szNk {
  color: rgb(125, 125, 125);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}

.OpenToWorkModal_tag_container__3Khlz {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1.25rem;
  flex-wrap: wrap;
}

.OpenToWorkModal_tag__2sBg4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #7d7d7d;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid #7d7d7d;
  border-radius: 3.125rem;
  padding: 0.25rem 0.75rem;
  min-width: 6rem;
  cursor: pointer;
}

.OpenToWorkModal_selected_tag__IQIjm {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1e24;
  color: #ffffff;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid #ed1e24;
  border-radius: 3.125rem;
  padding: 0.25rem 0.75rem;
  min-width: 6rem;
  cursor: pointer;
}

.OpenToWorkModal_checkbox_container__6KOzX {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  /* margin-top: .5rem; */
}

.OpenToWorkModal_add_frame__2CH1S {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1rem;
  color: #545454;
}

.OpenToWorkModal_bottom_container__1SlRm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 1rem 1.375rem 0 1.375rem;
  /* width: 100%; */
}

.OpenToWorkModal_btn_label__1ZS7t {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: center;
}

.OpenToWorkModal_btn_label_cancel__3GU-e {
  font-family: "Montserrat";
  color: rgb(133, 133, 133);
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;
}

.OpenToWorkModal_input_field__1RosP {
  /* border-radius: 25px;
  outline: none;
  border: 1px #c2c2c2 solid;
  margin-top: 1rem;
  width: 100%;
  min-height: 1rem; */

  border-radius: 5px;
  width: 100%;
  margin-top: 0.75rem;
  height: 2rem;
  outline: none;
  border: 1px #000 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: .875rem;
  font-weight: 400;
  font-family: Montserrat;

}
.OpenToWorkViewModal_main__2rQqC {
  box-sizing: border-box;
}

.OpenToWorkViewModal_middle_container__22dmk {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.OpenToWorkViewModal_info_container__2C8VO {
  display: flex;
  align-items: center;
  margin-bottom: 1.75rem;
  text-transform: capitalize;
  width: 100%;
  /* row-gap: 1rem; */
}

.OpenToWorkViewModal_name_wrapper__3DYRw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
}

.OpenToWorkViewModal_name__2g-YA {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0%;
  text-align: left;
}

.OpenToWorkViewModal_otw__2Ehxh {
  color: rgb(110, 110, 110);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.OpenToWorkViewModal_icon_wrapper__BMnFY {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.OpenToWorkViewModal_icon_wrapper__BMnFY > img {
  width: 1.375rem;
  cursor: pointer;
}

.OpenToWorkViewModal_wrapper__HsYk8 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin-bottom: 1.5625rem;
}

.OpenToWorkViewModal_title_label__2Cn07 {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
}

.OpenToWorkViewModal_value__20wId {
  color: rgb(97, 97, 97);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
  text-transform: capitalize;
}

.OpenToWorkConfirmationModal_main__3mDrF {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1.5rem 1.5rem;
    padding-top: 5px;
    border-radius: 25px;
    width: 18vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .OpenToWorkConfirmationModal_main__3mDrF > img {
    width: 7rem;
    padding: 10px 0;
  }
  
  .OpenToWorkConfirmationModal_question_text__hZxzy {
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
  
  .OpenToWorkConfirmationModal_btn_div__XAUew {
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* margin-bottom: 20px; */
    width: 100%;
  }
  
  .OpenToWorkConfirmationModal_btn_label__23ldT {
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.0625rem;
    letter-spacing: 0em;
    text-align: left;
  }
  /*   
    @media(max-width: 600px){
      .action_modal_container {
        width: 75%
      }
    }
    
    @media(max-width: 400px){
      .action_modal_container {
        width: 70%
      }
    } */
  
  @media (max-width: 600px){
    .OpenToWorkConfirmationModal_main__3mDrF{
      width:auto;
    }  
  }
.WelcomeToCourseModal_main__2mXiI {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  border-width: 8px 0px 8px 0px;
  border-style: solid;
  border-color: #ED1E24;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  background-color: #fff;
  box-shadow: 0px 4px 21px 0px #00000040;
  max-width: 40vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 10px;
  border-radius: 16px;
}

.WelcomeToCourseModal_main__2mXiI > img {
  width: 51%;
  margin: auto;
  /* padding: 15px 0; */
}

.WelcomeToCourseModal_description__25w-- {
  font-family: "Montserrat";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.0625rem;
  text-align: center;
  /* width: 60%; */
  color: #7a7a7a;
  margin: auto;
  padding: 0 5px;
}

.WelcomeToCourseModal_heading__1UYnG {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  color: #000000;
}

.WelcomeToCourseModal_description2__2diY1 {
  font-family: "Montserrat";
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 2.0625rem;
  text-align: center;
  color: #7a7a7a;
  padding: 0 5px;
}

.WelcomeToCourseModal_btn_div__2e709 {
  margin: auto;
  padding: 15px 0;
  cursor: pointer;

  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.WelcomeToCourseModal_link__3_F4q {
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  /* Styles for phones go here */
  .WelcomeToCourseModal_main__2mXiI {
    max-width: 83vw;
    height: 446px;
  }

  .WelcomeToCourseModal_heading__1UYnG {
    font-size: 1.25rem;
    line-height: 20px;
  }

  .WelcomeToCourseModal_description2__2diY1 {
    font-size: 0.9375rem;
    line-height: 1.0rem;
  }

  .WelcomeToCourseModal_btn_div__2e709 {
    margin: auto;
    cursor: pointer;
  }

  .WelcomeToCourseModal_Button__21NrE{
    width: 131px;
    height: 41px;
    padding: 18px 32px;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media (min-width:601px) and (max-width:1000px){
  .WelcomeToCourseModal_main__2mXiI {
    max-width: 50vw;
  }
  .WelcomeToCourseModal_main__2mXiI > img {
    width: 40%;
  }
  .WelcomeToCourseModal_heading__1UYnG {
    font-size: 1.5rem;
  }
  .WelcomeToCourseModal_description2__2diY1 {
    font-size: 1.1rem;
  }
  /* .btn_div {
    padding: 0;
  } */
}
.Button_outline_btn__3cOfg {
  /* margin-top: 20px; */
  /* width: 100%; */
  padding: 12px 28px;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Button_full_width__S-USu {
  width: 100%;
}
.Button_outline_btn__3cOfg:hover {
  background-color: #ED1E24;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Button_solid_btn__35DS7 {
  /* margin-top: 20px; */
  /* width: 100%; */
  padding: 12px 28px;;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ED1E24;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Button_solid_btn__35DS7:hover {
  background-color: #ffffff;
  color: #ED1E24;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.Button_solid_btn__35DS7:active, .Button_outline_btn__3cOfg:active {
  /* transform: scale(0.98); */
  box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.14);
}

input{
  border: 0;
}
.Button_disable__1-h25{
  padding: 12px 28px;
  border: 1px solid #f6f6f6;
  border-radius: 50px;
  /* cursor: auto; */
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  color: #959595;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: 0.2s all;
}

@media (max-width: 500px) {
  .Button_solid_btn__35DS7{
    font: normal normal 600 10px Montserrat;
    width: auto;
    height: 35px;
    padding: 10px 21.5px;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  .Button_outline_btn__3cOfg{
    font: normal normal 600 10px Montserrat;
    padding: 12px 18px;
  }
  .Button_disable__1-h25{
    padding: 7.5px 16.5px;
    font: normal normal 600 10px Montserrat;
  }
}
.CreateAssignment_main_container__ZOjHd {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}
.CreateAssignment_back_btn__1eGhN {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.CreateAssignment_feed_container__2Cro_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130vh;
}
.CreateAssignment_title_container__2Y19R {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.CreateAssignment_form_container__vjGb5 {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  margin: 25px 0;
  width: 100%;
  margin-bottom: 85px;
}
.CreateAssignment_input_label__RavD2 {
  font-size: 13px;
  /* margin: 0 10px; */
  /* padding-left: 1rem; */
  /* margin-left: 0px; */
  /* margin-top: 20px; */
  font-weight: normal;
}
.CreateAssignment_input_title_container__3Gj4v {
  display: flex;
  align-items: center;
  font: normal normal 600 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin: 15px 0;
}
.CreateAssignment_lable_icon__22C8v {
  margin-right: 10px;
}
.CreateAssignment_input_field__HzF7B {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.CreateAssignment_input_field1__d6U55 {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 12.5rem;
  outline: none;
  padding: 17px 0px 15px 17px;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.CreateAssignment_attachment_icon_container__hSeyH {
  /* padding: 0px 15px; */
  display: flex;
  flex-direction: row;
}
.CreateAssignment_uploadBox__yZpWv input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  position: relative;
}
.CreateAssignment_fileUpload__3LTe1 {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  width: 22px;
  /* height: 100%; */
}
.CreateAssignment_photo__VIl02 {
  /* transition: all .3s; */
  height: 22px;
  cursor: pointer;
}
.CreateAssignment_uploadBox__yZpWv:hover {
  -webkit-filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
          filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
}

/* assigned to modal css */
.CreateAssignment_modal_container__2NgAX {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 90vh;
}
.CreateAssignment_modal_header__i7QcS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.CreateAssignment_close_icon__2Q18W {
  cursor: pointer;
  width: 30px;
}
.CreateAssignment_max_due_container__xlM9E {
  display: flex;
  margin-top: 0px;
}

.CreateAssignment_lock_dueDate__3fNGW {
  /* color: yellow; */
  display: flex;
  margin: 16px 0;
  font-size: 14px;
  opacity: 0.7;
}
.CreateAssignment_lock_dueDate__3fNGW p {
  margin-left: 4px;
}

.CreateAssignment_checkDone__KBypk {
  color: #ED1E24;
}
input[type="checkbox"] {
  accent-color: #ED1E24;
}

.CreateAssignment_assign_btn_container__2C9Et {
  margin-top: 0px;
  float: right;
}
.CreateAssignment_date_time_container__1U8jF {
  display: flex;
  font-family: Montserrat;
}

/* discard modal */
.CreateAssignment_discard_modal_container__2OjQm {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 63vh;
}
.CreateAssignment_discard_modal_title__1EPOQ {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.95em;
}
.CreateAssignment_discard_modal_msg__2xwY0 {
  margin: 25px 0px;
  font-size: 0.95em;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.CreateAssignment_discard_btn__bE4at {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* MEDIA */

@media (max-width: 1450px) {
  .CreateAssignment_feed_container__2Cro_ {
    width: 120vh;
  }
}

@media (max-width: 960px) {
  .CreateAssignment_feed_container__2Cro_ {
    width: 90%;
  }
  .CreateAssignment_modal_container__2NgAX {
    width: 90%;
  }
}
@media (min-width: 780px) and (max-width: 800px){
  .CreateAssignment_main_container__ZOjHd {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .CreateAssignment_main_container__ZOjHd {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .CreateAssignment_max_due_container__xlM9E {
    display: flex;
    flex-direction: column;
  }
  .CreateAssignment_modal_container__2NgAX {
    width: 85%;
  }
  .CreateAssignment_date_time_container__1U8jF {
    flex-direction: column;
  }
  .CreateAssignment_date_time_container__1U8jF > div {
    margin-bottom: 10px;
  }
  .CreateAssignment_back_btn__1eGhN{
    display: none;
  }
  .CreateAssignment_close_icon__2Q18W {
    width: 22px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 510px) {
  .CreateAssignment_discard_modal_container__2OjQm {
    width: 83%;
  }
  .CreateAssignment_form__2YaH0{
    margin: 0 5px !important;
  }
  .CreateAssignment_form_container__vjGb5 {
    box-shadow: none;
 
  }
}

@media (max-width: 380px) {
  .CreateAssignment_discard_modal_container__2OjQm {
    width: 76%;
  }
}
.CreateAssignment_form__2YaH0{
  margin: 0 30px;
}
input::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
input::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
.CreateAssignment_modal_form__1LCMN{
  margin: 0 15px;
}

.CreateAssignment_due_date_time_container__1WCkT{
  margin-left: 20px;
  margin-top: 0px;
}
@media (max-width: 780px) {
  .CreateAssignment_due_date_time_container__1WCkT {
    margin-left: 0px ;

  }
}
@media (max-height: 700px) {
  .CreateAssignment_input_title_container__3Gj4v {
    font: normal normal 600 14px Montserrat;
  }
}

.CreateAssignment_loader_style__3rE01{
  text-align: center;
   margin-top: 200px
}

.CreateAssignment_radio_group__1yQpA{
  /* margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; */
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.CreateAssignment_tooltip_icon__1LA45{
  margin-right: 14px;
}

@media (max-width: 580px){
  .CreateAssignment_modal_container__2NgAX {
    border-radius: 10px;
  }
  .CreateAssignment_input_title_container__3Gj4v {
    font: normal normal 400 14px Montserrat;
  }

}
.PublicationFeed_main__UgqkH {
  box-sizing: border-box;
}

.PublicationFeed_header__3F5k0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PublicationFeed_card__2i9r0 {
  box-shadow: 0px 0px 20px #5555551a;
  padding: 1.25rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.PublicationFeed_description__3aQ7I {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: .625rem;
}

.PublicationFeed_add_btn__eoD2Y {
    border: 2px solid #ed1e24;
    padding: 12px 20px;
    border-radius: 40px;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.PublicationFeed_btn_label__18pRt {
    color: #ed1e24;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.PublicationFeed_add_btn__eoD2Y:hover  {
    background-color: #ed1e24;
    color: #fff;
}

.PublicationFeed_add_btn__eoD2Y:hover > p  {
    color: #fff;
}

.PublicationFeed_publication_container__1kKCF {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  align-items: start;

}

.PublicationFeed_text_wrapper__1L-DT {
  width: 100%;
  box-sizing: border-box;
}

.PublicationFeed_title_header__1ksNv {
  display: flex;
  justify-content: space-between;
  width:auto;
  box-sizing: border-box;
}

.PublicationFeed_title__2GuyV {
  color:#ED1E24;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  margin-bottom: .3125rem;
}

.PublicationFeed_title_header__1ksNv > img {
  width: 1.25rem;
  cursor: pointer;
  margin-left: auto;
}

.PublicationFeed_publisher_and_date__z_yJV {
  color: #555;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: .5rem;
}

.PublicationFeed_url__2K_Cc {
  display: flex;
  align-items: center;
  border: 1.5px solid #555555;
  border-radius: 40px;
  padding: .375rem 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;

  color:#858585;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: .75rem;
}

.PublicationFeed_url__2K_Cc > img {
  width: 1.125rem;
  margin-left: 0.5rem;
}

.PublicationFeed_description__3aQ7I {
  color: #555;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PublicationFeed_media___nDjQ {
  width: 4rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;

}

/* .btn_label:hover {
    color: #fff;
} */

.AddPatentResearchTrainingModal_main__3mHgB {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-sizing: border-box;
  /* width: 40vw; */
}

.AddPatentResearchTrainingModal_label__1cnqH {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: .75rem;
  margin-left: 1.25rem;
}


.AddPatentResearchTrainingModal_add_image_container__24RqJ {
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.AddPatentResearchTrainingModal_image_wrapper__34Zc4 {
  position: relative;
  box-sizing: border-box;
  /* width: 20%; */
  aspect-ratio: 1 / 1;
}

.AddPatentResearchTrainingModal_image_wrapper__34Zc4 > img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.AddPatentResearchTrainingModal_upload_button_container__2gb91 {
  margin-left: 1.25rem;
  box-sizing: border-box;
}

.AddPatentResearchTrainingModal_add_image_label__2cQ6M {
  color:#535353;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AddPatentResearchTrainingModal_upload_btn__3sHoK {
  display: flex;
  width: 40%;
  padding: 0.5rem;
  border: 2px solid #ED1E24;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.2s;
  font-family: Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  margin-top: 1.25rem;
}

.AddPatentResearchTrainingModal_upload_btn__3sHoK p {
  margin: auto;
  font-size: 1.25rem;
  font-weight: 500;
}

.AddPatentResearchTrainingModal_upload_btn__3sHoK:hover {
  background-color: #ED1E24;
}

.AddPatentResearchTrainingModal_upload_btn__3sHoK:hover p {
  color: #fff;
}

.AddPatentResearchTrainingModal_file_upload__208Fu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
}

.AddPatentResearchTrainingModal_field_container__3g-sB {
  box-sizing: border-box;
  margin-bottom: 2rem;
}

.AddPatentResearchTrainingModal_label_icon__2NJRa {
  display: flex;
  align-items: center;  
  margin-bottom: .75rem;
  box-sizing: border-box;
}

.AddPatentResearchTrainingModal_label_icon__2NJRa img {
  width: 1.5rem;
  margin-left: 1rem;
  margin-right: .75rem;
}

.AddPatentResearchTrainingModal_label_icon__2NJRa p {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AddPatentResearchTrainingModal_input_field__8sAen {
  outline: none;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #5555555A;
  padding: .625rem;
  font-family: Montserrat;
  font-size: 1rem;
  border-radius: 50px;
}

.AddPatentResearchTrainingModal_text_area__FZmhx {
  box-sizing: border-box;
  border: 1px solid #5555555A;
  padding: .625rem;
  font-size: 1rem;
  border-radius: 30px;
}


@media only screen and (max-width: 700px) {
  .AddPatentResearchTrainingModal_main__3mHgB {
    width: 90%;
    box-sizing: border-box;
  }

  .AddPatentResearchTrainingModal_upload_btn__3sHoK {
    box-sizing: border-box;
    width: 100%;
  }

  .AddPatentResearchTrainingModal_upload_btn__3sHoK p {
    margin: auto;
    font-size: .875rem;
    font-weight: 500;
  }
}

.ProjectFeed_main__3U2Sf {
    box-sizing: border-box;
  }
  
  .ProjectFeed_header__2_A3C {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ProjectFeed_card__h565l {
    box-shadow: 0px 0px 20px #5555551a;
    padding: 1.25rem;
    border-radius: 10px;
    margin-top: 1rem;
  }
  
  .ProjectFeed_description__MBeiM {
      color: #555;
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: .625rem;
  }
  
  .ProjectFeed_add_btn__1nzim {
      border: 2px solid #ed1e24;
      padding: 12px 20px;
      border-radius: 40px;
      cursor: pointer;
      background-color: #fff;
      margin-bottom: 1rem;
  }
  
  .ProjectFeed_btn_label__eRbFv {
      color: #ed1e24;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
  }
  
  .ProjectFeed_add_btn__1nzim:hover  {
      background-color: #ed1e24;
      color: #fff;
  }
  
  .ProjectFeed_add_btn__1nzim:hover > p  {
      color: #fff;
  }
  
  /* .btn_label:hover {
      color: #fff;
  } */
  

  /* -------- show content ----- */


.ProjectFeed_publication_container__NDoH6 {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
  
  }
  
  .ProjectFeed_text_wrapper__tcNZD {
    width: 100%;
    box-sizing: border-box;
  }
  
  .ProjectFeed_title_header__2dNIj {
    display: flex;
    justify-content: space-between;
    width:auto;
    box-sizing: border-box;
  }
  
  .ProjectFeed_title__2lvkc {
    color:#ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin-bottom: .3125rem;
  }
  
  .ProjectFeed_title_header__2dNIj > img {
    width: 1.25rem;
    cursor: pointer;
    margin-left: auto;
  }
  
  .ProjectFeed_publisher_and_date__55CdB {
    color: #555;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .5rem;
  }
  
  .ProjectFeed_url__mvEzv {
    display: flex;
    align-items: center;
    border: 1.5px solid #555555;
    border-radius: 40px;
    padding: .375rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  
    color:#858585;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: .75rem;
  }
  
  .ProjectFeed_url__mvEzv > img {
    width: 1.125rem;
    margin-left: 0.5rem;
  }
  
  .ProjectFeed_description__MBeiM {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .ProjectFeed_media__2olKI {
    width: 4rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 1rem;
  
  }
.AddProjectModal_main__3CrNn {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-sizing: border-box;
    /* width: 40vw; */
  }
  
  .AddProjectModal_label__1LO0g {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: .75rem;
    margin-left: 1.25rem;
  }
  
  
  .AddProjectModal_add_image_container__3yeiO {
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }
  
  .AddProjectModal_image_wrapper__FQ15m {
    position: relative;
    box-sizing: border-box;
    /* width: 20%; */
    aspect-ratio: 1 / 1;
  }
  
  .AddProjectModal_image_wrapper__FQ15m > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .AddProjectModal_upload_button_container__uzMwh {
    margin-left: 1.25rem;
    box-sizing: border-box;
  }
  
  .AddProjectModal_add_image_label__3PNSm {
    color:#535353;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .AddProjectModal_upload_btn__2C39h {
    display: flex;
    width: 40%;
    padding: 0.5rem;
    border: 2px solid #ED1E24;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Montserrat;
    letter-spacing: 0px;
    color: #ED1E24;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-top: 1.25rem;
  }
  
  .AddProjectModal_upload_btn__2C39h p {
    margin: auto;
    font-size: 1.25rem;
    font-weight: 500;
  }
  
  .AddProjectModal_upload_btn__2C39h:hover {
    background-color: #ED1E24;
  }
  
  .AddProjectModal_upload_btn__2C39h:hover p {
    color: #fff;
  }
  
  .AddProjectModal_file_upload__2bbRP {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }
  
  .AddProjectModal_field_container__3Ey6w {
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
  
  .AddProjectModal_label_icon__3XUjQ {
    display: flex;
    align-items: center;  
    margin-bottom: .75rem;
    box-sizing: border-box;
  }
  
  .AddProjectModal_label_icon__3XUjQ img {
    width: 1.5rem;
    margin-left: 1rem;
    margin-right: .75rem;
  }
  
  .AddProjectModal_label_icon__3XUjQ p {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .AddProjectModal_input_field__28tkA {
    outline: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #5555555A;
    padding: .625rem;
    font-family: Montserrat;
    font-size: 1rem;
    border-radius: 50px;
  }
  
  .AddProjectModal_text_area__3Vn-f {
    box-sizing: border-box;
    border: 1px solid #5555555A;
    padding: .625rem;
    font-size: 1rem;
    border-radius: 30px;
  }

  .AddProjectModal_date_container__36_Tc {
    display: flex;
    grid-gap: 1.625rem;
    gap: 1.625rem;
    align-items: center;
  }
  

@media only screen and (max-width: 700px) {
  .AddProjectModal_main__3CrNn {
    width: 90%;
    box-sizing: border-box;
  }

  .AddProjectModal_upload_btn__2C39h {
    box-sizing: border-box;
    width: 100%;
  }

  .AddProjectModal_upload_btn__2C39h p {
    margin: auto;
    font-size: .875rem;
    font-weight: 500;
  }

  .AddProjectModal_date_container__36_Tc {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    align-items: start;
  }
}

  
.ResearchFeed_main__1eXJi {
    box-sizing: border-box;
  }
  
  .ResearchFeed_header__1VDyi {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ResearchFeed_card__3psy3 {
    box-shadow: 0px 0px 20px #5555551a;
    padding: 1.25rem;
    border-radius: 10px;
    margin-top: 1rem;
  }
  
  .ResearchFeed_description__1yi1t {
      color: #555;
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: .625rem;
  }
  
  .ResearchFeed_add_btn__1ijh6 {
      border: 2px solid #ed1e24;
      padding: 12px 20px;
      border-radius: 40px;
      cursor: pointer;
      background-color: #fff;
      margin-bottom: 1rem;
  }
  
  .ResearchFeed_btn_label__2bEsb {
      color: #ed1e24;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
  }
  
  .ResearchFeed_add_btn__1ijh6:hover  {
      background-color: #ed1e24;
      color: #fff;
  }
  
  .ResearchFeed_add_btn__1ijh6:hover > p  {
      color: #fff;
  }
  
  /* .btn_label:hover {
      color: #fff;
  } */
  

  /* -------- show content ----- */


.ResearchFeed_publication_container__3YFAb {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
  
  }
  
  .ResearchFeed_text_wrapper__C-qIh {
    width: 100%;
    box-sizing: border-box;
  }
  
  .ResearchFeed_title_header__RXT49 {
    display: flex;
    justify-content: space-between;
    width:auto;
    box-sizing: border-box;
  }
  
  .ResearchFeed_title__2NzAk {
    color:#ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin-bottom: .3125rem;
  }
  
  .ResearchFeed_title_header__RXT49 > img {
    width: 1.25rem;
    cursor: pointer;
    margin-left: auto;
  }
  
  .ResearchFeed_publisher_and_date__2b2C0 {
    color: #555;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .5rem;
  }
  
  .ResearchFeed_url__3vcIR {
    display: flex;
    align-items: center;
    border: 1.5px solid #555555;
    border-radius: 40px;
    padding: .375rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  
    color:#858585;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: .75rem;
  }
  
  .ResearchFeed_url__3vcIR > img {
    width: 1.125rem;
    margin-left: 0.5rem;
  }
  
  .ResearchFeed_description__1yi1t {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .ResearchFeed_media__38AHF {
    width: 4rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 1rem;
  
  }
.TrainingFeed_main__3nh3S {
    box-sizing: border-box;
  }
  
  .TrainingFeed_header__2HNS0 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .TrainingFeed_card__ET3ht {
    box-shadow: 0px 0px 20px #5555551a;
    padding: 1.25rem;
    border-radius: 10px;
    margin-top: 1rem;
  }
  
  .TrainingFeed_description__PfRLe {
      color: #555;
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: .625rem;
  }
  
  .TrainingFeed_add_btn__3H2dV {
      border: 2px solid #ed1e24;
      padding: 12px 20px;
      border-radius: 40px;
      cursor: pointer;
      background-color: #fff;
      margin-bottom: 1rem;
  }
  
  .TrainingFeed_btn_label__3ukYm {
      color: #ed1e24;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
  }
  
  .TrainingFeed_add_btn__3H2dV:hover  {
      background-color: #ed1e24;
      color: #fff;
  }
  
  .TrainingFeed_add_btn__3H2dV:hover > p  {
      color: #fff;
  }
  
  /* .btn_label:hover {
      color: #fff;
  } */
  

  /* -------- show content ----- */


.TrainingFeed_publication_container__2Dy75 {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
    align-items: start;
  
  }
  
  .TrainingFeed_text_wrapper__23GKy {
    width: 100%;
    box-sizing: border-box;
  }
  
  .TrainingFeed_title_header__3Xvzh {
    display: flex;
    justify-content: space-between;
    width:auto;
    box-sizing: border-box;
  }
  
  .TrainingFeed_title__TIBzi {
    color:#ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    margin-bottom: .3125rem;
  }
  
  .TrainingFeed_title_header__3Xvzh > img {
    width: 1.25rem;
    cursor: pointer;
    margin-left: auto;
  }
  
  .TrainingFeed_publisher_and_date__1lP8o {
    color: #555;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: .5rem;
  }
  
  .TrainingFeed_url__2FpEU {
    display: flex;
    align-items: center;
    border: 1.5px solid #555555;
    border-radius: 40px;
    padding: .375rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  
    color:#858585;
    font-family: Montserrat;
    font-size: .875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: .75rem;
  }
  
  .TrainingFeed_url__2FpEU > img {
    width: 1.125rem;
    margin-left: 0.5rem;
  }
  
  .TrainingFeed_description__PfRLe {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .TrainingFeed_media__2j_tE {
    width: 4rem;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 1rem;
  
  }
#topviewable {
  font-family: "Montserrat", sans-serif;
}

#leftfeed {
  display: none;
}

@media print {
  @page {
      /* size: A3 portrait; */
      size: 15in 20in;
      margin: 2.25rem 3rem  !important;
      /* margin: 25mm 25mm 25mm 25mm; */
  }
  html, body {
    height: 99%;    
}

  body {
      padding: 4.65rem;
  }

  #leftfeed {
    display: block;
  }

  #pagebreak{ 
    page-break-after: always; 
    -webkit-column-break-after: always; 
            break-after: always;
  }
}
#topviewable {
  font-family: "Montserrat", sans-serif;
}

#leftfeed {
  display: none;
}

@media print {
  @page {
      /* size: A3 portrait; */
      size: 15in 20in;
      margin: 2.25rem 3rem  !important;
      /* margin: 25mm 25mm 25mm 25mm; */
  }
  html, body {
    height: 99%;    
}

  body {
      padding: 4.65rem;
  }

  #leftfeed {
    display: block;
  }

  #pagebreak{ 
    page-break-after: always; 
    -webkit-column-break-after: always; 
            break-after: always;
  }
}
.MyPostsFeed_main__2O4Zd {
  width: 100%;
  box-sizing: border-box;
  min-height: 30rem;
  padding-bottom: 1rem;
}

.MyPostsFeed_my_post_label__1Fwvd {
  color: #000;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.MyPostsFeed_nothing_posted__1wQw9 {
  margin: 3rem 0;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-family: Montserrat;
  font-size: 1.25rem;
  color: #ED1E24;
}

.MyPostsFeed_loader_style__1tlnN{
  text-align: center;
  margin-top: 5rem;
}

.StatsFeedAnimated_main__R5MZf {
  margin-top: 0;
  margin-bottom: 1rem;
}

.StatsFeedAnimated_card__kDOWX {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: space-between;
  margin-top: 0.25rem;
  box-shadow: 0px 0px 20px #5555551a;
  padding: .625rem;
  border-radius: 0.625rem;
}

.StatsFeedAnimated_wrapper__31kmP {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.StatsFeedAnimated_circle__21Zn9 {
  width: 7rem;
  height: auto;
  position: relative;
}

.StatsFeedAnimated_count__1vJYA {
  position: absolute; /* Position relative to the parent container */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); /* Adjust position to center */
  height: auto; /* Maintain aspect ratio */
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Montserrat;
  color: rgb(237, 30, 36);
}


.StatsFeedAnimated_count_label__2mB09 {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}
.AddLanguageModal_main__rMkXA {
  box-sizing: border-box;
}

.AddLanguageModal_middle_container__3ufR4 {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.AddLanguageModal_bottom_container__249Ta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 1rem 1.375rem 0 1.375rem;
  /* width: 100%; */
}

.AddLanguageModal_btn_label__2LImF {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: center;
}

.AddLanguageModal_btn_label_cancel__ESGtw {
  font-family: "Montserrat";
  color: rgb(133, 133, 133);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;
}

.AddLanguageModal_tagList__1231r {
  background-color: #EFEFEF;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 600;
  margin: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 0.875rem;
  min-width: 5rem;
  text-align: center;
  cursor: pointer;
}
.BoostProfileSection_main__2yaqL {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  font-family: Montserrat; 
}

.BoostProfileSection_hide_connect_label__dHJ-T {
  opacity: 0;
}

.BoostProfileSection_boost_btn__3SIXA {
  border-radius: 50px;
  padding: 1rem;
  text-align: center;
  border: 1.2px solid #ed1e24;
  color: #ed1e24;
  margin: 0.5rem 0 1.5rem 0;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
}

.BoostProfileSection_boost_btn__3SIXA p {
  font-size: 1rem;
  font-weight: 600;
  font-family: Montserrat; 
  color: #ed1e24;
}

.BoostProfileSection_boost_btn__3SIXA img {
  width: 1.5rem;
  margin-right: 0.75rem;
}

.BoostProfileSection_text_wrapper__2i_IJ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BoostProfileSection_activity_container__1O-DD {
  background-color: #f6f6f6;
  padding: 1rem;
  border-radius: 0.75rem;
  box-sizing: border-box;
  cursor: pointer;
  padding-bottom: 1.5rem;
  font-family: Montserrat; 
}

.BoostProfileSection_post_label__2H1W- {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.625rem 0;
  padding-bottom: 0;
  box-sizing: border-box;
  font-family: Montserrat; 
}

.BoostProfileSection_manage_label__aRlM6 {
  color: #707070;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  margin-left: 4.2rem;
  margin-top: 0;
  box-sizing: border-box;
  font-family: Montserrat; 
}

.class_app_body__3f_mZ {
  display: flex;
  background-color: #ffffff;
  font-family: "Montserrat";
  margin-top: 100px;
  padding-bottom: 80px;
}
@media (max-width: 990px) {
  .class_app_body__3f_mZ > div > div{
    width: 100vw;
  }
  .class_app_body__3f_mZ > div{
    margin: 0;
  }
}
@media (max-width: 900px) {
  .class_app_body__3f_mZ > div > div > div{
    width: 100%;
  }
  .class_app_body__3f_mZ > div > div > div > div > div{
    width: 100%;
  }
}
@media (max-width: 768px) {
  .class_col-xs-0__1ZQkT,
  .class_col-sm-0__20Gm7,
  .class_col-md-0__2P7qH,
  .class_col-lg-0__1CBRi {
    flex: 0 0;
    max-width: 0;
    padding-right: 0;
    padding-left: 0;
    display: none;
  }
}

@media (max-width: 801px) {
  .class_app_body__3f_mZ {
    margin-top: 0;
  }
}

.Imagechangecover_whitecoverBackground__2LF6P {
  height: 30px;
  width: 30px;
  position: relative;
  left: 20%;
  bottom: 90%;
  border-radius: 100%;
  background-color: white;
  border: 1px whitesmoke solid;
  cursor: pointer;
}
.Imagechangecover_profilecoverImg__MruT_ {
  border-radius: 10px;
}
/* .whitecoverBackground {
    height: 30px;
    width: 30px;
    position: relative;
    left: 20%;
    bottom: 90%;
    border-radius: 100%;
    background-color: white;
    border: 1px whitesmoke solid;
    cursor: pointer;
  }
  .profilecoverImg {
    border-radius:10px;
  } */

/* @media (max-width: 1099px) {
  .cover {
    width: 100%;
  }
}*/
@media (min-width: 768px) {
  .Imagechangecover_cover__32Kdl {
    width: 100%;
  }
}

/* @media (min-width:768px){
   img.profilecoverImg{
     width: 100% !important;
   } 
  } */

/* Top left text */
.Imagechangecover_topLeft__Fz9vP {
  position: absolute;
  top: 8px;
  left: 16px;
}

.Imagechangecover_button1__2LDxy {
  display: flex;
  padding: 10px 15px;
  border: 1px solid;
  border-radius: 30px;
  width: 115px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  background-color: white;
  /* padding: 16px; */
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.Imagechangecover_button1__2LDxy:hover {
  background-color: #fff;
}
.Imagechangecover_selectionOption__6pXY2 {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.Imagechangecover_selectionOption__6pXY2 > img {
  margin-right: 10px;
}
.Imagechangecover_selectionOption__6pXY2:hover {
  background-color: #fff;
}

.Imagechangecover_dropdown__3zV40 {
  position: relative;
  display: inline-block;
}

.Imagechangecover_Hr3__BLLjO {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.Imagechangecover_dropdownMenu1__3dhN- {
  margin-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  opacity: 1;
  margin-left: -80px;
  position: absolute;
  background-color: white;
  min-width: 180px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  display: block;
  right: 0;
  padding: 7px 0;
}
.Imagechangecover_dropdownMenu1__3dhN- a:hover {
  background-color: #ddd;
}
.Imagechangecover_dropdownMenu1__3dhN- a {
  color: black;
  text-decoration: none;
  display: block;
}

@media (max-width: 768px) {
  .Imagechangecover_mediaQueryBreak__395aG {
    display: block;
    margin-bottom: 2px;
  }
  .Imagechangecover_subDash__3rL-s {
    display: none;
  }
  .Imagechangecover_createText__WqFAR{
    display: none;
  }
  .Imagechangecover_button1__2LDxy{
    /* width:fit-content; */
    /* width: 35px;
    height: 35px; */
    border-radius: 50%;
    justify-content: center;
    padding: 6.5px;
    width: unset;
    align-items: center;
  }
}

.classfeed_classfeed__3AyFM {
  margin-top: 15px;
}

.classfeed_classMessageSender__Li8qA {
  background-color: #fff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  margin-top: 13px;
}

.classfeed_classmessageSender_top__3mA4L {
  background-color: white;
  padding: 10px 0 0 0;
  border-radius: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.classfeed_cover__3-fyE {
  position: relative;
}

.classfeed_cover__3-fyE::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 10px;
  z-index: 1;
}

.classfeed_classmessageSenderDivider__372Zz {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.classfeed_classmessage_input__2rzqk {
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
  box-shadow: 0px 0px 0px #3f3f411a;
}

.classfeed_classmessage_input__2rzqk > form {
  display: flex;
  width: 100%;
}

.classfeed_classmessage_input__2rzqk > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

.classfeed_classmessageSender_bottom__2LIen {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.classfeed_classmessageSender_rightDiv__3vore {
  order: 2;
  margin-left: auto;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

.classfeed_classfilter__3c2K- {
  /* margin-left: 440px; */
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  margin-top: -10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
}

.classfeed_filterOption__2T-Bi {
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  color: #b4b4b4;
  padding: 10px;
  cursor: pointer;
  grid-gap: 10px;
  gap: 10px;
}

.classfeed_filterOption__2T-Bi > h4 {
  font: normal normal medium 14px/18px Montserrat;
  letter-spacing: 0.7px;
  color: #b4b4b4;
}

.classfeed_filterImage__GqJrE {
  width: 20px;
}

.classfeed_class_discussion__3Wm8W {
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  position: relative;
}

.classfeed_classpost_header__3FDfP {
  display: flex;
  margin-bottom: 10px;
  grid-gap: 7px;
  gap: 7px;
  padding: 30px 26px 0 26px;
}

.classfeed_classpost_profile_pic__35UYN {
  height: 65px;
  width: 65px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 0px;
}

.classfeed_classpost_Info__jpKsa {
  padding-top: 3px;
}

.classfeed_classpost_info_userName__3xEZu {
  font: normal normal medium 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.classfeed_class_visible__2ar3x {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  padding-top: 4px;
  padding-bottom: 1px;
  display: flex;
}

.classfeed_class_eye__3AvO2 {
  height: 20px;
  width: 22px;
  margin-right: 10px;
  margin-top: -2px;
}

.classfeed_class_started__2BIN2 {
  padding: 20px;
}

.classfeed_class_started__2BIN2 > h4 {
  font: normal normal normal 14px/20px Montserrat;
}

.classfeed_class_started__2BIN2 > h3 {
  font: normal normal normal 14px/20px Montserrat;
}

.classfeed_class_value__2xWSK > h4 {
  font: normal normal normal 14px/20px Montserrat;
}

.classfeed_class_help__CFnrO {
  text-align: right;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.classfeed_class_help__CFnrO > h4 {
  font: normal normal normal 14px/20px Montserrat;
  color: #ED1E24;
  text-decoration: underline;
}

.classfeed_activeimg__34zAK {
  height: 13px;
  width: 14px;
  padding-right: 10px;
}
@media (max-width: 800px) {
  .classfeed_cover__3-fyE {
    margin-top: 20px;
  }
}

.classfeed_loader__1bCcS{
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 580px){
  .classfeed_class_discussion__3Wm8W {
    margin-bottom: 24px;
  }
}

.memberlist_membersListContainer__1ejRY {
  margin-top: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  flex-direction: row;
  border-radius: 10px;
}
.memberlist_memberContainer__3dr08 {
  display: flex;
  flex-direction: row;
  padding: 10px 26px 10px 26px;
  justify-content: space-between;
  align-items: center;
}
.memberlist_memberImage__VJwRW {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  border-radius: 25px;
}
.memberlist_memberHr__2io-T {
  border: 1px solid #e5e5e5;
}
.memberlist_memberDetailContainer__1LYOs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.memberlist_memberImageContainer__2oXNb {
  display: flex;
  flex-direction: column;
}
.memberlist_memberPriv__3lJKd {
  border-radius: 5px;
  border: 1px solid #ED1E24;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.memberlist_memberPriv__3lJKd {
  border-radius: 5px;
  border: 1px solid #ED1E24;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.memberlist_actionBody__3klok {
  position: relative;
}
.memberlist_actionMenu__2XBx3 {
  list-style: none;
  width: 120px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #ffffff;
  box-shadow: 3px 2px 4px 2px #55555529;
  border-radius: 5px;
  position: absolute;
  right: 0px;
  margin: 0;
  padding: 0 10px;
}
.memberlist_css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper__3Ljyh {
  box-shadow: 3px 2px 4px 2px #55555529 !important;
}

.AssignmentFeed_assignment_feed__1D6Xo {
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 16px;
}

.AssignmentFeed_noAssignmentOrQuiz__3ceXX {
  padding: 24px;
  padding-top: 40px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Montserrat";
  color: #7b7b7b;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7b7b7b;
  font-size: 14px;
  justify-content: center;
}
.AssignmentFeed_loader__2vSNJ{
  text-align: center;
  margin-top: 20px;
}

.AssignmentPost_Post__xu4tb {
    /* flex: 0.57; */
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    border-radius: 10px;
    position: relative;
  }
  .AssignmentPost_infoContainer__1muve {
    display: flex;
    justify-content: space-between;
  }
  .AssignmentPost_post_header__3s4zv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    grid-gap: 7px;
    gap: 7px;
    padding: 30px 26px 0 26px;
  }
  
  .AssignmentPost_post_Info__173uR {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .AssignmentPost_name_heading_div__3PNn9{
    display: flex;
  }
  
  .AssignmentPost_post_tag__3Ui71 {
    position: absolute;
    right: 70px;
    top: 0;
    padding: 8px 24px 7px 24px;
    background: #ffaf02 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 5px 5px;
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: capitalize;
  }
  
  .AssignmentPost_post_info_follower__1HmZA {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .AssignmentPost_post_info_timestamp__JVhg8 {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .AssignmentPost_post_profile_pic__1TVqj {
    height: 65px;
    width: 65px;
    border-radius: 100%;
    object-fit: cover;
  }
  
  .AssignmentPost_post_Info__173uR > p {
    font-size: 12px;
    color: #848484;
  }
  
  .AssignmentPost_post_header_options__1AK-r {
    color: #b4b4b4;
    order: 2;
    margin-left: auto;
  }
  
  .AssignmentPost_post_body__2CYsA {
    overflow-wrap: break-word;
    padding: 10px 26px;
  }

  .AssignmentPost_assignment_post_heading__2BMXL{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  
  .AssignmentPost_post_info_userName__PNy3s {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .AssignmentPost_post_option__2feUa {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-left: 10px;
    padding-top: 10px;
  }
  .AssignmentPost_assignment_post_description__3hM7e{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
  }
  .AssignmentPost_assignment_post_description_para__38K5y{
    width: 65%;
    color: #707070;
    font-size: 13px;
    overflow-wrap: break-word;
    margin-bottom: 5px;
    max-height: 9.375rem;
    overflow-y: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.AssignmentPost_assignment_post_description_para__38K5y::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.AssignmentPost_assignment_post_description_para__38K5y {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
  
  .AssignmentPost_assignment_post_container__3jQCm{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 15px 30px;
    justify-content: space-between;
  }

  .AssignmentPost_assignment_post_attachment_container__21p4i{
    display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
    margin-top: 20px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }

  .AssignmentPost_assignment_post_attachment__u5rtc{
    /* flex: 1; */
    margin-top: 5px;
  }

    
  @media (max-width: 1099px) {
    .AssignmentPost_Post__xu4tb:last-child {
      margin-bottom: 80px;
    }
  }
  
  @media (max-width: 965px) {
    .AssignmentPost_post_option__2feUa {
      grid-gap: 2px;
      gap: 2px;
    }
  }
  
  @media (max-width: 768px) {
    .AssignmentPost_post_option__2feUa {
      grid-gap: 0px;
      gap: 0px;
    }
    .AssignmentPost_assignment_post_attachment_container__21p4i{
      grid-auto-flow: row;
    }
  }

  @media (max-width: 580px){
    .AssignmentPost_post_header__3s4zv {
      margin-bottom: 0px;
      padding: 10px;
    }
    .AssignmentPost_post_body__2CYsA {
      padding: 0px 14px;
    }
    .AssignmentPost_assignment_post_container__3jQCm{
      padding: 15px 10px;
    }
    .AssignmentPost_assignment_post_attachment_container__21p4i{  
      margin-top: 0px;
    }
    .AssignmentPost_assignment_post_open_button__1yJhh{
      margin-left:auto;
    }
    .AssignmentPost_post_profile_pic__1TVqj {
      height: 50px;
      width: 50px;
    }
    .AssignmentPost_post_info_userName__PNy3s {
      font-size: 15px;
      font-weight: 500;
    }
    .AssignmentPost_post_Info__173uR > p {
      font-size: 10px;
    }
    .AssignmentPost_assignment_post_heading__2BMXL{
      display: block;
    }
    
  }

  @media (max-width: 440px){
    .AssignmentPost_assignment_post_description_para__38K5y{
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .AssignmentPost_translateText__3UngM{
    font-weight: 500 !important;
    color: #ED1E24 !important;
    cursor: pointer;
  }
.AttachmentContainer_attachment_container__9DNBD{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 2fr 1fr;
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    width: 100%;
    padding: 10px 0;
    /* margin: auto; */
    margin-top: 15px;
    word-break: break-all;
}

.AttachmentContainer_attachment_file__3SQK_{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.AttachmentContainer_attachment_image__2bKZB{
    margin-right: 10px;
}

.AttachmentContainer_file_type__mRgoe{
    color: #707070;
    font-size: 12px;
    justify-self: center;
}

.AttachmentContainer_file_size__1w7TC{
    color: #707070;
    font-size: 12px;
}

.AttachmentContainer_file_name__1wqKh{
    margin-left: 20px;
    font-size: 14px;
    justify-self: center;
}

.AttachmentContainer_post_attachment_details__t8byK{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.AttachmentContainer_post_file_name__fMrkf{
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

}

.AttachmentContainer_post_file_desc__PSfV5{
    color: #707070;
    font-size: 12px;
    line-height: 22px;
    
}

.AttachmentContainer_post_file_desc_up__3RwYf{
    text-transform: uppercase;
}

.AttachmentContainer_attachment_post_container__2nLyl{
    width: 100% ;
    overflow: hidden;
    /* display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr; */
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px 0;
    margin: auto;
    word-break: break-all;
}

.AttachmentContainer_file_type__mRgoe{
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .AttachmentContainer_attachment_container__9DNBD{
        width: auto !important;
    }
}

.AttachmentContainer_link_type__KAmrb{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 5px;

}
.ViewAssignmentDetailsModal_view_assignment_modal__2hN3B{
    background-color: white;
    width: 65%;
    padding: 20px;
    border-radius: 20px;

}

.ViewAssignmentDetailsModal_view_assignment_heading__3397X{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.ViewAssignmentDetailsModal_view_assignment_description__2USkc{
    font-size: 14px;
    color: #707070;
    margin: 20px 10px;
    word-wrap: break-word;
    word-break: break-all;
    max-height: 9.375rem;
    overflow-y: auto;
}

  /* Hide scrollbar for Chrome, Safari and Opera */
.ViewAssignmentDetailsModal_view_assignment_description__2USkc::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ViewAssignmentDetailsModal_view_assignment_description__2USkc {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}



.ViewAssignmentDetailsModal_view_assignment_attachment__LimQG{
    margin-bottom: 20px;
}

  
.ViewAssignmentDetailsModal_view_assignment_button__2zJ4Z{
    display: flex;
    justify-content: flex-end;
}
.ViewAssignmentDetailsModal_dropdown__231eJ{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    margin-left: 15px;
    height: 45px;
}
.ViewAssignmentDetailsModal_bottom_btns__3SmbM{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.ViewAssignmentDetailsModal_threeDotsMenu__1KmmU{
    height: 45px;
}


@media (max-width: 1099px) {
    .ViewAssignmentDetailsModal_view_assignment_modal__2hN3B{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .ViewAssignmentDetailsModal_view_assignment_modal__2hN3B{
        width: 90%;
    }
}

@media (max-width: 580px) {
    .ViewAssignmentDetailsModal_view_assignment_modal__2hN3B{
        width: 90%;
        padding: 8px;
    }
    .ViewAssignmentDetailsModal_threeDotsMenu__1KmmU{
        height: 30px;
        margin-top: 8px;
    }
    .ViewAssignmentDetailsModal_view_assignment_description__2USkc{
        margin: 10px 0px;
    }
}
.DueDate_due_date_container__3J1V0 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.DueDate_due_date__31YlM {
  margin-left: 10px;
  /* font-weight: 600; */
  font-size: 11px;
}

@media (max-width: 580px){
  .DueDate_due_date__31YlM {
    font-weight: 400;
    font-size: 12px;
  }
}

.AssignmentHeading_headingDiv__36BQs{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 8px;
}

.AssignmentHeading_headingDiv__36BQs img{
    margin-right: 10px;
    background-color: #ED1E24;
    border-radius: 100%;
    padding: 5px;
    height: 20px;
}

.AssignmentHeading_headingDiv__36BQs h4{
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

@media (max-width: 580px){
    .AssignmentHeading_headingDiv__36BQs h4{
        font-size: 14px;
        font-weight: 500;
    }
}
.StartQuizModal_main__1lm-_ {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  padding-top: 5px;
  padding: 30px;
  border-radius: 20px;
  width: 30vw;
  min-width: 340px;
}

.StartQuizModal_middle_container__WM7kL {
  display: flex;
  flex-direction: column;
  grid-row-gap: 12px;
  row-gap: 12px;
  margin: 1rem 0rem;
}

.StartQuizModal_description__3bMz4 {
  margin-left: 1rem;
  color: #707070;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.StartQuizModal_count_div__38uei {
  margin: 1rem;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-bo */
}

.StartQuizModal_count_div__38uei > img {
  margin-right: 14px;
}

.StartQuizModal_buttons_div__33_KZ {
  display: flex;
  justify-content: end;
  width: 100%;
}

@media (max-width: 580px) {
  .StartQuizModal_main__1lm-_ {
    width: 30vw;
    min-width: 320px;
    padding: 20px;
  }
  .StartQuizModal_description__3bMz4 {
    color: #707070;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: 0.25px;
    margin-left: 0px;
  }
  .StartQuizModal_count_div__38uei {
    margin: 10px 0px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* margin-bo */
  }
  .StartQuizModal_count_div__38uei span{
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .StartQuizModal_btn_label__rHr6n{
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
}
.CourseFeed_myCoursesFeedContainer__-5S2_ {
  overflow-x: hidden;
}

.CourseFeed_addCourseCard__LIfKL {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  display: flex;
  width: 100%;
  height: 145px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  position: relative;
}

.CourseFeed_innerBorder__3vynk {
  display: flex;
  border: 1px dashed #b4b4b4;
  border-radius: 10px;
  width: 92%;
  height: 62%;
  cursor: pointer;
  justify-content: center;
}

.CourseFeed_addCourseText__3SlAX {
  display: flex;
  flex-direction: row;
  justify-content: space-end;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
}

.CourseFeed_plus__2sPIm {
  width: 30px;
}
.CourseTitle_main__3SDcF{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.CourseTitle_heading__2oemn{
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.CourseTitle_img_container__2KOki{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #ED1E24;
  border-radius: 100%;
  /* padding: 5px; */
  height: 32px;
  width: 32px;
  /* border: 1px solid #ED1E24; */
}

.CourseTitle_heading__2oemn span{
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  line-height: 17px;
}

.CourseTitle_cross__b8Q3Q{
  width: 20px;
  cursor: pointer;
}
.CourseFeedPost_course_card_container__3C062 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  /* width: 654px; */
  width: 100%;
  /* height: 427px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 30px;
  margin-top: 30px;
  border-radius: 10px;
  position: relative;
  padding-bottom: 4px;
}

.CourseFeedPost_image_container__1FLk1 {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 191px;
  cursor: pointer;
}


.CourseFeedPost_course_image__1MMg5 {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.CourseFeedPost_bottom_container__Uxux1{
  margin: 20px;
  margin-top: 15px;
}

.CourseFeedPost_course_header__1bfVr{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  grid-gap: 7px;
  gap: 7px;
}

.CourseFeedPost_description__1KXFR{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.CourseFeedPost_price__1xidr{
  color: #ED1E24;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
}

.CourseFeedPost_text_container__YXofT{
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.CourseFeedPost_class_tag_container__Fn2b5{
  margin-bottom: 0px;
  margin-top: 8px;
  width: 70%;
}

.CourseFeedPost_tag_label_container__3PMHg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CourseFeedPost_course_price_for_learner__2nKr5{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ED1E24;
}

.CourseFeedPost_enroll_btn_container__3nbKA{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CourseFeedPost_draft__3ecKa {
  text-transform: uppercase;
  border: 1px solid #f80300;
  background-color: #FF6666;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: .875rem;
  font-weight: bold;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* letter-spacing: 0.25rem; */
  font-family: 'Courier New', Courier, monospace;
}

.CourseFeedPost_published__7RyOf {
  text-transform: uppercase;
  border: 1px solid #40A578;
  background-color: #9DDE8B;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: .875rem;
  font-weight: bold;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 0.25rem;
  font-family: 'Courier New', Courier, monospace;
}

@media(max-width: 560px){
  .CourseFeedPost_course_card_container__3C062{
    min-height: 27.5rem;
  }
  /* .class_tag_container{
    margin-bottom: 10px;
  } */
  
}


.InstructorName_instructor_name__3dmoy{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.InstructorName_instructor_name__3dmoy span{
  margin-left: 10px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.StudentCount_student_count__2VVKA{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.StudentCount_student_count__2VVKA span{
  margin-left: 10px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.ClassTag_more_text__3WPWJ {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-transform: uppercase;
    color: #ED1E24;
    padding: 10px 0 0 10px;
}
.CourseActionModal_action_modal_container__11XlY{
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 63vh;
}

.CourseActionModal_header_text__d19Cv{
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
}

.CourseActionModal_main_text__oFMsq{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}

.CourseActionModal_buttons__2LPwF{
  display: flex;
  justify-content: flex-end;
  grid-gap: 10px;
  gap: 10px;
}

@media(max-width: 600px){
  .CourseActionModal_action_modal_container__11XlY {
    width: 75%
  }
}

@media(max-width: 400px){
  .CourseActionModal_action_modal_container__11XlY {
    width: 70%
  }
}
.CreateEditCourseModal_course_modal__1-Mf- {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5px 0px;
  height: 450px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.CreateEditCourseModal_course_modal__1-Mf-::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.CreateEditCourseModal_course_modal__1-Mf- {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.CreateEditCourseModal_course_form__25uZ6 {
  /* max-width: 605px; */
  /* min-width: 250px; */
  margin: 20px 32px 25px 32px;
}

.CreateEditCourseModal_thumbnail_area__LNLbD {
  /* width: 550px; */
  height: 125px;
  background: #F8FCFA 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #ED1E24 dashed 2px;
  opacity: 1;
  /* margin-left: 25px; */
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  position: relative;
}

.CreateEditCourseModal_upload_box__299ej {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.CreateEditCourseModal_upload_box__299ej input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.CreateEditCourseModal_upload_img__nQYVg {
  margin-bottom: 5px;
}

.CreateEditCourseModal_file_upload__r6WlK {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.CreateEditCourseModal_upload_txt__DPRYV {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.CreateEditCourseModal_support_txt__3k0qL {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #BDBDBD;
}

.CreateEditCourseModal_thumbnail_pic__yzq52 {
  width: 550px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 5px;
}

.CreateEditCourseModal_input_label__2Qmin {
  /* font-size: 13px; */
  margin: 15px 0 12px 0;
  /* padding-left: 1rem; */
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  /* font-weight: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.CreateEditCourseModal_input_field__3GEww {
  border-radius: 25px;
  width: 100%;
  /* width: 550px; */
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 10px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}

input[type="radio"] {
  accent-color: #00a77f;
}

.CreateEditCourseModal_textbox_field__rbp5k {
  border-radius: 25px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

}

.CreateEditCourseModal_hideCreateEditCourseModal__3ZOYI {
  display: none;
}

.CreateEditCourseModal_radio_label__QIkV- {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-right: 10px;
}

.CreateEditCourseModal_radio_div__2uVt_ {
  margin: 10px 0 0 0;
}

.CreateEditCourseModal_price_div__tqkPG {
  display: flex;
}

.CreateEditCourseModal_price_type_input__1yeTp {
  border-radius: 25px;
  width: 92px;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

.CreateEditCourseModal_price_input__3iA4v {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}

.CreateEditCourseModal_nextbtn_div__ZfIGI {
  margin-top: 15px;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.CreateEditCourseModal_fields_div__2bIa4 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {

  .CreateEditCourseModal_input_field__3GEww,
  .CreateEditCourseModal_textbox_field__rbp5k {
    width: 100%;
  }

  .CreateEditCourseModal_course_form__25uZ6 {
    /* width: 85%; */
    margin: 15px 16px 15px 16px;
  }

  .CreateEditCourseModal_price_input__3iA4v {
    width: 50%;
  }

  .CreateEditCourseModal_price_type_input__1yeTp {
    width: 50%;
  }

  @media (min-height: 850px) and (max-height: 950px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 660px;
    }
  }

  @media (min-height: 750px) and (max-height: 850px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 580px;
    }
  }

  @media (min-height: 650px) and (max-height: 750px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 480px;
    }
  }

  @media (min-height: 500px) and (max-height: 650px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 362px;
    }
  }

  @media (min-height: 350px) and (max-height: 500px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 312px;
    }
  }

  @media (max-height: 350px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 210px;
    }
  }
}

@media (min-width:500px) and (max-width: 1370px) {
  @media (max-height: 750px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 362px;
    }
  }

  @media (min-height: 750px) and (max-height: 950px) {
    .CreateEditCourseModal_course_modal__1-Mf- {
      height: 520px;
    }
  }
}
.MultipleSelectChip_input_field__17AG9{
    /* border-radius: 25px !important; */
    width: 100% !important;
    /* margin-left: 15px !important;
    height: 40px !important;
    outline: none !important;
    border: 1px #c2c2c2 solid !important;
    padding-left: 20px !important; */
    box-sizing: border-box !important;
}
.MultipleSelectChip_select_field__1DTQv{
    border-radius: 25px !important;
    width: 100% !important;
    /* margin-left: 6px !important; */
    height: 40px !important;
    outline: none !important;
    border: 0px #c2c2c2 solid !important;
    /* padding-left: 20px !important; */
    box-sizing: border-box !important;
}
.MultipleSelectChip_input_field__17AG9:hover{
    
    border: 0px #c2c2c2 solid !important;
    
}
.MultipleSelectChip_class_chip__2xzIF{
    background-color: #ED1E24 !important;
    color: #ffffff !important;
    font-weight: bold !important;
}
.MultipleSelectChip_classTagPara__2fq-r {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-transform: uppercase;
    color: #ED1E24;
    padding: 10px 0 0 10px;
}
.MultipleSelectChip_defaultMsg__1aTz2{
    margin: 5px;
    color: gray;
}

.UploadCourseFilesModal_popupModalBox__1EdFp {
  padding: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  background: #31313150;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.UploadCourseFilesModal_hidePopupModalBox___61vL {
  display: none;
}

.UploadCourseFilesModal_main_container__151oN {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* min-height: 220px; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 430px;
  /* height: fit-content; */
  padding-top: 5px;
  padding: 30px;
  border-radius: 32px;
  width: 50%;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.UploadCourseFilesModal_main_container__151oN::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.UploadCourseFilesModal_main_container__151oN {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.UploadCourseFilesModal_header__M5yDD {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UploadCourseFilesModal_add_attachment_text__11wYy {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 12px;

}

.UploadCourseFilesModal_drop_area__2W-Nt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  border: 1px dashed #ED1E24;
  background: #F8FCFA;
  border-radius: 5px;
  flex-direction: column;
}

.UploadCourseFilesModal_drop_area_active__3Sy0- {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  border: 1px solid #ED1E24;
  background: #F8FCFA;
  border-radius: 5px;
  flex-direction: column;
}

.UploadCourseFilesModal_drop_area__2W-Nt input[type='file']{
  opacity: 0;
  cursor: pointer;
  width: 49%;
  height: 14%;
  font-size: 0;
  position: absolute;
}

.UploadCourseFilesModal_drop_area_active__3Sy0- input[type='file']{
  opacity: 0;
  cursor: pointer;
  width: 49%;
  height: 14%;
  font-size: 0;
  position: absolute;
}

/* .drop_input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
} */

.UploadCourseFilesModal_upload_icon__GNPcz {
  width: 14px;
  height: 17px;
  /* fill: #ED1E24; */
  padding: 5px;
}

.UploadCourseFilesModal_drag_and_drop_text__Wjv4s {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat";
  text-align: center;
}

.UploadCourseFilesModal_drag_active_text__3rcdU {
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat";
  text-align: center;
}

.UploadCourseFilesModal_browse_text__35Yjy {
  color: #ED1E24;
}

.UploadCourseFilesModal_supported_files_text__1wpvo {
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.015em;
  text-align: center;
  color: #BDBDBD;
  margin-top: 3px;
}

.UploadCourseFilesModal_file_attachments_container__1dJsJ {
  margin-top: 15px;
  margin-bottom: 20px;
  /* max-height: 90px;
  overflow-y: scroll;
  min-height: 45px; */
}

add_links_text {
  font-family: "Euclid Square";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.UploadCourseFilesModal_add_links_input_container__16vRa {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}


.UploadCourseFilesModal_input_link__-pCwq {
  border: 1px solid #5555555A;
  padding: 10px;
  font-size: 15px;
  border-radius: 50px;
  width: 83%;
  margin-top: 10px;
}

.UploadCourseFilesModal_add_btn__3xZkZ {
  padding: 10px;
  border-radius: 50px;
}

.UploadCourseFilesModal_review_btn__oMenT {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/*--- Media ---*/

@media (max-width: 1450px) {
  .UploadCourseFilesModal_main_container__151oN{
    width: 70%;
  }
}

@media (max-width: 960px){
  .UploadCourseFilesModal_main_container__151oN{
    width: 75%;
  }
}

@media (max-width: 400px){
  .UploadCourseFilesModal_main_container__151oN{
    width: 80%;
  }
}
.PublishCourseModal_popupModalBox__2Nq6E {
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    background: #31313150;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.PublishCourseModal_hidePopupModalBox__3tsFV {
    display: none;
}

.PublishCourseModal_publishCourse__Xjj8V {
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 80%;
    position: relative;
    width: 50%;
    background: #fff;
    overflow-x: hidden;
    border-radius: 32px;
    z-index: 1000;
}

.PublishCourseModal_hidePopupModalBox__3tsFV {
    display: none;
}

.PublishCourseModal_verticalScrollbarContanier__1J4za {
    overflow-y: scroll !important;
}

.PublishCourseModal_verticalScrollbarContanier__1J4za::-webkit-scrollbar {
    display: none;
}

#PublishCourseModal_topHeaderImage__8VKTZ {
    position: -webkit-sticky;
    position: sticky;
    height: 220px;
}

.PublishCourseModal_publishCourseHeading__3TS4p {
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.PublishCourseModal_publishCourseHeading__3TS4p h4 {
    padding-left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.PublishCourseModal_courseType__3dJc7 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.PublishCourseModal_courseType__3dJc7 h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.PublishCourseModal_courseType__3dJc7 p {
    padding-left: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

#PublishCourseModal_publishingTo__1tony {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 30px 0 20px 0;
}

.PublishCourseModal_inputTags__3u6E1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 60px;
    width: 93%;
    border: 1px solid rgb(227, 223, 223);
    margin: 0 0 20px 10px;
    min-height: 42px;
    height: auto;
    padding: 0px 5px 0px 5px;
    overflow-x: scroll !important;
}

.PublishCourseModal_inputTags__3u6E1::-webkit-scrollbar {
    display: none;
}

.PublishCourseModal_course__30XcA h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0 30px 0;
}

.PublishCourseModal_course__30XcA p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.PublishCourseModal_courseDescription__1PHym h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 30px 0 30px 0;
}

.PublishCourseModal_courseDescription__1PHym p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.PublishCourseModal_coursePrice__dF9kw {
    display: flex;
    flex-direction: row;
    padding: 50px 0 30px 0;
}

.PublishCourseModal_coursePrice__dF9kw h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.PublishCourseModal_coursePrice__dF9kw p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ED1E24;
    padding-left: 50px;
}

.PublishCourseModal_courseFiles__3CqBI h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.PublishCourseModal_courseButtons__2xS9i {
    margin: 30px 0 10px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
}

.PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn1__2nKWu {
    margin-right: 15px;
    background: #FFFFFF;
    border: 1px solid #ED1E24;
    border-radius: 60px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ED1E24;
    padding: 15px;
    width: 20%;
    cursor: pointer;
}

.PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn2__2CCYa {
    background: #ED1E24;
    border-radius: 60px;
    border: 1px solid #ED1E24;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 15px;
    width: 20%;
    cursor: pointer;
}

.PublishCourseModal_courseFile___Oai- {
    width: 98%;
    margin-top: 10px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
}

@media screen and (max-width: 952px) {

    .PublishCourseModal_courseButtons__2xS9i{
        margin-top: 40px;
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn1__2nKWu {
        width: 26%
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn2__2CCYa {
        width: 26%
    }

    .PublishCourseModal_publishCourse__Xjj8V {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 60%;
        height: 70%;
    }

    .PublishCourseModal_inputTags__3u6E1 {
        height: auto;
        width: 90%;
        flex-wrap: wrap;
        margin-left: 5px;
    }

    #PublishCourseModal_publishingTo__1tony {
        padding-left: 0;
    }

    .PublishCourseModal_coursePrice__dF9kw {
        padding-top: 30px;
    }

    .PublishCourseModal_publishCourseHeading__3TS4p{
        padding-top: 20px;
    }

    #PublishCourseModal_topHeaderImage__8VKTZ {
        height: 180px;
    }
}


@media screen and (max-width: 634px) {

    .PublishCourseModal_publishCourseHeading__3TS4p img {
        width: 30px;
        height: 31px;
        padding-bottom: 0px;
    }

    .PublishCourseModal_courseButtons__2xS9i{
        margin-top: 10px;
    }

    .PublishCourseModal_publishCourseHeading__3TS4p {
        padding-top: 0px;
    }

    .PublishCourseModal_publishCourseHeading__3TS4p h4 {
        padding-left: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    .PublishCourseModal_publishCourse__Xjj8V {
        padding-top: 0px;
        padding-bottom: 10px;
        width: 70%;
        border-radius: 12px;
    }

    .PublishCourseModal_inputTags__3u6E1 {
        width: 85%;
    }

    .PublishCourseModal_courseFile___Oai- {
        width: 93%;
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn1__2nKWu {
        margin-right: 7px;
        padding: 10px 7px 10px 7px;
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn2__2CCYa {
        padding: 10px 7px 10px 7px;
    }

}

@media screen and (max-width: 439px) {
    .PublishCourseModal_publishCourse__Xjj8V {
        width: 80%;
    }

    .PublishCourseModal_inputTags__3u6E1 {
        width: 80%;
    }

    .PublishCourseModal_courseFile___Oai- {
        width: 87%;
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn1__2nKWu {
        margin-right: 5px;
        width: 30%;
    }

    .PublishCourseModal_courseButtons__2xS9i #PublishCourseModal_cbtn2__2CCYa {
        width: 30%;
    }

    .PublishCourseModal_publishCourseHeading__3TS4p{
        padding-top: 0px;
    }

    #PublishCourseModal_topHeaderImage__8VKTZ {
        height: 170px;
    }

}
.CourseLength_main__3CxSv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.CourseLength_main__3CxSv span {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  /* line-height: 15px; */
}

.CourseLength_main__3CxSv > img {
  margin-right: 10px;
  width: 1rem;
  height: 1rem;
}

.PickPublicPrivateCourseModal_popupModalBox__14jC6 {
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    background: #31313150;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.PickPublicPrivateCourseModal_publicPrivate__A8PRp {
    padding: 30px;
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    width: 60%;
    background: #fff;
    overflow: scroll !important;
    scrollbar-width: 0;
    border-radius: 32px;
    z-index: 1000;
    width: 674px;
    height: 468px;
}

.PickPublicPrivateCourseModal_publicPrivate__A8PRp::-webkit-scrollbar {
    display: none;
}

.PickPublicPrivateCourseModal_publicPrivateHeading__2Nb4v {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.PickPublicPrivateCourseModal_publicPrivateHeading__2Nb4v #PickPublicPrivateCourseModal_cross__EUrzV {
    padding-left: 25px;
    cursor: pointer;
}

.PickPublicPrivateCourseModal_insidePublicPrivateHeading__ON1BA {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.PickPublicPrivateCourseModal_insidePublicPrivateHeading__ON1BA p {
    padding-left: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.PickPublicPrivateCourseModal_publicPrivateBoxes__ob9TL {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    flex-direction: row;
    margin: 60px 0 30px 0;
}

.PickPublicPrivateCourseModal_publicPrivateBox__2fhIv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 4.84161px 4.84161px 22.3924px rgba(0, 0, 0, 0.08);
    border-radius: 34.8544px;
    width: 280.21px;
    height: 256px;
    cursor: pointer;
}

.PickPublicPrivateCourseModal_publicPrivateBox__2fhIv h2 {
    padding: 10px 0 10px 0;
    font-weight: 700;
    font-size: 19.3664px;
    line-height: 105%;
}

.PickPublicPrivateCourseModal_publicPrivateBox__2fhIv img {
    padding: 10px 0 10px 0;
}

.PickPublicPrivateCourseModal_publicPrivateBoxFlex__1bTM6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.PickPublicPrivateCourseModal_publicPrivateBoxFlex__1bTM6 img {
    margin: 4px 0 0 5px;
    width: 13.33px;
    height: 13.33px;
}

.PickPublicPrivateCourseModal_publicPrivateButton__1oadB {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.PickPublicPrivateCourseModal_publicPrivateButton__1oadB button {
    background: #ED1E24;
    border-radius: 60px;
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding: 20px 10px 20px 10px;
    width: 25%;
    cursor: pointer;
}

.PickPublicPrivateCourseModal_hidePopupModalBox__2BKDq {
    display: none;
}


.PickPublicPrivateCourseModal_nextbtn_div__26K_B {
    margin: 35px 40px 0px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

@media screen and (max-width: 800px) {

    .PickPublicPrivateCourseModal_publicPrivateBoxes__ob9TL {
        grid-gap: 30px;
        gap: 30px;
    }

    .PickPublicPrivateCourseModal_publicPrivate__A8PRp {
        width: 500px;
        height: 390px;
    }

    .PickPublicPrivateCourseModal_publicPrivateBox__2fhIv {
        width: 200px;
        height: 200px;
    }

    .PickPublicPrivateCourseModal_publicPrivateBox__2fhIv img {
        padding: 10px 0 0px 0;
    }

    .PickPublicPrivateCourseModal_publicPrivateBox__2fhIv h2 {
        padding: 10px 0 0px 0;
    }

    #PickPublicPrivateCourseModal_lockIcon__DX5v1 {
        width: 45px;
        height: 58px;
    }

    .PickPublicPrivateCourseModal_publicPrivateBoxFlex__1bTM6 h2 {
        font-weight: 700;
        font-size: 12px;
        line-height: 105%;
    }

    .PickPublicPrivateCourseModal_publicPrivateButton__1oadB button {
        width: 30%;
    }

    .PickPublicPrivateCourseModal_nextbtn_div__26K_B {
        margin: 15px 30px 0px auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

}

@media screen and (max-width: 630px) {

    .PickPublicPrivateCourseModal_publicPrivate__A8PRp {
        width: 320px;
        height: 263px;
        border-radius: 10px;
    }

    .PickPublicPrivateCourseModal_publicPrivateBoxes__ob9TL {
        grid-gap: 20px;
        gap: 20px;
        margin: 30px 0 15px 0;
    }

    .PickPublicPrivateCourseModal_publicPrivateBox__2fhIv {
        padding: 5px;
        width: 138px;
        height: 137px;
        border-radius: 24.1667px;
    }

    #PickPublicPrivateCourseModal_lockIcon__DX5v1 {
        width: 35.37px;
        height: 48px;
    }

    #PickPublicPrivateCourseModal_select__30tkU {
        width: 8.68px;
        height: 8.68px;
    }

    #PickPublicPrivateCourseModal_cross__EUrzV {
        height: 12px;
        width: 12px;
    }

    .PickPublicPrivateCourseModal_insidePublicPrivateHeading__ON1BA img {
        width: 30px;
        height: 31px;
    }

    .PickPublicPrivateCourseModal_insidePublicPrivateHeading__ON1BA p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding-left: 10px;
    }

    .PickPublicPrivateCourseModal_publicPrivateButton__1oadB button {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        padding: 10px 5px 10px 5px;
    }

    .PickPublicPrivateCourseModal_nextbtn_div__26K_B {
        margin: 5px 0px 0px auto;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

}

@media screen and (max-width: 430px) {
    .PickPublicPrivateCourseModal_publicPrivate__A8PRp {
        margin: 20px;
    }

    .PickPublicPrivateCourseModal_publicPrivateBoxes__ob9TL {
        grid-gap: 10px;
        gap: 10px;
    }
}
/* .feed_container {
    margin-top: 20px;
    overflow-x: hidden;
    padding-bottom: 16px;
} */
.TemplateFeedPost_main__wID1n {
  /* display: grid; */
  /* min-height: 10rem; */
  /* grid-template-rows: 1fr 2fr 2fr; */
  /* grid-template-columns: 8fr 2fr; */
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  font-family: "Montserrat";
  padding: 20px;
}
.TemplateFeedPost_heading__3oCpM{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.TemplateFeedPost_paragraph__2LPNO{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.TemplateFeedPost_title__1Gxcy {
  display: flex;
  grid-column: 1;
  justify-self: start;
  align-self: center;
}

.TemplateFeedPost_menu__3jeDo {
  justify-self: end;
  align-self: center;
}

.TemplateFeedPost_details__24a3h {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  color: #707070;

  margin-top: 10px;
  justify-self: start;
  align-self: start;
}

.TemplateFeedPost_max_marks__i1HyP {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 15px;

  margin-top: 10px;
  justify-self: end;
  align-self: start;
  white-space: nowrap;
}

.TemplateFeedPost_bottom_container__3ZW5r {
  
  font-weight: 700;
  font-size: .75rem;
  font-family: 'Montserrat';
  justify-self: start;
  align-self: center;
}

.TemplateFeedPost_time__D3Ynk, .TemplateFeedPost_count__-7-FS {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.TemplateFeedPost_time__D3Ynk > img, .TemplateFeedPost_count__-7-FS>img {
  padding-right: 10px;
  height: 15px;
}

@media (max-width: 580px){
  .TemplateFeedPost_main__wID1n {
    padding: 15px;
    grid-template-rows: 0fr;
    grid-template-columns: 1fr;
  }
  .TemplateFeedPost_paragraph__2LPNO{
    display: grid;
    width: 100%;
    justify-content: space-between;
  }
  .TemplateFeedPost_details__24a3h {
    color: #707070;
    /* mb/Caption */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .TemplateFeedPost_max_marks__i1HyP {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .TemplateFeedPost_count__-7-FS{
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
/* .feed_container {
    margin-top: 15px;
    overflow-x: hidden;
} */
.hidden {
  display: none;
}
.sign-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.sign-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.8;
}

.sign-logo-section {
  width: 100vw;
  z-index: 1;
}
.sign-logo {
  margin-top: 20px;
  margin-left: 80px;
}
.sign-container-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sign-container-left > img {
  width: 200px;
  height: auto;
}
.sign-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90vh;
  padding: 0 150px;
  box-sizing: border-box;
}
.sign-heading {
  display: flex;
  flex-direction: column;
}
.sign-heading :nth-child(1) {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 700;
}
.sign-heading :nth-child(2) {
  font-size: 20px;
  opacity: 1;
  width: 80%;
  color:black;
  font-weight: 400;
}
.sign-input {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.sign-name {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
}
.sign-name > input {
  width: 100%;
  box-sizing: border-box;
}
.sign-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.sign-action > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-alert {
  padding: 5px 0 0 20px;
  font-weight: 600;
  color: #e0003b;
}
.hidden {
  display: none;
}
.sign-field {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 60px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.sign-field:focus {
  outline: none;
}
.sign-action > div:first-child {
  display: flex;
}
.checkbox {
  height: 15px;
  width: 15px;
  margin: 0 5px 0 0;
}
.forgot-password {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  -webkit-text-decoration: underline #000000;
          text-decoration: underline #000000;
}
.sign-btn {
  margin-top: 22px;
  font-size: 16px;
  width: 100%;
}
.button {
  color: white;
  border: 2px solid #000000;
  border-radius: 100px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: #000000;
  transition: 0.3s;
  text-transform: uppercase;
}
.button:hover {
  color: white;
  background-color: #333333;
  border: 2px solid #333333;
}

.account-already > span > a {
  color:black;
  text-decoration: none;
  font-weight: 700;
}
.sign-container-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 50%;
  object-fit: cover;
  object-position: left;
  box-sizing: border-box;
  /* Gradient light */
  background: linear-gradient(180.00deg, rgb(255, 245, 245),rgb(255, 236, 236) 100%);
}

.sign-container-right > div {
  width: 50%;
  color: white;
}
.category-section {
  margin-top: 20px;
}
.category-heading {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
.category-first {
  display: flex;
  flex-wrap: wrap;
}
.category-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  width: 40%;
}
.category-card:hover {
  background-color: #ED1E24;
  color: white;
}
.category-card > img {
  height: 80px;
  width: 80px;
  margin-right: 10px;
}
.category-card > div {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
}
.sign-container-right > img {
  height: 100%;
  width: 85%;
  object-fit: cover;
}
.or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.account-already {
  margin-top: 16px;
  font-size:14px;
  font-weight:400;
  text-align: center;
}

.subtext {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Montserrat;

}


/* ############################ Responsive 1650 ############################ */
@media only screen and (max-width: 1650px) {
  .sign-heading :nth-child(1) {
    font-size: 40px;
  }
  .sign-heading :nth-child(2) {
    font-size: 16px;
  }
  .sign-form {
    padding: 0 50px;
  }
  .category-card {
    flex-direction: column;
  }
  .category-card > div {
    margin: 0 0;
  }
  .category-card > img {
    margin: 0 0;
  }
}
/* ############################ Responsive 1100 ############################ */
@media only screen and (max-width: 900px) {
  .sign-container-right {
    display: none;
  }
  .sign-container-left {
    width: 100%;
  }
  .sign-container-left > div {
    align-items: center;
  }
  .sign-form {
    padding: 0 20px;
  }
  .sign-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .category-first {
    align-items: center;
    justify-content: center;
  }
  .category-card > div {
    font-size: 14px;
  }
  .sign-heading :nth-child(1) {
    font-size: 30px;
  }
  .sign-heading :nth-child(2) {
    font-size: 14px;
  }
}
/* ############################ Responsive 600 ############################ */
@media only screen and (max-width: 600px) {
  .category-first {
    flex-direction: column;
    justify-content: center;
  }
  .category-card {
    width: 100%;
  }
  .sign-logo {
    margin-top: 15px;
    margin-left: 10px;
    height: auto;
    width: 150px;
  }
  .sign-form {
    padding: 0 0px;
  }
  .sign-btn{
    font-size: 12px;
  }
  .forgot-password{
    font-size: 12px;
  }
  .or{
    margin-top: 12px;
    font-size:12px;
  }
  .account-already {
    margin-top: 12px;
    font-size:12px;
  }
}


@media only screen and (max-width: 400px) {
  .sign-form {
    margin-top:30px;
    height:auto;
  }
}
.headerSign{
    display: flex;
    justify-content: space-between;
}

.sign-logo {
    margin-top: 20px;
    margin-left: 80px;
  }


.sign-right-logo{
  margin-top: 25px;
  margin-right: 80px;
  height: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownLanguag{
    width: 100px;
    box-shadow: 3px 2px 4px 2px #55555529;
    border-radius: 5px;
    position: absolute;
    top: 80%;
    left: 40%;
    opacity: 1;
    background-color: #fff;
    z-index: 10;
  }
  
  .dropdownList{
    list-style: none;
    padding: 0;
  }
  
  .inactiveList{
    text-align: center;
    margin-top: 6; 
    color: #555555;
    cursor: "pointer"
  }
  
  .activeList{
    text-align: center;
    margin-top: 6; 
    color: #ED1E24;
    cursor: "pointer"
  }

  .languageShow{
      color: #000000;
      font-size:13px;
      font-weight: 400;
      display: inline;
      margin-left: 10px;
      position: relative;
      bottom: 2px;
  }



  @media only screen and (max-width: 1024px) {
    .dropdownLanguag{
      top: 80%;
      left: 40%;
    }
  }

  @media only screen and (max-width: 600px) {
    .sign-logo {
      margin-top: 15px;
      margin-left: 10px;
      height: auto;
      width: 150px;
    }
    .sign-right-logo {
        margin-top: 15px;
        margin-right: 10px;
      }
      .dropdownLanguag{
        top: 80%;
        left: -40%;
      }
  }
  
  
.zoomSubscription_subscriptionContainer__rY2DE {
  margin: 120px 250px 20px 250px;
}
.zoomSubscription_subscriptionBody__2RFxC {
  display: flex;
}
.zoomSubscription_subscriptionOptions__wTisj {
  display: flex;
}
.zoomSubscription_subscriptionOptions__wTisj > div {
  padding: 60px 50px;
  border-radius: 20px;
}
.zoomSubscription_heading__2ilEL {
  font-size: 40px;
}
.zoomSubscription_partition__3asGA {
  border: 1px solid #bbbbbb;
  margin: 20px 0;
}
.zoomSubscription_optionsCards__96S7Z {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  margin-bottom: 16px;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(odd) {
  background-color: #f8f8f8;
  margin-right: 25px;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(even) {
  background-color: #ED1E24;
  color: white;
  /* margin-left: 25px; */
}
.zoomSubscription_optionsCards__96S7Z:nth-child(odd) > div > div > hr {
  border: 4px solid #ED1E24;
  width: 30%;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(even) > div > div > hr {
  border: 4px solid #ffffff;
  width: 30%;
}
.zoomSubscription_optionType__3nc4O {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.zoomSubscription_optionHeading__2rFoV {
  font-size: 30px;
  font-weight: 800;
}
.zoomSubscription_optionHeading__2rFoV > hr {
  margin: 20px 0 60px 0;
}
.zoomSubscription_pricing__1GvD9 {
  text-align: right;
}
.zoomSubscription_pricing__1GvD9 > div:nth-child(odd) {
  font-size: 24px;
  font-weight: 500;
}
.zoomSubscription_pricing__1GvD9 > div:nth-child(even) {
  font-size: 18px;
}
.zoomSubscription_facilities__2vUm- > div {
  font-size: 16px;
  display: flex;
}
.zoomSubscription_facilities__2vUm- > div > div {
  margin-left: 15px;
  margin-bottom: 20px;
}
.zoomSubscription_facilities__2vUm- > div > img {
  height: 20px;
  width: auto;
}
.zoomSubscription_free__18oZu {
  background-color: none !important;
}
.zoomSubscription_zoomBtn__3NGF5 {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 60%;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(odd) > button {
  background-color: transparent;
  color: #ED1E24;
  border: 1px solid #ED1E24;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(odd) > button:hover {
  background-color: #ED1E24;
  color: #ffffff;
  border: 1px solid #ED1E24;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(even) > button {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
}
.zoomSubscription_optionsCards__96S7Z:nth-child(even) > button:hover {
  background-color: #ffffff;
  color: #ED1E24;
  border: 1px solid #ffffff;
}

/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 150px 200px 0 200px 60px;
  }
  .zoomSubscription_subscriptionOptions__wTisj > div {
    padding: 50px 40px;
  }
  .zoomSubscription_heading__2ilEL {
    font-size: 35px;
  }
}
/* ############################ Responsive 1420 ############################ */
@media only screen and (max-width: 1420px) {
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 120px 150px 0 150px;
  }
  .zoomSubscription_subscriptionOptions__wTisj > div {
    padding: 45px 30px;
  }
}
/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 110px 100px 0 100px;
  }
  .zoomSubscription_subscriptionOptions__wTisj > div {
    padding: 40px 30px;
  }
  .zoomSubscription_zoomBtn__3NGF5 {
    width: 80%;
  }
}
/* ############################ Responsive 1090 ############################ */
@media only screen and (max-width: 1090px) {
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 100px 80px 100px 80px;
  }
  .zoomSubscription_subscriptionOptions__wTisj > div {
    padding: 40px 30px;
  }
  .zoomSubscription_subscriptionOptions__wTisj {
    display: block;
  }
  .zoomSubscription_zoomBtn__3NGF5 {
    width: 80%;
  }
}
/* ############################ Responsive 850 ############################ */
@media only screen and (max-width: 850px) {
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 110px 80px 0 80px;
  }
  .zoomSubscription_subscriptionOptions__wTisj > div {
    padding: 40px 30px;
  }
  .zoomSubscription_zoomBtn__3NGF5 {
    width: 80%;
  }
  .zoomSubscription_optionsCards__96S7Z:nth-child(odd) {
    margin-right: 0px;
  }
  .zoomSubscription_optionsCards__96S7Z:nth-child(even) {
    margin-left: 0px;
  }
}
/* ############################ Responsive 550 ############################ */
@media only screen and (max-width: 770px) {
  .zoomSubscription_heading__2ilEL {
    font-size: 30px;
  }
  .zoomSubscription_subscriptionContainer__rY2DE {
    margin: 30px 20px 80px 20px;
  }
}

.ZoomConfigure_configureContainer__3feoU {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ZoomConfigure_rightImage__WvNRV {
  height: 100vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%);
  background: var(--Gradient-light, linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%));
}
.ZoomConfigure_rightImage__WvNRV > img {
  width: 80%;
  height: auto;
}
.ZoomConfigure_fieldContainer__3DL6R {
  width: 50%;
  padding: 0 220px;
  box-sizing: border-box;
}
.ZoomConfigure_fieldContainer__3DL6R > a {
  text-decoration: none;
}
.ZoomConfigure_field__arDCu {
  display: flex;
  flex-direction: column;
}
.ZoomConfigure_heading__3G_i- {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 20px;
}
.ZoomConfigure_subHeading__1WEmu {
  color: #555555;
  font-size: 16px;
}
.ZoomConfigure_configureField__2pOAI {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 60px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.ZoomConfigure_configureField__2pOAI:focus {
  outline: none;
}
.ZoomConfigure_zoomBtn__1roH4 {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  padding: 12px 30px 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #ED1E24;
  border: 1px solid #ED1E24;
}
.ZoomConfigure_zoomBtn__1roH4:hover {
  background-color: #ED1E24;
  color: #ffffff;
  border: 1px solid #ED1E24;
}
.ZoomConfigure_inputAlert__3Ep1x {
    padding: 5px 0 0 20px;
    font-weight: 600;
    color: #e0003b;
  }

/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
  .ZoomConfigure_fieldContainer__3DL6R {
    padding: 0 120px;
  }
}
/* ############################ Responsive 1420 ############################ */
@media only screen and (max-width: 1420px) {
  .ZoomConfigure_heading__3G_i- {
    font-size: 30px;
  }
  .ZoomConfigure_fieldContainer__3DL6R {
    padding: 0 110px;
  }
}
/* ############################ Responsive 1250 ############################ */
@media only screen and (max-width: 1250px) {
  .ZoomConfigure_fieldContainer__3DL6R {
    padding: 0 90px;
  }
}
/* ############################ Responsive 1090 ############################ */
@media only screen and (max-width: 1090px) {
  .ZoomConfigure_rightImage__WvNRV {
    display: none;
  }
  .ZoomConfigure_fieldContainer__3DL6R {
    padding: 0;
  }
  .ZoomConfigure_configureContainer__3feoU {
    height: 100vh;
    justify-content: center;
  }
  .ZoomConfigure_fieldContainer__3DL6R {
    width: 70%;
  }
}
/* ############################ Responsive 800 ############################ */
@media only screen and (max-width: 800px) {
  .ZoomConfigure_configureContainer__3feoU {
    height: 60vh;
    justify-content: center;
  }
  .ZoomConfigure_fieldContainer__3DL6R {
    width: 70%;
  }
}
/* ############################ Responsive 550 ############################ */
@media only screen and (max-width: 550px) {
  .ZoomConfigure_fieldContainer__3DL6R {
    width: 90%;
  }
}

.Settings_settingsContainer__17DJA {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Settings_settingsArea__3NS42 {
  width: 50%;
  padding: 0 150px;
  box-sizing: border-box;
}
.Settings_settingsSection__9XfD0 {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.Settings_settingsSection__9XfD0 > hr {
  margin: 0;
  border: 1px solid #e5e5e5;
}
.Settings_rightImage__1OK1Y {
  height: 100vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%);
  background: var(--Gradient-light, linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%));
}
.Settings_rightImage__1OK1Y > img {
  width: 80%;
  height: auto;
}
.Settings_heading__ejMUW {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}
.Settings_headingSection__o-hZP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.Settings_toggle__eWN3U {
  display: flex;
  align-items: center;
}
.Settings_accountType__2LwQY {
    padding: 15px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
    cursor: pointer;
}
.Settings_accountType__2LwQY:hover {
    color: white;
    background-color: #ED1E24;
}
.Settings_accountType__2LwQY {
    padding: 15px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Settings_link__3-NAT {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.Settings_accountType__2LwQY:hover {
    color: white;
    background-color: #ED1E24;
}
.Settings_sectionHeading__3jPMm {
  font-size: 20px;
  font-weight: 700;
}
.Settings_subHeading__1GHeS {
  font-size: 16px;
}
.Settings_css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase__1Z_rg.Settings_Mui-checked__3G-JW {
    color: black !important;
}


/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
    .Settings_settingsArea__3NS42 {
        width: 55%;
        padding: 0 60px;
    }
    .Settings_rightImage__1OK1Y {
        width: 45%;
    }
  }
  /* ############################ Responsive 1420 ############################ */
  @media only screen and (max-width: 1420px) {
    .Settings_settingsArea__3NS42 {
        width: 60%;
        padding: 0 60px;
    }
    .Settings_rightImage__1OK1Y {
        width: 40%;
    }
  }
    /* ############################ Responsive 1250 ############################ */
    @media only screen and (max-width: 1250px) {

      }
  /* ############################ Responsive 1090 ############################ */
  @media only screen and (max-width: 1090px) {
    .Settings_fieldContainer__1V3uP {
        padding: 0 90px;
    }
    .Settings_settingsContainer__17DJA {
        height: 80vh;
        justify-content: center;
    }
    .Settings_rightImage__1OK1Y {
        display: none;
    }
    .Settings_settingsArea__3NS42 { 
        padding: 0 ;
    }
  }
    /* ############################ Responsive 800 ############################ */
    @media only screen and (max-width: 800px) {
        .Settings_headingSection__o-hZP {
            display: block;
        }
        .Settings_subHeading__1GHeS {
            font-size: 14px;margin-bottom: 10px;
        }
        .Settings_settingsArea__3NS42 {
            width: 80%;
        }
        .Settings_heading__ejMUW {
            font-size: 30px;
        }
        .Settings_details__3Tcq6 {
          font-size: 16px;
        }
        .Settings_subHeading__1GHeS {
          font-weight: 700;
        }
        .Settings_link__3-NAT {
          width: 600px;
        }
        .Settings_settingsContainer__17DJA {
          align-items: flex-start;
          margin-top: 30px;
      }
      }
  /* ############################ Responsive 550 ############################ */
  @media only screen and (max-width: 550px) {
    .Settings_settingsArea__3NS42 {
        width: 90%;
    }
  }
  

.notificationsPage_outerContainer__mqCwP {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  font-weight: 500;
}

.notificationsPage_notification__2FNiK {
  display: flex;
  align-items: center;
  justify-items: center;
}

.notificationsPage_notification__2FNiK h1 {
  margin: 0px;
  margin-left: 3px;
}

.notificationsPage_dividerLine__o8gSo {
  width: 90%;
  margin: auto;
  height: 0;
  border-top: 1px solid #e5e5e5;
}

.notificationsPage_notificationOuterDiv__9LZh8 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.Mobileburger_mobileBurger__tZrD4 {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.invitestudents_groupinviteButton__3eEiQ {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 26px;
  margin-top: 26px;
}
.invitestudents_groupinviteButton__3eEiQ:hover {
  background-color: #ED1E24;
  color: white;
}
.invitestudents_inviteContent__1FwX3 {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding: 6px;
  margin-left: 10px;
}
.invitestudents_invitedStudentSerialNum__1zlpG {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
}
.invitestudents_invitedStudent__3Auwm {
  text-align: left;
  font: italic normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.5;
}
.invitestudents_tableHeading__2OzFW {
  text-align: left;
  font: normal normal medium 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
}

.invitestudents_inviteHeading__3Ducx {
  text-align: left;
  font: normal normal medium 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 20px;
  padding-top: 30px;
}
.invitestudents_groupHeading__16UOX {
  text-align: left;
  font: normal normal medium 28px/34px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.invitestudents_listElement__38F7N {
  text-align: left;
  font: normal normal normal 16px/19px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  padding-bottom: 20px;
}
.invitestudents_inviteHr__tJD5d {
  border: none;
  border-left: 4px solid #ED1E24;
  margin-right: 8px;
}
.invitestudents_instructionsContainer__3giqc {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #5555551a;
  flex-direction: row;
  border-radius: 10px;
  height: 271px;
}
.invitestudents_greyContainer__1VKwW {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  height: 271px;
}
.invitestudents_inviteList__DYENO .invitestudents_listElement__38F7N::before {
  content: "\2022";
  color: #ED1E24;
  font-weight: bold;
  display: inline-block;
  width: 1.09em;
  margin-left: -1.085em;
}

.DownloadInviteeListModal_modalHeading__3qivp {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.DownloadInviteeListModal_comingSoon__1xz0s {
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-top: 60px;
  padding-left: 40px;
  padding-bottom: 30px;
  border-radius: 10px 10px 0px 0px;
  z-index: 20;
}
.DownloadInviteeListModal_groupinviteButton__1uGot {
  border: 1px #ED1E24 solid;
  background-color: white;
  border-radius: 2rem;
  width: 70%;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #ED1E24;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 26px;
  margin-top: 26px;
}
.DownloadInviteeListModal_groupinviteButton__1uGot:hover {
  background-color: #ED1E24;
  color: white;
}
.DownloadInviteeListModal_bottomContainer__2SGNm {
  padding-left: 40px;
  padding-bottom: 10px;
  padding-right: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.DownloadInviteeListModal_bottom_details__BtJv3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.DownloadInviteeListModal_bottom__29Gow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.DownloadInviteeListModal_outerContainer__3GpQb {
  background: #ffffff;
  border-radius: 10px;
  max-width: 537px;
  position: relative;
}

.DownloadInviteeListModal_close__3n5FJ {
  width: 21px;
  height: 21px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #171616;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.errorFourZeroFour_fourZeroFour__6PK2Q {
  text-align: center;
  line-height: 1.5;
  position: absolute;
  z-index: 100000000000;
  background-color: white;
  width: 100vw;
  height: 100%;
}
.errorFourZeroFour_fourZeroFourOops__1lSte {
  color: #ED1E24;
  font-size: 100px;
  font-weight: 600;
  margin: 0;
}

.errorFourZeroFour_fourZeroFourText__O56hd {
  font-size: 24px;
  font-weight: 600;
}

.errorFourZeroFour_fourZeroFourButton__2DoXM {
  padding: 20px 50px;
  border-radius: 30px;
  border: none;

  background-color: #ED1E24;
  cursor: pointer;
  color: white;
}

.errorFourZeroFour_fourZeroFourImage__1rFnf {
  height: 50vh;
  margin-top: 10px;
}

.subjectCard_container__33Xt9 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  width: 257px;
  height: 350px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.subjectCard_container__33Xt9:hover {
  cursor: pointer;
}
.subjectCard_imageContainer__M2_K2 {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  height: 140px;
  position: relative;
}

.subjectCard_imageContainer__M2_K2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none; /* Allows you to interact with the underlying content */
  border-radius: 10px 10px 0px 0px;
}
.subjectCard_textContainer__sieQR {
  padding: 0 20px;
}
.subjectCard_subjectImage__3YC7w {
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 1;
  object-fit: cover;
}
.subjectCard_subjectText__1rcmQ {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #555555;
  margin-bottom: 30px;
}
.subjectCard_gradeText__3TnCs {
  text-align: left;
  line-height: 19px;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0px;
  color: #555555;
  font-family: "Montserrat";
  opacity: 1;
  margin-bottom: 10px;
}
.subjectCard_nameText__16633 {
  text-align: left;
  line-height: 22px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  font-family: "Montserrat";
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.myClasses_app_body__cmHSh {
  display: flex;
  background-color: #ffffff;
  font-family: "Montserrat";
  margin-top: 50px;
}

.myClasses_classContainer__4umny {
  margin-bottom: 100px;
}

/* .topHeading {
  text-align: left;
  line-height: 34px;
  font-size: 28px;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 10px;
  border-left: 5px solid #ED1E24;
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
} */

.myClasses_plusSign__3UTBU {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 38px;
  height: 38px;
  margin-bottom: 20px;
  color: white;
}

.myClasses_sampleCardContainer__1JbFE {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #b4b4b4;
  display: flex;
  width: 257px;
  height: 350px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 10px;
  opacity: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.myClasses_sampleCardText__1jBKJ {
  text-align: center;
  line-height: 24px;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0px;
  color: #555555;
  text-transform: capitalize;
  opacity: 1;
}

.myClasses_sampleCardContainer__1JbFE:hover {
  cursor: pointer;
}

.myClasses_subjectCardsContainer__SjpPt {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

@media (max-width: 900px) {
  .myClasses_app_body__cmHSh {
    margin-top: 0px;
  }

  .myClasses_subjectCardsContainer__SjpPt {
    justify-content: center;
  }

  .myClasses_topHeading__3cb3- {
    text-align: center;
  }

  .myClasses_app_body__cmHSh {
    justify-content: center;
  }

  .myClasses_classContainer__4umny {
    width: 90%;
  }

  .myClasses_classContainer__4umny {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .myClasses_classContainer__4umny {
    margin-top: 20px;
  }
}
.Academics_academicsContainer__29yFI {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Academics_academicsHeading__30bF2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    cursor: pointer;
    padding: 0 15px 5px 15px;
}

.Academics_academicsHeadingOnClick__3lgYV {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0.0015em;
    color: #ED1E24;
    padding: 0 15px 5px 15px;
    border-bottom: 2px solid #ED1E24;
}

.Academics_hideAcademicMyClasses__2Vl4P {
    display: none;
}

.Academics_hideAcademicMyGroups__2qkjk {
    display: none;
}

.Academics_hideAcademicMyCourses__RMUjO {
    display: none;
}

.Academics_headWrapper__3eeIs{
    margin: 120px 0px 0px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.Academics_acBtn__2A4cI{
    margin-right: 50px;
}

@media screen and (max-width: 800px) {
    .Academics_headWrapper__3eeIs {
        margin: 20px 0px 0px 0px;
        justify-content: center;
    }
    .Academics_academicsContainer__29yFI{
        justify-content: center;
        grid-gap:10px;
        gap:10px;
    }
    .Academics_acBtn__2A4cI{
        margin-right: 0px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 500px) {
    .Academics_acBtn__2A4cI{
        display: none;
    }

    .Academics_academicsContainer__29yFI {
        grid-gap: 10px;
        gap: 10px;
        justify-content: center;
        margin: 0;
    }

    .Academics_headWrapper__3eeIs {
        margin-top: 20px;
        margin-left: 0px;
        justify-content: center;
    }

    .Academics_academicsHeading__30bF2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.0015em;
        border-bottom: 2px solid #000000;
    }

    .Academics_academicsHeadingOnClick__3lgYV {
        font-weight: 600;
        font-size: 14px;
        line-height: 36px;
        letter-spacing: 0.0015em;
        color: #ED1E24;
        padding: 0 15px 5px 15px;
    }
}
.MyCourses_myCoursesContainer__1JaTT {
    margin: 0px 0px 150px 100px;
    overflow-x: hidden;
}

.MyCourses_myCoursesTop__20VUr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.MyCourses_myCoursesTopHeading__3I_P8 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    border-left: 4.5px solid #ED1E24;
    margin-top: 30px;
    margin-bottom: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MyCourses_myCoursesTop__20VUr img {
    margin-right: 15px;
    display: none;
}

.MyCourses_myCoursesMobile__kL8BK {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.MyCourses_hideMyCoursesMobile__154Mr {
    display: none;
}

.MyCourses_mobileEnCourses__DYfex{
    display: none;
}

.MyCourses_myCoursesMobileHeading__3elcC {
    display: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    margin-bottom: 30px;
    border-left: 4.5px solid #ED1E24;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MyCourses_myCoursesMobile__kL8BK img {
    margin-right: 15px;
    margin-bottom: 30px;
    display: none;
}

.MyCourses_myCoursesSecondHeading__2J4qP {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 15px;
    border-left: 4.5px solid #ED1E24;
    margin-top: 30px;
    margin-bottom: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.MyCourses_myCoursesPlusSign__1Mkds {
    background: #ED1E24 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 38px;
    height: 38px;
    margin-bottom: 20px;
    color: white;
}

.MyCourses_CreateCardText__35VpL {
    text-align: center;
    line-height: 24px;
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: bold;
    letter-spacing: 0px;
    color: #555555;
    text-transform: capitalize;
    opacity: 1;
}

.MyCourses_CreateCardContainer__3xRkY {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px dashed #b4b4b4;
    display: flex;
    min-width: 257px;
    height: 365px;
    margin-bottom: 20px;
    margin-right: 30px;
    border-radius: 10px;
    opacity: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.MyCourses_CreateCardContainer__3xRkY:hover {
    cursor: pointer;
}

.MyCourses_CourseCardsVerticalScroll__21ekG {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.MyCourses_CourseCardsVerticalScrollMobile__adb-S {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 50px;
}

.MyCourses_CreatedCardsBox__3XULx {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.MyCourses_CourseCardsScrollMobile__1V-b7{
    display: none;
}

.MyCourses_CourseCardsHorizontalScroll__2R6Nh {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: scroll !important;
    margin-right: 30px;
}

.MyCourses_CourseCardsScrollMobileStudent__RxysM{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: scroll !important;
    margin-right: 30px;
    padding-top: 50px;
}

.MyCourses_CourseCardsHorizontalScroll__2R6Nh::-webkit-scrollbar {
    display: none;
}

.MyCourses_DisplayEnCourseDisplay__2KK-3{
    display: block;
}

.MyCourses_hideEnCourse__2Ina0{
    display: none;
}

@media screen and (max-width: 800px) {
    .MyCourses_myCoursesContainer__1JaTT {
        margin: 0px 0px 150px 50px;
    }
    .MyCourses_CourseCardsScrollMobileStudent__RxysM{
        padding-top: 20px;
        margin-right: 0px;
    }
}

@media screen and (max-width: 500px) {

    .MyCourses_myCoursesContainer__1JaTT {
        margin: 0px 0px 150px 10px;
    }

    .MyCourses_myCoursesTop__20VUr img {
        display: flex;
    }

    .MyCourses_myCoursesMobile__kL8BK img {
        display: flex;
    }

    .MyCourses_myCoursesMobileHeading__3elcC {
        display: flex;
    }

    .MyCourses_mobileEnCourses__DYfex{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .MyCourses_CreateCardContainer__3xRkY {
        display: none;
    }

    .MyCourses_CreatedCardsBox__3XULx {
        display: none;
    }

    .MyCourses_CourseCardsScrollMobile__1V-b7 {
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .MyCourses_CourseCardsScrollMobileStudent__RxysM{
        padding-top: 20px;
        display: flex; 
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 0px;
    }

    .MyCourses_myCoursesSecondHeading__2J4qP {
        display: none;
    }

    .MyCourses_CourseCardsVerticalScroll__21ekG {
        display: none;
    }

    .MyCourses_CourseCardsVerticalScrollMobile__adb-S {
        display: none;
    }
}
.MyCourseCard_myCourseCard__3XIIW {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 8px;
    min-width: 257px;
    max-width: 257px;
    height: 365px;
    margin: 0 15px 15px 0;
    cursor: pointer;
    position: relative;
}

.MyCourseCard_myCourseCard__3XIIW img {
    width: 100%;
    height: 160px;
    border-radius: 8px 8px 0 0;
}

.MyCourseCard_myCourseCardContent__dlWYW {
    margin: 15px;
}

.MyCourseCard_myCourseCardContent__dlWYW h6 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #A6A6A6;
    padding-bottom: 10px;
    text-transform: uppercase;
}

.MyCourseCard_myCourseCardContent__dlWYW h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
    padding-bottom: 10px;
}

.MyCourseCard_myCourseCardContent__dlWYW h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #ED1E24;
    padding-bottom: 10px;
}

.MyCourseCard_studentCount__2zEbW {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.MyCourseCard_studentCount__2zEbW img {
    height: 20px;
    width: 20px;
    padding-right: 10px;
}

.MyCourseCard_classTagPara__1rFM- {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-transform: uppercase;
    color: #ED1E24;
    padding: 10px 0 0 10px;
}

.MyCourseCard_progress_icon__1lwTg > img{
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
    z-index: 2;
}

.MyCourseCard_cardImage__36yfW{
    width: 100%;
}

.MyCourseCard_cardImage__36yfW::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px 10px 0px 0px;
}

.MyCourseCard_draft__393sg {
    text-transform: uppercase;
    border: 1px solid #f80300;
    background-color: #FF6666;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: .875rem;
    font-weight: bold;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* letter-spacing: 0.25rem; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: 'Courier New', Courier, monospace;
  }

.MyCourseCard_published__1VRNR {
    text-transform: uppercase;
    border: 1px solid #40A578;
    background-color: #9DDE8B;
    color: #ffffff;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: .875rem;
    font-weight: bold;
    margin-left: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    letter-spacing: 0.25rem;
    font-family: 'Courier New', Courier, monospace;
}
.PublicCourses_CoursesContainer__15DqB {
    margin: 100px 0px 100px 50px;
    overflow-x: hidden;
}


.PublicCourses_loader_style__1lx5f{
    text-align: center;
     margin-top: 200px
  }

.PublicCourses_CourseHeadingContainer__3S8uf {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px 20px 10px;
}

.PublicCourses_CourseHeading__1w2CG {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin-left: 15px;
}

.PublicCourses_CourseCardsVerticalScroll__2N0Tk {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
    .PublicCourses_CoursesContainer__15DqB {
        margin: 0px 0px 100px 50px;
    }
}

@media screen and (max-width: 500px) {
    .PublicCourses_CoursesContainer__15DqB {
        margin: 0px 0px 100px 0px;
    }
}
.PublicCourseCard_publicCourseCardContainer__3bEel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    height: 460px;
    width: 340px;
    grid-gap: 16px;
    gap: 16px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    cursor: pointer;
}

.PublicCourseCard_imageContainer__1QR2- {
    background: #ED1E24 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    height: 200px;
    width: 100%;
    position: relative;
}

.PublicCourseCard_imageContainer__1QR2-::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px 10px 0px 0px;
}

.PublicCourseCard_courseImage__21uAd {
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}

.PublicCourseCard_description__2sKAF {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

#PublicCourseCard_courseTitle__3Obh0 {
    margin-bottom: 10px;
}

#PublicCourseCard_instructor__2UvYs {
    margin-top: 5px;
}

#PublicCourseCard_descCont__2vPFj {
    height: 60px;
    overflow: hidden;
}

.PublicCourseCard_priceAndEnrollContainer__L7qO0 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    width: 300px;
}

.PublicCourseCard_publicCoursePrice__17An3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ED1E24;
}

.PublicCourseCard_publicCourseButton__Ej5Hm {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10.949px;
    line-height: 13px;
    background-color: #ED1E24;
    color: #FFFFFF;
    border-radius: 60px;
    padding: 14px 49px;
    cursor: pointer;
    border: none;
}

.PublicCourseCard_smallContanier__3e7c2{
    padding: 0px 20px 10px 20px;
}
.DateTimePicker_date_field__33jMT {
  border-radius: 25px !important;
  margin-right: 20px !important;
  height: 40px !important;
  outline: none !important;
  border: 1px #c2c2c2 solid !important;
  /* padding-left: 20px !important; */
  box-sizing: border-box !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Montserrat;
  font-size: 13px;
}

@media (max-width: 780px) {
  .DateTimePicker_date_field__33jMT {
    margin-right: 0px !important;

  }
}

.LinkModal_input_link__OgnBL{
    border: 1px solid #5555555A;
    padding: 10px;
    font-size: 15px;
    border-radius: 50px;
    width: 90%;
}

.LinkModal_button_div__1T5p9{
    margin: 15px;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}
.ViewAssignmentPage_main_container__3Uuz1 {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.ViewAssignmentPage_back_btn__3TZsK {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.ViewAssignmentPage_threeDotsMenu__382OK {
  height: 40px;
  cursor: pointer;
}

.ViewAssignmentPage_feed_container__27YEK {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px; /*135vh*/
  padding-top: 12px;
}

.ViewAssignmentPage_title_container__1qn66 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ViewAssignmentPage_dueDate__Z2qft {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 10%;
  float: right;
}

.ViewAssignmentPage_dropDown__1zIWt ul {
  list-style: none;
  padding: 1px 0px;
}

.ViewAssignmentPage_dropDown__1zIWt img {
  height: 40px;
}
.ViewAssignmentPage_duedate_component__39zvT {
  margin-right: 10px;
  margin-top: 10px;
}

.ViewAssignmentPage_submissions_container__24LbF {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  margin: 15px auto;
  margin-bottom: 0%;
  width: 100%;
  /* for content */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* width: 135vh; */
}

.ViewAssignmentPage_choice__39IHD {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: Montserrat;
  /* width: 42%; */
}
.ViewAssignmentPage_choice__39IHD > .ViewAssignmentPage_status__1yYNg {
  margin: 0 15px;
}
.ViewAssignmentPage_status__1yYNg {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  cursor: pointer;
}

.ViewAssignmentPage_count__3GhT9 {
  font-size: 2rem;
}

.ViewAssignmentPage_options__2d2jC {
  font-size: 0.9rem;
  color: #7b7b7b;
}

/* Attachment Container */

.ViewAssignmentPage_assignment_details_container__3M2Bo {
  margin-top: 30px;
  padding: 15px 0;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  min-height: 150px;
  width: 100%;
  border-radius: 10px;
}

.ViewAssignmentPage_attachment_container_div__1jQoo {
  /* margin-top: 15px; */
  margin: 15px 25px 0 25px;
}

.ViewAssignmentPage_assignment_details_description__nvmuY {
  margin: 10px 25px;
  color: #707070;
  font-size: 14px;
}

.ViewAssignmentPage_std_work_btn__1-cJ_ {
  margin-right: 10px;
}
.ViewAssignmentPage_duedate_component_mobile__1ypCg {
  display: none;
}
.ViewAssignmentPage_dropdown_mobile__29qJR {
  display: none;
}

@media (min-width: 800px) and (max-width: 1018px) {
  .ViewAssignmentPage_main_container__3Uuz1 {
    margin-top: 94px !important;
    /* margin-bottom: 90px !important; */
  }
}
@media  (max-width: 420px) {
  .ViewAssignmentPage_options__2d2jC {
    font-size: 0.75rem;
    width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;   
  }
  .ViewAssignmentPage_status__1yYNg{
    max-width: 20%;
  }
  .ViewAssignmentPage_count__3GhT9 {
    font-size: 1.75rem;
  }
}

@media (max-width: 1018px) {
  .ViewAssignmentPage_feed_container__27YEK {
    width: 90%;
  }
  .ViewAssignmentPage_duedate_component__39zvT {
    display: none;
  }
  .ViewAssignmentPage_main_container__3Uuz1 {
    margin-top: 0;
  }
  .ViewAssignmentPage_duedate_component_mobile__1ypCg {
    display: block;
    margin-top: 15px;
  }
  .ViewAssignmentPage_title_container__1qn66 {
    flex-direction: column;
    align-items: flex-start;
  }
  .ViewAssignmentPage_dropdown__Wfywe {
    display: none;
  }
  .ViewAssignmentPage_dropdown_mobile__29qJR {
    display: block;
    margin-right: 10px;
  }
  .ViewAssignmentPage_submissions_container__24LbF {
    flex-direction: column;
    align-items: flex-start;
  }
  .ViewAssignmentPage_std_work_btn__1-cJ_ {
    margin-left: auto;
    margin-top: 25px;
  }
  .ViewAssignmentPage_choice__39IHD > .ViewAssignmentPage_status__1yYNg {
    margin: 0 10px;
  }
  .ViewAssignmentPage_choice_menu__1A9-z {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ViewAssignmentPage_assignment_details_container__3M2Bo {
    margin-bottom: 90px;
  }
  .ViewAssignmentPage_back_btn__3TZsK {
    display: none;
  }
  .ViewAssignmentPage_attachment_container_div__1jQoo{
    margin: 15px 15px 0 15px;
  }
  .ViewAssignmentPage_assignment_details_description__nvmuY {
    margin: 10px 15px;
  }
}

@media (min-width: 1018px) and (max-width: 1090px) {
  .ViewAssignmentPage_feed_container__27YEK {
    width: 90%;
  }
}

.ViewAssignmentPage_loader_style__3c_Jm{
  text-align: center;
   margin-top: 200px
}

@media (max-width: 580px){
  .ViewAssignmentPage_feed_container__27YEK {
    width: 95%;
  }
  .ViewAssignmentPage_count__3GhT9 {
    font-size: 20px;
    font-weight: 500;
  }
  .ViewAssignmentPage_choice__39IHD{
    width: 70%;
  }
  .ViewAssignmentPage_threeDotsMenu__382OK {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
  .ViewAssignmentPage_assignment_details_container__3M2Bo {
   min-height: 0px;
   margin-top:24px;
  }
  .ViewAssignmentPage_assignment_details_description__nvmuY {
    margin: 0px 10px;
  }
}
.AssignmentListIndividual_assignment_individual_list_container__b5Edo {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 4fr 1fr;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  width: 95%;
  cursor: pointer;
}
.AssignmentListIndividual_assignment_individual_list_container_tchr__3zAfs {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 3fr 1fr 1fr 1fr;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  width: 95%;
  cursor: pointer;
}

/* .assignment_status_div{
    width: 100px;
} */

.AssignmentListIndividual_assignment_status_div_tchr__1wBFT {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.AssignmentListIndividual_assignment_status__1JLPa {
  color: #7b7b7b;
}

.AssignmentListIndividual_assignment_due_date__372Kq {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 700px) {
  .AssignmentListIndividual_assignment_individual_list_container__b5Edo {
    grid-template-columns: 2fr 2fr 1fr;
    padding: 16px 10px;
  }
  .AssignmentListIndividual_assignment_individual_list_container_tchr__3zAfs {
    grid-template-columns: 3fr 2fr;
    padding: 15px 7px;
  }
  .AssignmentListIndividual_assignment_status_div_tchr__1wBFT {
    display: none;
  }
  .AssignmentListIndividual_assignment_due_date__372Kq {
    color: #555;
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .AssignmentListIndividual_assignment_status__1JLPa {
    color: #7b7b7b;
    font-size: 13px;
    font-weight: 500;
  }
}


.ViewAssignmentStatus_main_container__iB_VP {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.ViewAssignmentStatus_back_btn__117Bj {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.ViewAssignmentStatus_feed_container__3qqLT {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px;
  padding-top: 12px;
}

.ViewAssignmentStatus_title_container__1Z2Ve {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ViewAssignmentStatus_dueDate__34oWX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
}

.ViewAssignmentStatus_dropDown__Lsj6V ul {
  list-style: none;
  padding: 1px 0px;
}

.ViewAssignmentStatus_submissions_container__1O_lg {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0;
  margin: 15px auto;
  margin-bottom: 0%;
  width: 130vh;
  /* for content */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ViewAssignmentStatus_choice__2G9fe {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: Montserrat;
}

.ViewAssignmentStatus_choice__2G9fe > .ViewAssignmentStatus_status__NSegD {
  margin: 0 15px;
}

.ViewAssignmentStatus_status__NSegD {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  cursor: pointer;
}

.ViewAssignmentStatus_count__Y5AwM {
  font-size: 2rem;
}

.ViewAssignmentStatus_options__1p4pp {
  font-size: 0.9rem;
  color: #7b7b7b;
}

.ViewAssignmentStatus_bottom_container__15dKf {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.ViewAssignmentStatus_student_list_container__154yj {
  /* box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px; */
  /* padding: 0px 10px; */
  margin: 10px auto;
  /* margin-top: 15px; */
  width: 100%;
  font-family: Montserrat;
  margin-top: 0;
  max-height: 290px;
  /* overflow-y: scroll; */
  min-height: 210px;
}

.ViewAssignmentStatus_inner_list_container__33_nK{
  max-height: 220px;
  min-height: 220px;
  overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.ViewAssignmentStatus_inner_list_container__33_nK::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.ViewAssignmentStatus_return_btn__fMMIw {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
}

.ViewAssignmentStatus_student_list_header__3iCI7 {
  display: flex;
  /* justify-content: space-between; */
  color: #858585;
  font-family: Montserrat;
  font-weight: 400;

  border-bottom: 0.5px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  /* width: 95%; */
}

.ViewAssignmentStatus_student_list_header_quiz__1qEER {
  display: flex;
  justify-content: space-between;
  color: #858585;
  font-family: Montserrat;
  font-weight: 400;

  border-bottom: 0.5px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  /* width: 95%; */
}

.ViewAssignmentStatus_student_list_heading__onm8G {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewAssignmentStatus_student_list_date__1vPOm {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewAssignmentStatus_student_list_rmk__ZoQ1q {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewAssignmentStatus_student_list_grade__2Ea7K {
  width: 23%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ViewAssignmentStatus_student_list_assign__PMX1b {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ViewAssignmentStatus_checkbox_round__2Sks0 {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #767676;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.ViewAssignmentStatus_checkbox_round__2Sks0:checked {
  background-color: #ED1E24;
  border: 1px solid #ED1E24;
}

.ViewAssignmentStatus_unassign_dropdown__3hVMz {
  /* height: 50px; */
  cursor: pointer;
  margin-left: 10px;
}

/* Not used */
.ViewAssignmentStatus_assignment_list__179FX {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  margin-top: 15px;
  max-height: 400px;
  overflow-y: scroll;
}
.ViewAssignmentStatus_student_list_combo__wqzlU {
  display: flex;
  width: 100%;
}
.ViewAssignmentStatus_view_ass_btn_span_mobile__2UzYC {
  display: none;
}
.ViewAssignmentStatus_view_ass_btn_span__11OWW {
  margin-right: 10px;
}
.ViewAssignmentStatus_no_std_msg__2J8Zm {
  height: 210px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #7b7b7b;
  font-size: 14px;
  justify-content: center;
}
@media (min-width: 800px) and (max-width: 1018px) {
  .ViewAssignmentStatus_main_container__iB_VP {
    margin-top: 94px !important;
  }
}

@media (max-width: 1018px) {
  .ViewAssignmentStatus_feed_container__3qqLT {
    width: 90%;
  }
  .ViewAssignmentStatus_main_container__iB_VP {
    margin-top: 0;
  }
  .ViewAssignmentStatus_student_list_header__3iCI7 {
    display: none;
  }
  .ViewAssignmentStatus_student_list_header_quiz__1qEER {
    display: none;
  }
  .ViewAssignmentStatus_title_container__1Z2Ve {
    flex-direction: column;
    align-items: flex-start;
  }
  .ViewAssignmentStatus_dueDate__34oWX {
    margin-top: 15px;
  }
  .ViewAssignmentStatus_view_ass_btn_span__11OWW {
    display: none;
  }
  .ViewAssignmentStatus_choice__2G9fe > .ViewAssignmentStatus_status__NSegD {
    margin: 0 6px;
  }
  .ViewAssignmentStatus_view_ass_btn_span_mobile__2UzYC {
    display: inline-block;
  }
  .ViewAssignmentStatus_submissions_container__1O_lg > div {
    margin: 0 10px;
  }
  .ViewAssignmentStatus_back_btn__117Bj{
    display: none;
  }
}
@media (max-width: 1099px) {
  .ViewAssignmentStatus_bottom_container__15dKf {
    margin-bottom: 85px;
  }
}

@media (max-width: 800px) {
  .ViewAssignmentStatus_student_list_container__154yj {
    max-height: none;
    overflow-y: auto;
  }
}

@media (max-width: 800px) {
  .ViewAssignmentStatus_inner_list_container__33_nK {
    max-height: none;
    overflow-y: auto;
  }
}

@media (max-width: 420px) {
  .ViewAssignmentStatus_status__NSegD{
    max-width: 20%;
  }
  .ViewAssignmentStatus_count__Y5AwM {
    font-size: 1.75rem;
  }
  .ViewAssignmentStatus_options__1p4pp {
    font-size: 0.75rem;
    overflow: hidden;
    width:99%;
    white-space: nowrap;
    text-overflow: ellipsis;   
  }
  .ViewAssignmentStatus_choice__2G9fe{
    margin-left:auto;
    max-width: 68%;
  }
}

@media (max-width: 338px) {
  .ViewAssignmentStatus_view_ass_btn_span_mobile__2UzYC{
    display: none;
  }
}

.ViewAssignmentStatus_loader_style__24KXH{
  text-align: center;
   margin-top: 200px
}


.StudentAssignmentSubmissionList_assignment_submission_list_container__1aECp {
  display: flex;
  /* justify-content: space-between; */
  border: 1px solid #e5e5e5;
  padding: 13px 15px;
  align-items: center;
  font-family: Montserrat;
}

.StudentAssignmentSubmissionList_assignment_status_div__k8z7B {
  width: 45%;
  display: flex;
  justify-content: center;
}
.StudentAssignmentSubmissionList_quiz_status_div__177u7{
  width: 44%;
  display: flex;
  justify-content: center;
}
.StudentAssignmentSubmissionList_assignment_grade_div__10XK2 {
  width: 34%;
  display: flex;
  justify-content: center;

}

.StudentAssignmentSubmissionList_assignment_status__3AKGM {
  /* color: #7b7b7b; */
  color: black;
  font-size: 15px;
}

.StudentAssignmentSubmissionList_assignment_marks__2eexV {
  /* text-decoration: underline; */
  /* color: #7b7b7b; */
  color: black;
  white-space: pre;
  /* padding-bottom: 0.25px; */
  border-bottom: 1px solid #A9A9A9;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.StudentAssignmentSubmissionList_assignment_due_date__1uJgL {
  color: black;
  font-size: 14px;
  font-weight: 500;
  width: 25%;
  display: flex;
  justify-content: center;
}
.StudentAssignmentSubmissionList_column_width__3Eq07 {
  width: 35%;
}
.StudentAssignmentSubmissionList_three_column__1I11H {
  display: flex;
  width: 100%;
}
.StudentAssignmentSubmissionList_three_column_assignment__L36sh {
  display: flex;
  width: 100%;
}
.StudentAssignmentSubmissionList_checkbox_round__2FOly {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #767676;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.StudentAssignmentSubmissionList_checkbox_round__2FOly:checked {
  background-color: #ED1E24;
  border: 1px solid #ED1E24;
}
.StudentAssignmentSubmissionList_checkbox_round__2FOly:disabled {
  background-color: grey;
  border: 1px solid grey;
}
.StudentAssignmentSubmissionList_full_list__yvBVW {
  display: flex;
  align-items: center;
  width: 100%;
}

.StudentAssignmentSubmissionList_action_div__17OOO{
  display: flex;
  justify-content: center;
  align-items: center;
  width:12%;
}

.StudentAssignmentSubmissionList_assign_text__3AH2d{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ED1E24;
  cursor: pointer;
}



@media (max-width: 800px) {
  .StudentAssignmentSubmissionList_three_column__1I11H {
    flex-direction: column;
    width: 50%;
    /* justify-content: space-between; */
  }
  /* .full_list{
    flex-direction: column;
    align-items: center;
  } */
  .StudentAssignmentSubmissionList_three_column__1I11H > div {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
  }
   .StudentAssignmentSubmissionList_column_width__3Eq07 {
    width: 50%;
  }
}

@media (max-width: 800px) {
  .StudentAssignmentSubmissionList_three_column_assignment__L36sh {
    flex-direction: column;
    width: 60%;
  }
  
  .StudentAssignmentSubmissionList_three_column_assignment__L36sh > div {
    width: 100%;
    margin-bottom: 5px;
    margin-left: 5px;
  }  

   .StudentAssignmentSubmissionList_column_width__3Eq07 {
    width: 50%;
  }
}

@media (max-width: 580px){
  .StudentAssignmentSubmissionList_assignment_status__3AKGM {
    color: #555;
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .StudentAssignmentSubmissionList_assignment_marks__2eexV {
    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .StudentAssignmentSubmissionList_assign_text__3AH2d{
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    color: #ED1E24;
    cursor: pointer;
  }
  .StudentAssignmentSubmissionList_assignment_submission_list_container__1aECp {
    padding: 18px 15px;
  }
}



.StudentNameComponent_name_container___VDK_ {
  display: flex;
  align-items: center;
}

.StudentNameComponent_student_name__2Cq8P {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-right: 10px;
}

.StudentNameComponent_avatar__U85vT {
  margin-right: 18px; 
}
/* 
@media (max-width: 800px) {
  .name_container {
    margin-bottom: 20px;
  }  
} */

@media (max-width: 600px) {
 .StudentNameComponent_name_container___VDK_, .StudentNameComponent_student_name__2Cq8P, .StudentNameComponent_avatar__U85vT{
    display:flex;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
    white-space: nowrap;
  }
}
.ViewAssignmentGradingPage_main_container__2oSQO {
  /* height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 90px; */
  /*  */
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.ViewAssignmentGradingPage_back_btn__1UhiI {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.ViewAssignmentGradingPage_center_page_container__3hRF0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px; /*140vh*/
  padding-top: 10px;
}

.ViewAssignmentGradingPage_title_container__18zXr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ViewAssignmentGradingPage_student_header_container__2w-sF {
  display: flex;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-radius: 10px;
  margin-top: 30px;
  width: 100%;
}

.ViewAssignmentGradingPage_student_name__1DtND {
  margin-left: 20px;
}

.ViewAssignmentGradingPage_left_arrow__rYzO8 {
  width: 12px;
  height: 24px;
  margin-right: 22px;
}

.ViewAssignmentGradingPage_right_arrow__2hjHn {
  width: 12px;
  height: 24px;
  margin-left: 22px;
}

.ViewAssignmentGradingPage_student_name_container__DwUy4 {
  display: flex;
  align-items: center;
}

.ViewAssignmentGradingPage_submission_date__1_DbI {
  font-weight: 400;
  color: #858585;
}

.ViewAssignmentGradingPage_assignment_details_container__Ubw7x {
  /* display: flex; */
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 200px;
  width: 100%;
}

.ViewAssignmentGradingPage_assignment_status__2oTz6 {
  display: flex;
  justify-content: space-between;
  padding: 35px;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}

.ViewAssignmentGradingPage_assignment_right_and_left__2I_N0 {
  display: flex;
  width: 100%;
}

.ViewAssignmentGradingPage_assignment_details_left_container__3cOye {
  /* margin-top: 30px; */
  padding: 15px;
  /* box-shadow: 0px 0px 20px 0px #3f3f411a; */
  flex-grow: 2;
  /* margin-right: 30px; */
  border-right: 1px solid #e5e5e5;
}

.ViewAssignmentGradingPage_assignment_details_right_container__3Y7Zc {
  /* margin-top: 30px; */
  padding: 0px 40px;
}

.ViewAssignmentGradingPage_attachment_container_div__1Lr-A {
  margin-top: 15px;
}

.ViewAssignmentGradingPage_work_div__2_tGX {
  padding: 15px 0;
  /* box-shadow: 0px 0px 20px 0px #3f3f411a; */
}

.ViewAssignmentGradingPage_grade_container__18Tqt {
  margin: 20px 0;
}

.ViewAssignmentGradingPage_grade_and_save__289Ng {
  display: flex;
  justify-content: space-evenly;
}
/* 
.grade_input {
  width: 55px;
  padding: 0px 10px;
} */

.ViewAssignmentGradingPage_grade_input_container__2Touh {
  border-radius: 30px;
  border: 1px solid #a9a9a9;
  padding: 0 30px;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.ViewAssignmentGradingPage_grade_input__1FcIC {
  width: 35px;
  outline: none;
  font-size: 16px;
  text-align: right;
  font-weight: 400;
  border-bottom: 1px solid #a9a9a9;
  margin-right: 3px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ViewAssignmentGradingPage_total_marks__QPnXA {
  margin-right: 40px;
}

.ViewAssignmentGradingPage_private_comment_input__1WuLb {
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #a9a9a9;
  padding: 10px;
  outline: none;
  width: 92%;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
}

.ViewAssignmentGradingPage_assignment_add_button__17yCv {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.ViewAssignmentGradingPage_assignment_save_button__v7BUM {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.ViewAssignmentGradingPage_max_marks_text__blxIw {
  margin-left: 5px;
  font-size: 16px;
}
.ViewAssignmentGradingPage_return_btn__22Dm4 {
  margin-right: 20px;
}
.ViewAssignmentGradingPage_left_arrow_name___QnBx {
  display: flex;
  align-items: center;
  min-width: 200px;
}
.ViewAssignmentGradingPage_comment_heading__1-A1p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
  margin-bottom: 12px;
}
.ViewAssignmentGradingPage_comment_container__3XNFB {
  margin: 6px;
  margin-top: 35px;
}
.ViewAssignmentGradingPage_comment_container_mobile__2no3R {
  display: none;
}

@media (min-width: 800px) and (max-width: 1018px) {
  .ViewAssignmentGradingPage_main_container__2oSQO {
    margin-top: 94px !important;
  }
}

@media (max-width: 1018px) {
  .ViewAssignmentGradingPage_center_page_container__3hRF0 {
    width: 95%;
  }
  .ViewAssignmentGradingPage_main_container__2oSQO {
    margin-top: 0;
  }
  .ViewAssignmentGradingPage_assignment_status__2oTz6 {
    flex-direction: column;
    align-items: flex-start;
  }
  .ViewAssignmentGradingPage_assignment_status__2oTz6 > span {
    margin-bottom: 10px;
  }
  .ViewAssignmentGradingPage_student_header_container__2w-sF {
    flex-direction: column;
  }
  .ViewAssignmentGradingPage_student_header_container__2w-sF > .ViewAssignmentGradingPage_student_name__1DtND {
    width: 100%;
    margin: 0;
  }
  .ViewAssignmentGradingPage_student_name_container__DwUy4 {
    justify-content: space-between;
    margin: 0 10px 10px 10px;
  }
  .ViewAssignmentGradingPage_student_header_container__2w-sF > .ViewAssignmentGradingPage_return_btn__22Dm4 {
    display: flex;
    align-self: flex-end;
    margin-right: 10px;
  }
  .ViewAssignmentGradingPage_assignment_right_and_left__2I_N0 {
    flex-direction: column;
  }
  .ViewAssignmentGradingPage_assignment_details_right_container__3Y7Zc {
    /* margin-top: 30px; */
    padding: 12px;
  }
  .ViewAssignmentGradingPage_grade_input_container__2Touh {
    width: 100%;
  }
  .ViewAssignmentGradingPage_comment_container__3XNFB {
    display: none;
  }
  .ViewAssignmentGradingPage_comment_container_mobile__2no3R {
    display: block;
    margin: 6px;
  }
  .ViewAssignmentGradingPage_back_btn__1UhiI{
    display: none;
  }
}

.IndividualComment_individual_comment_div__1yPjr {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.IndividualComment_individual_comment_div__1yPjr img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
}

.IndividualComment_indiviudal_comment_content__3kCHY {
  background-color: #f9f9f9;
  border-radius: 10px;
  flex: 1 1;
  padding: 10px;
}

.IndividualComment_indiviudal_comment_content__3kCHY p {
  font-size: 13px;
  margin-top: 5px;
}

.IndividualComment_indiviudal_comment_content_flex__1aLwp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.IndividualComment_indiviudal_comment_content_flex__1aLwp p {
  font-size: 13px;
}

*{
  font-family: 'Montserrat', 'sans-serif';
}

.StudentAssignmentDetailsPage_main_container__25Zst{
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
.StudentAssignmentDetailsPage_back_btn__1fp6D{
    color: #858585;
    font-weight: 400;
    padding: 20px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 90px;
}
.StudentAssignmentDetailsPage_title_container__v1psB{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 140vh; */
}

.StudentAssignmentDetailsPage_assignment_details_container__xlAPY{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 1fr;
}

.StudentAssignmentDetailsPage_assignment_details_left_container__qiOfV{
    margin-top: 30px;
    padding: 15px;
    box-shadow: 0px 0px 20px 0px #3F3F411A;
    /* flex-grow: 2; */
    margin-right: 30px;
    border-radius: 10px;
}

.StudentAssignmentDetailsPage_assignment_details_right_container__2-WVW{
    margin-top: 30px;
}

.StudentAssignmentDetailsPage_attachment_container_div__1ivTA{
    margin-top: 15px;
}

.StudentAssignmentDetailsPage_assignment_details_description__3LMbZ{
    width: 95%;
    margin: auto;
    color: #707070;
    font-size: 14px;
}

.StudentAssignmentDetailsPage_work_div__1OgqI{
    padding: 15px;
    box-shadow: 0px 0px 20px 0px #3F3F411A;
    border-radius: 10px;
}

.StudentAssignmentDetailsPage_assignment_submission_buttons__1f-cg{
    width: 90%;
    margin: auto;
    margin-top: 15px;
    margin-right: 10px;
}

.StudentAssignmentDetailsPage_grade_container__WqOOy{
    margin: 20px 0;
}
.StudentAssignmentDetailsPage_grade_div__3SAsZ{
    border-radius: 20px;
    border: 1px solid #A9A9A9;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
}
.StudentAssignmentDetailsPage_private_comment_input__3HbsU{
    margin-top: 20px;
    border-radius: 20px;
    border: 1px solid #a9a9a9;
    padding: 10px;
    width: 90%;
}

.StudentAssignmentDetailsPage_comment_container__2TeQ_{
    margin: 6px;
}

.StudentAssignmentDetailsPage_comment_heading__1cVG- {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #000000;
    margin-bottom: 12px;
  }


.StudentAssignmentDetailsPage_assignment_add_button__23XVy{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.StudentAssignmentDetailsPage_drop_down_outer__1yend{
    position: relative;
}

.StudentAssignmentDetailsPage_drop_down__1Aiwq{
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 99;
}

.StudentAssignmentDetailsPage_yourWorkHead__32zmv{
    margin-bottom: 10px;
}

.StudentAssignmentDetailsPage_margin_bottom__3RFMR{
    margin-bottom: 10px;
}

.StudentAssignmentDetailsPage_sub_heading__yFlUp{
    font-size: 14px;
}

@media (max-width: 800px) {
    .StudentAssignmentDetailsPage_assignment_details_container__xlAPY{
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
    .StudentAssignmentDetailsPage_button_contain_div__L3S10{
        display: flex;
    }
    .StudentAssignmentDetailsPage_main_container__25Zst{
        margin: auto;
        margin-top: 10px;
        width: 95%;

    }
    .StudentAssignmentDetailsPage_assignment_details_left_container__qiOfV{
        margin-right: 0;
    }
    .StudentAssignmentDetailsPage_grade_div__3SAsZ{
        width: 70%;
    }
    .StudentAssignmentDetailsPage_private_comment_div__3HWjW{
        margin-bottom: 90px;
        box-shadow: none ;
        padding: 0 ;
    }
    .StudentAssignmentDetailsPage_back_btn__1fp6D{
        display: none;
    }
}

.StudentAssignmentDetailsPage_loader_style__2gy2F{
    text-align: center;
     margin-top: 200px
}
.FileTypeDropDown_drop_down_menu__3R78X{
    padding: 10px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px #3F3F411A;

}

.FileTypeDropDown_drop_down_item__2kWud{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    cursor: pointer;
}

.FileTypeDropDown_drop_down_item__2kWud:hover {
    background-color: #ffcfd0;
}

.FileTypeDropDown_drop_down_item_icon__2qBej{
    width: 1.5rem;

}

.FileTypeDropDown_drop_down_item_name__1Lnnd{
    margin-left: 10px;
    font-size: 12px;
}

.FileTypeDropDown_fileUpload__1xSNq{
    opacity: 0;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  
    /* IE 5-7 */
    filter: alpha(opacity=0);
  
    /* Netscape or and older firefox browsers */
    -moz-opacity: 0;
  
    /* older Safari browsers */
    -khtml-opacity: 0;
  
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.FileTypeDropDown_drop_down_item__2kWud input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
.StudentAssignmentList_main_container__1FNFk {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 90px;
}
.StudentAssignmentList_back_btn__2fDpL {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.StudentAssignmentList_title_container__2mjvd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* width: 96%; */
  margin: auto;
  /* width: 140vh; */
}

.StudentAssignmentList_assignment_list__3sBEO {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  margin-top: 15px;
  max-height: 400px;
  overflow-y: scroll;
  width: 96%;
  margin: auto;
  /* margin-bottom: 85px; */
}

.StudentAssignmentList_assignment_class_container__dZAwv {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #3f3f411a;
  padding: 20px 10px;
  margin: auto;
  margin-bottom: 30px;
  width: 94%;
}

.StudentAssignmentList_assignment_class_name__3BDnr {
  border-radius: 20px;
  border: 1px solid #858585;
  padding: 10px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.StudentAssignmentList_assignment_class_head__z5YZ0 {
  color: #858585;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  .StudentAssignmentList_main_container__1FNFk {
    margin-top: 0;
  }
  .StudentAssignmentList_assignment_class_container__dZAwv {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .StudentAssignmentList_assignment_list__3sBEO {
    max-height: 100%;
    margin-bottom: 77px;
  }
}

.StudentAssignmentList_loader_style__3BqPz{
  text-align: center;
   margin-top: 200px
}

.OpenAsgFromMail_loader_style__389Nu{
  text-align: center;
   margin-top: 200px
}
.CourseView_CourseView__cTTo4{
    margin: auto;
    margin-top:90px;
    width: 82vw;
    
    /* padding-left:80px;
    padding-right:80px; */
}
.CourseView_banner__1x634{
    max-width: 100%;
    width:100%;
    background-repeat: no-repeat;
    background-size:contain;
    position: relative;
}
.CourseView_banner__1x634::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98.5%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px;
}

.CourseView_banner__1x634 img{
   width:100%; 
   border-radius: 8px;
   height:33vh;
}

.CourseView_CourseDetails__2-nO6{
    padding-top:12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CourseView_CourseDetailsLeft__3REAr{
    width: 75%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 16px;
    padding:24px;
    margin-bottom: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px solid black; */

}
.CourseView_CourseDetailsRight__1Kl8w{
    display: flex;
    flex-direction: column;
    width:25%;
    margin-left:8px;
}

/* -------- */

.CourseView_course_top_section__2eGuw {
    display: flex;
    justify-content: space-between;
    grid-gap: 0.5rem;
    gap: 0.5rem
}

.CourseView_top_section_right__3SYTP {
    width: 30%;
}

/* ------- */
.CourseView_loader__2cGbj{
    width: 100%;
    margin-top:20px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.CourseView_BuyingDetail__JCqfV{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background:white;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    border-radius: 16px;
}
.CourseView_caption__XqXk_{
    font-family: Montserrat;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-top:12px;
    padding-bottom:12px;
}

.CourseView_descTitle__U2gpm{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;    
    padding-bottom:12px;
}
.CourseView_desc__rJWqM{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom:12px;
}

.CourseView_exploration__dAaPF{
    padding-top:24px;
    padding-left: 8px;

}

.CourseView_exploreCards__2bYcr{
    padding-top:24px;
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
}

.CourseView_card__3TH3D{
    height: 300px;
    width: calc(100% / 5);
    border-radius: 10px;
    background:rgb(207, 207, 241);
}
.CourseView_enrolled__1DBb7{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:8px;
    /* padding-top:8px; */
    background:white;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    border-radius: 16px;
    cursor: pointer;
}

.CourseView_enrolled__1DBb7:hover {
    background-color: #ED1E24;
}

.CourseView_enrolled__1DBb7 p{
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #ED1E24;
}

.CourseView_enrolled__1DBb7:hover p {
    color: #FFFFFF;    
}

.CourseView_link__2nY1c {
    text-decoration: none;
}

/* Course attachment content */
.CourseView_attachmentContainer__3e9gz{
    width: 75%;
    margin-top:24px;
    margin-bottom:24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    box-sizing: border-box;
    border-radius: 16px;
}

.CourseView_attachmentContainer__3e9gz > p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom:12px;
}

.CourseView_download_card__36DyX {
    margin-top: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding: 1rem 1.5rem;
}

@media only screen and (max-width: 1000px) {
    .CourseView_banner__1x634 img{
        height:15vh;
    }
    
    .CourseView_exploration__dAaPF {
        margin-bottom: 90px;
    }
    
    .CourseView_CourseView__cTTo4{
        width:95%;
    }
    
    .CourseView_enrolled__1DBb7 p{
        font-size: 14px;
    }

    .CourseView_BuyingDetail__JCqfV{
        padding:15px 15px;
    }

    .CourseView_enrolled__1DBb7{
        padding:20px;
    }

}


@media only screen and (max-width: 800px) {
 
    .CourseView_CourseView__cTTo4{
        margin-top:0px;
        padding-left:20px;
        padding-right:20px;
        width:95%;
    }

    .CourseView_caption__XqXk_{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-top:12px;
        padding-bottom:12px;
    }
    
    .CourseView_descTitle__U2gpm{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        padding-bottom:12px;
    }

    .CourseView_desc__rJWqM{
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-bottom:12px;
    }
    
    .CourseView_dropdownBtn__1qniQ{
        width:40%;
    }

    .CourseView_exploreCards__2bYcr{
        padding-top:12px;
        display: flex;
        justify-content: center;
        grid-gap:12px;
        gap:12px;

    }
    
    .CourseView_card__3TH3D{
        height: 300px;
        width: calc(100% / 3);
        border-radius: 10px;
        background:rgb(73, 73, 210);
    }

    .CourseView_attachmentContainer__3e9gz{
        width: 100%;
        padding:12px;
    }
    
    .CourseView_attachmentContainer__3e9gz > p{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }
}

@media only screen and (max-width: 500px) {
    .CourseView_CourseView__cTTo4{
        padding: 0px;
        margin-bottom: 20%;
    }

    .CourseView_CourseDetails__2-nO6{
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .CourseView_CourseDetailsLeft__3REAr{
        box-sizing: border-box;
        padding: 13px;
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .CourseView_CourseDetailsRight__1Kl8w{
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .CourseView_banner__1x634 img{
        height:18vh;
    }

    .CourseView_exploration__dAaPF {
        margin-bottom: 90px;
        display: none;
    }

    .CourseView_enrolled__1DBb7{
        padding:25px;
    }

    .CourseView_descTitle__U2gpm{
        font-weight: 600;
    }

    .CourseView_BuyingDetail__JCqfV{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        padding: 20px 20px;
    }
}

.PriceBar_price__b724x{
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    padding-bottom:24px;
    text-align: left;
    text-transform: uppercase;
}

.PriceBar_priceText__3CmV_{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    padding-bottom:24px;
    text-align: left;
    padding-left:2px;
}

@media only screen and (max-width: 1000px) {
    .PriceBar_priceText__3CmV_{
        padding-bottom:10px;
    }
}

@media only screen and (max-width: 800px) {
    
    .PriceBar_price__b724x{
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        padding-bottom:8px;
    }
    
    .PriceBar_priceText__3CmV_{
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        padding-bottom:8px;
    }
    
    
}

@media (max-width:500px)
{
    .PriceBar_priceText__3CmV_{
        font-size: 0.8rem;
        line-height: 19px;
    }
    .PriceBar_price__b724x{
        padding-bottom: 0px;
    }
}
.CourseButton_actionBtn__2v5Kv{
    box-sizing: border-box;
    border: 1px solid #ED1E24;   
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    border-radius:50px;
    padding-top:12px;
    padding-bottom:12px;
    width:100%;
    background: #ED1E24;
    color:white;
    cursor: pointer;
    min-height:none !important;
}

.CourseButton_Outlined__1SWxJ{
    background:"white";
    color:"#ED1E24"
}


@media only screen and (max-width: 800px) {
    .CourseButton_actionBtn__2v5Kv{
        font-weight: 700;
        font-size: 14px;
        padding-top: 12px;
        padding-bottom: 12px;
        width: 100%;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 500px) {
    .CourseButton_actionBtn__2v5Kv{
        padding: 10px;
        margin-left: 5px;
    }
}
.dropdown {
  position: relative;
  font-family: 'Montserrat';

}
.dropdown.open > .dropdown-menu {
  display: block;
}
.dropdown .dropdown-toggle {
  display: inline-block;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid #ED1E24;   
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  border-radius:50px;
  padding-top:12px;
  padding-bottom:12px;
  width:100%;
  background: white;
  color:#ED1E24;
  cursor: pointer;
}
.dropdown .dropdown-toggle .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9 ;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown .dropdown-menu {
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  width: 100%;
  /* min-width: 160px; */
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* border: 1px solid #ccc; */
  /* border: 1px solid rgba(0, 0, 0, 0.15); */
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown .dropdown-menu li {
  cursor: pointer;
}
.dropdown .dropdown-menu li :hover {
  background: #ddd;
  background-color: #ED1E24;
}
.dropdown .dropdown-menu li > a {
  display: block;
  padding: 5px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdownIcon{
  color:#ED1E24;
  margin-left:6px;
}



@media only screen and (max-width: 800px) {

  .dropdown .dropdown-toggle {
    font-size: 14px;
    padding-top:12px;
    padding-bottom:12px;
    padding-left: 12px;
    padding-right: 12px;

  }
  .dropdown .dropdown-toggle .caret {
    margin-left: 2px;
  }
  .dropdown .dropdown-menu {
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    width:auto;

  }
  .dropdown .dropdown-menu li > a {
    padding: 5px 10px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    text-decoration: none;
  }

  .dropdownIcon{
    color:#ED1E24;
    margin-left:6px;
  }
}


@media only screen and (max-width: 500px) {

  .dropdown .dropdown-toggle {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .dropdown .dropdown-menu li > a {
    padding: 5px 8px;
  }
  .dropdown .dropdown-menu {
    font-size: 13px;
    left: 0px;
  }
}
.CourseContent_main__3UzSc {
  margin-top: 10px;
  width: 100%;
}
.CourseContent_header__28upF {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CourseContent_header_left__3StbB{
  display: flex;
  flex-direction: column;
}

.CourseContent_heading__3dGMa {
  display: block;
  font-weight: 700;
  font-size: 1.125rem;
  padding-bottom: 10px;
}
.CourseContent_details__QtKSh {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 400;
}

.CourseContent_progress_container__2lMou {
  display: flex;
  align-self: center;
  border: 1px solid #E5E5E5;
  border-radius: 33px;
  padding: 8px 10px;
  align-items: center;
  margin-left: auto;
  width: 50%;
}
.CourseContent_progress_container__2lMou > span {
  font-weight: 600;
  font-size: 1.125rem;
  padding: 0 10px;
  white-space: nowrap;
}

.CourseContent_details__QtKSh span {
  padding-right: 10px;
  padding-bottom: 16px;
}

.CourseContent_content__1TzBW {
  width: 75%;
}

.CourseContent_section_title__3wXUl {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Montserrat";
}

.CourseContent_icon__3W15w {
  width: 1.125rem;
  margin-right: 8px;
}



.CourseContent_section_container__388vN {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.CourseContent_lecture_container__C4HCf {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.CourseContent_lecture_count__1EeSK {
  margin-left: auto;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: .875rem;
  color: #000000;
}

.CourseContent_lecture_title__3OqH1 {
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000000;
}

.CourseContent_lecture_title_completed__1eApw {
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ED1E24;

}

.CourseContent_duration__1NY1c {
  margin-left: auto;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 300;
  color: #000000;
}

.CourseContent_duration_completed__qyFHd {
  margin-left: auto;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ED1E24;

}

@media (max-width:1000px){
  .CourseContent_header__28upF {
    display: block;
    margin-bottom:10px;
  }

  .CourseContent_progress_container__2lMou {
    display: flex;
    width: 96%;
  }
}

@media (max-width:500px){
  .CourseContent_header__28upF {
    display: block;
    margin: 10px 0px;
  }
  
  .CourseContent_header_left__3StbB{
    display: block;
  }
  
  .CourseContent_progress_container__2lMou {
    display: flex;
    width: 94%;
  }

  .CourseContent_details__QtKSh {
    font-size: 12px;
  }
  .CourseContent_lecture_title__3OqH1 {
    word-break: break-word;
    width: 70%;
  }
}


.ExploreCourseCard_main__2PqvU {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.ExploreCourseCard_left_container__WrPMM {
  position: relative;
  width: 100%;
}

.ExploreCourseCard_left_container__WrPMM > img {
  width: 100%;
  height: 100%;
  max-height: 100px;
  object-fit: cover;
}

.ExploreCourseCard_left_container__WrPMM > span {
  font-weight: 500;
  font-size: 0.5rem;
  padding: 2px 5px;
  background-color: #D9D9D9;
  color: #555555;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.8;
}

.ExploreCourseCard_right_container__TnYoT {
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-left: 12px;
  align-items: flex-start;
}

.ExploreCourseCard_title__1JwI_ {
  margin-bottom: 10px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 0.875rem;
  color: #000;
  line-height: 1.0625rem;
}

@media (max-width:1000px){
  .ExploreCourseCard_main__2PqvU{
    display: block;
  }
}

.KnowYourInstructorCard_main__IGtOh {
    box-sizing: border-box;
    padding: 1.25rem 1rem;
    border: 1px solid;
    border-radius: .75rem;
    min-height: 10rem;
    font-family: Montserrat;

    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem
}

.KnowYourInstructorCard_title__1bP1q {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.KnowYourInstructorCard_instructor_name__bM9Ti {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ED1E24;
}

.KnowYourInstructorCard_profile_pic__NLVHK {
    padding: 0.5rem;
    border-radius: 50px;
    background-color: #b6b0b0;
    min-height: 5rem;
    margin: 0.5rem 0;
}

.KnowYourInstructorCard_stats__3g2lV {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    gap: 8px;
}

.KnowYourInstructorCard_stats__3g2lV img {
    margin-right: 0.5rem;
}

.KnowYourInstructorCard_count__yph6B {
    display: flex;
    align-items: center;

    font-weight: 450;
}

.KnowYourInstructorCard_middle_container__1GtA2 {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}
.FeatureVideosPage_main_container__19zS- {
    padding-top: 80px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat';
    flex-direction: column;    
    background-image: url(/static/media/bg1.4831d900.svg);
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
    max-width:100%
}

.FeatureVideosPage_heading__2tXZN {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 130%;
    text-align: center;
    color: #000000;
    z-index: 1;
    white-space: nowrap;
}

.FeatureVideosPage_videos_container__3Mr6W {
    width: 80%;
    overflow: hidden;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;    
}

.FeatureVideosPage_video_card__2p7DW {
    width: 45%;
}

.FeatureVideosPage_video_card__2p7DW > span {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
}



  /* ############################ Responsive 1000 ############################ */
@media only screen and (max-width: 1000px) {
    .FeatureVideosPage_heading__2tXZN {
      font-size: 2rem;
      white-space: nowrap;
      text-align: center;
    }
    .FeatureVideosPage_main_container__19zS- {
        padding-top: 120px;
    }

    .FeatureVideosPage_videos_container__3Mr6W {
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .FeatureVideosPage_video_card__2p7DW {
        width: 100%;
        margin-bottom: 50px;
    }

    .FeatureVideosPage_video_card__2p7DW > span {
        font-size: 1.3rem;
        white-space: nowrap;  
        margin-top: 10px;              
    }

  }

/* ############################ Responsive 600 ############################ */
@media only screen and (max-width: 600px) {
    .FeatureVideosPage_heading__2tXZN {
        font-size: 1.7rem;
    }
    
    .FeatureVideosPage_videos_container__3Mr6W {
        width: 80%;
        margin-bottom: 50px;
    }
    
    .FeatureVideosPage_video_card__2p7DW {
        width: 100%;
        margin-bottom: 25px;
    }

}

/* ############################ Responsive 400 ############################ */

.FeatureVideosPage_main_container__19zS- {
    padding-left: 10px;
    padding-right: 10px;
}
.CourseStreamPage_main__3OGhk {
    max-width: 100%;
    /* margin-top: 90px; */
    font-family: Montserrat;
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    scroll: none;

}
.CourseStreamPage_container_full__1AHPC {
    display: flex;
    justify-content: center;
    width: 90% ;
    padding: 0px 1.5rem;
    /* border: 1px solid orangered; */
    margin: auto;
    max-height: 75vh;
}
.CourseStreamPage_container_shrunk__3KrRD {
    width: 72%;
    margin-left: auto;
    padding: 0 1rem;
    /* border: 2px solid orangered; */
    max-height: 75vh;
}
.CourseStreamPage_loader_style__39AUr{
    text-align: center;
    margin-top: 200px
}
@media (max-width: 500px) {
    .CourseStreamPage_main__3OGhk {
        max-width: 100%;
        width: 100%;
        overflow-x: hidden;
        min-height: 100vh;
    }
    .CourseStreamPage_document_wrapper__1jlWt{
       max-width: 100%;
       max-height: 100%;
    }
    .CourseStreamPage_container_full__1AHPC {
        padding: 0px;
        margin-top: 0px;
    }
}

.CourseHeader_main__2pGD8 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding: 20px 40px;
  font-family: Montserrat;
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
}

.CourseHeader_logo__2g0YP {
  border-right: 1px solid #a6a6a6;
  padding-right: 16px;
  margin-right: 1rem;
  cursor: pointer;
}

.CourseHeader_title__1g8AF {
  margin-right: auto;
  font-size: 1.75rem;
  font-weight: 700;
}

.CourseHeader_progress__2cYA7 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.CourseHeader_progress__2cYA7 > span {
  font-weight: 500;
  color: #000000;
  font-size: 1.2rem;
  padding: 0 10px;
}

.CourseHeader_angleLeft__27jqm{
  display: none;
}

@media (max-width: 600px) {
  .CourseHeader_main__2pGD8 {
    display: flex;
    z-index: 900;
    width: 100%;
    padding: 20px 0px;
  }
  
  .CourseHeader_logo__2g0YP, .CourseHeader_progress__2cYA7 {
    display:none;
  }

  .CourseHeader_title__1g8AF {
    margin-right: auto;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .CourseHeader_angleLeft__27jqm{
    display:block;
    margin-right: 0rem;
  }
  
}

@media (min-width: 601px) and (max-width: 1000px) {
  .CourseHeader_progress__2cYA7 > span {
    display: none;
  }

  .CourseHeader_title__1g8AF {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}
.ContentDrawer_drawer_header__1gV1I{
  display: flex;
  align-items: center;
  font-weight: 600;
}

.ContentDrawer_section_title__62xRP {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Montserrat";
}

.ContentDrawer_icon__1aGUi {
  width: 1.25rem;
  height: 1.25rem;
  margin: 8px;
}

.ContentDrawer_lecture_container__suntn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.ContentDrawer_lecture_title__3UTL6 {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #555555;
}

.ContentDrawer_lecture_title_active__2j2Be {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000;

}

.ContentDrawer_lecture_title_completed__2FT9H {
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ED1E24;
}

.ContentDrawer_duration__1TAMg {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color: #555555;
}

.ContentDrawer_duration_active__2Hb6b {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color: #000000;
}

.ContentDrawer_duration_completed__1jWRl {
  margin-left: auto;
  font-family: Montserrat;
  font-size: .75rem;
  font-weight: 300;
  color:#ED1E24;
}

@media (max-width: 600px) {
  .ContentDrawer_slider_icon__1nJK_{
    border: 1px white #000;
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1px !important;
    margin-top: 25px !important;
  }
  .ContentDrawer_lecture_title__3UTL6 {
    word-break: break-all;
    width: 70%;
  }
}
.CourseQuizView_main__oTSnE {
  width: 100%;
  font-family: Montserrat;
  /* border: 1px solid red; */
}

.CourseQuizView_title_container__3aRGe {
  margin-bottom: 4rem;
}

.CourseQuizView_title__M9KJG {
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
}

.CourseQuizView_count__2mcaF {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.CourseQuizView_middle_container__1sYqa {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* border: 1px solid red; */
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 2.5rem;
}

.CourseQuizView_btn_label__2ZGky {
  font-size: 20px;
  font-weight: 700;
}

.CourseQuizView_instructions__1C5hh > span {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.CourseQuizView_bottom_container__6PPw7 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* border: 1px solid red; */
  border-top: 2px solid #e5e5e5;
  margin: 16px 0;
  padding: 16px 0;
}

.CourseQuizView_max_marks_text__3eAFH,
.CourseQuizView_your_grade_text__1so4j {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.CourseQuizView_marks_value__1T6Ne {
  color: #000;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 131.579% */
}

.CourseQuizView_loader_style__2zp1q {
  text-align: center;
  margin-top: 200px;
}

@media (max-width: 580px){
  .CourseQuizView_main__oTSnE {
   margin-left: 40px;
  }
  .CourseQuizView_title__M9KJG {
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .CourseQuizView_count__2mcaF {
    color: #000;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .CourseQuizView_instructions__1C5hh > span {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .CourseQuizView_instructions__1C5hh > p {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
  }
  .CourseQuizView_title_container__3aRGe {
    margin-bottom: 30px;
  }
  .CourseQuizView_bottom_container__6PPw7 {
    border-top: none;
    display: block;
  }
  .CourseQuizView_max_marks_text__3eAFH, .CourseQuizView_your_grade_text__1so4j {
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .CourseQuizView_marks_value__1T6Ne {
    color: #000;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
  }
  .CourseQuizView_bottom_container_top__1CyrV{
    margin-bottom: 17px;
  }
  .CourseQuizView_btn_label__2ZGky {
    color: #FFF;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.AttemptQuizComponent_quiz_outer_container__XaZ-X {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.AttemptQuizComponent_quiz_modal_container__1JRI3 {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  min-height: 80vh;
  max-height: 150vh;
  padding-top: 5px;
  padding: 20px;
  border-radius: 25px;
  width: 70vw;
}

.AttemptQuizComponent_quiz_header__2-EKu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.AttemptQuizComponent_quiz_header_one__1W-4e{
  display: flex;
}

.AttemptQuizComponent_quiz_header_one__1W-4e > img{
  height: 24px;
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
  margin-top: 12px;
}

.AttemptQuizComponent_quiz_header__2-EKu > img {
  height: 24px;
  width: 30px;
  margin-right: 1rem;
  cursor: pointer;
}

.AttemptQuizComponent_btn_label__IB2nx {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  /* line-height: 1.0625rem; */
  /* letter-spacing: 0em; */
  text-align: center;
}

.AttemptQuizComponent_progress_and_timer_container__10R_Q {
  position: relative;
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.AttemptQuizComponent_required_label__TT80T{
  text-transform: lowercase;
  position: absolute;
  color: #E81123;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal; 
  bottom: 8px;
  right: 8px;
}

.AttemptQuizComponent_grade_label__2PBIs  {
  margin-left: auto;
  margin-right: 10px;
  font-weight: 500;
  font: 1rem;
}

.AttemptQuizComponent_grade_input_container__3LoHz {
  border-radius: 59px;
  border: 1px solid #A6A6A6;
  padding: 2px 14px;
  display: flex;
}

.AttemptQuizComponent_grade_input_container__3LoHz > input {
  width: 30px;
  outline: none;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #a9a9a9;
  margin: 2px 0;
}


.AttemptQuizComponent_progress_container__3JXkI,
.AttemptQuizComponent_progress_bar__265WF,
.AttemptQuizComponent_timer_container__1b6lJ {
  width: 30%;
  /* border: 1px solid blue */
}

.AttemptQuizComponent_timer_container__1b6lJ {
  display: grid;
  grid-template-columns: 0fr 0fr;
  /* align-items: center;
  justify-content: flex-end; */
  width: 20%;
}

.AttemptQuizComponent_timer_container__1b6lJ > img {
  margin-right: 8px;
  margin-left: auto;
}

.AttemptQuizComponent_time_running_out__1eNSw {
  color: red;
  -webkit-animation: AttemptQuizComponent_blinker__KjbGl 1.5s linear infinite;
          animation: AttemptQuizComponent_blinker__KjbGl 1.5s linear infinite;
  font-weight: 600;
}

@-webkit-keyframes AttemptQuizComponent_blinker__KjbGl {  
  50% { opacity: 0; }
}

@keyframes AttemptQuizComponent_blinker__KjbGl {  
  50% { opacity: 0; }
}

.AttemptQuizComponent_question_container__H4U4_ {
  /* display: flex; */
  /* flex-direction: column; */
  box-sizing: border-box;
  /* justify-content: center;
  align-items: flex-start; */
  padding: 32px;
  min-height: 300px;
  width: 100%;
  border-radius: 16px;
  margin-top: 1rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.AttemptQuizComponent_question_header__1X9Dn {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #555;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AttemptQuizComponent_question_header__1X9Dn > span:first-child {
  border-radius: 30px;
  border: 1px solid #dbdbdb;
  padding: 8px 20px;
}

.AttemptQuizComponent_pagination_container__2TWeL {
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  margin-bottom: 90px;
}

.AttemptQuizComponent_grade_input_inner_container__1f0Cm{
  margin: 3px;
}

@media (max-width: 580px){
  .AttemptQuizComponent_quiz_outer_container__XaZ-X {
    width: 94vw;
  }
  .AttemptQuizComponent_btn_label__IB2nx {
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
  }
  .AttemptQuizComponent_progress_and_timer_container__10R_Q {
    padding: 15px;
    margin-top: 10px;
  }
  .AttemptQuizComponent_progress_and_timer_container__10R_Q span{
    letter-spacing: -0.07px;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 15px 0px;
  }
  .AttemptQuizComponent_progress_container__3JXkI{
    font-size: 14px;
    font-weight: 400;
    display: contents;
  }
  .AttemptQuizComponent_question_header__1X9Dn {
    font-size: 15px;
    font-weight: 400;
  }
  .AttemptQuizComponent_pagination_container__2TWeL {
   padding: 10px;
   margin-bottom: 15px;
  }
  .AttemptQuizComponent_pagination_container__2TWeL nav{
    margin: 10px 0px;
  }
  .AttemptQuizComponent_question_container__H4U4_ {
    padding: 20px;
    min-height: auto;
  }

.AttemptQuizComponent_timer_container__1b6lJ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.AttemptQuizComponent_question_header__1X9Dn > span:first-child {
  width: 100%;
  color: #555;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.AttemptQuizComponent_question_header__1X9Dn > span:nth-child(2) {
  display: none;
}

.AttemptQuizComponent_grade_input_container__3LoHz > input {
  width: 30px;
  outline: none;
  text-align: center;
  margin: 3px 0px 3px 0px;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-bottom: none;
}

.AttemptQuizComponent_grade_input_container__3LoHz {
  display: flex;
  padding: 3px;
}

.AttemptQuizComponent_grade_input_inner_container__1f0Cm{
  margin: 2.3px 0px 0px 0px;
}

.AttemptQuizComponent_choice_text__37nnO{
  color: #555;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.AttemptQuizComponent_quiz_sub_header__2TQLO{
  display: block;
}

.AttemptQuizComponent_required_label_mobile__2YpFt{
  float: right;
  text-transform: lowercase;
  color: #E81123;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: normal; 
}

.AttemptQuizComponent_quiz_header_one__1W-4e > img{
  height: 15px;
  width: 15px;
  margin-right: 1rem;
  margin-top: 7px;
  cursor: pointer;
}

}


.QuestionChoiceComponent_main__E6dYm {
  margin-top: 1.5rem;
  box-sizing: border-box;
  color: #212121;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.QuestionChoiceComponent_main_disabled__35-Ni {
  pointer-events: none;
  margin-top: 1.5rem;
  box-sizing: border-box;
  color: #212121;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.QuestionChoiceComponent_fib_choice_container__Khp30 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  justify-items: start;
  align-items: baseline;
  margin-bottom: 1rem;  
}

.QuestionChoiceComponent_span_container__Q7Aox {
  margin-top: 1rem;
  text-transform: capitalize;
}

.QuestionChoiceComponent_list_number_span__3HaDk {
  margin-right: 0.5rem;
  font-weight: 500;
}

.QuestionChoiceComponent_dropdown_container__NTq6d {
  display: flex;
  align-items: center;
  min-width: 150px;
  max-width: 80%;
  box-sizing: border-box;
  /* width: fit-content; */
}


.QuestionChoiceComponent_grid_choice_container__2b0nW {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
          column-gap: 0.5rem;
  justify-items: start;
  align-items: baseline;
  margin-bottom: 1rem;  
  width: 45%;
}

.QuestionChoiceComponent_image_choice__2Q11d {
  width: 40%;
}

.QuestionChoiceComponent_answer_container__2JNBh {
  width: 80%;
}

.QuestionChoiceComponent_not_found_text__jPp7H {
  color: #ED1E24;
  margin: auto;
}

.QuestionChoiceComponent_correct_incorrect_div__3Nl2K{
  display: flex;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;

}

.QuestionChoiceComponent_correct_incorrect_div__3Nl2K > img {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.QuestionChoiceComponent_correct_answer__1nMGZ {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;
  color: #46CB4C;
}

.QuestionChoiceComponent_answer_label__t9bYn {
  font-weight: 600;
}

.QuestionChoiceComponent_answer_label_mcq__2H71E {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  margin-top: 1rem;
  color: #46CB4C;
  font-weight: 600;
}

.QuestionChoiceComponent_correct_answer_mcq__377Vc {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #46CB4C;
}

.QuestionChoiceComponent_not_graded_yet_text__2ZXDo {
  color: #E81123;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  margin-top: 1rem; 
}

@media (max-width: 580px){
  .QuestionChoiceComponent_main__E6dYm span{
    color: #212121;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 184.615% */
    letter-spacing: 0.02px;
  }
  .QuestionChoiceComponent_grid_choice_container__2b0nW {
    grid-column-gap: 3.5rem;
    -webkit-column-gap: 3.5rem;
            column-gap: 3.5rem;
    width:auto;
  }
  .QuestionChoiceComponent_span_container__Q7Aox {
    white-space: nowrap;
  }
  .QuestionChoiceComponent_correct_incorrect_div__3Nl2K{
    display: flex;
    text-align: left;
    margin-top: 1rem;
    color: #E81123;
    color: var(--Wrong, #E81123);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
  }
  .QuestionChoiceComponent_correct_answer__1nMGZ {
    color: #46CB4C;
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
    margin-top: 0px;
    margin-left: 30px;
  }
  .QuestionChoiceComponent_answer_label_mcq__2H71E {
    color: #46CB4C;
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
    margin-top: 0px;
  }
  .QuestionChoiceComponent_correct_answer_mcq__377Vc {
    color: #46CB4C;
    color: var(--Green, #46CB4C);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02px;
  }
  .QuestionChoiceComponent_not_graded_yet_text__2ZXDo {
    color: #E81123;
    font-family: Montserrat;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin-top: 1rem; 
  }
}
.AnswerChoiceDropDown_text__3rH5T {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat';

}

.AnswerTextArea_text__1j78_ {
    font-family: 'Montserrat';
    font-weight: 400;
    font-weight: 1rem;
    color: black;
}
.ConfirmSubmissionModal_main__3jPrp {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem 1.5rem;
  padding-top: 5px;
  border-radius: 25px;
  width: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ConfirmSubmissionModal_main__3jPrp > img {
  width: 7rem;
  padding: 10px 0;
}

.ConfirmSubmissionModal_main_text__3G5X2 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0.5rem;
}

.ConfirmSubmissionModal_sub_text__2N4FD {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
}

.ConfirmSubmissionModal_btn_div__7Pvzb {
  display: flex;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: 100%;
}

.ConfirmSubmissionModal_btn_label__W3Vti {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: center;
}
/*   
  @media(max-width: 600px){
    .action_modal_container {
      width: 75%
    }
  }
  
  @media(max-width: 400px){
    .action_modal_container {
      width: 70%
    }
  } */

@media (max-width: 1200px) {
  .ConfirmSubmissionModal_main__3jPrp {
    width: 50vw;
  }
}

@media (max-width: 800px) {
  .ConfirmSubmissionModal_main__3jPrp {
    width: 70vw;
  }
}
  

@media (max-width: 600px) {
  .ConfirmSubmissionModal_main__3jPrp {
    width: 70vw;
  }
}

.CreateCoursePage_main__N94yk {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  width: 80vw;
  margin-top: 120px;
  margin-bottom: 100px;
  font-family: "Montserrat";
}

.CreateCoursePage_input_label__3-nF3 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;
}

.CreateCoursePage_input_field__2opJ3 {
  border-radius: 25px;
  width: 100%;
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 10px;
  padding-right: 10px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  box-sizing: border-box;
  margin-bottom: 12px;
}

/* -------- Thumbnail --------- */
.CreateCoursePage_thumbnail_area__2vLCU {
  /* width: 550px; */
  height: 125px;
  background: #ffd7d7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #ED1E24 dashed 1px;
  opacity: 1;
  margin-top: 28px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  position: relative;
}

.CreateCoursePage_upload_box__2Udld {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.CreateCoursePage_upload_box__2Udld input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.CreateCoursePage_upload_img__3o5OU {
  margin-bottom: 5px;
}

.CreateCoursePage_file_upload__29g1g {
  opacity: 0;
  cursor: pointer;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.CreateCoursePage_upload_txt__1W6_V {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.CreateCoursePage_support_txt__1kfaE {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  /* color: #BDBDBD; */
  color: black;
}
/* 
.thumbnail_pic {
  width: 550px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 5px;
} */

.CreateCoursePage_thumbnail_pic__8trMm {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  object-fit: cover;
}

.CreateCoursePage_change_thumbnail__3q3wJ {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.CreateCoursePage_tooltip_icon__MFlfS{
  margin-left: 10px;
  height: 14px;
}

/* ---------- */

.CreateCoursePage_textbox_field__3ZTHs {
  border-radius: 25px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.CreateCoursePage_radio_label__1bTLj {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-right: 10px;
}

.CreateCoursePage_radio_div__2c5mP {
  margin-top: 10px;
}

input[type="radio"] {
  accent-color: #00a77f;
}

.CreateCoursePage_price_div__yp6ve {
  display: flex;
}

.CreateCoursePage_price_type_input__142oq {
  border-radius: 25px;
  width: 92px;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  text-align: center;
}

.CreateCoursePage_price_input__2MDH9 {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 20px;
  box-sizing: border-box;
}

.CreateCoursePage_btn_div__3j88N {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-top: 15px;
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.CreateCoursePage_sign_preview_container__1SQYB > img {
  width: 10%;
}

@media (max-width:500px){
  .CreateCoursePage_main__N94yk{
    margin-top: 13px;
    width: 93.5vw;
    margin-right: 13px;
    margin-left: 13px;
  }

  .CreateCoursePage_thumbnail_area__2vLCU {
    height:90px;
    margin-top:15px;
  }
  
  .CreateCoursePage_price_input__2MDH9 {
    width: 50%;
    padding:20px 15px;
  }

  .CreateCoursePage_price_type_input__142oq {
    width:50%;
    padding:20px 15px;
  }

  .CreateCoursePage_radio_group__3cpAb{
    margin-top:17px;
  }

  .CreateCoursePage_btn_div__3j88N {
    margin-top:30px;
  }
}

@media (max-width:850px){
  .CreateCoursePage_main__N94yk{
    margin-top:20px;
  }
}
.ConfirmAddContentModal_main__3MPMX {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem 1.5rem;
  padding-top: 5px;
  border-radius: 25px;
  width: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ConfirmAddContentModal_main__3MPMX > img {
  width: 7rem;
  padding: 10px 0;
}

.ConfirmAddContentModal_question_text__QEdRT {
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.ConfirmAddContentModal_btn_div__2mLdG {
  display: flex;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: 100%;
}

.ConfirmAddContentModal_btn_label__J46QO {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}
/*   
  @media(max-width: 600px){
    .action_modal_container {
      width: 75%
    }
  }
  
  @media(max-width: 400px){
    .action_modal_container {
      width: 70%
    }
  } */

@media (max-width: 600px){
  .ConfirmAddContentModal_main__3MPMX{
    width:auto;
  }  
}
.AddSignatureModal_main__FeqQU {
  box-sizing: border-box;
  min-height: 50vh;
  min-width: 36vw;
  width: 36vw;
  height: 50vh;
}

.AddSignatureModal_middle_container__8eYYa {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1.125rem 1.375rem;
}

.AddSignatureModal_button_container__3NyvE {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.AddSignatureModal_tab_container__cyYas {
  height: 100%;
  width: 100%;
  min-height: 38vh;
  background-color: #ebebeb;
  margin-bottom: 0.5rem;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.AddSignatureModal_reset_icon__1OvIG {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.AddSignatureModal_upload_box__3sAb5 {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.AddSignatureModal_upload_box__3sAb5 input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.AddSignatureModal_upload_img__3ggCT {
  margin-bottom: 5px;
}

.AddSignatureModal_upload_text__1JNHP {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.25rem 1rem;
  border: 1px solid #ed1e24;
  color: #ed1e24;
  border-radius: 1rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.AddSignatureModal_support_txt__1xfqi {
  font-size: .75rem;
}

.AddSignatureModal_file_upload__ia4T9 {
  opacity: 0;
  cursor: pointer;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}


.AddSignatureModal_thumbnail_pic__3XKwu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  object-fit: contain;
}

.AddSignatureModal_change_thumbnail__1f5kF {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 30px;
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

/* ----------- Media Query -------------- */

@media (max-width: 800px) {
  .AddSignatureModal_main__FeqQU {
    box-sizing: border-box;
    width: 90vw;
    height: 70vh;
  }
  

}
.ReviewCoursePage_main__11MtO {
    display: flex;
    margin: auto;
    justify-content: center;
    width: 80vw;
    margin-top: 120px;
    border: 1px solid red;
}
.AddCourseContentPage_main__39oc0 {
  display: grid;
  grid-template-columns: 35fr 65fr;
  margin: auto;
  justify-content: center;
  width: 80vw;
  margin-top: 120px;
  margin-bottom: 100px;
}

.AddCourseContentPage_left_container__2AijT {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* background-color: antiquewhite; */
  /* min-height: 150px; */

}

.AddCourseContentPage_right_container__OtJK0 {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  min-height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 16px;
}

.AddCourseContentPage_thumbnail_container__3P6UM {
  margin-bottom: 16px;
  max-width: 95%;
  height: 180px;
  position: relative;
}
.AddCourseContentPage_thumbnail_container__3P6UM::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 10px;
  z-index: 1;
}

.AddCourseContentPage_thumbnail_container__3P6UM > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
 
}

.AddCourseContentPage_course_type_label__1P59g {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 20px 8px 0;
}

.AddCourseContentPage_course_type_span__fzXQI {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
}

.AddCourseContentPage_inputTags__3_RX0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  width: 92%;
  border: 1px solid rgb(227, 223, 223);
  margin: 0px 0 16px 0;
  /* min-height: 35px; */
  /* height: auto; */
  padding: 5px;
  overflow-x: scroll !important;
}

.AddCourseContentPage_inputTags__3_RX0::-webkit-scrollbar {
  display: none;
}

.AddCourseContentPage_input_label__1NPeN {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;
}

.AddCourseContentPage_courseType__3F0wp {
  margin: 25px 0 10px 0;
}

.AddCourseContentPage_subtext__3ODjt {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin: 8px 0;
}

.AddCourseContentPage_add_content_label__1BhcH {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  /* padding-bottom: 8px; */
  margin: 15px 0 12px 0;

}

.AddCourseContentPage_add_section_btn__3tqZ5 {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-right: auto;
  margin-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AddCourseContentPage_btn_div__2W3xv {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  margin-bottom: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AddCourseContentPage_btn_label__3tg2l {
  font-family: "Montserrat";
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}


.AddCourseContentPage_loader_style__1mlbh{
  text-align: center;
   margin-top: 200px
}

@media (max-width:800px) {
  .AddCourseContentPage_main__39oc0 {
    margin: 12px;
    display: block;
    width: auto;
    margin-bottom: 10vh;
  }

  .AddCourseContentPage_left_container__2AijT {
    display: none;
  }

  .AddCourseContentPage_right_container__OtJK0 {
    min-height: 201px;
    padding:15px;
  }

  .AddCourseContentPage_add_content_label__1BhcH {
    margin: 0px 0 12px 0;
    line-height: normal;
    font-size: 18px;
  }

  .AddCourseContentPage_btn_div__2W3xv {
    margin-right: 0px;
    margin-bottom: 0px;
  }

}

@media (max-width:1000px) {
  .AddCourseContentPage_main__39oc0{
    width: 95vw;
  }

}

@media (max-width:500px) {
  .AddCourseContentPage_main__39oc0 {
    margin: 12px;
    display: block;
    width: auto;
    margin-bottom: 10vh;
  }

  .AddCourseContentPage_left_container__2AijT {
    display: none;
  }

  .AddCourseContentPage_right_container__OtJK0 {
    min-height: 0px;
    padding:15px;
  }

  .AddCourseContentPage_add_content_label__1BhcH {
    margin: 0px 0 12px 0;
    line-height: normal;
    font-size: 18px;
  }

  .AddCourseContentPage_btn_div__2W3xv {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .AddCourseContentPage_btn_label__3tg2l{
    font-size: 10px;
    font-weight: 700;
  }

}
.OuterSectionContainer_main__m0j3r {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin-bottom: 1rem;
}

.OuterSectionContainer_section_header__1X0cN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
}

.OuterSectionContainer_section_title__3p772 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 0.875rem;
  color: #000;
}
.OuterSectionContainer_section_title__3p772 > span {
  font-weight: 400;
  margin-left: 10px;
}

.OuterSectionContainer_icons__2cM5n {
  margin-right: 1.75rem;
}

.OuterSectionContainer_icons__2cM5n > img {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.OuterSectionContainer_icons_disabled__CpM7K {
  margin-right: 1.75rem;
}

.OuterSectionContainer_icons_disabled__CpM7K > img {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
}

.OuterSectionContainer_btn_label__2oOlc {
  font-family: "Montserrat";
  font-size: .75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}


.OuterSectionContainer_selection_bar__2zEiV {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1.4px solid #ED1E24;
  border-radius: 50px;
  color: #ED1E24;
}

.OuterSectionContainer_option__3JB97 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  cursor: pointer;
}

.OuterSectionContainer_option_quiz__3EZox {
  border-left: 1.4px solid #e5e5e5;
  border-right: 1.4px solid #e5e5e5;
}

.OuterSectionContainer_option__3JB97 > img {
  width: 11px;
  height: 11px;
  margin-right: 4px;
}

@media (max-width:500px){
  .OuterSectionContainer_main__m0j3r {
    border-radius: 19px;
  }
  .OuterSectionContainer_icons__2cM5n {
    margin-right: 13px;
  }
  .OuterSectionContainer_section_title__3p772 {
    font-size: 15px;
  }
  .OuterSectionContainer_section_title__3p772 > span{
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  .OuterSectionContainer_btn_label__2oOlc {
    font-size: 10px;
    font-weight: 700;
  }
  .OuterSectionContainer_option__3JB97 {
    font-size: 11px;
    font-weight: 700;
  }
  .OuterSectionContainer_option__3JB97 > img {
    width: 11px;
    height: 11px;
    margin-right: 4px;
  }

}
.AddSectionLectureForm_main__1YvY7 {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.AddSectionLectureForm_input_container__1lABN {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.AddSectionLectureForm_input_container__1lABN > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.AddSectionLectureForm_input_container__1lABN > input {
  text-decoration: none;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  margin-left: 0.5rem;
}

.AddSectionLectureForm_input_container__1lABN > input::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: .8125rem;
  line-height: 16px;
  color: #A6A6A6;
}

.AddSectionLectureForm_input_container__1lABN > input::placeholder{
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: .8125rem;
  line-height: 16px;
  color: #A6A6A6;
}

.AddSectionLectureForm_btn_div__1D9WY {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AddSectionLectureForm_btn_label__3cfVq {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width:500px){
  .AddSectionLectureForm_input_container__1lABN {
    display: block;
  }

  .AddSectionLectureForm_input_container__1lABN > input {
    width: 95%;
    margin: 10px 0px;
    font-size: 14px;
  }
  
  .AddSectionLectureForm_btn_div__1D9WY {
      margin-top: 0.5rem;
      margin-right: 0px;
      grid-gap: 5px;
  }

  .AddSectionLectureForm_btn_label__3cfVq {
    font-size:8px;
  }

  .AddSectionLectureForm_input_container__1lABN > span {
    font-size: 14px;
    font-weight: 600;
  }
}
.AddArticleVideoComponent_main__1uWjH {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  margin-top: 1rem;
}

.AddArticleVideoComponent_header__1vX4C {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  border-bottom: 1px solid #e5e5e5;
}

.AddArticleVideoComponent_header__1vX4C > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
}

.AddArticleVideoComponent_url_container__2nUf-,
.AddArticleVideoComponent_file_container__3WH3s {
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  padding-left: 0.75rem;
}

.AddArticleVideoComponent_url_input__1Wa1I,
.AddArticleVideoComponent_file_input__3dVbi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-right: 1px solid #e5e5e5;
  padding: 5px;
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 300;
}

.AddArticleVideoComponent_placeholder_text__2xK0g {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #a6a6a6;
}

.AddArticleVideoComponent_url_input__1Wa1I > input,
.AddArticleVideoComponent_file_input__3dVbi > input {
  outline: none;
  width: 100%;
}

.AddArticleVideoComponent_url_input__1Wa1I > img,
.AddArticleVideoComponent_file_input__3dVbi > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  margin-left: auto;
}

.AddArticleVideoComponent_btn_add_url__9y2-z,
.AddArticleVideoComponent_btn_add_article__6JdXW {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 20%;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
}

.AddArticleVideoComponent_btn_add_article__6JdXW > button,
.AddArticleVideoComponent_btn_add_url__9y2-z > button {
  width: 100%;
  height: 100%;
  background-color: white;
  text-decoration: none;
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  /* white-space: nowrap; */
  color: #000;
  font-size: 0.875rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.AddArticleVideoComponent_btn_add_article__6JdXW > button:active,
.AddArticleVideoComponent_btn_add_url__9y2-z > button:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  box-shadow: 2px 1px 1px 1px rgba(0, 0, 0, 0.14);
}

/* .btn_add_article span {
  white-space: nowrap;
} */

.AddArticleVideoComponent_url_input__1Wa1I > ::-webkit-input-placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #a6a6a6;
}

.AddArticleVideoComponent_url_input__1Wa1I > ::placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
  color: #a6a6a6;
}

.AddArticleVideoComponent_time_input_container__3F9My > input {
  width: 1.1rem;
  text-align: center;
  margin-right: 5px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.875rem;
  /* line-height: 16px; */
  color: #000;
  outline: none;
}

.AddArticleVideoComponent_time_input_container__3F9My > input::-webkit-input-placeholder {
  font-size: 0.875rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  /* line-height: 24px; */
  color: #a6a6a6;
}

.AddArticleVideoComponent_time_input_container__3F9My > input::placeholder {
  font-size: 0.875rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  /* line-height: 24px; */
  color: #a6a6a6;
}

.AddArticleVideoComponent_time_input_container__3F9My {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.AddArticleVideoComponent_time_container__1O632 {
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 33px;
  padding-left: 0.75rem;
  margin-top: 1rem;
}

.AddArticleVideoComponent_time_unit__1H1wR {
  color: #000;
  font-size: 0.875rem;
  font-family: Montserrat;
}

.AddArticleVideoComponent_colon__3LB0Q {
  color: #000;
  font-size: 0.875rem;
  font-family: Euclid Square;
  font-style: normal;
  font-weight: 600;
  padding: 0 5px;
}

@media (max-width:500px){
  .AddArticleVideoComponent_main__1uWjH {
    margin-top: 0rem;
  }

  .AddArticleVideoComponent_header__1vX4C {
    padding: 10px 20px;
  }
  
  .AddArticleVideoComponent_header__1vX4C > span{
    font-size: 14px;
    font-weight: 500;
  }

  .AddArticleVideoComponent_btn_add_article__6JdXW button{
    font-size: 10px;
    font-weight: 500;
  }

  .AddArticleVideoComponent_placeholder_text__2xK0g {
    font-size: 10px;
  }

  .AddArticleVideoComponent_time_unit__1H1wR {
    font-size: 10px;
    margin-top: 5px;
  }

  .AddArticleVideoComponent_url_input__1Wa1I > input,.AddArticleVideoComponent_file_input__3dVbi > input {
  font-size: 10px;
  height: 20px;
  }

  .AddArticleVideoComponent_btn_add_url__9y2-z > button{
    font-size: 10px;
  }

  .AddArticleVideoComponent_url_input__1Wa1I,.AddArticleVideoComponent_file_input__3dVbi {
  font-size: 10px;
  }

  .AddArticleVideoComponent_url_container__2nUf-,
.AddArticleVideoComponent_file_container__3WH3s {
    height: 33px;
}

}
.ContentTypeButtonMenu_btn_label__2oI3Q {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

.ContentTypeButtonMenu_menu_item_label__o3ew3 {
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.ContentTypeButtonMenu_menu_icon__1z36i {
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 0.5rem;
}

@media (max-width:500px){
  .ContentTypeButtonMenu_btn_label__2oI3Q {
    font-size: 8px;
    font-weight: 700;
  }

}
.UpdateLectureComponent_main__2FpQA {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.UpdateLectureComponent_heading__3O6fP {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  color: #000;
  margin-bottom: 0.75rem;
}

.UpdateLectureComponent_input_container__3cR2T {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.UpdateLectureComponent_input_container__3cR2T > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

.UpdateLectureComponent_input_container__3cR2T > input {
  text-decoration: none;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  margin-left: 0.5rem;
}

.UpdateLectureComponent_input_container__3cR2T > input::-webkit-input-placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 16px;
  color: #a6a6a6;
}

.UpdateLectureComponent_input_container__3cR2T > input::placeholder {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 16px;
  color: #a6a6a6;
}

.UpdateLectureComponent_content_container__8sLTq {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.UpdateLectureComponent_content_container__8sLTq > span {
  font-size: 0.875rem;
  font-weight: 400;
}

.UpdateLectureComponent_content__3h_5e {
  display: flex;
  width: 100%;
  justify-self: flex-end;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.UpdateLectureComponent_content__3h_5e > span {
  color: #a6a6a6 ;
  font-size: .875rem;
  font-family: "Montserrat";
  font-weight: 400;
  margin-right: .625rem;
}

.UpdateLectureComponent_content__3h_5e > img {
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  /* margin-left: auto; */
}

.UpdateLectureComponent_btn_div__1-zLp {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.UpdateLectureComponent_btn_label__2Mir7 {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width:500px){
  .UpdateLectureComponent_heading__3O6fP {
    font-size: 13px;
    font-weight: 600;
  }
  .UpdateLectureComponent_input_container__3cR2T > span {
    font-size: 13px;
  }
  .UpdateLectureComponent_input_container__3cR2T > input {
    font-size: 13px;
  }

  .UpdateLectureComponent_content__3h_5e > span {
    font-size: 13px;
  }

  .UpdateLectureComponent_content_container__8sLTq > span{
    font-size: 13px;
  } 
  .UpdateLectureComponent_btn_label__2Mir7 {
    font-size: 8px;
  }
}

.AddQuizForm_main__2Jg0r {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 1rem 0.75rem 1rem 0.75rem;
  margin: 1rem 0;
}

.AddQuizForm_add_quiz_container__yX1fi {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.AddQuizForm_add_quiz_container__yX1fi > span {
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-right: 8px;
}


.AddQuizForm_btn_div__-ndKe {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 10px;
  margin-top: 1rem;
  /* margin-bottom: 20px; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AddQuizForm_btn_label__2XNap {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width: 580px){
  .AddQuizForm_add_quiz_container__yX1fi {
    display: block;
  }
  .AddQuizForm_add_quiz_container__yX1fi > span {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .AddQuizForm_btn_label__2XNap {
    font-family: Montserrat;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.LectureItem_main__fvfpV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat";
  border: 1px solid #e5e5e5;
  border-radius: 50px;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.LectureItem_lecture_title__2k-X1 {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 0.875rem;
  color: #000;
}

.LectureItem_lecture_last_name__v-iXR{
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.LectureItem_lecture_last_name__v-iXR > span{
  margin-left: 10px;
}

.LectureItem_lecture_last_name__v-iXR img{
  width: 15px;
  margin-right: 5px;
}

.LectureItem_lecture_title__2k-X1 > img {
  width: 1rem;
  height: 1rem;
  padding: 0 0.75rem;
}

.LectureItem_icons__15Q7N {
  margin-right: 1rem;
}

.LectureItem_icons__15Q7N > img {
  width: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.LectureItem_icons_disabled__2tnLC {
  margin-right: 1rem;
}

.LectureItem_icons_disabled__2tnLC > img {
  width: 1rem;
  margin-left: 1rem;
}

@media (max-width:500px){
  .LectureItem_main__fvfpV {
    border-radius: 19px;
  }

  .LectureItem_lecture_title__2k-X1 {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: flex-start;
    margin-left: 5px;
  }
  
  .LectureItem_icons__15Q7N {
    margin-right: 0px;
  }

  .LectureItem_lecture_last_name__v-iXR{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 0px;
  }

  .LectureItem_lecture_last_name__v-iXR img{
    width: 15px;
    margin-right: 5px;
  }

  .LectureItem_lecture_last_name__v-iXR > span{
    margin-left: 10px;
    word-break: break-all;
  }
}

.CreateQuizTemplatePage_main_container__1KzF- {
    margin-top: 90px;
    height: 100%;
    width: 100%;
    font-family: Montserrat;
}

.CreateQuizTemplatePage_feed_container__2lVsM {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 80vw;
    margin-top: 25px;
}

.CreateQuizTemplatePage_title_container__2wspN {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.CreateQuizTemplatePage_buttons__12fC6 {
  display: flex;
  justify-content: space-between;
  width: 17%;
}

.CreateQuizTemplatePage_middle_container__38MhZ {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.CreateQuizTemplatePage_left_container__3mF6X {
    /* margin-right: 0px; */
    width: 100%;
}

/* --- add question stuff */
.CreateQuizTemplatePage_addQuestionCard__1Hya5 {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    display: flex;
    width: 100%;
    height: 145px;
    margin-bottom: 20px;
    margin-top: 15px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: relative;
    cursor: pointer;
  }
  
  .CreateQuizTemplatePage_innerBorder__2bOJW {
    display: flex;
    border: 1px dashed #b4b4b4;
    border-radius: 10px;
    width: 92%;
    height: 62%;
    cursor: pointer;
    justify-content: center;
  }
  
  .CreateQuizTemplatePage_addQuestionText__3x_Zt {
    display: flex;
    flex-direction: row;
    justify-content: space-end;
    align-items: center;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
  }
  
  .CreateQuizTemplatePage_plus__2Xnr- {
    width: 30px;
  }

  .CreateQuizTemplatePage_addChoices__dGe_7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  
  .CreateQuizTemplatePage_addChoices__dGe_7 > img {
    margin-top: 10px;
    width: 22px;
    cursor: pointer;
  }

  .CreateQuizTemplatePage_back_btn__2uzB0 {
    font-weight: 400;
    color: #858585;
    cursor: pointer;
    padding: 20px;
    font-size: 0.875rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  @media(max-width: 580px){
    .CreateQuizTemplatePage_main_container__1KzF- {
      margin-top: 0px;
    }

   .CreateQuizTemplatePage_feed_container__2lVsM {
      width: 100%;
      margin-top: 0px;
    }

   .CreateQuizTemplatePage_middle_container__38MhZ {
     margin-top: 10px;
     width: 90%;
   }

  .CreateQuizTemplatePage_plus__2Xnr- {
    width: 25px;
  }
  .CreateQuizTemplatePage_addQuestionCard__1Hya5 {
    height: 82px;

  }
  .CreateQuizTemplatePage_btnPosition__3zZyd{
    float: right;
    margin-left: 70%;
    margin-bottom: 100px;
  }
}
.QuestionTypeModal_modal_container__1sV-W {
    text-align: left;
    background: #ffffff 0% 0% no-repeat padding-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-top: 5px;
    padding: 24px 0px 24px 40px;
    border-radius: 5px;
    width: 75vh;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    grid-gap: 20px;
    gap: 20px;
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .QuestionTypeModal_header__2TcNk {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    line-height: 17px;
    color: #A6A6A6;
    margin: 5px 0;
  }

  .QuestionTypeModal_question_types__3OYcA {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
  }

  .QuestionTypeModal_question_icon__3B7ER {
    box-sizing: border-box;
    width: 50%;
    margin: 12px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .QuestionTypeModal_question_icon__3B7ER > img {
    margin-right: 6px;
    /* height: 17px; */
  }

  .QuestionTypeModal_question_icon__3B7ER > span {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
  }
  @media (max-width: 580px){
    .QuestionTypeModal_modal_container__1sV-W {
      padding: 25px;
      width: 37vh;
      border-radius: 14px;
    }
    .QuestionTypeModal_question_types__3OYcA {
      display: grid;
      grid-template-columns: 1fr;
    }
    .QuestionTypeModal_question_icon__3B7ER > span {
      white-space: nowrap;
    }
  }
.OuterQuestionContainer_boxContainer__2tc8h {
  display: flex;
  flex-direction: column;
  /* margin-top: 200px; */
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 10px;
}

.OuterQuestionContainer_partOne__3bCWF {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.OuterQuestionContainer_partOne__3bCWF>div {
  flex: 1 1;
}

.OuterQuestionContainer_leftChild__2C6aw {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  grid-gap: 8px;
  gap: 8px;
  background-color: red;
  height: 44px;
  width: 354px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
}

.OuterQuestionContainer_leftChild__2C6aw span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.OuterQuestionContainer_rightChild__3POtc {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.OuterQuestionContainer_rightChild__3POtc span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.OuterQuestionContainer_rightChild__3POtc img {
  margin-right: 22px;
  cursor: pointer;
}

#OuterQuestionContainer_requiredIcon__3AVcD {
  margin-right: 5px;
}

.OuterQuestionContainer_choices__Xuky6 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.OuterQuestionContainer_addChoices__1iaoU {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;
}

.OuterQuestionContainer_addChoices__1iaoU > img {
  width: 22px;
}

.OuterQuestionContainer_maxMarksSpan__3-zvg {
  white-space: nowrap;
  padding-right: 10px;
}

.OuterQuestionContainer_rightChild__3POtc > input {
  border: 1px solid #555555;
  border-radius: 60px;
  /* padding: 0 0.4rem; */
  text-align: center;
  width: 2.7rem;
  line-height: 1.5rem;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}




@media(max-width: 630px) {
  .OuterQuestionContainer_rightChild__3POtc img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }

  .OuterQuestionContainer_leftChild__2C6aw {
    padding: 6px 7px;
    width: 70%;
    border-radius: 8px;
    height: 28px;
    margin-top: 10px;
  }

  .OuterQuestionContainer_boxContainer__2tc8h {
    width: 89%;
  }

  .OuterQuestionContainer_partOne__3bCWF {
    display: block;
  }

  .OuterQuestionContainer_rightChild__3POtc {
    justify-content: center;
  }

  .OuterQuestionContainer_rightChild__3POtc span {
    font-size: 13px;
    line-height: 10px;
    font-weight: 500;
  }

  .OuterQuestionContainer_leftChild__2C6aw img {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  .OuterQuestionContainer_leftChild__2C6aw span {
    color: #555;
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media(max-width: 515px) {
  .OuterQuestionContainer_leftChild__2C6aw {
    padding: 3px 3.5px;
  }

  #OuterQuestionContainer_requiredIcon__3AVcD {
    width: 25px;
    height: 25px;
  }
}

@media(max-width: 405px) {

  #OuterQuestionContainer_requiredIcon__3AVcD {
    width: 20px;
    height: 20px;
  }

  .OuterQuestionContainer_leftChild__2C6aw {
    grid-gap: 4px;
    gap: 4px;
  }

}
.QuestionInputField_questionInputField__1IbrJ {
    border: 1px solid #DBDBDB;
    border-radius: 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
.FillInBlankContainer_FillInTheBlankQuestion__20noK {
    /* margin-top: 200px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.FillInBlankContainer_FillInTheBlankQuestion__20noK input {
    border: 1px solid #DBDBDB;
    border-radius: 30px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    margin-right: 25px;
}

.FillInBlankContainer_FillInTheBlankQuestion__20noK input:focus {
    outline: none;
}

.FillInBlankContainer_FillInTheBlankQuestion__20noK button {
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: white;
    border: none;
    color: #ED1E24;
    z-index: 1;
    white-space: nowrap;
}

@media (max-width: 580px){
    .FillInBlankContainer_FillInTheBlankQuestion__20noK {
        display: block;
    }
    .FillInBlankContainer_FillInTheBlankQuestion__20noK button {
        float: right;
        margin-top: 10px;
    }
}
.PossibleChoice_main__JDaG9{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 5px;
}

.PossibleChoice_text_input__377PT {
    outline: none;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    line-height: 24px;
    margin-left: 4px;
    width: 100%;
}

.PossibleChoice_text_input__377PT:focus {
    border-bottom: 1px solid #000000;
    outline: none;
}

.PossibleChoice_text_input__377PT::-webkit-input-placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.PossibleChoice_text_input__377PT::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.PossibleChoice_correct__ZHBB5 {
    opacity: 0;
    display: flex;
    align-items: center;
    flex-wrap: none;
    flex-direction: row;
}

.PossibleChoice_correct__ZHBB5 > span {
    font-family: 'Montserrat';
    font-size: 16px; 
    font-weight: 400;
    line-height: 24px;
    white-space:nowrap;
    margin: 0 5px;
}

.PossibleChoice_correct__ZHBB5 > img {
    width: 12px;
    cursor: pointer;
    padding-right: 5px;
}

input:focus ~ .PossibleChoice_correct__ZHBB5 {
  opacity: 1;
}

.PossibleChoice_correct__ZHBB5 + img {
    /* visibility: hidden; */
    width: 18px;
    height: 16px;
    margin-right: 2rem;
    margin-left: 5px;
}

/* -- for grid questions */
.PossibleChoice_text_input_grid__R6SzZ {
    outline: none;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    color: #212121;
    line-height: 24px;
    margin-left: 4px;
    margin-right: 2rem;
}

.PossibleChoice_text_input_grid__R6SzZ:focus {
    border-bottom: 1px solid #000000;
    outline: none;
}

.PossibleChoice_text_input_grid__R6SzZ::-webkit-input-placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.PossibleChoice_text_input_grid__R6SzZ::placeholder{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A6A6A6;
  }

.PossibleChoice_grid_choice__2b9JH {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
}

.PossibleChoice_text_input_grid__R6SzZ + img {
    opacity: 0;
    width: 12px;
    cursor: pointer;
    margin-right: 1rem;
}

.PossibleChoice_text_input_grid__R6SzZ:focus ~ img {
    opacity: 1;
}

.PossibleChoice_upload_icon__1cAoH {
    width: 1.5rem;
    cursor: pointer;
    padding-left: 8px;
  margin-right: 10px;
  
}

.PossibleChoice_image_container__nQu0g {
    width: 50%;
}

.PossibleChoice_fib_option__3iZY_, .PossibleChoice_img_empty_option__1Hgan{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.PossibleChoice_fib_cross__uoU5S, .PossibleChoice_img_cross__sheXJ {
    padding-top: 12px;
    margin-left: auto;
    cursor: pointer;
}

.PossibleChoice_image_checked__Ak8SC {
    margin-left: auto;
    margin-right: 25px;
    width: 18px;
    height: 16px;
}

@media (max-width: 580px){
    .PossibleChoice_grid_choice_inner__2NPNR{
        display: flex;
        flex-direction: row;
    }
    .PossibleChoice_text_input_grid__R6SzZ {
        width: 50%;
    }
}
.QuizTemplateForm_main_container__2S2bs {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}
.QuizTemplateForm_back_btn__2FKSF {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.QuizTemplateForm_feed_container__sk5_G {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130vh;
}
.QuizTemplateForm_title_container__qkuTV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.QuizTemplateForm_form_container__2d4Di {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  /* margin: 25px 0; */
  width: 100%;
  margin-bottom: 85px;
  margin-top: 20px;
}
.QuizTemplateForm_input_label__2L9sY {
  font-size: 13px;
  /* margin: 0 10px; */
  /* padding-left: 1rem; */
  /* margin-left: 0px; */
  /* margin-top: 20px; */
  font-weight: normal;
}

.QuizTemplateForm_add_template__33y1D {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 99.5%;
  margin-top: 20px;
  margin-bottom: 0px;
  font: normal normal 500 16px Montserrat;
}

.QuizTemplateForm_input_title_container__30M9q {
  display: flex;
  align-items: center;
  font: normal normal 500 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin: 15px 0;
}
.QuizTemplateForm_lable_icon__21ZnE {
  margin-right: 10px;
}
.QuizTemplateForm_input_field__JsaRf {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.QuizTemplateForm_input_field1__2teR6 {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 140px;
  outline: none;
  padding: 17px 0px 15px 17px;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.QuizTemplateForm_attachment_icon_container__1zZoL {
  /* padding: 0px 15px; */
  display: flex;
  flex-direction: row;
}
.QuizTemplateForm_uploadBox__c_ogT input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
  position: relative;
}
.QuizTemplateForm_fileUpload__3VSAH {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  /* top: 0; */
  /* left: 0; */
  /* bottom: 0; */
  /* right: 0; */
  width: 22px;
  /* height: 100%; */
}
.QuizTemplateForm_photo__2w7Qj {
  /* transition: all .3s; */
  height: 22px;
  cursor: pointer;
}
.QuizTemplateForm_uploadBox__c_ogT:hover {
  -webkit-filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
          filter: invert(39%) sepia(71%) saturate(5058%) hue-rotate(213deg)
    brightness(95%) contrast(93%);
}

/* assigned to modal css */
.QuizTemplateForm_modal_container__2c577 {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding: 20px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 90vh;
}
.QuizTemplateForm_modal_header__9hDD9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.QuizTemplateForm_close_icon__3GEQE {
  cursor: pointer;
  width: 30px;
}
.QuizTemplateForm_max_due_container__2W2uJ {
  display: flex;
  margin-top: 0px;
}

.QuizTemplateForm_lock_dueDate__HchK0 {
  /* color: yellow; */
  display: flex;
  margin: 16px 0;
  font-size: 14px;
  opacity: 0.7;
}
.QuizTemplateForm_lock_dueDate__HchK0 p {
  margin-left: 4px;
}

.QuizTemplateForm_checkDone__13gfT {
  color: #ED1E24;
}
input[type="checkbox"] {
  accent-color: #ED1E24;
}

.QuizTemplateForm_assign_btn_container__3RCTH {
  margin-top: 0px;
  float: right;
}
.QuizTemplateForm_date_time_container__2cGop {
  display: flex;
  font-family: Montserrat;
}

/* discard modal */
.QuizTemplateForm_discard_modal_container__3OU2n {
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 5px;
  padding: 30px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: 63vh;
}
.QuizTemplateForm_discard_modal_title__3aCSJ {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.95em;
}
.QuizTemplateForm_discard_modal_msg__JV50D {
  margin: 25px 0px;
  font-size: 0.95em;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.QuizTemplateForm_discard_btn__3wU91 {
  float: right;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

/* MEDIA */

@media (max-width: 1450px) {
  .QuizTemplateForm_feed_container__sk5_G {
    width: 120vh;
  }
}

@media (max-width: 960px) {
  .QuizTemplateForm_feed_container__sk5_G {
    width: 90%;
  }
  .QuizTemplateForm_modal_container__2c577 {
    width: 90%;
  }
}
@media (min-width: 780px) and (max-width: 800px){
  .QuizTemplateForm_main_container__2S2bs {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .QuizTemplateForm_main_container__2S2bs {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .QuizTemplateForm_max_due_container__2W2uJ {
    display: flex;
    flex-direction: column;
  }
  .QuizTemplateForm_modal_container__2c577 {
    width: 85%;
  }
  .QuizTemplateForm_date_time_container__2cGop {
    flex-direction: column;
  }
  .QuizTemplateForm_date_time_container__2cGop > div {
    margin-bottom: 10px;
  }
  .QuizTemplateForm_back_btn__2FKSF{
    display: none;
  }
  .QuizTemplateForm_close_icon__3GEQE {
    width: 22px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 510px) {
  .QuizTemplateForm_discard_modal_container__3OU2n {
    width: 83%;
  }
  .QuizTemplateForm_form__2YGlt{
    margin: 0 5px !important;
  }
  .QuizTemplateForm_form_container__2d4Di {
    box-shadow: none;
    padding: 0px;
  }
}

@media (max-width: 380px) {
  .QuizTemplateForm_discard_modal_container__3OU2n {
    width: 76%;
  }
}
.QuizTemplateForm_form__2YGlt{
  margin: 0 30px;
}
input::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
input::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
.QuizTemplateForm_modal_form__25JTG{
  margin: 0 15px;
}

.QuizTemplateForm_due_date_time_container__2w4v8{
  margin-left: 20px;
  margin-top: 0px;
}
@media (max-width: 780px) {
  .QuizTemplateForm_due_date_time_container__2w4v8 {
    margin-left: 0px ;

  }
}
@media (max-height: 700px) {
  .QuizTemplateForm_input_title_container__30M9q {
    font: normal normal 600 14px Montserrat;
  }
}

.QuizTemplateForm_loader_style__2Jlpt{
  text-align: center;
   margin-top: 200px
}

.QuizTemplateForm_radio_group__2nBXp{
  /* margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; */
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.QuizTemplateForm_tooltip_icon__1gQkJ{
  margin-right: 14px;
}

@media (max-width: 580px){
  .QuizTemplateForm_add_template__33y1D {
    display: none;
  }
  .QuizTemplateForm_input_title_container__30M9q {
    font: normal normal 500 14px Montserrat;
  }
  .QuizTemplateForm_lable_icon__21ZnE {
    width: 20px;
    height: 20px;
  }
  .QuizTemplateForm_input_field__JsaRf {
    height: 50px;
    border: 1px solid #DBDBDB;
    font-size: 13px;
    padding: 10px;
  }
  .QuizTemplateForm_input_field1__2teR6 {
    border-radius: 20px;
    border: 1px solid #DBDBDB;
    height: 251px;
    padding: 12px;
    font-size: 13px;
  }
  .QuizTemplateForm_mob_btn__-bWDR{
    float: right;
    margin-top: 15px;
  }
}
.CreateQuizPage_main_container__YP-oV {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}
.CreateQuizPage_back_btn__3si0g {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}
.CreateQuizPage_feed_container__3eHRj {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 130vh;
}
.CreateQuizPage_title_container__3yKee {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CreateQuizPage_form_settings_container__53hWs {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.CreateQuizPage_form_container__-Qo1h {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  width: 100%;
  flex: 2 1;
}
.CreateQuizPage_input_label__12W3v {
  font-size: 13px;
  /* margin: 0 10px; */
  /* padding-left: 1rem; */
  /* margin-left: 0px; */
  /* margin-top: 20px; */
  font-weight: normal;
}

/* .add_template {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 99.5%;
  margin-top: 20px;
  margin-bottom: 0px;
  font: normal normal 500 16px Montserrat;
} */

.CreateQuizPage_input_title_container__YGQDJ {
  display: flex;
  align-items: center;
  font: normal normal 500 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  margin: 15px 0;
}
.CreateQuizPage_label_icon__1klbb {
  margin-right: 10px;
}
.CreateQuizPage_input_field__181hy {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  padding: 3px 4px 3px 22px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}
.CreateQuizPage_input_field1__37Ma_ {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 140px;
  outline: none;
  padding: 17px 0px 15px 17px;
  border: 1px #c2c2c2 solid;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
}

.CreateQuizPage_settings_container__1pm0S {
  flex: 1 1;
  margin-left:10px;
}

.CreateQuizPage_tooltip_icon__3c_zh{
  margin-left: 0.5rem;
  height: 0.8rem;
}

/* MEDIA */

@media (max-width: 1450px) {
  .CreateQuizPage_feed_container__3eHRj {
    width: 120vh;
  }
}

@media (max-width: 960px) {
  .CreateQuizPage_feed_container__3eHRj {
    width: 90%;
  }
  .CreateQuizPage_modal_container__3MxLr {
    width: 90%;
  }
}
@media (min-width: 780px) and (max-width: 800px){
  .CreateQuizPage_main_container__YP-oV {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .CreateQuizPage_main_container__YP-oV {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }
  .CreateQuizPage_max_due_container__vIt82 {
    display: flex;
    flex-direction: column;
  }
  .CreateQuizPage_modal_container__3MxLr {
    width: 85%;
  }
  .CreateQuizPage_date_time_container__1ZRlH {
    flex-direction: column;
  }
  .CreateQuizPage_date_time_container__1ZRlH > div {
    margin-bottom: 10px;
  }
  .CreateQuizPage_back_btn__3si0g{
    display: none;
  }
  .CreateQuizPage_close_icon__CDHfz {
    width: 22px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@media (max-width: 510px) {
  .CreateQuizPage_discard_modal_container__1ZJnz {
    width: 83%;
  }
  .CreateQuizPage_form__1gY33{
    margin: 0 5px !important;
  }
  .CreateQuizPage_form_container__-Qo1h {
    box-shadow: none;
    padding: 0px;
  }
}

@media (max-width: 380px) {
  .CreateQuizPage_discard_modal_container__1ZJnz {
    width: 76%;
  }
}
.CreateQuizPage_form__1gY33{
  margin: 0 30px;
}
input::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
input::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::-webkit-input-placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
textarea::placeholder{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #A6A6A6;
}
.CreateQuizPage_modal_form__3c5da{
  margin: 0 15px;
}

.CreateQuizPage_due_date_time_container__1L8Ys{
  margin-left: 20px;
  margin-top: 0px;
}
@media (max-width: 780px) {
  .CreateQuizPage_due_date_time_container__1L8Ys {
    margin-left: 0px ;

  }
}
@media (max-height: 700px) {
  .CreateQuizPage_input_title_container__YGQDJ {
    font: normal normal 600 14px Montserrat;
  }
}

.CreateQuizPage_loader_style__1ij9n{
  text-align: center;
   margin-top: 200px
}

.CreateQuizPage_radio_group__8XsYK{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
}

.CreateQuizPage_tooltip_icon__3c_zh{
  margin-right: 14px;
}

@media(max-width: 580px){
  .CreateQuizPage_settings_container__1pm0S{
    display: none;
  }
  .CreateQuizPage_input_title_container__YGQDJ {
    font: normal normal 500 14px Montserrat;
  }
  .CreateQuizPage_input_field1__37Ma_ {
    min-height: 251px;
  }
  .CreateQuizPage_mob_btn__1TckK{
    float: right;
    margin-top: 15px;
  }
  .CreateQuizPage_setting_btn__2pPD_{
    
  }
}
.QuizSettings_main__2c9Io {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  align-items: flex-start;
  padding: 32px 24px;
  /* gap: 0.4rem; */
  background: #ffffff;
  font-family: "Montserrat";
  max-width: 400px;
}

.QuizSettings_dropdown__KPI2c {
  display: flex;
  justify-content: space-between;
}

.QuizSettings_dropdown__KPI2c > div {
  margin-right: 10px;
}

.QuizSettings_header__3WojD {
  display: flex;
  align-items: center;
  margin: 0px;
}

.QuizSettings_header__3WojD > span {
  font-weight: 500;
  margin-left: 5px;
}

.QuizSettings_header__3WojD > img {
  width: 20px;
  height: 20px;
}
.QuizSettings_time__2s2oC{
  width: 100%;
}

.QuizSettings_time_switch__16nGj > span {
  font-weight: 500;
  margin-right: 8px;
}

.QuizSettings_order__QTrcF, .QuizSettings_attempts__2NKz5, .QuizSettings_reveal__1cxS0 {
  width: 100%;
}

.QuizSettings_order__QTrcF > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.QuizSettings_attempts__2NKz5 > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.QuizSettings_reveal__1cxS0 > span {
  font-weight: 500;
  display: block;
  /* margin:10px 0 6px 0; */
  margin: 10px 0;
}

.QuizSettings_time_switch__16nGj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QuizSettings_time_container__3YlgK {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.QuizSettings_time_container__3YlgK > span {
  white-space: nowrap;
}

.QuizSettings_time_unit__1dAzw {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  align-items: center;
}

.QuizSettings_time_unit__1dAzw > span {
    font-size: 0.625rem;
    font-weight: 400;
    font-family: Montserrat;
    margin-left: 10px;
    line-height: 2.4;
}

.QuizSettings_time_input__6e91_ {
  border-radius: 25px;
  /* width: 90%; */
  max-width: 4rem;
  /* margin-left: 15px; */
  height: 40px;
  outline: none;
  border: 1px #c2c2c2 solid;
  /* padding-left: 20px; */
  /* padding: 3px 4px 3px 22px; */
  /* box-sizing: border-box; */
  font-size: 14px;
  font-weight: 400;
  font-family: Montserrat;
  margin-left: 6px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: center;
}

.QuizSettings_header_cross__1OBCA{
  display: none;
}

@media (max-width: 580px){
  .QuizSettings_main__2c9Io{
    width:80%;
  }
  .QuizSettings_header__3WojD > span {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 500;
  }
  .QuizSettings_time_switch__16nGj > span {
    font-weight: 500;
    margin-right: 8px;
    font-size: 14px;
  }
  .QuizSettings_order__QTrcF > span {
    font-weight: 500;
    font-size: 14px;
  }
  .QuizSettings_attempts__2NKz5 > span {
    font-weight: 500;
    font-size: 14px;
  }
  .QuizSettings_reveal__1cxS0 > span {
    font-weight: 500;
    font-size: 14px;
  }
  .QuizSettings_header_top__1u0VR{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .QuizSettings_header_cross__1OBCA{
    display:block;
  }
  
}
.DropDown_text__20lBE {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat';

}
.AttemptAndPreviewQuizPage_main_container__2GnHX {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}

.AttemptAndPreviewQuizPage_back_btn__3PlfH {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.AttemptAndPreviewQuizPage_loader_style__3HhWX {
  text-align: center;
  margin-top: 200px;
}

@media (max-width: 580px){
  .AttemptAndPreviewQuizPage_main_container__2GnHX{
    margin-top: 0px;
    margin-bottom: 150px;
  }
}
.ViewQuizStatus_main_container__14ziM {
    margin-top: 90px;
    height: 100%;
    width: 100%;
  }
  
  .ViewQuizStatus_back_btn__2GcCx {
    color: #858585;
    font-weight: 400;
    padding: 20px;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
  }
  
  .ViewQuizStatus_feed_container__1r2zg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 1018px;
    padding-top: 12px;
  }
  
  .ViewQuizStatus_title_container__14cgX {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .ViewQuizStatus_dueDate__16TUt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
  }
  
  .ViewQuizStatus_dropDown__30UPp ul {
    list-style: none;
    padding: 1px 0px;
  }
  
  .ViewQuizStatus_submissions_container__2n68O {
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    padding: 20px 0;
    margin: 15px auto;
    margin-bottom: 0%;
    width: 130vh;
    /* for content */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .ViewQuizStatus_choice__2_jCP {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: Montserrat;
  }
  
  .ViewQuizStatus_choice__2_jCP > .ViewQuizStatus_status__3mAD0 {
    margin: 0 15px;
  }
  
  .ViewQuizStatus_status__3mAD0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
    cursor: pointer;
  }
  
  .ViewQuizStatus_count__2_Tnk {
    font-size: 2rem;
  }
  
  .ViewQuizStatus_options__wS8px {
    font-size: 0.9rem;
    color: #7b7b7b;
  }
  
  .ViewQuizStatus_bottom_container__M_Ua2 {
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  .ViewQuizStatus_student_list_container__1JSR6 {
    /* box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 10px; */
    /* padding: 0px 10px; */
    margin: 10px auto;
    /* margin-top: 15px; */
    width: 100%;
    font-family: Montserrat;
    margin-top: 0;
    max-height: 290px;
    /* overflow-y: scroll; */
    min-height: 210px;
  }
  
  .ViewQuizStatus_inner_list_container__151dy{
    max-height: 220px;
    min-height: 220px;
    overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  .ViewQuizStatus_inner_list_container__151dy::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  
  .ViewQuizStatus_return_btn__3HoJ9 {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
  }
  
  .ViewQuizStatus_student_list_header__3NH3u {
    display: flex;
    /* justify-content: space-between; */
    color: #858585;
    font-family: Montserrat;
    font-weight: 400;
  
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px;
    align-items: center;
    /* width: 95%; */
  }
  
  .ViewQuizStatus_student_list_header_quiz__hr5co {
    display: flex;
    justify-content: space-between;
    color: #858585;
    font-family: Montserrat;
    font-weight: 400;
  
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px;
    align-items: center;
    /* width: 95%; */
  }
  
  .ViewQuizStatus_student_list_heading__1LXlb {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ViewQuizStatus_student_list_date__158xy {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ViewQuizStatus_student_list_rmk__1EIr_ {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ViewQuizStatus_student_list_grade__V3560 {
    width: 23%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ViewQuizStatus_student_list_assign__2upQf {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .ViewQuizStatus_checkbox_round__sOTmC {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #767676;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0 20px 0 0;
  }
  
  .ViewQuizStatus_checkbox_round__sOTmC:checked {
    background-color: #ED1E24;
    border: 1px solid #ED1E24;
  }
  
  .ViewQuizStatus_unassign_dropdown__1JohA {
    /* height: 50px; */
    cursor: pointer;
    margin-left: 10px;
  }
  
  /* Not used */
  .ViewQuizStatus_assignment_list__36Xz1 {
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #3f3f411a;
    margin-top: 15px;
    max-height: 400px;
    overflow-y: scroll;
  }
  .ViewQuizStatus_student_list_combo__2Rug8 {
    display: flex;
    width: 100%;
  }
  .ViewQuizStatus_view_ass_btn_span_mobile__2wU5r {
    display: none;
  }
  .ViewQuizStatus_view_ass_btn_span__tA7dh {
    margin-right: 10px;
  }
  .ViewQuizStatus_no_std_msg__3-tUf {
    height: 210px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #7b7b7b;
    font-size: 14px;
    justify-content: center;
  }
  @media (min-width: 800px) and (max-width: 1018px) {
    .ViewQuizStatus_main_container__14ziM {
      margin-top: 94px !important;
    }
  }
  
  @media (max-width: 1018px) {
    .ViewQuizStatus_feed_container__1r2zg {
      width: 90%;
    }
    .ViewQuizStatus_main_container__14ziM {
      margin-top: 0;
    }
    .ViewQuizStatus_student_list_header__3NH3u {
      display: none;
    }
    .ViewQuizStatus_student_list_header_quiz__hr5co {
      display: none;
    }
    .ViewQuizStatus_title_container__14cgX {
      flex-direction: column;
      align-items: flex-start;
    }
    .ViewQuizStatus_dueDate__16TUt {
      margin-top: 15px;
    }
    .ViewQuizStatus_view_ass_btn_span__tA7dh {
      display: none;
    }
    .ViewQuizStatus_choice__2_jCP > .ViewQuizStatus_status__3mAD0 {
      margin: 0 6px;
    }
    .ViewQuizStatus_view_ass_btn_span_mobile__2wU5r {
      display: inline-block;
    }
    .ViewQuizStatus_submissions_container__2n68O > div {
      margin: 0 10px;
    }
    .ViewQuizStatus_back_btn__2GcCx{
      display: none;
    }
  }
  @media (max-width: 1099px) {
    .ViewQuizStatus_bottom_container__M_Ua2 {
      margin-bottom: 85px;
    }
  }
  
  @media (max-width: 800px) {
    .ViewQuizStatus_student_list_container__1JSR6 {
      max-height: none;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 800px) {
    .ViewQuizStatus_inner_list_container__151dy {
      max-height: none;
      overflow-y: auto;
    }
  }
  
  @media (max-width: 420px) {
    .ViewQuizStatus_status__3mAD0{
      max-width: 20%;
    }
    .ViewQuizStatus_count__2_Tnk {
      font-size: 1.75rem;
    }
    .ViewQuizStatus_options__wS8px {
      font-size: 0.75rem;
      overflow: hidden;
      width:99%;
      white-space: nowrap;
      text-overflow: ellipsis;   
    }
    .ViewQuizStatus_choice__2_jCP{
      margin-left:auto;
      max-width: 68%;
    }
  }
  
  @media (max-width: 338px) {
    .ViewQuizStatus_view_ass_btn_span_mobile__2wU5r{
      display: none;
    }
  }
  
  .ViewQuizStatus_loader_style__14i58{
    text-align: center;
     margin-top: 200px
  }

  @media (max-width: 580px){
    .ViewQuizStatus_feed_container__1r2zg {
      width: 95%;
    }
    .ViewQuizStatus_count__2_Tnk {
      font-size: 18px;
      font-weight: 500;
    }
    .ViewQuizStatus_no_std_msg__3-tUf {
      padding: 5px;
    }
    .ViewQuizStatus_return_btn__3HoJ9 {
      padding: 25px;
    }
  }
  
  
.ReattemptQuizPage_main_container__2RwMn {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.ReattemptQuizPage_back_btn__1qZM_ {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
}

.ReattemptQuizPage_title_container__2J2aA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.ReattemptQuizPage_due_date__30-Fc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
}

.ReattemptQuizPage_content_container__2JL1y {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px;
  padding-top: 12px;
}

.ReattemptQuizPage_middle_container__DB2C0 {
  display: flex;
  width: 100%;
}

.ReattemptQuizPage_left_container__6z1FM {
  width: 70%;
  padding: 2rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 10px;
  min-height: 400px;
  margin-bottom: 100px;
  /* border: 1px solid red; */
  color: #707070;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ReattemptQuizPage_right_container__n7v76 {
  display: flex;
  flex-direction: column;
  width: 30%;

  margin-left: 1.5rem;
  /* border: 1px solid green; */
  /* box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1); */
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ReattemptQuizPage_attempt_container__2KZe7 {
  display: flex;
  flex-direction: column;
  /* width: 25%; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.ReattemptQuizPage_max_attempt__fMVYU {
  margin-bottom: 1.5rem;
}
.ReattemptQuizPage_your_attempt__12qLk {
  margin-bottom: 2.5rem;
}

.ReattemptQuizPage_btn_label__n-WP2 {
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.ReattemptQuizPage_grade_container__Pnegl {
  /* box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1); */
  padding: 1.5rem;
  border-radius: 10px;
}

.ReattemptQuizPage_grade_label__1JAHZ {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ReattemptQuizPage_marks_div__uUKbx {
  margin-top: 1rem;
  border-radius: 59px;
  border: 1px solid #a6a6a6;
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem 2rem;
  text-align: center;
}

@media (max-width: 580px){
  .ReattemptQuizPage_main_container__2RwMn {
    margin: 0px;
    height: 100%;
    width: 87%;
  }
  .ReattemptQuizPage_content_container__2JL1y {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  .ReattemptQuizPage_title_container__2J2aA {
    display: block;
    width: 100%;
    margin-bottom: 1rem;  
  }
  .ReattemptQuizPage_title_container_heading__2hUNU{
    width: 100%;
  }
  .ReattemptQuizPage_due_date__30-Fc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    margin-top: 10px;
    color: #555;
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .ReattemptQuizPage_middle_container__DB2C0 {
    display: block;
    width: 100%;
  }
  .ReattemptQuizPage_left_container__6z1FM {
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
    border-radius: 10px;
    min-height: 131px;
    margin-bottom: 15px;
    color: #707070;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.25px;
  }
  .ReattemptQuizPage_right_container__n7v76 {
    display: flex;
    flex-direction: column;
    width: 109%;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    border-radius: 10px;
    margin-left: 0rem;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ReattemptQuizPage_your_attempt__12qLk {
    margin-bottom: 2rem;
  }
  .ReattemptQuizPage_btn_label__n-WP2 {
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .ReattemptQuizPage_attempt_container__2KZe7 {
    display: flex;
    flex-direction: column;
    /* width: 25%; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  }
  .ReattemptQuizPage_grade_label__1JAHZ {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ReattemptQuizPage_marks_div__uUKbx {
    margin-top: 1rem;
    border-radius: 59px;
    border: 1px solid #a6a6a6;
    padding: 13.777px 27.554px;
    text-align: center;
    width: 60%;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ReattemptQuizPage_grade_container__Pnegl {
    padding: 0rem;
  }
  
}
.ReattemptConfirmationModal_main__BVwr0 {
    background: #ffffff 0% 0% no-repeat padding-box;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1.5rem 1.5rem;
    padding-top: 5px;
    border-radius: 25px;
    width: 35vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ReattemptConfirmationModal_main__BVwr0 > img {
    width: 7rem;
    padding: 10px 0;
  }
  
  .ReattemptConfirmationModal_question_text__2w1An {
    font-family: Montserrat;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
  
  .ReattemptConfirmationModal_btn_div__3pgOd {
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* margin-bottom: 20px; */
    width: 100%;
  }
  
  .ReattemptConfirmationModal_btn_label__zfZML {
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.0625rem;
    letter-spacing: 0em;
    text-align: left;
  }
  /*   
    @media(max-width: 600px){
      .action_modal_container {
        width: 75%
      }
    }
    
    @media(max-width: 400px){
      .action_modal_container {
        width: 70%
      }
    } */
  
  @media (max-width: 600px){
    .ReattemptConfirmationModal_main__BVwr0{
      width:80%;
    }  
  }
.GradebookView_main_container__3AuIr {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
  box-sizing: border-box;
}

.GradebookView_back_btn__1-ktP {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.GradebookView_loader_style__2gDTr {
  text-align: center;
  margin-top: 200px;
}

.GradebookView_gradebook_outer_container__2VxPA {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* border: 1px solid greenyellow; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.GradebookView_header__23zoi {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
  /* border: 1px solid black; */
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.05),
    0px 2px 8px 0px rgba(25, 33, 61, 0.04);
  border-radius: 10px;
  margin-bottom: 2.5rem;
}

.GradebookView_count_container__2-2k2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.GradebookView_count_label__1qfdY {
  color: #8a8a8a;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  margin-bottom: 0.875rem;
}

.GradebookView_count__3MnoR {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 90.909% */
}

.GradebookView_searchbar__1EdVd {
  display: flex;
  padding: 4px 12px;
  width: 40%;
  border: 1px solid #EBEBEB;
  text-decoration: none;  
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.GradebookView_menu_icon__30NpG {
  padding-right: 4px;
}

.GradebookView_searchbar__1EdVd > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: .25rem;
  color: #49454F;
  font-weight: 300;

}

.GradebookView_searchbar__1EdVd > input::-webkit-input-placeholder {
  font-family: Montserrat;
  color: #555555;
  font-weight: 300;

}

.GradebookView_searchbar__1EdVd > input::placeholder {
  font-family: Montserrat;
  color: #555555;
  font-weight: 300;

}

.GradebookView_label_and_button_container__2orEI {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  align-items: center;
  position: relative;
}

.GradebookView_student_details_label__l89Aa {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}

.GradebookView_print_btn__1z_Hu {
  display: flex;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #EF3F3F;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  cursor: pointer;
}

.GradebookView_print_btn__1z_Hu > img {
  width: 24px;
  margin-right: 10px;
}

.GradebookView_print_btn__1z_Hu:active { 
  -webkit-transform: scale(0.98); 
          transform: scale(0.98); 
} 

.GradebookView_table_header__1QBKj {
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.5fr;  
  /* grid-template-columns: 0.3fr 0fr 0.5fr 0.5fr 0.5fr 0.6fr;;   */
  align-items: center; 
  padding: 1.5rem;
  margin-bottom: 1.625rem;
}

.GradebookView_table_header__1QBKj > p {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
  margin-right: 0.5rem;
}

.GradebookView_hideBtn__1O_Sd {
  visibility: hidden;
}

.GradebookView_no_results__1tVz5 {
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  color: #ED1E24;
}

.GradebookView_pagination_container__3_IyS {
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 32px 16px 32px 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  margin-bottom: 90px;
}

/* -------- MEDIA QUERIES ---------- */

/* 
  ##Device = Tablets, Ipads 
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .GradebookView_main_container__3AuIr {
    margin-top: 3rem;
  }
  .GradebookView_gradebook_outer_container__2VxPA {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .GradebookView_label_and_button_container__2orEI {
    margin-bottom: 1rem;
  }

  .GradebookView_menu_icon__30NpG {
    display: none;
  }

  .GradebookView_student_details_label__l89Aa {
    font-size: 1.25rem;
  }

  .GradebookView_table_header__1QBKj {
    margin-bottom: 0;
  }

  .GradebookView_table_header__1QBKj > p {
    font-size: 1rem;
  }

  .GradebookView_count__3MnoR {
    font-size: 1.25rem;
  }

  .GradebookView_count_label__1qfdY {
    font-size: 1rem;
  }

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  .GradebookView_hide__1aMVM {
    display: none;
  }


  .GradebookView_label_and_button_container__2orEI {
    margin-bottom: 1rem;
  }

  .GradebookView_table_header__1QBKj {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 0.5fr;
    margin-bottom: 0;
  }

  .GradebookView_main_container__3AuIr {
    margin-top: 0.5rem;
  }
  .GradebookView_gradebook_outer_container__2VxPA {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .GradebookView_menu_icon__30NpG {
    display: none;
  }

  .GradebookView_student_details_label__l89Aa {
    font-size: 1.25rem;
  }

  .GradebookView_table_header__1QBKj > p {
    font-size: 1rem;
  }

  .GradebookView_count__3MnoR {
    font-size: 1.25rem;
  }

  .GradebookView_count_label__1qfdY {
    font-size: 1rem;
  }

  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .GradebookView_hide__1aMVM {
    display: none;
  }

  .GradebookView_label_and_button_container__2orEI {
    margin-bottom: 1rem;
  }

  .GradebookView_table_header__1QBKj {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 0.5fr;
    margin-bottom: 0;
  }

  .GradebookView_main_container__3AuIr {
    margin-top: 0.5rem;
  }
  .GradebookView_gradebook_outer_container__2VxPA {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .GradebookView_menu_icon__30NpG {
    display: none;
  }

  .GradebookView_student_details_label__l89Aa {
    font-size: 1.25rem;
  }

  .GradebookView_table_header__1QBKj > p {
    font-size: 1rem;
  }

  .GradebookView_count__3MnoR {
    font-size: 1.25rem;
  }

  .GradebookView_count_label__1qfdY {
    font-size: 1rem;
    margin-bottom: .3125rem;
  }
  
}
.GradebookTableRow_main__21Rkn {
  display: grid;
  grid-template-columns: 0.25fr 1fr 0.5fr 0.5fr 0.5fr 0.6fr;  
  align-items: center; 
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  margin-bottom: 1.25rem;
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.05),
    0px 2px 8px 0px rgba(25, 33, 61, 0.04);
  border-radius: 10px;
  padding: 1.5rem;
  box-sizing: border-box;
}

.GradebookTableRow_btn_label__2UG6M {
  color:#ed1e24;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.GradebookTableRow_view_btn__2HmV3 {
  background-color: #FFCFD0;
  border-color: #ED1E24;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .625rem .75rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  color: #ED1E24;
}

.GradebookTableRow_view_btn__2HmV3 > img {
  width: 24px;
  margin-right: 10px;
}

.GradebookTableRow_view_btn__2HmV3:active { 
  -webkit-transform: scale(0.98); 
          transform: scale(0.98); 
} 

/* -------- MEDIA QUERIES ---------- */

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  /* CSS */
  
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  /* CSS */
  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  .GradebookTableRow_hide__3HOjC {
    display: none;
  }

  .GradebookTableRow_main__21Rkn {
    display: grid;
    grid-template-columns: 0.25fr 1.5fr 1fr;
    font-size: .875rem;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 1.25rem;
  }

  .GradebookTableRow_view_btn__2HmV3 {
    font-size: .875rem;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .GradebookTableRow_hide__3HOjC {
    display: none;
  }
  .GradebookTableRow_main__21Rkn {
    display: grid;
    grid-template-columns: 0.25fr 1.5fr 1fr;
    font-size: .875rem;
    padding: 0.5rem 1rem;
    margin: auto;
    margin-bottom: 1.25rem;
  }

  .GradebookTableRow_view_btn__2HmV3 {
    font-size: 0.875rem;
  }
  
}
.GradebookStudentDetails_main_container__JjylZ {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
  box-sizing: border-box;
}

.GradebookStudentDetails_back_btn__2O0sH {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.GradebookStudentDetails_loader_style__6BKFC {
  text-align: center;
  margin-top: 200px;
}

.GradebookStudentDetails_gradebook_outer_container__2m1C- {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  /* border: 1px solid greenyellow; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.GradebookStudentDetails_gradebook_inner_container__1LyHJ {
  border-radius: 10px;
  background: #fff;
  margin-top: 1.5rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);

  min-height: 640px;
  padding: 1rem;
  margin-bottom: 5rem;
  box-sizing: border-box;
}

.GradebookStudentDetails_header__2ijcl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1.5rem;
  position: relative;
  box-sizing: border-box;
}

.GradebookStudentDetails_btn_container__5l4Vj {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: .5rem;
  gap: .5rem;
  position: absolute;
  right: 0;
  bottom: -75%;
  z-index: 5;
  box-sizing: border-box;
}

.GradebookStudentDetails_btn__1zRn5, .GradebookStudentDetails_btn2__36o4I {
  display: flex;
  padding: .375rem .625rem;
  border-radius: 5px;
  background-color: #FFF;
  justify-content: center;
  align-items: center;
  color: #ED1E24;
  border: 1.5px solid #ED1E24;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  cursor: pointer;
}
.GradebookStudentDetails_btn__1zRn5 > img, .GradebookStudentDetails_btn2__36o4I > img {
  width: 1.25rem;
  margin-right: 10px;
  cursor: pointer;
}

.GradebookStudentDetails_btn2__36o4I {
  position: relative;
  margin-left: 1.25rem;
  right: 9%;
}

.GradebookStudentDetails_hide_print_label__3NuTm {
  opacity: 0;
}

.GradebookStudentDetails_btn__1zRn5:active, .GradebookStudentDetails_btn2__36o4I:active { 
  -webkit-transform: scale(0.98); 
          transform: scale(0.98); 
} 

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
}

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_box__1F2fS {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_icon__oqOJD {
  background-color: #ED1E24;
  padding: .4688rem .4375rem;
  margin-right: 0.5rem;
  border-radius: 6px;
  
}

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_icon__oqOJD > img{
  width: .9375rem;
  height: .9375rem;
  display: block;
}

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_label__3H7g3 {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_label__3H7g3 span {
  font-weight: 600;
}

.GradebookStudentDetails_avg_score_container__174pQ {
  padding: .875rem 1.625rem;
  border-radius: 10px;
  background-color: #ffcfd0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.GradebookStudentDetails_avg_score_container__174pQ .GradebookStudentDetails_label__3H7g3 {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.GradebookStudentDetails_avg_score_container__174pQ .GradebookStudentDetails_avgScore__2tsWf {
  color: #ed1e24;
  font-family: Montserrat;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
}

/* ------------- MENU --------- */
.GradebookStudentDetails_btn_label__SZZtq {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

.GradebookStudentDetails_menu_item_label__3BqKM {
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.GradebookStudentDetails_menu_icon__1lJ-r {
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 0.5rem;
}

/* -------- MEDIA QUERIES ---------- */

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .GradebookStudentDetails_gradebook_outer_container__2m1C- {
    width: 90vw;
  }
  
}

/* 
  ##Device = Tablets, Ipads 
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 820px) and (max-width: 1024px) {

  .GradebookStudentDetails_gradebook_outer_container__2m1C- {
    width: 90vw;
  }
  
  

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 819px) {

  .GradebookStudentDetails_main_container__JjylZ {
    margin-top: 0;
  }

  .GradebookStudentDetails_gradebook_outer_container__2m1C- {
    width: 90vw;
  }

  .GradebookStudentDetails_header__2ijcl {
    display: grid;
    /* column-gap: .875rem;
    row-gap: 0.5rem; */
    grid-gap: 0.875rem;
    gap: 0.875rem;
    grid-template-columns: 1fr 1fr;
  }

  .GradebookStudentDetails_btn_container__5l4Vj {
    position: relative;
    bottom: 0;
    right: 0;
    justify-content: start;
    align-self: center;
    justify-self: end;
  }

  .GradebookStudentDetails_asg_quiz_count_container__1CTd7 {
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    align-items: flex-start;
  }
  
  /* asg_quiz_count_container .label {
    font-size: .875rem;

  } */

  .GradebookStudentDetails_avg_score_container__174pQ {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-row-start: 1;
    grid-column-start: 2;
    align-self: center;
    justify-self: end;
  } 


  .GradebookStudentDetails_btn__1zRn5 {
    display: none;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .GradebookStudentDetails_main_container__JjylZ {
    margin-top: 0;
  }

  .GradebookStudentDetails_gradebook_outer_container__2m1C- {
    width: 90vw;
  }

  .GradebookStudentDetails_header__2ijcl {
    display: grid;
    /* column-gap: .875rem;
    row-gap: 0.5rem; */
    grid-gap: 0.875rem;
    gap: 0.875rem;
    grid-template-columns: 1fr 1fr;
    margin: 0;
  }

  .GradebookStudentDetails_btn_container__5l4Vj {
    position: relative;
    bottom: 0;
    right: 0;
    justify-content: start;
    align-self: center;
    justify-self: end;
  }

  .GradebookStudentDetails_asg_quiz_count_container__1CTd7 {
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    align-items: flex-start;
  }
  
  .GradebookStudentDetails_asg_quiz_count_container__1CTd7 .GradebookStudentDetails_label__3H7g3 {
    font-size: .875rem;
  }

  .GradebookStudentDetails_avg_score_container__174pQ {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    grid-row-start: 1;
    grid-column-start: 2;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: end;
  } 

  .GradebookStudentDetails_avg_score_container__174pQ .GradebookStudentDetails_label__3H7g3 {
    font-size: .875rem;
  }

  .GradebookStudentDetails_avg_score_container__174pQ .GradebookStudentDetails_avgScore__2tsWf {
    font-size: 1.2rem;
  }

  .GradebookStudentDetails_btn__1zRn5 {
    display: none;
  }


  
  
  
}

.StudentNameComponent_name_container__uJsrK {
  display: flex;
  align-items: center;
}

.StudentNameComponent_student_name__sOiHW {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  margin-right: 10px;
}

.StudentNameComponent_class_name__31ha0 {
  color: #636363;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 228.571% */
  letter-spacing: -0.14px;
}

.StudentNameComponent_avatar__1jFuI {
  margin-right: 18px;
}
/* 
  @media (max-width: 800px) {
    .name_container {
      margin-bottom: 20px;
    }  
  } */

/* @media (max-width: 600px) {
  .name_container,
  .student_name,
  .avatar {
    display: flex;
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    white-space: nowrap;
  }
} */

@media (min-width: 320px) and (max-width: 480px) {

  .StudentNameComponent_student_name__sOiHW {
    font-size: .875rem;
  }


}

.GradebookTabComponent_main__2Lm3s {
  width: 100%;
  margin-top: 2rem;
}

.GradebookTabComponent_label_title__21sN3 {
  color: #434343;
  font-family: Montserrat;
  font-size: 1.125rem;
  margin-bottom: 1.2rem;
  margin-top: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.GradebookTabComponent_label__1WjyF {
  color: #434343;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: .625rem;
  text-transform: capitalize;
}

.GradebookTabComponent_label__1WjyF > span {
  font-weight: 400;
}

.GradebookTabComponent_email__3pIN0 {
  text-transform: lowercase;
}

@media (max-width: 800px) {

  .GradebookTabComponent_label__1WjyF {
    font-size: .875rem;
  }

  .GradebookTabComponent_label_title__21sN3 {
    font-size: 1rem;
  }


}

.IndividualQuizGradebook_main_container__1vbg5 {
    margin-top: 90px;
    height: 100%;
    width: 100%;
    font-family: Montserrat;
    box-sizing: border-box;
  }
  
  .IndividualQuizGradebook_back_btn__2R_bl {
    font-weight: 400;
    color: #858585;
    cursor: pointer;
    padding: 20px;
    font-size: 0.875rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .IndividualQuizGradebook_loader_style__2ZsTw {
    text-align: center;
    margin-top: 200px;
  }
  
  .IndividualQuizGradebook_gradebook_outer_container__2OBij {
    /* display: flex;
      flex-direction: column;
      align-items: center; */
    /* border: 1px solid greenyellow; */
    width: 64vw;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 1rem;
  }
  
  .IndividualQuizGradebook_gradebook_inner_container__TXUi2 {
    border-radius: 10px;
    background: #fff;
    margin-top: 1.5rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  
    min-height: 640px;
    padding: 1rem;
    margin-bottom: 5rem;
    box-sizing: border-box;
  }
  
  .IndividualQuizGradebook_header__2T24T {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 1rem 1.5rem;
    position: relative;
    box-sizing: border-box;
  }

  .IndividualQuizGradebook_quiz_info__IEG7_ {
    color: #636363;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    border-left: 1px solid #E5E5E5;
    padding-left: 1rem;
  }

  .IndividualQuizGradebook_quiz_info__IEG7_ > p {
    padding-bottom: 5px;
  }
  
  .IndividualQuizGradebook_btn_container__2lH50 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: .5rem;
    gap: .5rem;
    position: absolute;
    right: 0;
    bottom: -75%;
    z-index: 5;
    box-sizing: border-box;
  }
  
  .IndividualQuizGradebook_btn__WezXX, .IndividualQuizGradebook_btn2__PYKhG {
    display: flex;
    padding: .375rem .625rem;
    border-radius: 5px;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    color: #ED1E24;
    border: 1.5px solid #ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    cursor: pointer;
  }
  .IndividualQuizGradebook_btn__WezXX > img, .IndividualQuizGradebook_btn2__PYKhG > img {
    width: 1.25rem;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .IndividualQuizGradebook_btn2__PYKhG {
    right: 9%;
  }
  
  .IndividualQuizGradebook_btn__WezXX:active, .IndividualQuizGradebook_btn2__PYKhG:active { 
    -webkit-transform: scale(0.98); 
            transform: scale(0.98); 
  } 
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    box-sizing: border-box;
    margin-right: 0.5rem;
  }
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_box__2kmWP {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_icon__2iRFe {
    background-color: #ED1E24;
    padding: .4688rem .4375rem;
    margin-right: 0.5rem;
    border-radius: 6px;
    
  }
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_icon__2iRFe > img{
    width: .9375rem;
    height: .9375rem;
    display: block;
  }
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_label__15Ntf {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_label__15Ntf span {
    font-weight: 600;
  }
  
  .IndividualQuizGradebook_avg_score_container__2_HT1 {
    padding: .875rem 1.625rem;
    border-radius: 10px;
    background-color: #ffcfd0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
  }
  
  .IndividualQuizGradebook_avg_score_container__2_HT1 .IndividualQuizGradebook_label__15Ntf {
    color: #3c3c3c;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .IndividualQuizGradebook_avg_score_container__2_HT1 .IndividualQuizGradebook_avgScore__3TN1R {
    color: #ed1e24;
    font-family: Montserrat;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1rem;
  }
  
  /* ------------- MENU --------- */
  .IndividualQuizGradebook_btn_label__bJrgA {
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.0625rem;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .IndividualQuizGradebook_menu_item_label__3OUgm {
    color: #000;
    font-size: 0.75rem;
    font-family: "Montserrat";
    font-weight: 500;
  }
  
  .IndividualQuizGradebook_menu_icon__1JCAc {
    width: 1.1875rem;
    height: 1.1875rem;
    margin-right: 0.5rem;
  }
  
  /* -------- MEDIA QUERIES ---------- */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    .IndividualQuizGradebook_gradebook_outer_container__2OBij {
      width: 90vw;
    }
    
  }
  
  /* 
    ##Device = Tablets, Ipads 
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 820px) and (max-width: 1024px) {
  
    .IndividualQuizGradebook_gradebook_outer_container__2OBij {
      width: 90vw;
    }
    
    
  
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 819px) {
  
    .IndividualQuizGradebook_main_container__1vbg5 {
      margin-top: 0;
    }
    
    .IndividualQuizGradebook_gradebook_outer_container__2OBij {
      width: 90vw;
    }
  
    .IndividualQuizGradebook_header__2T24T {
      display: grid;
      /* column-gap: .875rem;
      row-gap: 0.5rem; */
      grid-gap: 0.875rem;
      gap: 0.875rem;
      grid-template-columns: 1fr 0.35fr;
    }

    .IndividualQuizGradebook_quiz_info__IEG7_ {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: .875rem;
      align-self: start;
      justify-self: start;
      border: none;
    }
    .IndividualQuizGradebook_quiz_info__IEG7_ > p {
      padding-bottom: 5px;
    }
  
    .IndividualQuizGradebook_btn_container__2lH50 {
      position: relative;
      bottom: 0;
      right: 0;
      justify-content: start;
      align-self: center;
      justify-self: end;
    }
  
    .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I {
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start;
    }
    
    /* asg_quiz_count_container .label {
      font-size: .875rem;
  
    } */
  
    .IndividualQuizGradebook_avg_score_container__2_HT1 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      grid-row-start: 1;
      grid-column-start: 2;
      align-self: center;
      justify-self: end;
    } 
  
  
    .IndividualQuizGradebook_btn__WezXX {
      display: none;
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
  
    .IndividualQuizGradebook_main_container__1vbg5 {
      margin-top: 0;
    }
    
    .IndividualQuizGradebook_gradebook_outer_container__2OBij {
      width: 90vw;
    }
  
    .IndividualQuizGradebook_header__2T24T {
      display: grid;
      /* column-gap: .875rem;
      row-gap: 0.5rem; */
      grid-gap: 0.875rem;
      gap: 0.875rem;
      grid-template-columns: 1fr 0.35fr;
      margin: 0;
    }

    .IndividualQuizGradebook_quiz_info__IEG7_ {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: .875rem;
      align-self: start;
      justify-self: start;
      border: none;
    }
    .IndividualQuizGradebook_quiz_info__IEG7_ > p {
      padding-bottom: 5px;
    }
  
    .IndividualQuizGradebook_btn_container__2lH50 {
      position: relative;
      bottom: 0;
      right: 0;
      justify-content: start;
      align-self: center;
      justify-self: end;
    }
  
    .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I {
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start;
    }
    
    .IndividualQuizGradebook_asg_quiz_count_container__2ZH_I .IndividualQuizGradebook_label__15Ntf {
      font-size: .875rem;
    }
  
    .IndividualQuizGradebook_avg_score_container__2_HT1 {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      grid-row-start: 1;
      grid-column-start: 2;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      align-self: center;
      justify-self: end;
    } 
  
    .IndividualQuizGradebook_avg_score_container__2_HT1 .IndividualQuizGradebook_label__15Ntf {
      font-size: .875rem;
    }
  
    .IndividualQuizGradebook_avg_score_container__2_HT1 .IndividualQuizGradebook_avgScore__3TN1R {
      font-size: 1.2rem;
    }
  
    .IndividualQuizGradebook_btn__WezXX {
      display: none;
    }
  
  
    
    
    
  }
  
.QuizGradebookTable_main__3t7I3 {
    margin-top: 4.75rem;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    /* border: 1px solid blue; */
    padding: 12px;
    background-color: #F6F6F6;
}

.QuizGradebookTable_icon__7S56q {
    width: 1rem;
}


@media (max-width: 800px) {
    .QuizGradebookTable_main__3t7I3 {
        margin-top: 2rem;
    }
}

.IndividualAssignmentGradebook_main_container__1lQia {
    margin-top: 90px;
    height: 100%;
    width: 100%;
    font-family: Montserrat;
    box-sizing: border-box;
  }
  
  .IndividualAssignmentGradebook_back_btn__ZprfL {
    font-weight: 400;
    color: #858585;
    cursor: pointer;
    padding: 20px;
    font-size: 0.875rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .IndividualAssignmentGradebook_loader_style__uF8-T {
    text-align: center;
    margin-top: 200px;
  }
  
  .IndividualAssignmentGradebook_gradebook_outer_container__2k2iD {
    /* display: flex;
      flex-direction: column;
      align-items: center; */
    /* border: 1px solid greenyellow; */
    width: 64vw;
    margin: auto;
    box-sizing: border-box;
    padding-bottom: 1rem;
  }
  
  .IndividualAssignmentGradebook_gradebook_inner_container__1OOG1 {
    border-radius: 10px;
    background: #fff;
    margin-top: 1.5rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  
    min-height: 640px;
    padding: 1rem;
    margin-bottom: 5rem;
    box-sizing: border-box;
  }
  
  .IndividualAssignmentGradebook_header__1-K6Z {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 1rem 1.5rem;
    position: relative;
    box-sizing: border-box;
  }

  .IndividualAssignmentGradebook_asg_info__276ha {
    color: #636363;
    font-family: Montserrat;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    border-left: 1px solid #E5E5E5;
    padding-left: 1rem;
  }

  .IndividualAssignmentGradebook_asg_info__276ha > p {
    padding-bottom: 5px;
  }

  .IndividualAssignmentGradebook_assignment_wrapper__2nEI1 {
    padding: 0 1.5rem;
  }

  .IndividualAssignmentGradebook_assignment_container__30GtH {
    margin-top: 5rem;
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    background-color: #f6f6f6;
    border-radius: .625rem;
  }

  .IndividualAssignmentGradebook_assignment_container__30GtH .IndividualAssignmentGradebook_subheading__1-Ca5 {
    color: #868DA6;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  .IndividualAssignmentGradebook_file_container__3jPA5 {
    width: 100%;
    margin: 0.875rem 0;
    padding: 0 1.25rem;
    box-sizing: border-box;
  }

  .IndividualAssignmentGradebook_description__3EgLp {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;    
  }

  .IndividualAssignmentGradebook_no_sub___w_jk {
    color: #ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    text-align: center;
    margin-top: 0.875rem;
  }
  
  .IndividualAssignmentGradebook_btn_container__3m1c5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: .5rem;
    gap: .5rem;
    position: absolute;
    right: 0;
    bottom: -75%;
    z-index: 5;
    box-sizing: border-box;
  }
  
  .IndividualAssignmentGradebook_btn__889Vz, .IndividualAssignmentGradebook_btn2__1AjlO {
    display: flex;
    padding: .375rem .625rem;
    border-radius: 5px;
    background-color: #FFF;
    justify-content: center;
    align-items: center;
    color: #ED1E24;
    border: 1.5px solid #ED1E24;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    cursor: pointer;
  }
  .IndividualAssignmentGradebook_btn__889Vz > img, .IndividualAssignmentGradebook_btn2__1AjlO > img {
    width: 1.25rem;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .IndividualAssignmentGradebook_btn2__1AjlO {
    right: 9%;
  }
  
  .IndividualAssignmentGradebook_btn__889Vz:active, .IndividualAssignmentGradebook_btn2__1AjlO:active { 
    -webkit-transform: scale(0.98); 
            transform: scale(0.98); 
  } 
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    box-sizing: border-box;
    margin-right: 0.5rem;
  }
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_box__1Y_DO {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_icon__1rbB3 {
    background-color: #ED1E24;
    padding: .4688rem .4375rem;
    margin-right: 0.5rem;
    border-radius: 6px;
    
  }
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_icon__1rbB3 > img{
    width: .9375rem;
    height: .9375rem;
    display: block;
  }
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_label__2q65L {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_label__2q65L span {
    font-weight: 600;
  }
  
  .IndividualAssignmentGradebook_avg_score_container__3O9Vi {
    padding: .875rem 1.625rem;
    border-radius: 10px;
    background-color: #ffcfd0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
  }
  
  .IndividualAssignmentGradebook_avg_score_container__3O9Vi .IndividualAssignmentGradebook_label__2q65L {
    color: #3c3c3c;
    font-family: Montserrat;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .IndividualAssignmentGradebook_avg_score_container__3O9Vi .IndividualAssignmentGradebook_avgScore__1MTjW {
    color: #ed1e24;
    font-family: Montserrat;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 1rem;
  }
  
  /* ------------- MENU --------- */
  .IndividualAssignmentGradebook_btn_label__1Klbb {
    font-family: "Montserrat";
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.0625rem;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .IndividualAssignmentGradebook_menu_item_label__3KL0u {
    color: #000;
    font-size: 0.75rem;
    font-family: "Montserrat";
    font-weight: 500;
  }
  
  .IndividualAssignmentGradebook_menu_icon__2mF31 {
    width: 1.1875rem;
    height: 1.1875rem;
    margin-right: 0.5rem;
  }
  
  /* -------- MEDIA QUERIES ---------- */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    .IndividualAssignmentGradebook_gradebook_outer_container__2k2iD {
      width: 90vw;
    }
    
  }
  
  /* 
    ##Device = Tablets, Ipads 
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 820px) and (max-width: 1024px) {
  
    .IndividualAssignmentGradebook_gradebook_outer_container__2k2iD {
      width: 90vw;
    }
    
    
  
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 819px) {
  
    .IndividualAssignmentGradebook_main_container__1lQia {
      margin-top: 0;
    }
    
    .IndividualAssignmentGradebook_gradebook_outer_container__2k2iD {
      width: 90vw;
    }
  
    .IndividualAssignmentGradebook_header__1-K6Z {
      display: grid;
      /* column-gap: .875rem;
      row-gap: 0.5rem; */
      grid-gap: 0.875rem;
      gap: 0.875rem;
      grid-template-columns: 1fr 0.35fr;
    }

    .IndividualAssignmentGradebook_asg_info__276ha {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: .875rem;
      align-self: start;
      justify-self: start;
      border: none;
    }
    .IndividualAssignmentGradebook_asg_info__276ha > p {
      padding-bottom: 5px;
    }

    .IndividualAssignmentGradebook_file_container__3jPA5, .IndividualAssignmentGradebook_assignment_wrapper__2nEI1 {
      padding: 0 0.875rem;
    }
  
    .IndividualAssignmentGradebook_btn_container__3m1c5 {
      position: relative;
      bottom: 0;
      right: 0;
      justify-content: start;
      align-self: center;
      justify-self: end;
    }
  
    .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ {
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start;
    }
    
    /* asg_quiz_count_container .label {
      font-size: .875rem;
  
    } */
  
    .IndividualAssignmentGradebook_avg_score_container__3O9Vi {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      grid-row-start: 1;
      grid-column-start: 2;
      align-self: center;
      justify-self: end;
    } 
  
  
    .IndividualAssignmentGradebook_btn__889Vz {
      display: none;
    }
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
  
    .IndividualAssignmentGradebook_main_container__1lQia {
      margin-top: 0;
    }
    
    .IndividualAssignmentGradebook_gradebook_outer_container__2k2iD {
      width: 90vw;
    }
  
    .IndividualAssignmentGradebook_header__1-K6Z {
      display: grid;
      /* column-gap: .875rem;
      row-gap: 0.5rem; */
      grid-gap: 0.875rem;
      gap: 0.875rem;
      grid-template-columns: 1fr 0.35fr;
      margin: 0;
    }

    .IndividualAssignmentGradebook_asg_info__276ha {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-size: .875rem;
      align-self: start;
      justify-self: start;
      border: none;
    }
    .IndividualAssignmentGradebook_asg_info__276ha > p {
      padding-bottom: 5px;
    }

    .IndividualAssignmentGradebook_file_container__3jPA5, .IndividualAssignmentGradebook_assignment_wrapper__2nEI1 {
      padding: 0 0.875rem;
    }
  
    .IndividualAssignmentGradebook_btn_container__3m1c5 {
      position: relative;
      bottom: 0;
      right: 0;
      justify-content: start;
      align-self: center;
      justify-self: end;
    }
  
    .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ {
      flex-direction: column;
      grid-gap: 5px;
      gap: 5px;
      align-items: flex-start;
    }
    
    .IndividualAssignmentGradebook_asg_quiz_count_container__2ymWQ .IndividualAssignmentGradebook_label__2q65L {
      font-size: .875rem;
    }
  
    .IndividualAssignmentGradebook_avg_score_container__3O9Vi {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      grid-row-start: 1;
      grid-column-start: 2;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      align-self: center;
      justify-self: end;
    } 
  
    .IndividualAssignmentGradebook_avg_score_container__3O9Vi .IndividualAssignmentGradebook_label__2q65L {
      font-size: .875rem;
    }
  
    .IndividualAssignmentGradebook_avg_score_container__3O9Vi .IndividualAssignmentGradebook_avgScore__1MTjW {
      font-size: 1.2rem;
    }
  
    .IndividualAssignmentGradebook_btn__889Vz {
      display: none;
    }
  
  
    
    
    
  }
  
.LiveChat_main__1QzhA {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  width: 80vw;
  margin: auto;
  margin-top: 5.75rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.LiveChat_sidebar__LlrqJ {
  box-sizing: border-box;
  border: 1 px solid #9a9a9a;
  width: 100%;
  height: 100%;
  /* padding: .625rem 1.125rem; */

  /* display: grid; */
  height: 87vh;
}

.LiveChat_sidebar_header__2O0_r,
.LiveChat_chat_header__294V7 {
  padding: 1.25rem 1.125rem;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-weight: 600;
  height: 3.5vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9a9a9a;
}

.LiveChat_chat_header__294V7 {
  display: flex;
  align-items: center;
}

.LiveChat_menu__3zBFK {
  cursor: pointer;
  margin-left: auto;
}

.LiveChat_contact_list__1XH8v {
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 100%;
}

.LiveChat_contact_list__1XH8v::-webkit-scrollbar {
  display: none;
}

.LiveChat_current_contact__1AESe {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-left: 1.5rem;
}

.LiveChat_back_arrow__2o6FA {
  cursor: pointer;
}

.LiveChat_current_contact__1AESe > p {
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.LiveChat_searchbar__V7Iku {
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  width: 95%;
  margin-bottom: auto;
  background-color: #f0f0f0;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  border-bottom: 2px solid #ed1e24;
  background: #f0f0f0;
  margin: auto;
  margin-top: 0.625rem;
}

.LiveChat_search_label__1IleO {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.LiveChat_search_input__SxVH5 {
  outline: none;
  background-color: #f0f0f0;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
}

.LiveChat_search_icon__37Yk4 {
  width: 14px;
  /* padding: 8px 8px 8px 8px; */

  margin-left: 0.25rem;
  margin-right: 1rem;
}

.LiveChat_back_icon__1IexO {
  width: 1.25rem;
  cursor: pointer;

  margin-left: 0.25rem;
  margin-right: 1rem;
}

.LiveChat_chat_window__UbQFX {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #9a9a9a;
  width: 100%;
  height: 89.5vh;

  background-image: url(/static/media/Chat_Background.2f416810.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: repeat;
}

.LiveChat_right_blank_panel__1MbCD {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #9a9a9a;
  width: 100%;
  height: 89vh;

  background-image: url(/static/media/illustration_chat.931c14e5.svg);
  background-position: center;
  background-size: 46%;
  background-repeat: no-repeat;
}

.LiveChat_messages__KEdwl {
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  overscroll-behavior: contain;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.LiveChat_messages__KEdwl::-webkit-scrollbar {
  display: none;
}

.LiveChat_date_split__1itpW {
  margin: 1.875rem auto;
  border-radius: 6px;
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  background: #e8e8e8;
  box-shadow: 0px 0px 1.7px 0px rgba(0, 0, 0, 0.25);

  color: #1D1D1D;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.35px;
}

.LiveChat_textbox__Rta5w {
  background-color: #f0f0f0;
  height: 8vh;
  margin-top: auto;
  padding: 1rem 1.13rem;
  box-sizing: border-box;
}

.LiveChat_delete_messages_menu__3OcIe {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.LiveChat_selected_count__3gTPd {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LiveChat_text_input__TKezi {
  outline: none;
  background-color: white;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
  padding-left: 0.5rem;

  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LiveChat_text_input__TKezi::-webkit-input-placeholder {
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LiveChat_text_input__TKezi::placeholder {
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.LiveChat_hide_sidebar__38ilt {
  display: none;
}

/* --------- Media Queries -------- */

/* Tablets */
@media (max-width: 1139px) {

  .LiveChat_main__1QzhA {
    width: 100%;
  }
  
  .LiveChat_sidebar__LlrqJ {
    height: 80vh;
  }

  .LiveChat_chat_window__UbQFX {
    height: 81vh;
  }

  .LiveChat_hide_sidebar__38ilt {
    display: none;
  }
  
}

/* Low Resolution Tablets, Mobiles */
@media (max-width: 800px) {

  .LiveChat_main__1QzhA {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .LiveChat_sidebar__LlrqJ {
    width: 100%;
  }

  .LiveChat_chat_window__UbQFX {
    height: 82vh;
  }

  .LiveChat_hide_sidebar__38ilt {
    display: none;
  }
  
}

@media (max-width: 600px) {

  .LiveChat_main__1QzhA {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .LiveChat_sidebar__LlrqJ {
    width: 100%;
  }

  .LiveChat_chat_window__UbQFX {
    height: 81vh;
  }

  .LiveChat_hide_sidebar__38ilt {
    display: none;
  }
  
}

/* Most of the Smartphones Mobiles */
@media (max-width: 380px) {
  
  .LiveChat_main__1QzhA {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .LiveChat_chat_window__UbQFX {
    height: 80vh;
  }

  .LiveChat_sidebar__LlrqJ {
    width: 100%;
  }
  
  .LiveChat_chat_window__UbQFX {
    height: 75vh;
  }

  .LiveChat_hide_sidebar__38ilt {
    display: none;
  }
  
}
.ChatContactListItem_main__TTEF2 {
  display: flex;
  box-sizing: border-box;
  width: 95%;
  align-items: center;
  margin: auto;
  margin-top: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 6px;
}

.ChatContactListItem_main__TTEF2:hover {
  background-color: #f0f0f0;
}

.ChatContactListItem_selected__3CT29 {
  background-color: #f0f0f0;
}

.ChatContactListItem_data_wrap__1S-Rz {
  display: grid;
  grid-template-columns: 0.8fr 0.25fr;
  width: 100%;
  box-sizing: border-box;
  margin-left: 0.625rem;
}

.ChatContactListItem_name__15iwm {
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.425px;
}

.ChatContactListItem_last_message__2oqnK{
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.35px;
  margin-top:.3125rem;
}

.ChatContactListItem_timestamp__1GjWo {
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
}

.ChatBubble_main__7oFw5 {
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  -webkit-filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
          filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
  padding: 0.25rem 0.625rem;
  min-height: 3rem;
  margin-top: .375rem;
  margin-right: auto;
  margin-left: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;  
}

.ChatBubble_wrapper__3gDHx {
  display: flex;
  align-items: center;
}

.ChatBubble_message__1o2Ig {
  color: #111b21;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.35px;
}

.ChatBubble_sender__49YO- {
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 0.25rem 0.625rem;
  min-height: 3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  -webkit-filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
          filter: drop-shadow(0px 0px 20px rgba(63, 63, 65, 0.1));
  background: #ffeced;
  margin-top: .375rem;
  margin-left: auto;
  margin-right: 0;
}

.ChatBubble_date_wrap__2v7-Z {
  display: flex;
  align-items: center;
  justify-content: right;
  color: #535353;
  font-family: Montserrat;
  font-size: .625rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.225px;
  margin-left: 1rem;
  margin-top: auto;
  
}


.ChatBubble_text__xHF9h {
    font-family: 'Montserrat';
    font-weight: 400;
    font-weight: 1rem;
    color: #000;
}

.ChatBubble_editing__N0q-e {
  background-color: #B7E0FF;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;;
}
.DeleteConfirmationModal_main__3Y9UN {
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem;
  border-radius: 1.5625rem;
  width: 63vh;
}

.DeleteConfirmationModal_text__1X00A {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  margin: 1.125rem 0px;
  letter-spacing: 0.1px;
  line-height: 22px;
}

.DeleteConfirmationModal_buttons_container__3X2uh {
  display: flex;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  gap: 0.5rem;

}

@media (max-width: 510px) {
  .DeleteConfirmationModal_main__3Y9UN {
    width: 83%;
  }
}

@media (max-width: 380px) {
  .DeleteConfirmationModal_main__3Y9UN {
    width: 76%;
  }
}

.EnterpriseDashboard_main__3H0SI {
  box-sizing: border-box;
  margin-top: 6.5rem;
  /* width: 100%; */
  margin-left: 10vw;
  margin-right: 10vw;

  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 0.75fr 1.5fr 1.5fr;

  /* height: 88vh; */
  min-height: 70vh;
  height: 100%;
  grid-gap: 1rem;
}

.EnterpriseDashboard_sidebar__bTFGq {
  grid-row: 1 / -1;
  /* background-color: orangered; */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  border-radius: 1.25rem;
  background: rgb(255, 255, 255);

  display: grid;
  grid-template-rows: 0.75fr 3fr;
  grid-gap: 1.5rem;
}

.EnterpriseDashboard_sidebar_top__3AsXi {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1rem;
}

.EnterpriseDashboard_sidebar_top__3AsXi > p {
  font: Montserrat;
  font-weight: 600;
  font-size: 1.88rem;
}

.EnterpriseDashboard_sidebar_top__3AsXi > img {
  width: 25%;
}
.EnterpriseDashboard_header__1or4m {
  grid-column: 2 / -1;
  /* background-color: dodgerblue; */
  /* box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1); */
  border-radius: 1.25rem;
  /* background: rgb(255, 255, 255); */
  /* height: auto; */

  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  /* gap: 4rem; */
}

.EnterpriseDashboard_table__2rrqP {
  grid-column: 2 / -1;
  grid-row: 2 / -1;
  /* background-color: coral; */
  /* box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1); */
  border-radius: 1.25rem;
  background: rgb(255, 255, 255);
  padding: 1.875rem;
  height: 65vh;
}

.EnterpriseDashboard_flex_wrap__1nrFi {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* gap: 3rem */
}

.EnterpriseDashboard_header_card__2LBS- {
  width: 100%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.EnterpriseDashboard_main_header_card__1_V3d {
  width: 90%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.EnterpriseDashboard_counter__25CNr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  height: 100%;
  padding: 1rem 1.5rem;
  justify-items: start;
  align-items: center;
  box-sizing: border-box;
}

.EnterpriseDashboard_count__1s7l4 {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.13rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDashboard_count_label__3S1__ {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDashboard_icon_wrapper__34pAF {
  border-radius: 50px;
  padding: 1rem;
  justify-self: end;
  /* border: 1px solid red; */

  /* Gradient homepage */
  background: linear-gradient(
    180deg,
    rgb(255, 236, 236),
    rgb(255, 246, 246) 93.835%
  );

  aspect-ratio: 1/1;
}

.EnterpriseDashboard_counter_icon__1B5-X {
  height: 1.75rem;
  aspect-ratio: 1/1;
}

/* .header_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.75rem;
} */

.EnterpriseDashboard_searchbar__2SqBJ {
  display: flex;
  padding: 0.5rem 0.75rem;
  width: 80%;
  border: 0.06rem solid rgb(208, 208, 208);
  border-radius: 0.63rem;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.EnterpriseDashboard_menu_icon__2A_Ul {
  padding-right: 4px;
}

.EnterpriseDashboard_searchbar__2SqBJ > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: 0.25rem;
  color: #49454f;
  font-weight: 400;
  font-size: 1rem;
  color: rgb(18, 18, 18);
}

.EnterpriseDashboard_searchbar__2SqBJ > input::-webkit-input-placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDashboard_searchbar__2SqBJ > input::placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDashboard_search_dropdown_wrapper__z68Za {
  position: relative;
}

.EnterpriseDashboard_searchbar_wrapper__2GiLA {
  /* padding: 1.75rem 2.5rem; */
  padding: 0 2.5rem;
  justify-content: center;

  display: flex;
  flex-direction: column;
}

.EnterpriseDashboard_asg_quiz_searchbar_wrapper__v-n1P {
  justify-content: center;
  width: 40%;
  display: flex;
  flex-direction: column;
}

.EnterpriseDashboard_dropdown_wrapper__2XiDy {
  width: 30%;
  padding: 1.75rem 2.5rem;

  display: flex;
  flex-direction: column;
}

.EnterpriseDashboard_search_label__1OrL5 {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin: 0.35rem 0 1rem 0;
}

.EnterpriseDashboard_search_dropdown__1mVey {
  box-sizing: border-box;
  background: white;
  width: 85%;
  border: 1px solid rgb(208, 208, 208);
  position: absolute;
  max-height: 20rem;
  overflow-y: scroll;
  padding: 0.75rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
}

.EnterpriseDashboard_dropdown_row__IkI4a {
  margin-bottom: 0.5rem;
}

.EnterpriseDashboard_charts_container__trs7Q {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  height: 100%;
}

.EnterpriseDashboard_bar_chart__27PRB {
  grid-column: 1 / -1;
}

.EnterpriseDashboard_post_feed_container__d-_np {
  margin: auto;
  width: 65%;
}

.EnterpriseDashboard_post_feed_wrapper__3CcZ0 {
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.EnterpriseDashboard_post_feed_wrapper__3CcZ0::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.EnterpriseDashboard_post_feed_wrapper__3CcZ0 {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.EnterpriseDashboard_data_not_found_image__2SwYe {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.EnterpriseDashboard_not_found__55CEr {  
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}
.EnterpriseDashboard_top_navbar__3goID {
  display: none;
}

.EnterpriseDashboard_top_navbar__3goID > .EnterpriseDashboard_menu_icon__2A_Ul {
  cursor: pointer;
  width: 2.5rem;
}

.EnterpriseDashboard_top_navbar__3goID > .EnterpriseDashboard_logo_wrapper__18AUm {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding-right: 1.5rem;
}

.EnterpriseDashboard_logo_wrapper__18AUm > p {
  font-size: 1.5rem;
  font-weight: 700;
}

.EnterpriseDashboard_dashboard_main_header__3Oj9H {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 1rem;
  gap: 1rem;
}

/* ---------------------- Media Queries ---------------- */


@media (max-width: 1140px) {
  .EnterpriseDashboard_sidebar__bTFGq {
    display: none;
  }
  .EnterpriseDashboard_header__1or4m, .EnterpriseDashboard_table__2rrqP {
    grid-column: 1/-1;
  }
  .EnterpriseDashboard_main__3H0SI {
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .EnterpriseDashboard_top_navbar__3goID {
    display: flex;
    background-color: #f0f0f0;
    margin-top: 7.5rem;
    width: 85%;
    border-radius: 0.25rem;
    margin: auto;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .EnterpriseDashboard_charts_container__trs7Q {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .EnterpriseDashboard_dashboard_main_header__3Oj9H {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .EnterpriseDashboard_main_header_card__1_V3d {
    width: 45%;
  }

  .EnterpriseDashboard_counter__25CNr {
    padding: .5rem .75rem;
  }

  .EnterpriseDashboard_count__1s7l4 {
    font-size: 1.5rem;
  }

  .EnterpriseDashboard_counter_icon__1B5-X {
    height: 1.5rem;
  }
  .EnterpriseDashboard_icon_wrapper__34pAF {
    padding: 0.5rem;
  }

  .EnterpriseDashboard_count_label__3S1__{
    font-size: .75rem;
  }

  .EnterpriseDashboard_post_feed_wrapper__3CcZ0 {
    margin-bottom: 2rem;
  }
  .EnterpriseDashboard_post_feed_container__d-_np {
    width: 90%;
  }

  .EnterpriseDashboard_dropdown_wrapper__2XiDy {
    display: none;
  }

  .EnterpriseDashboard_flex_wrap__1nrFi{
    align-items: center;

  }

  .EnterpriseDashboard_asg_quiz_searchbar_wrapper__v-n1P {
    width: 90%;
    margin-left: 1rem;
  }

  .EnterpriseDashboard_add_new_faculty_student__3vYDM {
    display: none;
  }


}

@media (max-width: 800px) {
  .EnterpriseDashboard_top_navbar__3goID {
    margin-top: 1rem;

  }
}
.EnterpriseDataTable_main__3ElVf {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.EnterpriseDataTable_count_header__3Y7nU {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EnterpriseDataTable_type__1RPHA {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDataTable_total_count__18KR7 {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseDataTable_count__11xEn {
  background: linear-gradient(90deg, rgb(237, 30, 36), rgb(238, 130, 130));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-right: 0.5rem;
}

.EnterpriseDataTable_pagination_container__VxoY_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.EnterpriseDataTable_data_not_found_image__2biFb {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.EnterpriseDataTable_active_mode__3N71g, .EnterpriseDataTable_inactive_mode__39b0w {
  font-family: Montserrat;
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.06rem;
  letter-spacing: 0%;
  text-align: center;
  padding: 0.2rem;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
}

.EnterpriseDataTable_active_mode__3N71g {
  box-sizing: border-box;
  color: rgb(70, 203, 76);
  /* Green */
  border: 0.06rem solid rgb(70, 203, 76);
  border-radius: 1rem;
  background: rgb(226, 249, 227);

}

.EnterpriseDataTable_inactive_mode__39b0w {
  color: #ed1f25;
  box-sizing: border-box;
  /* Green */
  border: 0.06rem solid #ed1f25;
  border-radius: 1rem;
  background: #fff5f5;
}

.EnterpriseDataTable_active_mode__3N71g:hover {
  background: rgb(70, 203, 76) ;
  color: rgb(226, 249, 227)

}

.EnterpriseDataTable_inactive_mode__39b0w:hover {
  background: #ed1f25;
  color: #fff5f5

}

/* --------------- Media Query ------------------ */

@media (max-width: 900px) {
  .EnterpriseDataTable_type__1RPHA {
    font-size: 1rem;
  }
  .EnterpriseDataTable_total_count__18KR7 {
    font-size: 0.75rem;
  }
  .EnterpriseDataTable_count__11xEn {
    font-size: 1rem;
  }
  .EnterpriseDataTable_pagination_container__VxoY_ {
    margin-bottom: 3.5rem;
  }
}
.EnterpriseDropDownFilter_main__-Qrrb {
    min-width: 16rem;
}
.EnterpriseDropDownFilter_text__2vg6u {
    color: #4D4D4D;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'Montserrat';

}
.EnterpriseFacultyDashboard_main__1eXcR {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-top: 5.5%;
  height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  grid-gap: 1rem;
  gap: 1rem;
}

.EnterpriseFacultyDashboard_sidebar_container__tA3iY {
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.EnterpriseFacultyDashboard_right_container__32w73 {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.EnterpriseFacultyDashboard_header_container__1leKg {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2.5rem;
}

.EnterpriseFacultyDashboard_main_header_wrapper__FSbQ3 {
  display: flex;
  align-items: center;
  grid-gap: 4rem;
  gap: 4rem;
}

.EnterpriseFacultyDashboard_header_wrapper__28ql3 {
  display: flex;
  border-bottom: 2px solid rgb(237, 30, 36);
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255); 
  justify-content: space-between;
  padding: 1.5rem 2rem;
  align-items: center;
  min-height: 5rem;
}

.EnterpriseFacultyDashboard_header_left__3IvF0, .EnterpriseFacultyDashboard_header_right__fbPUD {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.EnterpriseFacultyDashboard_sidebar_header__13YnK {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.EnterpriseFacultyDashboard_username_label_wrapper__1ktRi {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.EnterpriseFacultyDashboard_username_label_wrapper__1ktRi > img {
  width: 1.25rem;
  height: 1.25rem;
}

.EnterpriseFacultyDashboard_username_label_wrapper__1ktRi > p {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.19rem;
  font-weight: 400;
  line-height: 1.44rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseFacultyDashboard_usertype__2Da5B {
  color: rgb(85, 85, 85);
  font-family: Montserrat;
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: 0%;
  text-align: center;
}

.EnterpriseFacultyDashboard_sidebar_menu_item_wrapper__39R5t {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  gap: 0.75rem
}

.EnterpriseFacultyDashboard_sidebar_menu_item_wrapper__39R5t > img {
  width: 2rem;
}

.EnterpriseFacultyDashboard_header_right__fbPUD {
  width: 45%;
}

.EnterpriseFacultyDashboard_searchbar__29-5g {
  display: flex;
  padding: 0.5rem 0.75rem;
  width: 90%;
  border: 0.06rem solid rgb(208, 208, 208);
  border-radius: 0.63rem;
  text-decoration: none;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.EnterpriseFacultyDashboard_searchbar__29-5g > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: 0.25rem;
  color: #49454f;
  font-weight: 400;
  font-size: 1rem;
  color: rgb(18, 18, 18);
}

.EnterpriseFacultyDashboard_searchbar__29-5g > input::-webkit-input-placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseFacultyDashboard_searchbar__29-5g > input::placeholder {
  color: rgb(163, 163, 163);
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseFacultyDashboard_charts_container__3D2OH {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  box-sizing: border-box;
  width: 100%;
  min-height: 60vh;
  flex: 1 1
}

.EnterpriseFacultyDashboard_bar_chart__1XIcz {
  grid-column: 1 / -1;
}

.EnterpriseFacultyDashboard_post_feed_container__2a3vg {
  margin: auto;
  width: 65%;
}

.EnterpriseFacultyDashboard_post_feed_wrapper__1N6Dh {
  overflow: scroll;
  margin-bottom: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.EnterpriseFacultyDashboard_post_feed_wrapper__1N6Dh::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.EnterpriseFacultyDashboard_post_feed_wrapper__1N6Dh {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.EnterpriseFacultyDashboard_data_not_found_image__2FxKt {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.EnterpriseFacultyDashboard_not_found__1iJeS {  
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-bottom: 1rem;
}

.EnterpriseFacultyDashboard_header_card__3jA7M {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  /* Primary */
  border-bottom: 0.125rem solid rgb(237, 30, 36);
  border-radius: 1.25rem;
  /* modules */
  box-shadow: 0rem 0rem 1.25rem 0rem rgba(63, 63, 65, 0.1);
  background: rgb(255, 255, 255);
}

.EnterpriseFacultyDashboard_counter__1UyTm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  height: 100%;
  padding: 1rem 1.5rem;
  justify-items: start;
  align-items: center;
  box-sizing: border-box;
}

.EnterpriseFacultyDashboard_count__EmW10 {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.13rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseFacultyDashboard_count_label__2JbAq {
  color: rgb(18, 18, 18);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.EnterpriseFacultyDashboard_icon_wrapper__1xqQb {
  border-radius: 50px;
  padding: 1rem;
  justify-self: end;
  /* border: 1px solid red; */

  /* Gradient homepage */
  background: linear-gradient(
    180deg,
    rgb(255, 236, 236),
    rgb(255, 246, 246) 93.835%
  );

  aspect-ratio: 1/1;
}

.EnterpriseFacultyDashboard_counter_icon__1n6ir {
  height: 1.75rem;
  aspect-ratio: 1/1;
}

.EnterpriseFacultyDashboard_profile_pic__fbBzJ {
  width: 8rem;
  border-radius: 50%;
  aspect-ratio: 1;
}



.EnterpriseFacultyDashboard_top_navbar__10sJm {
  display: none;
}

.EnterpriseFacultyDashboard_top_navbar__10sJm > .EnterpriseFacultyDashboard_menu_icon__fE997 {
  cursor: pointer;
  width: 2.5rem;
}

.EnterpriseFacultyDashboard_top_navbar__10sJm > .EnterpriseFacultyDashboard_logo_wrapper__3M3lc {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding-right: 1.5rem;
}

.EnterpriseFacultyDashboard_logo_wrapper__3M3lc > p {
  font-size: 1.5rem;
  font-weight: 700;
}


/* ---------------------- Media Queries ---------------- */


@media (max-width: 1140px) {
  .EnterpriseFacultyDashboard_sidebar_container__tA3iY {
    display: none;
  }
  .EnterpriseFacultyDashboard_main__1eXcR {
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    padding: 0.75rem;
    margin-top: 1rem;
  }

  .EnterpriseFacultyDashboard_top_navbar__10sJm {
    display: flex;
    background-color: #f0f0f0;
    margin-top: 7.5rem;
    width: 85%;
    border-radius: 0.25rem;
    margin: auto;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .EnterpriseFacultyDashboard_charts_container__3D2OH {
    grid-template-columns: 1fr;
    margin-bottom: 5rem;
  }

  .EnterpriseFacultyDashboard_main_header_wrapper__FSbQ3 {
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
  }

  .EnterpriseFacultyDashboard_header_card__3jA7M {
    width: 45%;
  }

  .EnterpriseFacultyDashboard_counter__1UyTm {
    padding: .5rem .75rem;
  }

  .EnterpriseFacultyDashboard_count__EmW10 {
    font-size: 1.5rem;
  }

  .EnterpriseFacultyDashboard_counter_icon__1n6ir {
    height: 1.5rem;
  }
  .EnterpriseFacultyDashboard_icon_wrapper__1xqQb {
    padding: 0.5rem;
  }

  .EnterpriseFacultyDashboard_count_label__2JbAq{
    font-size: .75rem;
  }

  .EnterpriseFacultyDashboard_header_left__3IvF0 {
    display: none;
  }

  .EnterpriseFacultyDashboard_header_right__fbPUD {
    width: 95%;
  }

  .EnterpriseFacultyDashboard_post_feed_wrapper__1N6Dh {
    margin-bottom: 2rem;
  }
  .EnterpriseFacultyDashboard_post_feed_container__2a3vg {
    width: 90%;
  }


}
.Opportunities_main__2AMDM {
  margin: auto;
  margin-top: 7.5rem;
  margin-bottom: 2.5rem;
  /* border: 1px solid red; */
  width: 72%;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.Opportunities_loader__2nsI5 {
  height: 90vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Opportunities_top_container__L-4jD {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  align-items: center;
  /* border: 1px sollid violet; */
}

.Opportunities_select__33ctb {
  box-sizing: border-box;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  background: rgb(255, 255, 255);
  cursor:pointer;
  padding: 0.25rem .875rem;
  color: #676767;
}

.Opportunities_selected__3SFzh {
  box-sizing: border-box;
  border: 1px solid rgb(237, 30, 36);
  border-radius: 1rem;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: rgb(237, 30, 36);
  cursor:pointer;
  padding: 0.25rem .875rem;
}

.Opportunities_tags_container__1jjsH {
  display: flex;
  align-items: center;
  grid-gap: 1.25rem;
  gap: 1.25rem;
  margin: 1rem 0;
  flex-wrap: wrap;
}

.Opportunities_tags__3H5xj {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;

  box-sizing: border-box;
  border: 1px solid rgb(237, 30, 36);
  border-radius: 50px;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 400;
  color: rgb(237, 30, 36);
  background: white;
  cursor:pointer;
  padding: 0.25rem 0.875rem;
  text-transform: capitalize;
  cursor: pointer;
}

.Opportunities_tags__3H5xj > img {
  width: 1.25rem;
  cursor: pointer;
}

.Opportunities_search_header__1jsH7 {
  margin-top: 1rem;
}

.Opportunities_middle_container__DlnB3 {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  /* border: 1px solid green; */
}

.Opportunities_search_section__3hMEB {
  display: flex;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 72%;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 0.5rem;
  padding: 0.25rem;
  margin-top: 1rem;
}

.Opportunities_connections_section__3ZT2U {
  /* display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 0.5rem;
  padding: 1.5rem; */
  margin-top: 1rem;
  width: 28%;
}

.Opportunities_noResults__1P3pJ {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  min-height: 20rem;
  margin: 14rem 8rem;
}

.Opportunities_pagination_container__uqmWF {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


/* ----------- Media Queries ---------- */

@media (max-width: 800px) {

  .Opportunities_main__2AMDM {
    margin-top: 1.5rem;
    margin-bottom: 5rem;
    width: 90%;
  }

  .Opportunities_connections_section__3ZT2U {
    display: none;
  }

  .Opportunities_search_section__3hMEB {
    width: 100%;
  }

  .Opportunities_tags__3H5xj {
    font-size: 0.875rem;
    padding: 0.2rem 0.5rem;
  }

  .Opportunities_tags__3H5xj > img {
    width: 1rem;
    cursor: pointer;
  }

  .Opportunities_noResults__1P3pJ {
    margin: 2rem 0
  }

  .Opportunities_noResults__1P3pJ > img {
    width: 14rem;
  }
  
}

.ProfileSearchResultComponent_main__2B-im {
  box-sizing: border-box;
  display: flex;
  padding: 0.75rem 0.5rem;
  font-family: "Montserrat";
  width: 100%;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  border-bottom: 1px solid #dadada;
}

.ProfileSearchResultComponent_left_container__1qZJV {
  border-radius: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 2rem;
}

.ProfileSearchResultComponent_left_container__1qZJV > img {
  width: 2rem;
  height: 2rem;
}

.ProfileSearchResultComponent_right_container__1LFan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 1px solid blue; */
}

.ProfileSearchResultComponent_name__3kTLS {
  font-size: 1rem;
  font-weight: 500;
  color: #ed1e24;
}

.ProfileSearchResultComponent_designation__neVo5 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #555555;
}

.ProfileSearchResultComponent_location__8zYLQ {
  font-size: 0.75rem;
  color: #858585;
}

.ProfileSearchResultComponent_button__3dETt {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #ed1e24;
  text-align: center;
  padding: 0.25rem 1rem;
  border: 1px solid #ed1e24;
  border-radius: 1.5625rem;
}

.ProfileSearchResultComponent_view_icon__11DMf {
  width: 2rem;
  cursor: pointer;
}


/* ------------ Media Queries ------- */

@media (max-width: 800px) {
  .ProfileSearchResultComponent_button__3dETt {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
  }
  
}
