.input_field{
    /* border-radius: 25px !important; */
    width: 100% !important;
    /* margin-left: 15px !important;
    height: 40px !important;
    outline: none !important;
    border: 1px #c2c2c2 solid !important;
    padding-left: 20px !important; */
    box-sizing: border-box !important;
}
.select_field{
    border-radius: 25px !important;
    width: 100% !important;
    /* margin-left: 6px !important; */
    height: 40px !important;
    outline: none !important;
    border: 0px #c2c2c2 solid !important;
    /* padding-left: 20px !important; */
    box-sizing: border-box !important;
}
.input_field:hover{
    
    border: 0px #c2c2c2 solid !important;
    
}
.class_chip{
    background-color: #ED1E24 !important;
    color: #ffffff !important;
    font-weight: bold !important;
}
.classTagPara {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ED1E24;
    padding: 10px 0 0 10px;
}
.defaultMsg{
    margin: 5px;
    color: gray;
}
