.Post {
  /* flex: 0.57; */
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  position: relative;
}
.infoContainer {
  display: flex;
  cursor: pointer;
}
.post_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 7px;
  padding: 30px 26px 0 26px;
  box-sizing: border-box;
  align-items: center;
}

.boost_btn {
  border-radius: 50px;
  padding: 1rem;
  text-align: center;
  border: 1px solid #ed1e24;
  background-color: #ed1e24;
  color: #ffffff;
  margin: 0.5rem 0 1.5rem 0;
  cursor: pointer;
  box-sizing: border-box;
}

.boost_btn p {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Montserrat; 
}

.post_Info {
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.post_tag {
  position: absolute;
  right: 70px;
  top: 0;
  padding: 8px 24px 7px 24px;
  background: #ffaf02 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0.6px;
  color: #ffffff;
  text-transform: capitalize;
}

.boost_post_tag {
  position: absolute;
  right: 12%;
  top: 0;
  padding: 0.1rem 1rem 0.3rem 1rem;
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 6px 6px;
  font-family: Montserrat;
  letter-spacing: 0.6px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
}

.post_info_follower {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  margin: 0;
}

.post_info_timestamp {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  margin: 0;
}

.post_profile_pic {
  height: 65px;
  width: 65px;
  border-radius: 100%;
  object-fit: cover;
}

.post_Info > p {
  font-size: 12px;
  color: #848484;
}

.post_header_options {
  color: #b4b4b4;
  order: 2;
  margin-left: auto;
}

.post_body_wrapper {
  position: relative;
}

.hide_post_body {
  filter: blur(0.5rem);
  position: relative;
  cursor: not-allowed;
  background-color: #b8b8b8;
  background-image: linear-gradient(147deg, #b8b8b8 0%, #ebebeb 100%);
}

.censor_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  /* padding: .3125rem; */
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  z-index: 5;
  position: absolute;
  font-size: 1.25rem;
  left:50%; 
  top:50%; 
  transform: translate(-50%, -50%);
  align-items: center;
  cursor: not-allowed;
}

.censor_text > p {
  text-align: center;
  /* color: #f3f3f3; */
  /* text-shadow: black 12px -24px 20px; */
}

.censor_text > img {
  height: 3.125rem;
}

.post_body {
  overflow-wrap: anywhere;
}

.post_body > p {
  padding: 17px 26px 0 26px;
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0px;
  color: #707070;
}

.post_body > img {
  width: 100%;
  height: auto;
}

.post_body_readMore {
  color: #ED1E24;
  cursor: pointer;
}

.post_info_userName {
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
}

.post_option {
  display: flex;
  gap: 20px;
  margin-left: 10px;
  padding-top: 10px;
}

.comment_input {
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
}

.comment_avatar {
  /* width: fit-content; */
  width: 45px !important;
  height: 45px !important;
  object-fit: cover;
  border-radius: 100%;
  /* object-fit: cover; */
  /* margin-left: 25px; */
  background: transparent 0% 0% no-repeat padding-box;
  cursor: pointer;
  opacity: 1 !important;
}

.comment_input_div {
  position: relative;
  display: flex;
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  /* padding-left: 16px; */
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
  min-width: 100px;
}

.comment_input > div > form {
  display: flex;
  width: 100%;
  /* flex-grow: 1; */
}

.comment_input_div:focus-within {
  border: 1px solid #ED1E24;
}

.comment_input > div > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1;
  /* margin-left: 10px; */
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

.comments_icons_div {
  display: flex;
  margin: auto;
  gap: 10px;
  padding-right: 15px;
}

.post_likeCommentBox {
  padding: 17px 26px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  gap: 19px;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #a6a6a6;
}

.comments_div {
  padding: 30px 30px 30px 25px;
}


.hide_connect_label {
  opacity: 0;
}

.button {
  display: flex;
  padding: 0.75rem 1.25rem;
  border: 1px solid #ED1E24;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #ED1E24;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  position: relative;
  height: fit-content;
}

.button:hover {
  background-color: #ED1E24;
}

.button:hover p {
  color: #fff;

}


.mostRelevant {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}

@media (max-width: 1099px) {
  .Post:last-child {
    margin-bottom: 80px;
  }
}

@media (max-width: 1099px) {
  .comments_icons_div {
    gap: 2px;
  }
}

@media (max-width: 768px) {
  .comments_icons_div {
    display: none;
  }
  .post_option {
    gap: 0px;
  }
}
@media (max-width: 965px) {
  .post_option {
    gap: 2px;
  }
}
.translateText{
  font-weight: 500 !important;
  color: #ED1E24 !important;
  cursor: pointer;
}