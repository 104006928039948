.container {
  background-color: #fff;
  /* display: flex; */
  max-height: 550px;
  overflow-y: auto;
  padding-right: 4px;
  /* flex-direction: column; */
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.15);
}

.container::-webkit-scrollbar {
  width: 5px;
}
.container::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}
.container::-webkit-scrollbar-thumb:hover {
  background: #e6e6e6;
}
.container::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
