.classfeed {
  margin-top: 15px;
}

.classMessageSender {
  background-color: #fff;
  box-shadow: 0px 0px 20px #3f3f411a;
  border-radius: 10px;
  margin-top: 13px;
}

.classmessageSender_top {
  background-color: white;
  padding: 10px 0 0 0;
  border-radius: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.cover {
  position: relative;
}

.cover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 10px;
  z-index: 1;
}

.classmessageSenderDivider {
  height: 0px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.classmessage_input {
  border-radius: 30px;
  display: flex;
  padding: 10px;
  color: #b4b4b4;
  padding-left: 19px;
  padding-right: 19px;
  box-shadow: 0px 0px 0px #3f3f411a;
}

.classmessage_input > form {
  display: flex;
  width: 100%;
}

.classmessage_input > form > input {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  padding-left: 16px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 25px;
  border: none;
  flex: 1;
  margin-left: 10px;
  outline-width: 0;
  font-weight: 600;
  cursor: pointer;
}

.classmessageSender_bottom {
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.classmessageSender_rightDiv {
  order: 2;
  margin-left: auto;
  display: flex;
  gap: 25px;
}

.classfilter {
  /* margin-left: 440px; */
  display: flex;
  gap: 25px;
  margin-top: -10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: flex-end;
}

.filterOption {
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  color: #b4b4b4;
  padding: 10px;
  cursor: pointer;
  gap: 10px;
}

.filterOption > h4 {
  font: normal normal medium 14px/18px Montserrat;
  letter-spacing: 0.7px;
  color: #b4b4b4;
}

.filterImage {
  width: 20px;
}

.class_discussion {
  background-color: white;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #3f3f411a;
  position: relative;
}

.classpost_header {
  display: flex;
  margin-bottom: 10px;
  gap: 7px;
  padding: 30px 26px 0 26px;
}

.classpost_profile_pic {
  height: 65px;
  width: 65px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 0px;
}

.classpost_Info {
  padding-top: 3px;
}

.classpost_info_userName {
  font: normal normal medium 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.class_visible {
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #848484;
  padding-top: 4px;
  padding-bottom: 1px;
  display: flex;
}

.class_eye {
  height: 20px;
  width: 22px;
  margin-right: 10px;
  margin-top: -2px;
}

.class_started {
  padding: 20px;
}

.class_started > h4 {
  font: normal normal normal 14px/20px Montserrat;
}

.class_started > h3 {
  font: normal normal normal 14px/20px Montserrat;
}

.class_value > h4 {
  font: normal normal normal 14px/20px Montserrat;
}

.class_help {
  text-align: right;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  cursor: pointer;
}

.class_help > h4 {
  font: normal normal normal 14px/20px Montserrat;
  color: #ED1E24;
  text-decoration: underline;
}

.activeimg {
  height: 13px;
  width: 14px;
  padding-right: 10px;
}
@media (max-width: 800px) {
  .cover {
    margin-top: 20px;
  }
}

.loader{
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 580px){
  .class_discussion {
    margin-bottom: 24px;
  }
}
