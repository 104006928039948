.main {
  width: 100%;
  box-sizing: border-box;
  min-height: 30rem;
  padding-bottom: 1rem;
}

.my_post_label {
  color: #000;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.nothing_posted {
  margin: 3rem 0;
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-family: Montserrat;
  font-size: 1.25rem;
  color: #ED1E24;
}

.loader_style{
  text-align: center;
  margin-top: 5rem;
}
