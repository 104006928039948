.hidden {
  display: none;
}
.sign-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.sign-container > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  opacity: 0.8;
}

.sign-logo-section {
  width: 100vw;
  z-index: 1;
}
.sign-logo {
  margin-top: 20px;
  margin-left: 80px;
}
.sign-container-left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.sign-container-left > img {
  width: 200px;
  height: auto;
}
.sign-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 90vh;
  padding: 0 150px;
  box-sizing: border-box;
}
.sign-heading {
  display: flex;
  flex-direction: column;
}
.sign-heading :nth-child(1) {
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 700;
}
.sign-heading :nth-child(2) {
  font-size: 20px;
  opacity: 1;
  width: 80%;
  color:black;
  font-weight: 400;
}
.sign-input {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.sign-name {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
}
.sign-name > input {
  width: 100%;
  box-sizing: border-box;
}
.sign-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}
.sign-action > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input-alert {
  padding: 5px 0 0 20px;
  font-weight: 600;
  color: #e0003b;
}
.hidden {
  display: none;
}
.sign-field {
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 60px;
  background-color: #f4f4f4;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.sign-field:focus {
  outline: none;
}
.sign-action > div:first-child {
  display: flex;
}
.checkbox {
  height: 15px;
  width: 15px;
  margin: 0 5px 0 0;
}
.forgot-password {
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  text-decoration: underline #000000;
}
.sign-btn {
  margin-top: 22px;
  font-size: 16px;
  width: 100%;
}
.button {
  color: white;
  border: 2px solid #000000;
  border-radius: 100px;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  background-color: #000000;
  transition: 0.3s;
  text-transform: uppercase;
}
.button:hover {
  color: white;
  background-color: #333333;
  border: 2px solid #333333;
}

.account-already > span > a {
  color:black;
  text-decoration: none;
  font-weight: 700;
}
.sign-container-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 50%;
  object-fit: cover;
  object-position: left;
  box-sizing: border-box;
  /* Gradient light */
  background: linear-gradient(180.00deg, rgb(255, 245, 245),rgb(255, 236, 236) 100%);
}

.sign-container-right > div {
  width: 50%;
  color: white;
}
.category-section {
  margin-top: 20px;
}
.category-heading {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
.category-first {
  display: flex;
  flex-wrap: wrap;
}
.category-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  flex-grow: 1;
  width: 40%;
}
.category-card:hover {
  background-color: #ED1E24;
  color: white;
}
.category-card > img {
  height: 80px;
  width: 80px;
  margin-right: 10px;
}
.category-card > div {
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
}
.sign-container-right > img {
  height: 100%;
  width: 85%;
  object-fit: cover;
}
.or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.account-already {
  margin-top: 16px;
  font-size:14px;
  font-weight:400;
  text-align: center;
}

.subtext {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Montserrat;

}


/* ############################ Responsive 1650 ############################ */
@media only screen and (max-width: 1650px) {
  .sign-heading :nth-child(1) {
    font-size: 40px;
  }
  .sign-heading :nth-child(2) {
    font-size: 16px;
  }
  .sign-form {
    padding: 0 50px;
  }
  .category-card {
    flex-direction: column;
  }
  .category-card > div {
    margin: 0 0;
  }
  .category-card > img {
    margin: 0 0;
  }
}
/* ############################ Responsive 1100 ############################ */
@media only screen and (max-width: 900px) {
  .sign-container-right {
    display: none;
  }
  .sign-container-left {
    width: 100%;
  }
  .sign-container-left > div {
    align-items: center;
  }
  .sign-form {
    padding: 0 20px;
  }
  .sign-heading {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .category-first {
    align-items: center;
    justify-content: center;
  }
  .category-card > div {
    font-size: 14px;
  }
  .sign-heading :nth-child(1) {
    font-size: 30px;
  }
  .sign-heading :nth-child(2) {
    font-size: 14px;
  }
}
/* ############################ Responsive 600 ############################ */
@media only screen and (max-width: 600px) {
  .category-first {
    flex-direction: column;
    justify-content: center;
  }
  .category-card {
    width: 100%;
  }
  .sign-logo {
    margin-top: 15px;
    margin-left: 10px;
    height: auto;
    width: 150px;
  }
  .sign-form {
    padding: 0 0px;
  }
  .sign-btn{
    font-size: 12px;
  }
  .forgot-password{
    font-size: 12px;
  }
  .or{
    margin-top: 12px;
    font-size:12px;
  }
  .account-already {
    margin-top: 12px;
    font-size:12px;
  }
}


@media only screen and (max-width: 400px) {
  .sign-form {
    margin-top:30px;
    height:auto;
  }
}