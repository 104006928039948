.main {
  box-sizing: border-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: fit-content;
  padding: 1.5rem;
  border-radius: 1.5625rem;
  width: 63vh;
}

.text {
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  margin: 1.125rem 0px;
  letter-spacing: 0.1px;
  line-height: 22px;
}

.buttons_container {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

}

@media (max-width: 510px) {
  .main {
    width: 83%;
  }
}

@media (max-width: 380px) {
  .main {
    width: 76%;
  }
}
