.main {
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card {
  box-shadow: 0px 0px 20px #5555551a;
  padding: 1.25rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.description {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: .625rem;
}

.add_btn {
    border: 2px solid #ed1e24;
    padding: 12px 20px;
    border-radius: 40px;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.btn_label {
    color: #ed1e24;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.add_btn:hover  {
    background-color: #ed1e24;
    color: #fff;
}

.add_btn:hover > p  {
    color: #fff;
}

.publication_container {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  align-items: start;

}

.text_wrapper {
  width: 100%;
  box-sizing: border-box;
}

.title_header {
  display: flex;
  justify-content: space-between;
  width:auto;
  box-sizing: border-box;
}

.title {
  color:#ED1E24;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-bottom: .3125rem;
}

.title_header > img {
  width: 1.25rem;
  cursor: pointer;
  margin-left: auto;
}

.publisher_and_date {
  color: #555;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: .5rem;
}

.url {
  display: flex;
  align-items: center;
  border: 1.5px solid #555555;
  border-radius: 40px;
  padding: .375rem 1rem;
  width: fit-content;
  cursor: pointer;

  color:#858585;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: .75rem;
}

.url > img {
  width: 1.125rem;
  margin-left: 0.5rem;
}

.description {
  color: #555;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.media {
  width: 4rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;

}

/* .btn_label:hover {
    color: #fff;
} */
