@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

*{
  font-family: 'Montserrat', 'sans-serif';
}

.main_container{
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
}
.back_btn{
    color: #858585;
    font-weight: 400;
    padding: 20px;
    cursor: pointer;
    width: fit-content;
    margin-top: 90px;
}
.title_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 140vh; */
}

.assignment_details_container{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 3fr 1fr;
}

.assignment_details_left_container{
    margin-top: 30px;
    padding: 15px;
    box-shadow: 0px 0px 20px 0px #3F3F411A;
    /* flex-grow: 2; */
    margin-right: 30px;
    border-radius: 10px;
}

.assignment_details_right_container{
    margin-top: 30px;
}

.attachment_container_div{
    margin-top: 15px;
}

.assignment_details_description{
    width: 95%;
    margin: auto;
    color: #707070;
    font-size: 14px;
}

.work_div{
    padding: 15px;
    box-shadow: 0px 0px 20px 0px #3F3F411A;
    border-radius: 10px;
}

.assignment_submission_buttons{
    width: 90%;
    margin: auto;
    margin-top: 15px;
    margin-right: 10px;
}

.grade_container{
    margin: 20px 0;
}
.grade_div{
    border-radius: 20px;
    border: 1px solid #A9A9A9;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
}
.private_comment_input{
    margin-top: 20px;
    border-radius: 20px;
    border: 1px solid #a9a9a9;
    padding: 10px;
    width: 90%;
}

.comment_container{
    margin: 6px;
}

.comment_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #000000;
    margin-bottom: 12px;
  }


.assignment_add_button{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.drop_down_outer{
    position: relative;
}

.drop_down{
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 99;
}

.yourWorkHead{
    margin-bottom: 10px;
}

.margin_bottom{
    margin-bottom: 10px;
}

.sub_heading{
    font-size: 14px;
}

@media (max-width: 800px) {
    .assignment_details_container{
        grid-auto-flow: row;
        grid-template-columns: 1fr;
    }
    .button_contain_div{
        display: flex;
    }
    .main_container{
        margin: auto;
        margin-top: 10px;
        width: 95%;

    }
    .assignment_details_left_container{
        margin-right: 0;
    }
    .grade_div{
        width: 70%;
    }
    .private_comment_div{
        margin-bottom: 90px;
        box-shadow: none ;
        padding: 0 ;
    }
    .back_btn{
        display: none;
    }
}

.loader_style{
    text-align: center;
     margin-top: 200px
}