.headerSign{
    display: flex;
    justify-content: space-between;
}

.sign-logo {
    margin-top: 20px;
    margin-left: 80px;
  }


.sign-right-logo{
  margin-top: 25px;
  margin-right: 80px;
  height: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownLanguag{
    width: 100px;
    box-shadow: 3px 2px 4px 2px #55555529;
    border-radius: 5px;
    position: absolute;
    top: 80%;
    left: 40%;
    opacity: 1;
    background-color: #fff;
    z-index: 10;
  }
  
  .dropdownList{
    list-style: none;
    padding: 0;
  }
  
  .inactiveList{
    text-align: center;
    margin-top: 6; 
    color: #555555;
    cursor: "pointer"
  }
  
  .activeList{
    text-align: center;
    margin-top: 6; 
    color: #ED1E24;
    cursor: "pointer"
  }

  .languageShow{
      color: #000000;
      font-size:13px;
      font-weight: 400;
      display: inline;
      margin-left: 10px;
      position: relative;
      bottom: 2px;
  }



  @media only screen and (max-width: 1024px) {
    .dropdownLanguag{
      top: 80%;
      left: 40%;
    }
  }

  @media only screen and (max-width: 600px) {
    .sign-logo {
      margin-top: 15px;
      margin-left: 10px;
      height: auto;
      width: 150px;
    }
    .sign-right-logo {
        margin-top: 15px;
        margin-right: 10px;
      }
      .dropdownLanguag{
        top: 80%;
        left: -40%;
      }
  }
  
  