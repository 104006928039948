.course_modal {
  text-align: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5px 0px;
  height: 450px;
  overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.course_modal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.course_modal {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.course_form {
  /* max-width: 605px; */
  /* min-width: 250px; */
  margin: 20px 32px 25px 32px;
}

.thumbnail_area {
  /* width: 550px; */
  height: 125px;
  background: #F8FCFA 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: #ED1E24 dashed 2px;
  opacity: 1;
  /* margin-left: 25px; */
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  position: relative;
}

.upload_box {
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.upload_box input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.upload_img {
  margin-bottom: 5px;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.upload_txt {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.support_txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #BDBDBD;
}

.thumbnail_pic {
  width: 550px;
  height: 125px;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 5px;
}

.input_label {
  /* font-size: 13px; */
  margin: 15px 0 12px 0;
  /* padding-left: 1rem; */
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  /* font-weight: normal; */
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.input_field {
  border-radius: 25px;
  width: 100%;
  /* width: 550px; */
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 10px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}

input[type="radio"] {
  accent-color: #00a77f;
}

.textbox_field {
  border-radius: 25px;
  width: 100%;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;

}

.hideCreateEditCourseModal {
  display: none;
}

.radio_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: #212121;
  margin-right: 10px;
}

.radio_div {
  margin: 10px 0 0 0;
}

.price_div {
  display: flex;
}

.price_type_input {
  border-radius: 25px;
  width: 92px;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

.price_input {
  border-radius: 25px;
  width: 100%;
  /* margin-left: 15px; */
  height: 35px;
  outline: none;
  border: 1px #c2c2c2 solid;
  padding-left: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
}

.nextbtn_div {
  margin-top: 15px;
  margin-left: auto;
  width: fit-content;
}

.fields_div {
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {

  .input_field,
  .textbox_field {
    width: 100%;
  }

  .course_form {
    /* width: 85%; */
    margin: 15px 16px 15px 16px;
  }

  .price_input {
    width: 50%;
  }

  .price_type_input {
    width: 50%;
  }

  @media (min-height: 850px) and (max-height: 950px) {
    .course_modal {
      height: 660px;
    }
  }

  @media (min-height: 750px) and (max-height: 850px) {
    .course_modal {
      height: 580px;
    }
  }

  @media (min-height: 650px) and (max-height: 750px) {
    .course_modal {
      height: 480px;
    }
  }

  @media (min-height: 500px) and (max-height: 650px) {
    .course_modal {
      height: 362px;
    }
  }

  @media (min-height: 350px) and (max-height: 500px) {
    .course_modal {
      height: 312px;
    }
  }

  @media (max-height: 350px) {
    .course_modal {
      height: 210px;
    }
  }
}

@media (min-width:500px) and (max-width: 1370px) {
  @media (max-height: 750px) {
    .course_modal {
      height: 362px;
    }
  }

  @media (min-height: 750px) and (max-height: 950px) {
    .course_modal {
      height: 520px;
    }
  }
}