.main {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    box-sizing: border-box;
    /* width: 40vw; */
  }
  
  .label {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: .75rem;
    margin-left: 1.25rem;
  }
  
  
  .add_image_container {
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }
  
  .image_wrapper {
    position: relative;
    box-sizing: border-box;
    /* width: 20%; */
    aspect-ratio: 1 / 1;
  }
  
  .image_wrapper > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .upload_button_container {
    margin-left: 1.25rem;
    box-sizing: border-box;
  }
  
  .add_image_label {
    color:#535353;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .upload_btn {
    display: flex;
    width: 40%;
    padding: 0.5rem;
    border: 2px solid #ED1E24;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Montserrat;
    letter-spacing: 0px;
    color: #ED1E24;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    margin-top: 1.25rem;
  }
  
  .upload_btn p {
    margin: auto;
    font-size: 1.25rem;
    font-weight: 500;
  }
  
  .upload_btn:hover {
    background-color: #ED1E24;
  }
  
  .upload_btn:hover p {
    color: #fff;
  }
  
  .file_upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
  }
  
  .field_container {
    box-sizing: border-box;
    margin-bottom: 2rem;
  }
  
  .label_icon {
    display: flex;
    align-items: center;  
    margin-bottom: .75rem;
    box-sizing: border-box;
  }
  
  .label_icon img {
    width: 1.5rem;
    margin-left: 1rem;
    margin-right: .75rem;
  }
  
  .label_icon p {
    color: #000;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .input_field {
    outline: none;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #5555555A;
    padding: .625rem;
    font-family: Montserrat;
    font-size: 1rem;
    border-radius: 50px;
  }
  
  .text_area {
    box-sizing: border-box;
    border: 1px solid #5555555A;
    padding: .625rem;
    font-size: 1rem;
    border-radius: 30px;
  }

  .date_container {
    display: flex;
    gap: 1.625rem;
    align-items: center;
  }
  

@media only screen and (max-width: 700px) {
  .main {
    width: 90%;
    box-sizing: border-box;
  }

  .upload_btn {
    box-sizing: border-box;
    width: 100%;
  }

  .upload_btn p {
    margin: auto;
    font-size: .875rem;
    font-weight: 500;
  }

  .date_container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: start;
  }
}

  