.sidebarClass {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
}
.classImage {
  flex: 0.3;
  padding: 10px 10px;
}
.classimg {
  width: 70px;
  height: 70px;
  border-radius: 100%;
}
.classPara {
  flex: 0.7;
  text-align: left;
  padding: 10px 0;
  margin: auto 0;
  color: #707070;
  font-weight: 600;
}
.classText {
  margin: 10px;
}

.sidebar_stat1 {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #ED1E24;
  cursor: pointer;
  font: normal normal 600 13px/16px Montserrat;
}

.classImageHover {
  position: relative;
  flex: 0.3 1;
  padding: 10px 10px;
}

.classImageHover::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 18px;
  width: 67%;
  height: 75%;
  background-color: rgb(248 115 119 / 50%);
  pointer-events: none;
  border-radius: 100px;
  z-index: 1;
}

@media (max-width: 580px){
  .classImageHover {
    position: relative;
    flex: 0.3 1;
    padding: 10px 10px;
  }
  
  .classImageHover::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 25px;
    width: 59%;
    height: 75%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 100px;
    z-index: 1;
  }
}
