* {
  scroll-behavior: smooth;
}

.container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 28px;
  padding-bottom: 30px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.container::-webkit-scrollbar {
  display: none;
}

.settingsContainer {
  display: flex;
  width: 100%;
  position: relative;
  padding-bottom: 38px;
}
