.assignment_individual_list_container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 4fr 1fr;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  width: 95%;
  cursor: pointer;
}
.assignment_individual_list_container_tchr {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 4fr 3fr 1fr 1fr 1fr;
  border-bottom: 1px solid #e5e5e5;
  padding: 15px;
  align-items: center;
  width: 95%;
  cursor: pointer;
}

/* .assignment_status_div{
    width: 100px;
} */

.assignment_status_div_tchr {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.assignment_status {
  color: #7b7b7b;
}

.assignment_due_date {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 700px) {
  .assignment_individual_list_container {
    grid-template-columns: 2fr 2fr 1fr;
    padding: 16px 10px;
  }
  .assignment_individual_list_container_tchr {
    grid-template-columns: 3fr 2fr;
    padding: 15px 7px;
  }
  .assignment_status_div_tchr {
    display: none;
  }
  .assignment_due_date {
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .assignment_status {
    color: #7b7b7b;
    font-size: 13px;
    font-weight: 500;
  }
}
