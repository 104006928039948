.main {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  width: 80vw;
  margin: auto;
  margin-top: 5.75rem;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.sidebar {
  box-sizing: border-box;
  border: 1 px solid #9a9a9a;
  width: 100%;
  height: 100%;
  /* padding: .625rem 1.125rem; */

  /* display: grid; */
  height: 87vh;
}

.sidebar_header,
.chat_header {
  padding: 1.25rem 1.125rem;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-weight: 600;
  height: 3.5vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9a9a9a;
}

.chat_header {
  display: flex;
  align-items: center;
}

.menu {
  cursor: pointer;
  margin-left: auto;
}

.contact_list {
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 100%;
}

.contact_list::-webkit-scrollbar {
  display: none;
}

.current_contact {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-left: 1.5rem;
}

.back_arrow {
  cursor: pointer;
}

.current_contact > p {
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;
}

.searchbar {
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  width: 95%;
  margin-bottom: auto;
  background-color: #f0f0f0;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  border-bottom: 2px solid #ed1e24;
  background: #f0f0f0;
  margin: auto;
  margin-top: 0.625rem;
}

.search_label {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.search_input {
  outline: none;
  background-color: #f0f0f0;
  font-family: "Montserrat";
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
}

.search_icon {
  width: 14px;
  /* padding: 8px 8px 8px 8px; */

  margin-left: 0.25rem;
  margin-right: 1rem;
}

.back_icon {
  width: 1.25rem;
  cursor: pointer;

  margin-left: 0.25rem;
  margin-right: 1rem;
}

.chat_window {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #9a9a9a;
  width: 100%;
  height: 89.5vh;

  background-image: url("./images/Chat_Background.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: repeat;
}

.right_blank_panel {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 1px solid #9a9a9a;
  width: 100%;
  height: 89vh;

  background-image: url("./images/illustration_chat.svg");
  background-position: center;
  background-size: 46%;
  background-repeat: no-repeat;
}

.messages {
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  overscroll-behavior: contain;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.messages::-webkit-scrollbar {
  display: none;
}

.date_split {
  margin: 1.875rem auto;
  border-radius: 6px;
  box-sizing: border-box;
  width: fit-content;
  padding: 0.5rem 1.25rem;
  background: #e8e8e8;
  box-shadow: 0px 0px 1.7px 0px rgba(0, 0, 0, 0.25);

  color: #1D1D1D;
  font-family: Montserrat;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.35px;
}

.textbox {
  background-color: #f0f0f0;
  height: 8vh;
  margin-top: auto;
  padding: 1rem 1.13rem;
  box-sizing: border-box;
}

.delete_messages_menu {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.selected_count {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text_input {
  outline: none;
  background-color: white;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 0.375rem;
  padding-left: 0.5rem;

  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text_input::placeholder {
  color: #6b7c85;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hide_sidebar {
  display: none;
}

/* --------- Media Queries -------- */

/* Tablets */
@media (max-width: 1139px) {

  .main {
    width: 100%;
  }
  
  .sidebar {
    height: 80vh;
  }

  .chat_window {
    height: 81vh;
  }

  .hide_sidebar {
    display: none;
  }
  
}

/* Low Resolution Tablets, Mobiles */
@media (max-width: 800px) {

  .main {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .sidebar {
    width: 100%;
  }

  .chat_window {
    height: 82vh;
  }

  .hide_sidebar {
    display: none;
  }
  
}

@media (max-width: 600px) {

  .main {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .sidebar {
    width: 100%;
  }

  .chat_window {
    height: 81vh;
  }

  .hide_sidebar {
    display: none;
  }
  
}

/* Most of the Smartphones Mobiles */
@media (max-width: 380px) {
  
  .main {
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 0;
  }

  .chat_window {
    height: 80vh;
  }

  .sidebar {
    width: 100%;
  }
  
  .chat_window {
    height: 75vh;
  }

  .hide_sidebar {
    display: none;
  }
  
}