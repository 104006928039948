.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}

.threeDotsMenu {
  height: 40px;
  cursor: pointer;
}

.feed_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px; /*135vh*/
  padding-top: 12px;
}

.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dueDate {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 10%;
  float: right;
}

.dropDown ul {
  list-style: none;
  padding: 1px 0px;
}

.dropDown img {
  height: 40px;
}
.duedate_component {
  margin-right: 10px;
  margin-top: 10px;
}

.submissions_container {
  box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
  border-radius: 10px;
  padding: 20px 0px;
  margin: 15px auto;
  margin-bottom: 0%;
  width: 100%;
  /* for content */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* width: 135vh; */
}

.choice {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: Montserrat;
  /* width: 42%; */
}
.choice > .status {
  margin: 0 15px;
}
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  cursor: pointer;
}

.count {
  font-size: 2rem;
}

.options {
  font-size: 0.9rem;
  color: #7b7b7b;
}

/* Attachment Container */

.assignment_details_container {
  margin-top: 30px;
  padding: 15px 0;
  box-shadow: 0px 0px 20px 0px #3f3f411a;
  min-height: 150px;
  width: 100%;
  border-radius: 10px;
}

.attachment_container_div {
  /* margin-top: 15px; */
  margin: 15px 25px 0 25px;
}

.assignment_details_description {
  margin: 10px 25px;
  color: #707070;
  font-size: 14px;
}

.std_work_btn {
  margin-right: 10px;
}
.duedate_component_mobile {
  display: none;
}
.dropdown_mobile {
  display: none;
}

@media (min-width: 800px) and (max-width: 1018px) {
  .main_container {
    margin-top: 94px !important;
    /* margin-bottom: 90px !important; */
  }
}
@media  (max-width: 420px) {
  .options {
    font-size: 0.75rem;
    width:100%;
    white-space: nowrap;
    text-overflow: ellipsis;   
  }
  .status{
    max-width: 20%;
  }
  .count {
    font-size: 1.75rem;
  }
}

@media (max-width: 1018px) {
  .feed_container {
    width: 90%;
  }
  .duedate_component {
    display: none;
  }
  .main_container {
    margin-top: 0;
  }
  .duedate_component_mobile {
    display: block;
    margin-top: 15px;
  }
  .title_container {
    flex-direction: column;
    align-items: flex-start;
  }
  .dropdown {
    display: none;
  }
  .dropdown_mobile {
    display: block;
    margin-right: 10px;
  }
  .submissions_container {
    flex-direction: column;
    align-items: flex-start;
  }
  .std_work_btn {
    margin-left: auto;
    margin-top: 25px;
  }
  .choice > .status {
    margin: 0 10px;
  }
  .choice_menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .assignment_details_container {
    margin-bottom: 90px;
  }
  .back_btn {
    display: none;
  }
  .attachment_container_div{
    margin: 15px 15px 0 15px;
  }
  .assignment_details_description {
    margin: 10px 15px;
  }
}

@media (min-width: 1018px) and (max-width: 1090px) {
  .feed_container {
    width: 90%;
  }
}

.loader_style{
  text-align: center;
   margin-top: 200px
}

@media (max-width: 580px){
  .feed_container {
    width: 95%;
  }
  .count {
    font-size: 20px;
    font-weight: 500;
  }
  .choice{
    width: 70%;
  }
  .threeDotsMenu {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
  .assignment_details_container {
   min-height: 0px;
   margin-top:24px;
  }
  .assignment_details_description {
    margin: 0px 10px;
  }
}