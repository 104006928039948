.CourseView{
    margin: auto;
    margin-top:90px;
    width: 82vw;
    
    /* padding-left:80px;
    padding-right:80px; */
}
.banner{
    max-width: 100%;
    width:100%;
    background-repeat: no-repeat;
    background-size:contain;
    position: relative;
}
.banner::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98.5%;
    background-color: rgb(248 115 119 / 50%);
    pointer-events: none;
    border-radius: 10px;
}

.banner img{
   width:100%; 
   border-radius: 8px;
   height:33vh;
}

.CourseDetails{
    padding-top:12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.CourseDetailsLeft{
    width: 75%;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    border-radius: 16px;
    padding:24px;
    margin-bottom: 20px;
    height: fit-content;
    /* border: 1px solid black; */

}
.CourseDetailsRight{
    display: flex;
    flex-direction: column;
    width:25%;
    margin-left:8px;
}

/* -------- */

.course_top_section {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem
}

.top_section_right {
    width: 30%;
}

/* ------- */
.loader{
    width: 100%;
    margin-top:20px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.BuyingDetail{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background:white;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    border-radius: 16px;
}
.caption{
    font-family: Montserrat;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    padding-top:12px;
    padding-bottom:12px;
}

.descTitle{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;    
    padding-bottom:12px;
}
.desc{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom:12px;
}

.exploration{
    padding-top:24px;
    padding-left: 8px;

}

.exploreCards{
    padding-top:24px;
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;
}

.card{
    height: 300px;
    width: calc(100% / 5);
    border-radius: 10px;
    background:rgb(207, 207, 241);
}
.enrolled{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:8px;
    /* padding-top:8px; */
    background:white;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    border-radius: 16px;
    cursor: pointer;
}

.enrolled:hover {
    background-color: #ED1E24;
}

.enrolled p{
    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #ED1E24;
}

.enrolled:hover p {
    color: #FFFFFF;    
}

.link {
    text-decoration: none;
}

/* Course attachment content */
.attachmentContainer{
    width: 75%;
    margin-top:24px;
    margin-bottom:24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding:24px;
    box-sizing: border-box;
    border-radius: 16px;
}

.attachmentContainer > p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom:12px;
}

.download_card {
    margin-top: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0px 0px 20px rgba(63, 63, 65, 0.102);
    padding: 1rem 1.5rem;
}

@media only screen and (max-width: 1000px) {
    .banner img{
        height:15vh;
    }
    
    .exploration {
        margin-bottom: 90px;
    }
    
    .CourseView{
        width:95%;
    }
    
    .enrolled p{
        font-size: 14px;
    }

    .BuyingDetail{
        padding:15px 15px;
    }

    .enrolled{
        padding:20px;
    }

}


@media only screen and (max-width: 800px) {
 
    .CourseView{
        margin-top:0px;
        padding-left:20px;
        padding-right:20px;
        width:95%;
    }

    .caption{
        font-size: 14px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-top:12px;
        padding-bottom:12px;
    }
    
    .descTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        padding-bottom:12px;
    }

    .desc{
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-bottom:12px;
    }
    
    .dropdownBtn{
        width:40%;
    }

    .exploreCards{
        padding-top:12px;
        display: flex;
        justify-content: center;
        gap:12px;

    }
    
    .card{
        height: 300px;
        width: calc(100% / 3);
        border-radius: 10px;
        background:rgb(73, 73, 210);
    }

    .attachmentContainer{
        width: 100%;
        padding:12px;
    }
    
    .attachmentContainer > p{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }
}

@media only screen and (max-width: 500px) {
    .CourseView{
        padding: 0px;
        margin-bottom: 20%;
    }

    .CourseDetails{
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .CourseDetailsLeft{
        box-sizing: border-box;
        padding: 13px;
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .CourseDetailsRight{
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .banner img{
        height:18vh;
    }

    .exploration {
        margin-bottom: 90px;
        display: none;
    }

    .enrolled{
        padding:25px;
    }

    .descTitle{
        font-weight: 600;
    }

    .BuyingDetail{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        padding: 20px 20px;
    }
}