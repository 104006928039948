.main{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.heading{
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.img_container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: #ED1E24;
  border-radius: 100%;
  /* padding: 5px; */
  height: 32px;
  width: 32px;
  /* border: 1px solid #ED1E24; */
}

.heading span{
  font-size: 14px;
  font-weight: 600;
  font-family: Montserrat;
  line-height: 17px;
}

.cross{
  width: 20px;
  cursor: pointer;
}