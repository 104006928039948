.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
}

.back_btn {
  color: #858585;
  font-weight: 400;
  padding: 20px;
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
}

.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.due_date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10%;
}

.content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 1018px;
  padding-top: 12px;
}

.middle_container {
  display: flex;
  width: 100%;
}

.left_container {
  width: 70%;
  padding: 2rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  border-radius: 10px;
  min-height: 400px;
  margin-bottom: 100px;
  /* border: 1px solid red; */
  color: #707070;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.right_container {
  display: flex;
  flex-direction: column;
  width: 30%;

  margin-left: 1.5rem;
  /* border: 1px solid green; */
  /* box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1); */
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.attempt_container {
  display: flex;
  flex-direction: column;
  /* width: 25%; */
  height: fit-content;
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
}

.max_attempt {
  margin-bottom: 1.5rem;
}
.your_attempt {
  margin-bottom: 2.5rem;
}

.btn_label {
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.grade_container {
  /* box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1); */
  padding: 1.5rem;
  border-radius: 10px;
}

.grade_label {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.marks_div {
  margin-top: 1rem;
  border-radius: 59px;
  border: 1px solid #a6a6a6;
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem 2rem;
  text-align: center;
}

@media (max-width: 580px){
  .main_container {
    margin: 0px;
    height: 100%;
    width: 87%;
  }
  .content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    padding: 10px;
  }
  .title_container {
    display: block;
    width: 100%;
    margin-bottom: 1rem;  
  }
  .title_container_heading{
    width: 100%;
  }
  .due_date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10%;
    margin-top: 10px;
    color: var(--text-grey, #555);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
  }
  .middle_container {
    display: block;
    width: 100%;
  }
  .left_container {
    width: 100%;
    padding: 1rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
    border-radius: 10px;
    min-height: 131px;
    margin-bottom: 15px;
    color: #707070;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    letter-spacing: 0.25px;
  }
  .right_container {
    display: flex;
    flex-direction: column;
    width: 109%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 10px;
    margin-left: 0rem;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .your_attempt {
    margin-bottom: 2rem;
  }
  .btn_label {
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .attempt_container {
    display: flex;
    flex-direction: column;
    /* width: 25%; */
    height: fit-content;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  }
  .grade_label {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .marks_div {
    margin-top: 1rem;
    border-radius: 59px;
    border: 1px solid #a6a6a6;
    padding: 13.777px 27.554px;
    text-align: center;
    width: 60%;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .grade_container {
    padding: 0rem;
  }
  
}