.main_container {
    margin-top: 90px;
    height: 100%;
    width: 100%;
    font-family: Montserrat;
}

.feed_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 80vw;
    margin-top: 25px;
}

.title_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 17%;
}

.middle_container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.left_container {
    /* margin-right: 0px; */
    width: 100%;
}

/* --- add question stuff */
.addQuestionCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    display: flex;
    width: 100%;
    height: 145px;
    margin-bottom: 20px;
    margin-top: 15px;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    position: relative;
    cursor: pointer;
  }
  
  .innerBorder {
    display: flex;
    border: 1px dashed #b4b4b4;
    border-radius: 10px;
    width: 92%;
    height: 62%;
    cursor: pointer;
    justify-content: center;
  }
  
  .addQuestionText {
    display: flex;
    flex-direction: row;
    justify-content: space-end;
    align-items: center;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
  }
  
  .plus {
    width: 30px;
  }

  .addChoices {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  
  .addChoices > img {
    margin-top: 10px;
    width: 22px;
    cursor: pointer;
  }

  .back_btn {
    font-weight: 400;
    color: #858585;
    cursor: pointer;
    padding: 20px;
    font-size: 0.875rem;
    width: fit-content;
  }

  @media(max-width: 580px){
    .main_container {
      margin-top: 0px;
    }

   .feed_container {
      width: 100%;
      margin-top: 0px;
    }

   .middle_container {
     margin-top: 10px;
     width: 90%;
   }

  .plus {
    width: 25px;
  }
  .addQuestionCard {
    height: 82px;

  }
  .btnPosition{
    float: right;
    margin-left: 70%;
    margin-bottom: 100px;
  }
}