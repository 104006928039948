.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
  box-sizing: border-box;
}

.back_btn {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: fit-content;
}

.loader_style {
  text-align: center;
  margin-top: 200px;
}

.gradebook_outer_container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* border: 1px solid greenyellow; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.header {
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  margin-top: 1rem;
  /* border: 1px solid black; */
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.05),
    0px 2px 8px 0px rgba(25, 33, 61, 0.04);
  border-radius: 10px;
  margin-bottom: 2.5rem;
}

.count_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}

.count_label {
  color: #8a8a8a;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 111.111% */
  margin-bottom: 0.875rem;
}

.count {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 90.909% */
}

.searchbar {
  display: flex;
  padding: 4px 12px;
  width: 40%;
  border: 1px solid #EBEBEB;
  text-decoration: none;  
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 300;
}

.menu_icon {
  padding-right: 4px;
}

.searchbar > input {
  outline: none;
  box-sizing: border-box;
  width: 100%;
  border-radius: 50px;
  padding: .25rem;
  color: #49454F;
  font-weight: 300;

}

.searchbar > input::placeholder {
  font-family: Montserrat;
  color: #555555;
  font-weight: 300;

}

.label_and_button_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  align-items: center;
  position: relative;
}

.student_details_label {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}

.print_btn {
  display: flex;
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #EF3F3F;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  cursor: pointer;
}

.print_btn > img {
  width: 24px;
  margin-right: 10px;
}

.print_btn:active { 
  transform: scale(0.98); 
} 

.table_header {
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 0.6fr 0.5fr 0.5fr 0.5fr;  
  /* grid-template-columns: 0.3fr 0fr 0.5fr 0.5fr 0.5fr 0.6fr;;   */
  align-items: center; 
  padding: 1.5rem;
  margin-bottom: 1.625rem;
}

.table_header > p {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
  margin-right: 0.5rem;
}

.hideBtn {
  visibility: hidden;
}

.no_results {
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  color: #ED1E24;
}

.pagination_container {
  box-sizing: border-box;
  border-radius: 16px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 32px 16px 32px 32px;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);
  margin-bottom: 90px;
}

/* -------- MEDIA QUERIES ---------- */

/* 
  ##Device = Tablets, Ipads 
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  
  .main_container {
    margin-top: 3rem;
  }
  .gradebook_outer_container {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .label_and_button_container {
    margin-bottom: 1rem;
  }

  .menu_icon {
    display: none;
  }

  .student_details_label {
    font-size: 1.25rem;
  }

  .table_header {
    margin-bottom: 0;
  }

  .table_header > p {
    font-size: 1rem;
  }

  .count {
    font-size: 1.25rem;
  }

  .count_label {
    font-size: 1rem;
  }

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  
  .hide {
    display: none;
  }


  .label_and_button_container {
    margin-bottom: 1rem;
  }

  .table_header {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 0.5fr;
    margin-bottom: 0;
  }

  .main_container {
    margin-top: 0.5rem;
  }
  .gradebook_outer_container {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .menu_icon {
    display: none;
  }

  .student_details_label {
    font-size: 1.25rem;
  }

  .table_header > p {
    font-size: 1rem;
  }

  .count {
    font-size: 1.25rem;
  }

  .count_label {
    font-size: 1rem;
  }

  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  
  .hide {
    display: none;
  }

  .label_and_button_container {
    margin-bottom: 1rem;
  }

  .table_header {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 0.5fr;
    margin-bottom: 0;
  }

  .main_container {
    margin-top: 0.5rem;
  }
  .gradebook_outer_container {
    margin-top: 0.75rem;
    width: 90vw;
  }

  .menu_icon {
    display: none;
  }

  .student_details_label {
    font-size: 1.25rem;
  }

  .table_header > p {
    font-size: 1rem;
  }

  .count {
    font-size: 1.25rem;
  }

  .count_label {
    font-size: 1rem;
    margin-bottom: .3125rem;
  }
  
}