.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
}

.back_btn {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: fit-content;
}

.loader_style {
  text-align: center;
  margin-top: 200px;
}

@media (max-width: 580px){
  .main_container{
    margin-top: 0px;
    margin-bottom: 150px;
  }
}