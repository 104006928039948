.main {
    box-sizing: border-box;
    padding: 1.25rem 1rem;
    border: 1px solid;
    border-radius: .75rem;
    min-height: 10rem;
    font-family: Montserrat;

    display: flex;
    flex-direction: column;
    gap: 0.25rem
}

.title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.instructor_name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ED1E24;
}

.profile_pic {
    padding: 0.5rem;
    border-radius: 50px;
    background-color: #b6b0b0;
    min-height: 5rem;
    margin: 0.5rem 0;
}

.stats {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stats img {
    margin-right: 0.5rem;
}

.count {
    display: flex;
    align-items: center;

    font-weight: 450;
}

.middle_container {
    display: flex;
    align-items: center;
    gap: 1rem;
}