.app-body {
  display: flex;
  background-color: #ffffff;
  font-family: Montserrat Family;
}
.dashboardInnerContainer {
  padding-top: 94px;
}
@media (max-width:990px){
  .dashboard {
    width: 100vw;
  }
  .dashboardInnerContainer {
    margin: 0;
  }
}
@media (max-width: 900px) {
  .header {
    display: none !important;
  }
  .col-xs-0,
  .col-sm-0,
  .col-md-0,
  .col-lg-0 {
    flex: 0 0 0;
    max-width: 0;
    padding-right: 0;
    padding-left: 0;
    display: none;
  }
  .dashboardFeedMiddleRow{
    width: 100%;
  }
}
@media (max-width: 800px) {
  .dashboardInnerContainer {
    padding-top: 0;
  }
  
}