.app_body {
  display: flex;
  background-color: #ffffff;
  font-family: "Montserrat";
  margin-top: 50px;
}

.classContainer {
  margin-bottom: 100px;
}

/* .topHeading {
  text-align: left;
  line-height: 34px;
  font-size: 28px;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 10px;
  border-left: 5px solid #ED1E24;
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
} */

.plusSign {
  background: #ED1E24 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 38px;
  height: 38px;
  margin-bottom: 20px;
  color: white;
}

.sampleCardContainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #b4b4b4;
  display: flex;
  width: 257px;
  height: 350px;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 10px;
  opacity: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sampleCardText {
  text-align: center;
  line-height: 24px;
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: bold;
  letter-spacing: 0px;
  color: #555555;
  text-transform: capitalize;
  opacity: 1;
}

.sampleCardContainer:hover {
  cursor: pointer;
}

.subjectCardsContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

@media (max-width: 900px) {
  .app_body {
    margin-top: 0px;
  }

  .subjectCardsContainer {
    justify-content: center;
  }

  .topHeading {
    text-align: center;
  }

  .app_body {
    justify-content: center;
  }

  .classContainer {
    width: 90%;
  }

  .classContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .classContainer {
    margin-top: 20px;
  }
}