.main_container {
  margin-top: 90px;
  height: 100%;
  width: 100%;
  font-family: Montserrat;
  box-sizing: border-box;
}

.back_btn {
  font-weight: 400;
  color: #858585;
  cursor: pointer;
  padding: 20px;
  font-size: 0.875rem;
  width: fit-content;
}

.loader_style {
  text-align: center;
  margin-top: 200px;
}

.gradebook_outer_container {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  /* border: 1px solid greenyellow; */
  width: 64vw;
  margin: auto;
  box-sizing: border-box;
}

.gradebook_inner_container {
  border-radius: 10px;
  background: #fff;
  margin-top: 1.5rem;
  box-shadow: 0px 0px 20px 0px rgba(63, 63, 65, 0.1);

  min-height: 640px;
  padding: 1rem;
  margin-bottom: 5rem;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1.5rem;
  position: relative;
  box-sizing: border-box;
}

.btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  position: absolute;
  right: 0;
  bottom: -75%;
  z-index: 5;
  box-sizing: border-box;
}

.btn, .btn2 {
  display: flex;
  padding: .375rem .625rem;
  border-radius: 5px;
  background-color: #FFF;
  justify-content: center;
  align-items: center;
  color: #ED1E24;
  border: 1.5px solid #ED1E24;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
  cursor: pointer;
}
.btn > img, .btn2 > img {
  width: 1.25rem;
  margin-right: 10px;
  cursor: pointer;
}

.btn2 {
  position: relative;
  margin-left: 1.25rem;
  right: 9%;
}

.hide_print_label {
  opacity: 0;
}

.btn:active, .btn2:active { 
  transform: scale(0.98); 
} 

.asg_quiz_count_container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  box-sizing: border-box;
  margin-right: 0.5rem;
}

.asg_quiz_count_container .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asg_quiz_count_container .icon {
  background-color: #ED1E24;
  padding: .4688rem .4375rem;
  margin-right: 0.5rem;
  border-radius: 6px;
  
}

.asg_quiz_count_container .icon > img{
  width: .9375rem;
  height: .9375rem;
  display: block;
}

.asg_quiz_count_container .label {
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.asg_quiz_count_container .label span {
  font-weight: 600;
}

.avg_score_container {
  padding: .875rem 1.625rem;
  border-radius: 10px;
  background-color: #ffcfd0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: fit-content;
}

.avg_score_container .label {
  color: #3c3c3c;
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.avg_score_container .avgScore {
  color: #ed1e24;
  font-family: Montserrat;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 1rem;
}

/* ------------- MENU --------- */
.btn_label {
  font-family: "Montserrat";
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
}

.menu_item_label {
  color: #000;
  font-size: 0.75rem;
  font-family: "Montserrat";
  font-weight: 500;
}

.menu_icon {
  width: 1.1875rem;
  height: 1.1875rem;
  margin-right: 0.5rem;
}

/* -------- MEDIA QUERIES ---------- */

@media (min-width: 1025px) and (max-width: 1280px) {
  
  .gradebook_outer_container {
    width: 90vw;
  }
  
}

/* 
  ##Device = Tablets, Ipads 
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 820px) and (max-width: 1024px) {

  .gradebook_outer_container {
    width: 90vw;
  }
  
  

  
}

/* 
  ##Device = Low Resolution Tablets, Mobiles
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 819px) {

  .main_container {
    margin-top: 0;
  }

  .gradebook_outer_container {
    width: 90vw;
  }

  .header {
    display: grid;
    /* column-gap: .875rem;
    row-gap: 0.5rem; */
    gap: 0.875rem;
    grid-template-columns: 1fr 1fr;
  }

  .btn_container {
    position: relative;
    bottom: 0;
    right: 0;
    justify-content: start;
    align-self: center;
    justify-self: end;
  }

  .asg_quiz_count_container {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  
  /* asg_quiz_count_container .label {
    font-size: .875rem;

  } */

  .avg_score_container {
    width: fit-content;
    grid-row-start: 1;
    grid-column-start: 2;
    align-self: center;
    justify-self: end;
  } 


  .btn {
    display: none;
  }
  
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

  .main_container {
    margin-top: 0;
  }

  .gradebook_outer_container {
    width: 90vw;
  }

  .header {
    display: grid;
    /* column-gap: .875rem;
    row-gap: 0.5rem; */
    gap: 0.875rem;
    grid-template-columns: 1fr 1fr;
    margin: 0;
  }

  .btn_container {
    position: relative;
    bottom: 0;
    right: 0;
    justify-content: start;
    align-self: center;
    justify-self: end;
  }

  .asg_quiz_count_container {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  
  .asg_quiz_count_container .label {
    font-size: .875rem;
  }

  .avg_score_container {
    width: fit-content;
    grid-row-start: 1;
    grid-column-start: 2;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: end;
  } 

  .avg_score_container .label {
    font-size: .875rem;
  }

  .avg_score_container .avgScore {
    font-size: 1.2rem;
  }

  .btn {
    display: none;
  }


  
  
  
}
