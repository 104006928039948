.main {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-sizing: border-box;
  border: 1px solid #fff;
  height: fit-content;
  padding: 1.5rem 1.5rem;
  border-radius: 16px;
  width: 23vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.icon > img {
  width: 6rem ;
  margin: auto;
}

.message {
  margin: 1rem 0;
  text-align: center;
  color: #000;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btn_container {
  display: flex;
  margin: auto;
}

.btn1,
.btn2 {
  border-radius: 50px;
  border: 1px solid #ED1E24;
  background-color: #ED1E24;
  padding: 0.3rem 1rem;
  text-align: center;
  color: #fff;
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  text-align: center;
}
.btn2{
  margin-left: 1rem;
}

/* ---------- Media Queries ----------- */

@media screen and (max-width: 1024px) {
  .main {
    width: 40vw;
  }
  
}


@media screen and (max-width: 900px) {
  .main {
    width: 55vw;
  }
  
}


@media screen and (max-width: 700px) {
  .main {
    width: 80vw;
  }
  
}


@media screen and (max-width: 600px) {
  .main {
    width: 85vw;
  }
  
}


@media screen and (max-width: 500px) {
  .main {
    width: 90vw;
  }
  
}