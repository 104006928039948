.main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding: 20px 40px;
  font-family: Montserrat;
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
}

.logo {
  border-right: 1px solid #a6a6a6;
  padding-right: 16px;
  margin-right: 1rem;
  cursor: pointer;
}

.title {
  margin-right: auto;
  font-size: 1.75rem;
  font-weight: 700;
}

.progress {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.progress > span {
  font-weight: 500;
  color: #000000;
  font-size: 1.2rem;
  padding: 0 10px;
}

.angleLeft{
  display: none;
}

@media (max-width: 600px) {
  .main {
    display: flex;
    z-index: 900;
    width: 100%;
    padding: 20px 0px;
  }
  
  .logo, .progress {
    display:none;
  }

  .title {
    margin-right: auto;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .angleLeft{
    display:block;
    margin-right: 0rem;
  }
  
}

@media (min-width: 601px) and (max-width: 1000px) {
  .progress > span {
    display: none;
  }

  .title {
    font-size: 1.2rem;
    white-space: nowrap;
  }
}