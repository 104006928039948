.Post {
    /* flex: 0.57; */
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #3f3f411a;
    border-radius: 10px;
    position: relative;
  }
  .infoContainer {
    display: flex;
  }
  .post_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 7px;
    padding: 30px 26px 0 26px;
    box-sizing: border-box;
  }
  
  .boost_btn {
    border-radius: 50px;
    padding: 1rem;
    text-align: center;
    border: 1px solid #ed1e24;
    background-color: #ed1e24;
    color: #ffffff;
    margin: 0.5rem 0 1.5rem 0;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .boost_btn p {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: Montserrat; 
  }


  .hide_connect_label {
    opacity: 0;
  }
  
  .post_Info {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  
  .post_tag {
    position: absolute;
    right: 12%;
    top: 0;
    padding: 0.1rem 1rem 0.3rem 1rem;
    background: #ED1E24 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 6px 6px;
    font-family: Montserrat;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .post_info_follower {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .post_info_timestamp {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #848484;
    margin: 0;
  }
  
  .post_profile_pic {
    width: 20%;
    height: fit-content;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  
  .profile_info {
    margin-left: 2rem;
  }
  
  .profile_name {
    color: #000;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 0.5rem;
  }
  
  .profile_about, .profile_location {
    color: #555;
    font-family: Montserrat;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: .3125rem;
  }

  .profile_location {
    font-weight: 400;
    padding-bottom: 0;
  }

  .profile_exp {
    display: flex;
    align-items: center;
    margin: 0.625rem 0;
    color: #555;

    text-align: start;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

  }

  .profile_exp .label {
    font-weight: 500;
  }

  .profile_exp > img {
    width: 1.688rem;
    margin-right: 0.5rem;
    background-color: #ED1E24;
    padding: 4px 6px;
    border-radius: 6px;
  }
  
  .post_Info > p {
    font-size: 12px;
    color: #848484;
  }
  
  .post_header_options {
    color: #b4b4b4;
    order: 2;
    margin-left: auto;
  }
  
  .post_body {
    display: flex;
    overflow-wrap: anywhere;
  }
  
  .post_body > p {
    padding: 17px 26px 0 26px;
    font: normal normal normal 14px/20px Montserrat;
    letter-spacing: 0px;
    color: #707070;
  }
  
  .post_body > img {
    width: 100%;
    height: auto;
  }
  
  .post_body_readMore {
    color: #ED1E24;
    cursor: pointer;
  }
  
  .post_info_userName {
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .post_option {
    display: flex;
    gap: 20px;
    margin-left: 10px;
    padding-top: 10px;
  }
  
  .comment_input {
    /* border: 1px solid lightgray; */
    border-radius: 30px;
    display: flex;
    padding: 10px;
    color: #b4b4b4;
    padding-left: 19px;
    padding-right: 19px;
  }
  
  .comment_avatar {
    /* width: fit-content; */
    width: 45px !important;
    height: 45px !important;
    object-fit: cover;
    border-radius: 100%;
    /* object-fit: cover; */
    /* margin-left: 25px; */
    background: transparent 0% 0% no-repeat padding-box;
    cursor: pointer;
    opacity: 1 !important;
  }
  
  .comment_input_div {
    position: relative;
    display: flex;
    font: normal normal normal 14px/16px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    /* padding-left: 16px; */
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 25px;
    border: none;
    flex: 1;
    margin-left: 10px;
    outline-width: 0;
    font-weight: 600;
    cursor: pointer;
    min-width: 100px;
  }
  
  .comment_input > div > form {
    display: flex;
    width: 100%;
    /* flex-grow: 1; */
  }
  
  .comment_input_div:focus-within {
    border: 1px solid #ED1E24;
  }
  
  .comment_input > div > form > input {
    font: normal normal normal 14px/16px Montserrat;
    letter-spacing: 0px;
    color: #555555;
    padding-left: 16px;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 25px;
    border: none;
    flex: 1;
    /* margin-left: 10px; */
    outline-width: 0;
    font-weight: 600;
    cursor: pointer;
  }
  
  .comments_icons_div {
    display: flex;
    margin: auto;
    gap: 10px;
    padding-right: 15px;
  }
  
  .post_likeCommentBox {
    padding: 17px 26px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    gap: 19px;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #a6a6a6;
  }
  
  .comments_div {
    padding: 30px 30px 30px 25px;
  }
  
  .mostRelevant {
    font: normal normal normal 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
  }
  
  /* --------- boosted Profile ---------- */
  
  .buttons_container {
    margin: auto;
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
  
  .button {
    display: flex;
    padding: 0.5rem 1.25rem;
    border: 1px solid #ED1E24;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.2s;
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #ED1E24;
    text-transform: uppercase;
    text-align: center;
    position: relative;
  }
  
  .button:hover {
    background-color: #ED1E24;
  }
  
  .button:hover p {
    color: #fff;

  }
  
  
  
  /* --------- boosted Profile ---------- */
  
  @media (max-width: 1099px) {
    .Post:last-child {
      margin-bottom: 80px;
    }
  }
  
  @media (max-width: 1099px) {
    .comments_icons_div {
      gap: 2px;
    }
  }
  
  @media (max-width: 768px) {
    .comments_icons_div {
      display: none;
    }
    .post_option {
      gap: 0px;
    }
  }
  @media (max-width: 965px) {
    .post_option {
      gap: 2px;
    }
  }
  .translateText{
    font-weight: 500 !important;
    color: #ED1E24 !important;
    cursor: pointer;
  }
  
  
  @media only screen and (max-width: 600px) {
    
    .buttons_container{
      flex-direction: column;
      gap: 15px;
    }
  }