.main {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.count_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.type {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.total_count {
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
}

.count {
  background: linear-gradient(90deg, rgb(237, 30, 36), rgb(238, 130, 130));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: Montserrat;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: left;
  margin-right: 0.5rem;
}

.pagination_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.data_not_found_image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100%;
}

.active_mode, .inactive_mode {
  font-family: Montserrat;
  font-size: 0.88rem;
  font-weight: 500;
  line-height: 1.06rem;
  letter-spacing: 0%;
  text-align: center;
  padding: 0.2rem;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
}

.active_mode {
  box-sizing: border-box;
  color: rgb(70, 203, 76);
  /* Green */
  border: 0.06rem solid rgb(70, 203, 76);
  border-radius: 1rem;
  background: rgb(226, 249, 227);

}

.inactive_mode {
  color: #ed1f25;
  box-sizing: border-box;
  /* Green */
  border: 0.06rem solid #ed1f25;
  border-radius: 1rem;
  background: #fff5f5;
}

.active_mode:hover {
  background: rgb(70, 203, 76) ;
  color: rgb(226, 249, 227)

}

.inactive_mode:hover {
  background: #ed1f25;
  color: #fff5f5

}

/* --------------- Media Query ------------------ */

@media (max-width: 900px) {
  .type {
    font-size: 1rem;
  }
  .total_count {
    font-size: 0.75rem;
  }
  .count {
    font-size: 1rem;
  }
  .pagination_container {
    margin-bottom: 3.5rem;
  }
}