.main {
  box-sizing: border-box;
}

.middle_container {
  text-align: left;

  background: #ffffff 0% 0% no-repeat padding-box;
  /* height: fit-content; */
  /* padding-top: 0.5rem; */
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  padding: 1.125rem 1.375rem;
}

.bottom_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(238, 238, 238);
  padding: 1rem 1.375rem 0 1.375rem;
  /* width: 100%; */
}

.btn_label {
  font-family: "Montserrat";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: center;
}

.btn_label_cancel {
  font-family: "Montserrat";
  color: rgb(133, 133, 133);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
  cursor: pointer;
}

.tagList {
  background-color: #EFEFEF;
  display: inline-block;
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 600;
  margin: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 0.875rem;
  min-width: 5rem;
  text-align: center;
  cursor: pointer;
}