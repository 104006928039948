.InfoCard{
    box-sizing:border-box;
    width: 100%;
    height: 300px;
    padding:10% 5%;
    background: #000000;
    border-radius: 1rem;
    white-space: normal;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0.06rem 0.06rem 0.5rem 0rem rgba(0, 0, 0, 0.25);
}

.InfoCard > h4{
    font-weight: 700;
    font-size: 24px;
    line-height: 146%;
}

.InfoCard >h6{
    margin-top: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 146%;
}

.InfoCard > p{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 146%;
}

@media only screen and (max-width: 1350px) {
    .InfoCard{
        height: 300px;
        padding:10% 8%;
    }
    
    .InfoCard > h4{
        font-size: 22px;
    }
    
    .InfoCard >h6{
        font-size: 18px;
    }
    
    .InfoCard > p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 950px) {
    .InfoCard{
        width: 100%;
        height: 300px;
        padding:10% 8%;
    }
    
    .InfoCard > h4{
        font-size: 18px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 16px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 14px;
    }
    
}

@media only screen and (max-width: 650px) {
    .InfoCard{
        width: 100%;
        height:250px;
        padding:10% 10%;
    }
    
    .InfoCard > h4{
        font-size: 18px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 16px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {

    .InfoCard{
        width: 100%;
        height:200px;
        padding:8% 8%;
    }
    
    .InfoCard > h4{
        font-size: 17px;
    }
    
    .InfoCard >h6{
        margin-top: 8px;
        font-size: 12px;
        line-height: 146%;
    }
    
    .InfoCard > p{
        margin-top: 8px;
        font-size: 10px;
        -webkit-line-clamp: 3;

    }
}