.settingsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.settingsArea {
  width: 50%;
  padding: 0 150px;
  box-sizing: border-box;
}
.settingsSection {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}
.settingsSection > hr {
  margin: 0;
  border: 1px solid #e5e5e5;
}
.rightImage {
  height: 100vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Gradient-light, linear-gradient(181deg, #EF3F3F -27.79%, #FFF 116.99%));
}
.rightImage > img {
  width: 80%;
  height: auto;
}
.heading {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px;
}
.headingSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.toggle {
  display: flex;
  align-items: center;
}
.accountType {
    padding: 15px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
    cursor: pointer;
}
.accountType:hover {
    color: white;
    background-color: #ED1E24;
}
.accountType {
    padding: 15px;
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
    display: flex;
    justify-content: center;
    align-items: center;
}
.link {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}
.accountType:hover {
    color: white;
    background-color: #ED1E24;
}
.sectionHeading {
  font-size: 20px;
  font-weight: 700;
}
.subHeading {
  font-size: 16px;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: black !important;
}


/* ############################ Responsive 1520 ############################ */
@media only screen and (max-width: 1520px) {
    .settingsArea {
        width: 55%;
        padding: 0 60px;
    }
    .rightImage {
        width: 45%;
    }
  }
  /* ############################ Responsive 1420 ############################ */
  @media only screen and (max-width: 1420px) {
    .settingsArea {
        width: 60%;
        padding: 0 60px;
    }
    .rightImage {
        width: 40%;
    }
  }
    /* ############################ Responsive 1250 ############################ */
    @media only screen and (max-width: 1250px) {

      }
  /* ############################ Responsive 1090 ############################ */
  @media only screen and (max-width: 1090px) {
    .fieldContainer {
        padding: 0 90px;
    }
    .settingsContainer {
        height: 80vh;
        justify-content: center;
    }
    .rightImage {
        display: none;
    }
    .settingsArea { 
        padding: 0 ;
    }
  }
    /* ############################ Responsive 800 ############################ */
    @media only screen and (max-width: 800px) {
        .headingSection {
            display: block;
        }
        .subHeading {
            font-size: 14px;margin-bottom: 10px;
        }
        .settingsArea {
            width: 80%;
        }
        .heading {
            font-size: 30px;
        }
        .details {
          font-size: 16px;
        }
        .subHeading {
          font-weight: 700;
        }
        .link {
          width: 600px;
        }
        .settingsContainer {
          align-items: flex-start;
          margin-top: 30px;
      }
      }
  /* ############################ Responsive 550 ############################ */
  @media only screen and (max-width: 550px) {
    .settingsArea {
        width: 90%;
    }
  }
  
