.container {
  padding-bottom: 2px;
  cursor: pointer;
  background: #eee;
  /* background: -moz-linear-gradient(left, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%);
    background: -webkit-linear-gradient(left, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%);
    background: linear-gradient(to right, #FFFFFF 0%, #8f8e8e 20%, #FFFFFF 100%); */
}

.innerContainer {
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #fff;
  border-left: 3.5px solid transparent;
}

.innerContainer:hover {
  /* background-color: #29daed; */
  background-color: #f5f7f6;
  border-left: 3.5px solid #ED1E24;
}

.innerContainerClicked {
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #ED1E24;
}

.profilePic {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  object-fit: cover;
}

.status {
  position: absolute;
  bottom: 4px;
  right: 4px;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #2ddf20;
  z-index: 5;
}

.profilePicContainer {
  position: relative;
}

.nameContainer {
  display: flex;
  /* flex-direction: column; */
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.name {
  font-weight: 500;
}

.innerNameContainer {
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.lastMessage {
  text-align: left;
  font-weight: 300;
}

.dateContainer {
  order: 2;
  margin-left: auto;
  align-content: flex-start;
  font: normal normal normal 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #555555;
  opacity: 0.7;
}
